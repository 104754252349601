import Cookies from "js-cookie"
import { message } from "antd"

// Flatten the nested structure into a single array
export const flattenRoutesPath = (obj, parentPath = "") => {
  const result = []
  for (const key in obj) {
    if (obj[key].path) {
      const newPath = obj[key].path
      result.push({ path: newPath, theme: obj[key].theme, brand: obj[key].brand })
    } else if (typeof obj[key] === "object") {
      result.push(...flattenRoutesPath(obj[key], parentPath + key))
    }
  }
  return result
}

// Detects dev mode
export const IS_DEV = import.meta.env.MODE === "development" || import.meta.env.MODE === "proxy-dev"

// Functions to display error message from response
export const getMessageFromError = (err) => {
  const errorMessage =
    err.response.data.msg ||
    err.response.data.message ||
    err.response.data.err ||
    err.response.data.error ||
    (Array.isArray(err.response.data.errors) ? err.response.data.errors[0].message : undefined)
  return errorMessage || err.response.data
}

export const handleError = (err, msg) => {
  // get error status code
  const { status } = err.response

  if (status === 500 || status === 503 || status === 404) {
    message.error("An error has occurred, please try again.", 5)
    return
  }

  if (typeof msg === "string") {
    message.error(msg, 5)
    return msg
  }
  if (typeof msg === "object" && msg[status]) {
    const messageToDisplay = typeof msg[status] === "object" ? msg[status] : msg[status]
    message.error(messageToDisplay, 5)
    return messageToDisplay
  }
  const messageToDisplay = getMessageFromError(err)
  message.error(messageToDisplay || "An error has occurred. Please try again.", 5)
  return messageToDisplay
}

export const getMessageFromResponse = (res) => {
  const responseMessage = res.data.msg || res.data.message
  return responseMessage ? responseMessage : undefined
}

export const handleResponse = (res, msg) => {
  const { status } = res
  const responseMessage = getMessageFromResponse(res)
  if (typeof msg === "string") {
    message.success(msg)
  } else if (typeof msg === "object" && msg[status]) {
    message.success(msg[status])
  } else if (responseMessage) {
    message.success(responseMessage)
  }
  return res
}

export const getDiscussionIdCookie = () => {
  const discussionId = Cookies.get("discussionId")

  if (discussionId === null || discussionId === undefined) {
    return null
  }

  return discussionId
}

export const truncateWords = (text, wordLimit) => {
  const words = text.split(" ")
  if (words.length <= wordLimit) {
    return text
  }
  return words.slice(0, wordLimit).join(" ") + "..."
}

export const isObjectNotEmpty = (obj) => Object.keys(obj).length > 0
