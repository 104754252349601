import { ITEMS_MENU_TOPIC } from "@/constants/constants"

const getTopicData = (styleDropdownItem, onNavigateToSubmenu) => {
  const DROPDOWN_PUBLIC_ITEMS = [
    {
      id: 1,
      label: "live activity",
      value: ITEMS_MENU_TOPIC.LIVE_ACTIVITY,
      styleClass: (e) => styleDropdownItem(e),
      onClick: () => onNavigateToSubmenu(ITEMS_MENU_TOPIC.LIVE_ACTIVITY),
    },
    {
      id: 2,
      label: "meeting",
      value: ITEMS_MENU_TOPIC.MEETING,
      styleClass: styleDropdownItem(),
      onClick: () => onNavigateToSubmenu(ITEMS_MENU_TOPIC.MEETING),
    },
    {
      id: 3,
      label: "quality",
      value: ITEMS_MENU_TOPIC.QUALITY,
      styleClass: styleDropdownItem(),
      onClick: () => onNavigateToSubmenu(ITEMS_MENU_TOPIC.QUALITY),
    },
    {
      id: 4,
      label: "projects",
      value: ITEMS_MENU_TOPIC.PROJECTS,
      styleClass: styleDropdownItem(),
      onClick: () => onNavigateToSubmenu(ITEMS_MENU_TOPIC.PROJECTS),
    },
    {
      id: 5,
      label: "refactory",
      value: ITEMS_MENU_TOPIC.REFACTORY,
      styleClass: styleDropdownItem(),
      onClick: () => onNavigateToSubmenu(ITEMS_MENU_TOPIC.REFACTORY),
    },
    {
      id: 6,
      label: "employees",
      value: ITEMS_MENU_TOPIC.EMPLOYEES,
      styleClass: styleDropdownItem(),
      onClick: () => onNavigateToSubmenu(ITEMS_MENU_TOPIC.EMPLOYEES),
    },
  ]
  return { DROPDOWN_PUBLIC_ITEMS }
}

export default getTopicData
