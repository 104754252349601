/**
 * PlanetContext.jsx
 * Manages and provides the current route type state throughout the application
 */

import { createContext, useState } from "react"

const RouteTypeContext = createContext()

const RouteTypeProvider = ({ children }) => {
  const [routeType, setRouteType] = useState("Home")

  return (
    <RouteTypeContext.Provider value={{ routeType, setRouteType }}>
      {children}
    </RouteTypeContext.Provider>
  )
}

export { RouteTypeContext, RouteTypeProvider }
