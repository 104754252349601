import { useAuth } from "@/hooks/onboarding/useAuth"
import "./search-menu.scss"

const SearchMenu = () => {
  const { profile } = useAuth()

  const displayUsername = () => {
    if (profile?.email) {
      return profile?.email.split("@")[0].split("+")[0].split(".")[0]
    }

    return "username"
  }

  return (
    <>
      <span className="search-menu__username">{displayUsername()}</span>
      <ul className="search-menu__menu-list">
        <li>my organization</li>
        <li>my account</li>
        <li>my dressing room</li>
        <li>logout</li>
      </ul>
    </>
  )
}

export default SearchMenu
