import { Spin } from "antd"
import { useMemo, useState } from "react"
import { useSearch } from "@/hooks/interface/useSearch.jsx"
import { MEDIA_TYPES } from "@/constants/constants"
import PdfViewer from "./PdfViewer"
import CustomVideoPlayer from "./CustomVideoPlayer"
import { isObjectNotEmpty } from "@/utils/utils.jsx"
import MEDIA_PDF_BG from "@/assets/images/search/media-pdf-background.jpg"
import MEDIA_VIDEO_BG from "@/assets/images/search/media-video-background.jpg"
import SOURCE_01_BG from "@/assets/images/search/document-de-reference-mobilize-power-v2g---2024.jpg"
import SOURCE_02_BG from "@/assets/images/search/dossier_de_formation_a_distance.jpg"
import SOURCE_03_BG from "@/assets/images/search/essai1_renault5_essayez-moi.jpg"
import SOURCE_04_BG from "@/assets/images/search/essai2_renault5_comparez-moi.jpg"
import SOURCE_05_BG from "@/assets/images/search/opening_plenary_renault5_25june_optim.jpg"
import SOURCE_06_BG from "@/assets/images/search/ws1_renault5_seduisez-moi.jpg"
import SOURCE_07_BG from "@/assets/images/search/ws2_renault5_montrez_moi_votre_experience.jpg"
import PDF from "@/assets/icons/UI/pdf.svg"
import VIDEO_PLAYER from "@/assets/icons/UI/videoPlayer.svg"
import "./search-media.scss"

const SOURCE_IMAGES = [
  { id: "84943", url: SOURCE_02_BG },
  { id: "84944", url: SOURCE_05_BG },
  { id: "84946", url: SOURCE_04_BG },
  { id: "84947", url: SOURCE_07_BG },
  { id: "84951", url: SOURCE_03_BG },
  { id: "84952", url: SOURCE_06_BG },
  { id: "84993", url: SOURCE_01_BG },
]

const SearchMedia = () => {
  const {
    pdfRender,
    setPdfRender,
    videoRender,
    setVideoRender,
    answerWaiting,
    dataResponse,
    mediaCountRef,
    setMediaClicked,
    receivedMediaContent,
  } = useSearch()

  const [loadingMedia, setLoadingMedia] = useState(false)
  const [typeOfMediaSelected, setTypeOfMediaSelected] = useState("")
  const [hideContent, setHideContent] = useState(false)
  const [mediaContent, setMediaContent] = useState({})

  // Handle media count memoization
  useMemo(() => {
    if (dataResponse?.medias && Array.isArray(dataResponse?.medias)) {
      mediaCountRef.current = {}
      const docIdCounts = {}

      dataResponse?.medias?.forEach((media) => {
        const docId = media.doc_id
        const typeOfMedia = media.type

        if (!docIdCounts[docId]) {
          docIdCounts[docId] = true

          if (!mediaCountRef.current[typeOfMedia]) {
            mediaCountRef.current[typeOfMedia] = 0
          }
          mediaCountRef.current[typeOfMedia]++
        }
      })
    }
  }, [dataResponse?.medias, mediaCountRef])

  const uniqueMedia = Object.keys(mediaCountRef.current)

  const hideFirstAndSecondContent = (type) => {
    setTypeOfMediaSelected(type)
    setLoadingMedia(true)
    setHideContent((prev) => !prev)

    setTimeout(() => {
      setLoadingMedia(false)
    }, 2000)
  }

  const displayMedia = (typeOfMedia, media) => {
    if (typeOfMedia === MEDIA_TYPES.PDF) {
      setVideoRender(false)
      setPdfRender(true)
    } else {
      setPdfRender(false)
      setVideoRender(true)
    }

    setMediaContent(media)
    setLoadingMedia(true)

    setTimeout(() => {
      setLoadingMedia(false)
    }, 1000)
  }

  const handleMediaClick = () => {
    // When a media card is clicked, expand the third panel and collapse the others

    setMediaClicked(true)
  }

  const groupBoundingBoxes = (data) => {
    const groupedData = {}

    if (data && Array.isArray(data)) {
      data?.forEach((mediaData) => {
        const { doc_id, type, url, ...rest } = mediaData

        if (type === "pdf" || type === "video") {
          if (!groupedData[doc_id]) {
            groupedData[doc_id] = { doc_id, type, url, ...rest, bounding_box: { areas: {} } }
          }

          if (type === "pdf") {
            const { bounding_box: { areas: { bounding_boxes } } = {} } = mediaData

            if (bounding_boxes) {
              bounding_boxes.forEach((box) => {
                const key = `${doc_id}_${box.page_number}`

                if (groupedData[doc_id].bounding_box.areas) {
                  if (!groupedData[doc_id].bounding_box.areas[key]) {
                    groupedData[doc_id].bounding_box.areas[key] = { bounding_boxes: [] }
                  }

                  groupedData[doc_id].bounding_box.areas[key].bounding_boxes.push(box)
                } else {
                  console.error(`No 'areas' property found for doc_id: ${doc_id}`)
                }
              })
            } else {
              console.error(`No 'bounding_boxes' property found for doc_id: ${doc_id}`)
            }
          }
        }
      })
    }

    return groupedData
  }

  if (!hideContent) {
    /* ******************** Display Media cards on loading ********************* */
    if (answerWaiting) {
      return (
        <>
          <div
            key="video"
            className="search-media__card search-media__card--video breathing-effect"
            style={{
              backgroundImage: `url(${MEDIA_VIDEO_BG})`,
              maxWidth: `calc(100% / ${uniqueMedia.length <= 1 ? 2 : uniqueMedia.length})`,
            }}
          >
            <img src={VIDEO_PLAYER} alt="video player" />
          </div>

          <div
            key="pdf"
            className="search-media__card search-media__card--pdf breathing-effect"
            style={{
              backgroundImage: `url(${MEDIA_PDF_BG})`,
              maxWidth: `calc(100% / ${uniqueMedia.length <= 1 ? 2 : uniqueMedia.length})`,
            }}
          >
            <img src={PDF} alt="pdf" />
          </div>
        </>
      )
    }

    /* ******************** Displays a media card by type after loading ********************* */
    if (uniqueMedia.length > 0 && receivedMediaContent) {
      return uniqueMedia.map((typeOfMedia) => {
        if (typeOfMedia === MEDIA_TYPES.VIDEO) {
          return (
            <div
              key={typeOfMedia}
              className="search-media__card search-media__card--video"
              onClick={() => {
                hideFirstAndSecondContent(typeOfMedia)
                handleMediaClick()
              }}
              style={{
                backgroundImage: `url(${MEDIA_VIDEO_BG})`,
                maxWidth: `calc(100% / ${uniqueMedia.length <= 1 ? 2 : uniqueMedia.length})`,
              }}
            >
              <img src={VIDEO_PLAYER} alt="video player" />
            </div>
          )
        }

        if (typeOfMedia === MEDIA_TYPES.PDF) {
          return (
            <div
              key={typeOfMedia}
              className="search-media__card search-media__card--pdf"
              onClick={() => {
                hideFirstAndSecondContent(typeOfMedia)
                handleMediaClick()
              }}
              style={{
                backgroundImage: `url(${MEDIA_PDF_BG})`,
                maxWidth: `calc(100% / ${uniqueMedia.length <= 1 ? 2 : uniqueMedia.length})`,
              }}
            >
              <img src={PDF} alt="pdf" />
            </div>
          )
        }

        return null
      })
    }
  } else {
    if (loadingMedia) {
      return (
        <div className="search-media__loading">
          <Spin size="large" />
        </div>
      )
    }

    /* ******************** Displays all available media by type after loading ********************* */
    if (receivedMediaContent && !pdfRender && !videoRender) {
      const groupedData = groupBoundingBoxes(dataResponse.medias)

      return Object.values(groupedData).map((media, index) => {
        const typeOfMedia = media.type

        const mediaIsVideo =
          typeOfMedia === MEDIA_TYPES.VIDEO && typeOfMediaSelected === MEDIA_TYPES.VIDEO
        const mediaIsPdf =
          typeOfMedia === MEDIA_TYPES.PDF && typeOfMediaSelected === MEDIA_TYPES.PDF

        const matchedImage = SOURCE_IMAGES.find((image) => image.id === media.doc_id)
        const imageUrl = matchedImage ? matchedImage.url : MEDIA_PDF_BG

        if (mediaIsVideo) {
          return (
            <div
              key={media.doc_id + index}
              className="search-media__card search-media__card--video card-animation"
              onClick={() => displayMedia(typeOfMedia, media)}
              style={{
                backgroundImage: `url(${MEDIA_VIDEO_BG})`,
                maxWidth: `calc(100% / ${uniqueMedia.length <= 1 ? 2 : uniqueMedia.length})`,
              }}
            >
              <img src={VIDEO_PLAYER} alt="video player" />
            </div>
          )
        }

        if (mediaIsPdf) {
          return (
            <div
              key={media.doc_id + index}
              className="search-media__card search-media__card--pdf card-animation"
              onClick={() => displayMedia(typeOfMedia, media)}
              style={{
                backgroundImage: `url(${imageUrl}`,
                maxWidth: `calc(100% / ${uniqueMedia.length <= 1 ? 2 : uniqueMedia.length})`,
              }}
            >
              <img src={PDF} alt="pdf" />
            </div>
          )
        }

        return null
      })
    }

    /* ******************** View the media ********************* */
    if (pdfRender && isObjectNotEmpty(mediaContent)) {
      return <PdfViewer source={mediaContent} />
    }

    if (videoRender && isObjectNotEmpty(mediaContent)) {
      return <CustomVideoPlayer videoSource={mediaContent} />
    }
  }

  return "No media found"
}

export default SearchMedia
