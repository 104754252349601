/* eslint-disable import/no-unresolved */
/**
 * World.js
 *
 * The World class is responsible for managing the overall state and interactions of the 3D environment
 * in the application. It includes the initialization, updating, and disposal of various components
 * such as planets, controls, and background elements. The class serves as a central point for orchestrating
 * animations, handling user interactions, and managing the lifecycle of 3D objects within the scene.
 *
 * Key functionalities include:
 * - Loading and setting up 3D models and assets.
 * - Managing the transition and interactions between different planets and views.
 * - Handling user input and control mechanisms for navigating the 3D world.
 * - Updating the state of the world in each frame of the rendering loop.
 * - Cleaning up and disposing of resources to ensure efficient memory management.
 *
 * Usage of this class is typically found in conjunction with a rendering loop, where it
 * continuously updates the state of the world and its components, and in event handlers
 * where user interactions require changes to the scene or its elements.
 */

import { DirectionalLight, LoadingManager } from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js"
import { createLogger } from "@/utils/debug/index.js"
import { IS_DEV } from "@/utils/utils.jsx"
import Controls, { CONTROL_TYPES } from "@/webgl/world/controls/index.js"
import Experience from "@/webgl/ExperienceManager"
import Planet from "@/webgl/world/components/Planet.js"
import Background from "@/webgl/world/components/Background.js"
import PLANET_RENAULT from "@/assets/webgl/planet_renault.glb?url"
import PLANET_RENAULUTION from "@/assets/webgl/planet_renaulution.glb?url"
import CursorManager from "@/utils/CursorManager.js"

const log = createLogger("World", "#000", "#FF0000").log

export const VIEWS = {
  INTRO: 0,
  RESET: 1,
  ENTERING_ORBIT: 2,
  IN_ORBIT: 3,
  ENTERING_BRAND: 4,
  IN_BRAND: 5,
  ENTERING_CONTINENT: 6,
  IN_CONTINENT: 7,
  ENTERING_EXPERIENCE: 8,
  IN_EXPERIENCE: 9,
  IN_INTERFACE: 10,
  IN_EXPLORATION: 11,
}

export const EXPLORATION = {
  NONE: 0,
  ENTERING_ORBIT: 1,
  IN_ORBIT: 2,
  ENTERING_CONTINENTAL_NEWS: 3,
  IN_CONTINENTAL_NEWS: 4,
  ENTERING_DEPARTMENTS: 5,
  IN_DEPARTMENTS: 6,
  ENTERING_GATES: 7,
  IN_GATES: 8,
}

export const PLANET_TYPE = {
  ENTERING_RENAULT: 0,
  IN_RENAULT: 1,
  ENTERING_RENAULUTION: 2,
  IN_RENAULUTION: 3,
}

export const EXPERIENCE_TYPE = {
  NONE: 0,
  IN_2D_EXPERIENCE: 1,
  IN_3D_EXPERIENCE: 2,
  IN_EXTERNAL_EXPERIENCE: 3,
}

const planetsConfig = {
  currentPlanet: "RENAULT",
  planets: {
    RENAULT: {
      name: "RENAULT",
      core: null,
      isInteractive: true,
      whiteLogosVisible: false,
      planetModelPath: PLANET_RENAULT,
      nonInteractiveBrands: ["rgn", "rgs"],
      brandsWithGates: ["renault", "mobilize", "alpine", "dacia"],
      isVisible: false,
      isGlowing: false,
      isTransitioning: false,
      transitionProgress: 0,
      tranisitionDirection: null,
      brands: {
        rgn: {},
        rgs: {},
        alpine: {},
        dacia: {},
        mobilize: {},
        renault: {},
      },
    },

    RENAULUTION: {
      name: "RENAULUTION",
      core: null,
      isInteractive: false,
      whiteLogosVisible: true,
      planetModelPath: PLANET_RENAULUTION,
      nonInteractiveBrands: ["rgn", "rgs"],
      brandsWithGates: [],
      isVisible: false,
      isGlowing: true,
      isTransitioning: false,
      transitionProgress: 0,
      tranisitionDirection: null,
      brands: {
        rgn: {},
        rgs: {},
        alpine: {},
        ampere: {},
        futureisneutral: {},
        horse: {},
        mobilize: {},
        power: {},
      },
    },
  },
}

export default class World {
  constructor() {
    // Setup
    this.setupBasicProperties()

    // Loaders setup
    this.initLoaders()
  }
  // Setup basic properties and initial state
  setupBasicProperties() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.camera = this.experience.camera
    this.time = this.experience.time

    this.currentView = VIEWS.INTRO
    this.currentPlanetType = PLANET_TYPE.IN_RENAULT
    this.currentExperienceType = EXPERIENCE_TYPE.NONE
    this.currentRoute = null

    this.avatar = null
    this.planets = {}
    this.planetsInitialized = false

    this.activeBrand = null
    this.allowNavigation = false
    this.currentPlanetInstance = null
    this.controls = null
    this.isToggling = false
    this.isTogglingBackward = false
    this.animationInProgress = false
    this.isAnimating = false

    this.currentUserAction = null
    this.interfaceIsVisible = false
    this.searchBarIsVisible = false
    this.recenterCameraButton = document.querySelector(".center-back-to-gates")

    this.zoomLevels = [0, 1, 2, 3]
    this.currentExplorationLevel = 3
    this.experience.currentExplorationLevel("IN-ORBIT")
    this.explorationView = EXPLORATION.IN_ORBIT

    this.lastInteractionType = null
    this.previousRoute = null
    this.selectedDepartment = null

    this.user = {
      brand: "renault",
      department: "design",
    }
  }

  // Initialize loaders for assets
  initLoaders() {
    this.loadingManager = new LoadingManager()
    this.dracoLoader = new DRACOLoader()
    this.dracoLoader.setDecoderPath(
      `${import.meta.env.BASE_URL}${IS_DEV ? "assets/webgl/draco/" : "webgl/draco/"}`,
    )
    this.dracoLoader.setDecoderConfig({ type: "wasm" })

    this.gltfLoader = new GLTFLoader(this.loadingManager)
    this.gltfLoader.setDRACOLoader(this.dracoLoader)
  }

  // Handle interface status updates
  handleInterfaceStatus(avatar, subMenuIsVisible, searchBarIsVisible) {
    this.avatar = avatar
    this.interfaceIsVisible = subMenuIsVisible === "item-visible" ? true : false
    this.searchBarIsVisible = searchBarIsVisible
  }

  // Initializes the world, setting up the camera, planets, controls, background, and lights.
  init() {
    this.initPlanets(planetsConfig)
    this.initControls()
    this.setBackgroundAndLights()
  }

  runCameraIntro() {
    return new Promise((resolve) => {
      try {
        setTimeout(() => {
          this.experience.currentPlanetView("IN-ORBIT")
        }, 1300)
        this.camera.runIntro(
          () => (this.animationInProgress = true),
          () => {
            this.currentView = VIEWS.IN_ORBIT

            this.animationInProgress = false
            setTimeout(() => {
              this.allowNavigation = true
              this.controls.use(CONTROL_TYPES.ORBIT)
            }, 500)
          },
        )

        setTimeout(() => {
          resolve()
        }, 1300)
      } catch (error) {
        console.error("Error occurred during runCameraIntro:", error)
      }
    })
  }

  /**
   * Initializes the planets in the 3D environment.
   * This involves setting up planets based on the provided configuration.
   * @param {Object} planetsConfig - Configuration object for the planets.
   */
  async initPlanets(planetsConfig) {
    const currentPlanetKey = planetsConfig.currentPlanet

    await this.preloadNextPlanet(currentPlanetKey)
    await this.setupActivePlanet(currentPlanetKey)

    this.planetsInitialized = true
    this.currentPlanetInstance = this.planets[currentPlanetKey]
  }

  /**
   * Sets up the active planet based on the given configuration.
   * Chooses the appropriate planet model and initializes it.
   * @param {string} nextPlanetKey - The configuration object for the planet.
   */
  async setupActivePlanet(nextPlanetKey) {
    const planetConfig = planetsConfig.planets[nextPlanetKey]

    if (!planetConfig.model) throw new Error(`Model for planet ${nextPlanetKey} is not loaded`)

    await this.setupIndividualPlanet(planetConfig.model, nextPlanetKey)
  }

  async preloadNextPlanet(currentPlanetKey) {
    const allPlanetKeys = Object.keys(planetsConfig.planets)
    const currentIndex = allPlanetKeys.indexOf(currentPlanetKey)
    const nextIndex = (currentIndex + 1) % allPlanetKeys.length
    const nextPlanetKey = allPlanetKeys[nextIndex]

    planetsConfig.planets[currentPlanetKey].isVisible = true

    await this.setupActivePlanet(nextPlanetKey)
    if (this.planets[nextPlanetKey]) {
      this.planets[nextPlanetKey].setPlanetVisibility(false) // Hide next planet
      planetsConfig.planets[nextPlanetKey].isVisible = false
    }
  }

  /**
   * Sets up an individual planet based on the given model and configuration.
   * It iterates through the scene elements of the planet model and assigns them based on the configuration.
   * @param {Object} planetModel - The 3D model of the planet.
   * @param {string} planetConfigKey - Key identifying the specific planet.
   */

  async setupIndividualPlanet(planetModel, planetConfigKey) {
    const planetConfig = planetsConfig.planets[planetConfigKey]

    planetModel.scene.traverse((element) => {
      const name = element.name.toLowerCase()
      const match =
        /^(continent|logo|safezone|ground|view|territory|outline|department)_(\w+?)(?:_|$)/i.exec(
          name,
        )

      if (!match && !/planet_center/i.test(name)) return

      const [_, type, brandName] = match || []

      switch (type) {
        case "department":
          planetConfig.brands[brandName].departmentsGrounds ||= []
          planetConfig.brands[brandName].departmentsGrounds.push(element)
          break

        case "territory":
          planetConfig.brands[brandName].territories ||= []
          planetConfig.brands[brandName].territories.push(element)

          element.children.forEach((child) => {
            if (child.name.includes("outline")) {
              child.visible = false
            }
          })
          break

        default:
          if (type) {
            planetConfig.brands[brandName][type] = element
          } else {
            planetConfig.core = element
          }
      }
    })

    let planetInstance
    planetInstance = new Planet(planetConfig)

    this.planets[planetConfigKey] = planetInstance

    if (planetInstance) {
      await planetInstance
        .preload()
        .then(() => {
          planetInstance.init()
        })
        .catch((error) => {
          console.error(`Error initializing planet ${planetConfigKey}:`, error)
        })
    }

    this.currentPlanetInstance = planetInstance
  }

  initControls() {
    this.controls = new Controls()
  }

  setBackgroundAndLights() {
    this.background = new Background(planetsConfig)

    const directionalLight1 = new DirectionalLight(0xffffff, 0.1)
    directionalLight1.position.set(15, 10, 20)

    this.scene.add(directionalLight1)

    const directionalLight2 = new DirectionalLight(0xffffff, 1)
    directionalLight2.position.set(-15, 10, -20)
    directionalLight2.castShadow = true
    directionalLight2.shadow.mapSize.width = 2048
    directionalLight2.shadow.mapSize.height = 2048
    directionalLight2.shadow.camera.near = 1
    directionalLight2.shadow.camera.far = 4
    directionalLight2.shadow.radius = 10

    this.scene.add(directionalLight2)
  }

  /**
   * Toggles the visibility of planets and updates the active planet configuration.
   * This method is triggered when the user clicks on the "SWITCH PLANETS" button.
   */
  async togglePlanets() {
    if (!this.planetsInitialized || this.isToggling) return

    const currentPlanetKey = planetsConfig.currentPlanet
    const nextPlanetKey = this.getNextPlanetKey()

    // Ensure the current planet instance matches the configuration
    if (this.currentPlanetInstance.currentPlanetConfig.name !== currentPlanetKey) {
      console.warn(`Current planet instance mismatch. Resetting to: ${currentPlanetKey}`)
      this.currentPlanetInstance = this.planets[currentPlanetKey]
    }

    this.isToggling = true
    const currentPlanetInstance = this.currentPlanetInstance
    currentPlanetInstance.destroyBloom()

    // Rotate the camera 360 degrees to transition to the next planet
    this.camera.rotateCamera360(
      this.isTogglingForward,
      (current) => {
        this.animationInProgress = true
        this.background.updateColors(current, nextPlanetKey) // Update background colors during the rotation
      },
      async () => {
        await this.switchPlanetVisibility(currentPlanetInstance, nextPlanetKey)
        this.updatePlanetEffects()

        log("Switched to", planetsConfig.currentPlanet)
        this.isToggling = false
        this.animationInProgress = false
      },
    )
  }

  /**
   * Determines the next planet key based on the current direction of toggling.
   * @returns {String} The key of the next planet.
   */
  getNextPlanetKey() {
    const currentPlanetKey = planetsConfig.currentPlanet
    const allPlanetKeys = Object.keys(planetsConfig.planets)
    const currentIndex = allPlanetKeys.indexOf(currentPlanetKey)

    // Calculate the next index based on the toggling direction
    const nextIndex = this.isTogglingBackward
      ? (currentIndex - 1 + allPlanetKeys.length) % allPlanetKeys.length
      : (currentIndex + 1) % allPlanetKeys.length

    return allPlanetKeys[nextIndex]
  }

  /**
   * Switches the visibility of the current planet and updates the configuration for the next planet.
   * @param {Object} currentPlanetInstance - The current planet instance.
   * @param {string} nextPlanetKey - The key of the next planet to switch to.
   */
  async switchPlanetVisibility(currentPlanetInstance, nextPlanetKey) {
    currentPlanetInstance.setPlanetVisibility(false)
    currentPlanetInstance.isVisible = false

    await this.updateCurrentPlanet(nextPlanetKey)

    // Show the new planet
    this.currentPlanetInstance.setPlanetVisibility(true)
    this.currentPlanetInstance.isVisible = true
  }

  /**
   * Updates the effects for the current planet based on the toggling direction.
   */
  updatePlanetEffects() {
    if (this.isTogglingForward) {
      this.currentPlanetInstance.setBloom() // Set bloom effect for the new planet
      this.currentPlanetInstance.createPlanetAtmosphere() // Create atmosphere for the new planet
    } else {
      this.currentPlanetInstance.destroyBloom() // Destroy bloom effect if toggling backward
    }

    this.experience.currentPlanet(`IN-${planetsConfig.currentPlanet}`) // Update the current planet status
    this.currentPlanetType = PLANET_TYPE[`IN_${planetsConfig.currentPlanet}`]
  }

  /**
   * Updates the current planet configuration to the new planet.
   * @param {String} nextPlanetKey The key of the next planet to show.
   */
  async updateCurrentPlanet(nextPlanetKey) {
    planetsConfig.currentPlanet = nextPlanetKey
    this.currentPlanetInstance = this.planets[nextPlanetKey]
    this.experience.currentPlanet(`ENTERING-${planetsConfig.currentPlanet}`)
    this.currentPlanetType = PLANET_TYPE[`ENTERING_${planetsConfig.currentPlanet}`]
    this.isTogglingForward = !this.isTogglingForward
  }

  /**
   * Handles changes in navigation routes within the 3D world.
   * This function is called when there is a change in the user's navigation route in WebGL.jsx, and it updates
   * the world's state based on the new route and parameters.
   *
   * @param {string} routeType - The type of the route from UI Interface in React (e.g., 'Home', 'Brand', 'Topic').
   * @param {Object} params - Additional parameters related to the route (e.g., brand or topic identifiers).
   */
  handleRouteChange(routeType, params) {
    // Check if the current planet is non-interactive and return early if so.
    const currentPlanetConfig = planetsConfig.planets[planetsConfig.currentPlanet]

    if (!currentPlanetConfig.isInteractive) {
      console.warn(`Planet ${planetsConfig.currentPlanet} is non-interactive.`)
      return
    }

    this.allowNavigation = true
    this.currentRoute = params

    this.previousRoute = this.currentRoute
    if (!routeType) {
      console.error("Missing necessary route parameters.")
      return
    }

    switch (routeType) {
      case "Home":
        //If not already in the orbit view, transition to it.
        if (this.currentView === VIEWS.IN_EXPERIENCE) {
          this.closeGate(this.activeGate)
        } else if (
          this.currentView !== VIEWS.IN_ORBIT &&
          this.explorationView === EXPLORATION.IN_ORBIT
        ) {
          this.enterOrbit()
        }

        break

      case "Brand":
        break

      case "Department":
        // Check for non-interactive brands and return early if the brand is not interactive.
        if (params.brand in currentPlanetConfig.nonInteractiveBrands) {
          console.warn("Non-interactive brand")
          return
        }

        // Transition to the department view if currently in orbit, or close the current gate if in a experience view.
        if (this.currentView === VIEWS.IN_ORBIT && this.lastInteractionType === "click") {
          this.enterDepartment(params.brand, params.department)
        } else if (this.currentView === VIEWS.IN_EXPERIENCE) {
          this.closeGate(this.activeGate)
        }
        break

      case "Experience":
        if (this.currentView === VIEWS.IN_ORBIT) {
          // Transition to a specific experience within a department.
          this.enterDepartment(params.brand, params.department)
        } else if (this.currentView === VIEWS.IN_CONTINENT) {
          this.openGate(params.experience)
        }

        break

      default:
        return null
    }
  }

  /**
   * Transitions the view to orbit around the planet.
   * This function triggers an animation that moves the camera to an orbital view of the planet.
   * It handles the visibility of various elements like logos and gates during this transition.
   * @returns {Promise} A promise that resolves when the transition to orbit view is complete.
   */
  enterOrbit() {
    if (this.currentView !== VIEWS.IN_CONTINENT) return Promise.resolve()

    this.currentExplorationLevel = 3

    return new Promise((resolve) => {
      try {
        log("Entering orbit")
        this.experience.currentPlanetView("ENTERING-ORBIT")

        const continent = this.currentPlanetInstance.continent.base

        // Hide active department label
        this.currentPlanetInstance.activeDepartment.mainLabel.element.firstChild.style.transform = `scale(0)`

        // Disable controls and show core
        this.activeBrand = null
        this.controls.use(CONTROL_TYPES.NONE)
        this.currentPlanetInstance.showCore()
        this.background.resetColors()

        // Set state to entering orbit
        this.currentView = VIEWS.ENTERING_ORBIT
        this.experience.currentExplorationLevel("IN-ORBIT")
        this.explorationView = EXPLORATION.IN_ORBIT

        // Start the camera transition
        this.camera.enterOrbit(
          (current) => {
            try {
              // Start the gates animation
              if (this.currentPlanetInstance.activeDepartment) {
                this.currentPlanetInstance.activeDepartment.gate.animateGates(
                  "out",
                  false,
                  current,
                  0,
                  true,
                  true,
                  () => {
                    // Once gates animation is complete
                    this.camera.resetPlanet(() => {
                      this.controls.use(CONTROL_TYPES.ORBIT)
                      this.experience.currentPlanetView("IN-ORBIT")
                      this.currentView = VIEWS.IN_ORBIT
                      this.animationInProgress = false
                      this.experience.currentExplorationLevel("IN-ORBIT")
                      this.explorationView = EXPLORATION.IN_ORBIT
                      resolve()
                    })
                  },
                )
              } else {
                // If no gates to animate, complete the transition
                this.camera.resetPlanet(() => {
                  this.controls.use(CONTROL_TYPES.ORBIT)
                  this.experience.currentPlanetView("IN-ORBIT")
                  this.currentView = VIEWS.IN_ORBIT
                  this.animationInProgress = false
                  this.experience.currentExplorationLevel("IN-ORBIT")
                  this.explorationView = EXPLORATION.IN_ORBIT
                  resolve()
                })
              }

              // Animate orbit transition
              this.animateOrbitTransition(current, continent)
              this.animationInProgress = true

              // Hide department labels
              continent.departmentLabels.forEach((value) => {
                if (value.element.firstChild.style.opacity > 0) {
                  value.element.firstChild.style.opacity = `0`
                  value.element.firstChild.style.transform = `scale(0)`
                }
              })
            } catch (error) {
              console.error("Error in orbit transition animation:", error)
              resolve() // Ensure promise resolves even if there's an error
            }
          },
          () => {
            // Optional callback if you need to manage specific completion tasks
          },
        )
      } catch (error) {
        console.error("Error occurred during enterOrbit:", error)
        resolve() // Ensure promise resolves even if there's an error
      }
    })
  }

  /**
   * Animates the opacity of logos and gates during the transition to orbit view.
   * @param {number} current - The current progress of the orbit animation.
   * @param {Object} brand - The currently active brand.
   */
  animateOrbitTransition(current, brand) {
    const sooner = Math.min(1, current / 0.05)

    if (brand && brand.gates) {
      brand.gates.setOpacity(1 - sooner)
      brand.gates.labels.setOpacity(1 - sooner)
    }
  }

  /**
   * Enters exploration mode based on the current zoom level and direction.
   * @param {number} deltaY - The delta value of the mouse scroll.
   */
  enterExploration(direction, reset = false) {
    if (this.currentView !== VIEWS.IN_ORBIT && this.currentView !== VIEWS.IN_CONTINENT) {
      console.warn("You can only enter an continent from the homepage")
      return
    }

    if (this.isAnimating) {
      console.warn("Animation is already occuring")
      return
    }

    let newZoomLevel = this.currentExplorationLevel

    if (reset && this.currentView !== VIEWS.IN_CONTINENT && this.currentExplorationLevel !== 3) {
      newZoomLevel = 3
      this.currentExplorationLevel = 3
    } else {
      newZoomLevel = this.currentExplorationLevel + direction
    }

    if (newZoomLevel < 0 || newZoomLevel >= this.zoomLevels.length) {
      this.isAnimating = false
      return
    }

    this.controls.cancelAutorotate()
    const brandName = this.user.brand

    const continent = this.currentPlanetInstance.base.children.find((cont) =>
      cont.name.includes(`continent-${brandName}`),
    )

    const continents = []
    this.currentPlanetInstance.base.children.forEach((continent) => {
      if (
        continent.name.includes("continent") &&
        this.currentPlanetInstance.currentPlanetConfig.brandsWithGates.includes(
          continent.name.split("-")[1],
        )
      ) {
        continents.push(continent)
      }
    })

    if (!continent) {
      this.isAnimating = false
      console.warn("Continent does not exit")
      return
    }

    let newsLabels
    continents.forEach((continent) => {
      newsLabels = continent.continentalNews
      Object.values(newsLabels).forEach((value) => (value.visible = true))
      continent.departmentLabels.forEach((value) => (value.visible = true))
    })

    this.activeBrand = brandName

    this.lastInteractionType = "scroll"
    const previousZoomLevel = this.currentExplorationLevel
    this.currentExplorationLevel = newZoomLevel

    if (!this.avatar) continent.defaultDepartment = null
    // If there's no defaultDepartment and zoom level is greater than 1, return
    if (!this.avatar && this.currentExplorationLevel < 1) {
      this.isAnimating = false

      console.warn("Default department does not exist. Stopping zoom at level 1.")
      return
    }

    this.camera.enterExploration(
      continent,
      this.currentExplorationLevel,
      () => {
        this.handleExplorationOnStart(brandName, continent)
      },
      (progress) =>
        this.handleExplorationOnProgress(continents, previousZoomLevel, progress, newsLabels),
      () => this.handleExplorationOnComplete(continents, continent, brandName, newsLabels),
      false,
    )
  }

  /**
   * Handles the start of the exploration transition.
   * @param {string} brandName - The name of the brand.
   */
  handleExplorationOnStart(brandName, continent) {
    const target = continent.selectedDepartment
      ? continent.selectedDepartment
      : continent.defaultDepartment

    // Progressively hides any news that is visible
    Object.values(continent.continentalNews).forEach((value) => {
      // Removes perspective classes
      value.element.firstChild.classList.remove("first-plan", "second-plan", "third-plan")
    })

    switch (this.currentExplorationLevel) {
      case 3: // In Orbit - Planet view
      case 2: // In Continental News
      case 1: // In Departments
        this.routeTo("/")
        break

      case 0: // In Gates
        this.routeTo(`${brandName}/${target.name.split("_")[1].toLowerCase()}`)
        this.currentPlanetInstance.activeDepartment = target
        break
      default:
        console.error("Invalid zoom level detected.")
        break
    }
  }

  /**
   * Handles the progress of the exploration transition.
   * @param {Object3D} continent - The continent object.
   * @param {number} previousZoomLevel - The previous zoom level.
   * @param {number} progress - The current progress of the transition.
   * @param {Object} newsLabels - The news labels object.
   */
  handleExplorationOnProgress(continents, previousZoomLevel, progress, newsLabels) {
    this.isAnimating = true

    const opacity = 1 - progress

    continents.forEach((continent) => {
      // Progressively hides any news that is visible
      Object.values(continent.continentalNews).forEach((value) => {
        // Removes perspective classes
        value.element.firstChild.classList.remove("first-plan", "second-plan", "third-plan")
      })
    })
    switch (this.currentExplorationLevel) {
      case 3: // In Orbit - Planet view
        // Reset states
        this.experience.currentExplorationLevel("ENTERING-ORBIT")
        this.explorationView = EXPLORATION.ENTERING_ORBIT

        // Handles news cards and department labels visibility
        continents.forEach((continent) => {
          // Progressively hides any news that is visible
          Object.values(continent.continentalNews).forEach((value) => {
            // Removes perspective classes
            value.element.firstChild.classList.remove("first-plan", "second-plan", "third-plan")

            if (value.element.firstChild.style.opacity > 0) {
              value.element.firstChild.style.opacity = opacity
              value.element.firstChild.style.transform = `scale(${opacity})`
              value.element.firstChild.style.pointerEvent = "none"
            }
          })

          // Progressively hides any department label that is visible
          continent.departmentLabels.forEach((value) => {
            value.element.firstChild.classList.remove("first-plan", "second-plan", "third-plan")

            if (value.element.firstChild.style.opacity > 0) {
              value.element.firstChild.style.opacity = opacity
              value.element.firstChild.style.transform = `scale(${opacity})`
              value.element.firstChild.style.pointerEvent = "none !important"
            }
          })
        })

        break
      case 2: // In Continental News
        // Reset states
        this.experience.currentExplorationLevel("ENTERING-CONTINENTAL-NEWS")
        this.explorationView = EXPLORATION.ENTERING_CONTINENTAL_NEWS

        // Progressively shows news cards of the default continent
        Object.values(newsLabels).forEach((value, index) => {
          const delay = index * 200
          setTimeout(
            () => {
              if (value.element.firstChild.style.opacity !== 1) {
                value.element.firstChild.style.opacity = progress
                value.element.firstChild.style.transform = `scale(${progress})`
              }
            },
            previousZoomLevel < this.currentExplorationLevel ? 0 : delay,
          )
        })

        // Progressively hides departments labels
        continents.forEach((continent) => {
          continent.departmentLabels.forEach((value) => {
            if (value.element.firstChild.style.opacity > 0) {
              value.element.firstChild.style.opacity = opacity
              value.element.firstChild.style.transform = `scale(${opacity})`
              value.element.firstChild.style.pointerEvent = "none !important"
            }
          })
        })

        break
      case 1: // In Departments
        // Reset states
        this.experience.currentExplorationLevel("ENTERING-DEPARTMENTS")
        this.explorationView = EXPLORATION.ENTERING_DEPARTMENTS

        // Progressively hides any news that is visible
        continents.forEach((continent) => {
          Object.values(continent.continentalNews).forEach((value) => {
            // Removes perspective classes
            value.element.firstChild.classList.remove("first-plan", "second-plan", "third-plan")

            if (value.element.firstChild.style.opacity > 0) {
              value.element.firstChild.style.opacity = opacity
              value.element.firstChild.style.transform = `scale(${opacity})`
              value.element.firstChild.style.transformOrigin = "center"
              value.element.firstChild.style.pointerEvent = "none"
            }
          })
        })

        // Makes gates invisible
        if (
          previousZoomLevel < this.currentExplorationLevel &&
          this.currentPlanetInstance.activeDepartment
        ) {
          this.currentPlanetInstance.activeDepartment.gate.animateGates(
            "out",
            false,
            progress,
            0,
            true,
            true,
            () => {
              this.currentPlanetInstance.activeDepartment.children.forEach((gate) => {
                if (!gate.isGate) return
                gate.visible = false
              })
            },
          )
        }

        break
      case 0: // In Gates
        // Reset states
        this.experience.currentPlanetView("ENTERING-CONTINENT")
        this.currentView = VIEWS.ENTERING_CONTINENT
        this.experience.currentExplorationLevel("ENTERING-GATES")
        this.explorationView = EXPLORATION.ENTERING_GATES

        // TEST
        // setTimeout(() => {
        //   this.currentPlanetInstance.activeDepartment.mainLabel.element.firstChild.style.opacity =
        //     opacity
        //   this.currentPlanetInstance.activeDepartment.mainLabel.element.firstChild.style.transform = `scale(9)`
        // }, 550)

        if (
          this.currentPlanetInstance.activeDepartment.mainLabel.element.firstChild.style.opacity > 0
        ) {
          this.currentPlanetInstance.activeDepartment.mainLabel.element.firstChild.style.opacity =
            opacity
          this.currentPlanetInstance.activeDepartment.mainLabel.element.firstChild.style.transform = `scale(${progress * 6})`
        }

        // Progressively hides department labels
        continents.forEach((continent) => {
          continent.departmentLabels.forEach((value) => {
            // if (value !== this.currentPlanetInstance.activeDepartment.mainLabel) {
            if (value !== this.currentPlanetInstance.activeDepartment.mainLabel) {
              if (value.element.firstChild.style.opacity > 0) {
                // Hide and scale other labels
                value.element.firstChild.style.opacity = 0
                // Uncomment and adjust if you want to scale down non-active labels too
                // value.element.firstChild.style.transform = `scale(${progress * 13})`;
              }
            }
            // }
          })
        })

        // Makes gates visible
        this.currentPlanetInstance.activeDepartment.gate.animateGates(
          "in",
          false,
          progress,
          1,
          true,
          true,
        )
        break
      default:
        console.error("Invalid zoom level detected.")
        break
    }
  }

  /**
   * Handles the completion of the exploration transition.
   * @param {Object3D} continent - The continent object.
   * @param {string} brandName - The name of the brand.
   * @param {Object} newsLabels - The news labels object.
   */
  handleExplorationOnComplete(continents, continent, brandName) {
    switch (this.currentExplorationLevel) {
      case 3: // In Orbit - Planet View
        // Reset states
        this.experience.currentExplorationLevel("IN-ORBIT")
        this.explorationView = EXPLORATION.IN_ORBIT
        this.controls.use(CONTROL_TYPES.ORBIT)
        this.camera.resetPlanet(() => {
          this.currentExplorationLevel = 3
          this.isAnimating = false
        })

        // Makes all news cards and departments labels invisible, therefore not rendered
        continents.forEach((continent) => {
          Object.values(continent.continentalNews).forEach((value) => {
            value.visible = false
          })

          continent.departmentLabels.forEach((value) => {
            value.visible = false
          })
        })

        break
      case 2: // In Continental News
        // Reset states
        this.experience.currentExplorationLevel("IN-CONTINENTAL-NEWS")
        this.explorationView = EXPLORATION.IN_CONTINENTAL_NEWS
        this.controls.use(CONTROL_TYPES.ORBIT)
        this.currentExplorationLevel = 2
        this.currentPlanetInstance.activeDepartment = continent.defaultDepartment

        // Makes all departments labels invisible, therefore not rendered
        continents.forEach((continent) => {
          continent.departmentLabels.forEach((value) => {
            value.visible = false
          })
        })
        this.isAnimating = false
        break
      case 1: // In Departments
        // Reset states
        this.experience.currentPlanetView("IN-ORBIT")
        this.currentView = VIEWS.IN_ORBIT
        this.experience.currentExplorationLevel("IN-DEPARTMENTS")
        this.explorationView = EXPLORATION.IN_DEPARTMENTS
        this.controls.use(CONTROL_TYPES.ORBIT)
        this.controls.orbit.enable()
        this.currentExplorationLevel = 1

        this.currentPlanetInstance.activeDepartment = continent.defaultDepartment

        // Makes all news cards invisible, therefore not rendered
        continents.forEach((continent) => {
          Object.values(continent.continentalNews).forEach((value) => {
            value.visible = false
          })
        })
        this.isAnimating = false
        break
      case 0: // In Gates
        // Reset states
        this.experience.currentPlanetView("IN-CONTINENT")
        this.currentView = VIEWS.IN_CONTINENT
        this.experience.currentExplorationLevel("IN-GATES")
        this.explorationView = EXPLORATION.IN_GATES
        this.currentExplorationLevel = 0
        this.currentPlanetInstance.currentBrand = brandName
        this.controls.use(
          CONTROL_TYPES.PAN,
          this.currentPlanetInstance.activeDepartment
            ? this.currentPlanetInstance.activeDepartment
            : null,
        )
        // Makes all news cards and departments labels invisible, therefore not rendered
        continents.forEach((continent) => {
          Object.values(continent.continentalNews).forEach((value) => {
            value.visible = false
          })

          continents.forEach((continent) => {
            continent.departmentLabels.forEach((value) => {
              if (value !== this.currentPlanetInstance.activeDepartment.mainLabel) {
                value.element.firstChild.style.transform = `scale(${0})`
              }
            })
          })
        })
        this.isAnimating = false
        break
      default:
        console.error("Invalid zoom level detected.")
        this.isAnimating = false
        break
    }

    // Update the previousZoomLevel after all actions are completed
    this.previousZoomLevel = this.currentExplorationLevel
  }

  enterDepartment(brandName, departmentName) {
    this.lastInteractionType = "click"
    if (this.currentView !== VIEWS.IN_ORBIT) {
      console.warn("You can only enter a department from the homepage or in exploration view")
      return
    }

    const continent = this.currentPlanetInstance.base.children.find((cont) =>
      cont.name.includes(`continent-${brandName}`),
    )

    continent.departmentLabels.forEach((value) => {
      value.element.firstChild.classList.remove("first-plan", "second-plan", "third-plan")
    })

    if (continent) {
      const departmentsContainer = continent.children.find((child) =>
        child.name.includes(`departments-${brandName}`),
      )

      if (departmentsContainer) {
        const department = departmentsContainer.children.find((dept) =>
          dept.name.includes(departmentName),
        )

        if (this.currentExplorationLevel === 1 || this.currentExplorationLevel === 3) {
          if (department) {
            this.currentPlanetInstance.activeDepartment = department
          } else {
            console.error(`Department ${departmentName} not found in departments-${brandName}`)
          }
        } else if (
          this.currentExplorationLevel === EXPLORATION.IN_ORBIT ||
          this.currentExplorationLevel === EXPLORATION.IN_CONTINENTAL_NEWS
        ) {
          this.currentPlanetInstance.activeDepartment = continent.defaultDepartment
        }
      } else {
        console.error(`Departments container for ${brandName} not found`)
      }
    } else {
      console.error(`Continent ${brandName} not found`)
    }

    log("Entering", this.currentPlanetInstance.activeDepartment.name.toUpperCase())
    this.experience.currentPlanetView("ENTERING-CONTINENT")
    this.currentView = VIEWS.ENTERING_CONTINENT
    this.activeDepartment = this.currentPlanetInstance.activeDepartment.name
    this.currentPlanetInstance.currentBrand = brandName
    this.controls.use(CONTROL_TYPES.NONE)

    continent.departmentLabels.forEach((value) => {
      value.element.firstChild.classList.remove("first-plan", "second-plan", "third-plan")
    })

    this.camera.enterExploration(
      this.currentPlanetInstance.activeDepartment,
      0,
      () => {},
      (progress) => this.handleEnterDepartmentProgress(progress, continent),
      () => this.handleEnterDepartmentComplete(continent),
      true,
    )
  }

  handleEnterDepartmentProgress(progress, continent) {
    this.experience.currentPlanetView("ENTERING-CONTINENT")
    this.currentView = VIEWS.ENTERING_CONTINENT
    this.experience.currentExplorationLevel("ENTERING-GATES")
    this.explorationView = EXPLORATION.ENTERING_GATES

    Object.values(continent.continentalNews).forEach((value) => {
      value.element.firstChild.style.opacity = 0
    })

    if (
      this.currentPlanetInstance.activeDepartment.mainLabel.element.firstChild.style.opacity > 0
    ) {
      this.currentPlanetInstance.activeDepartment.mainLabel.element.firstChild.style.opacity =
        1 - progress
      this.currentPlanetInstance.activeDepartment.mainLabel.element.firstChild.style.transform = `scale(${progress * 6})`
    }

    continent.departmentLabels.forEach((value) => {
      if (value !== this.currentPlanetInstance.activeDepartment.mainLabel) {
        if (value.element.firstChild.style.opacity > 0) {
          value.element.firstChild.style.opacity = 0
        }
      }
    })

    if (this.currentPlanetInstance.activeDepartment) {
      this.currentPlanetInstance.activeDepartment.gate.animateGates(
        "in",
        false,
        progress,
        1,
        true,
        true,
      )
    }

    this.animationInProgress = true
  }

  handleEnterDepartmentComplete(continent) {
    this.animationInProgress = false

    if (this.currentPlanetInstance.activeDepartment) {
      this.controls.use(
        CONTROL_TYPES.PAN,
        this.currentPlanetInstance.activeDepartment
          ? this.currentPlanetInstance.activeDepartment
          : null,
      )
    } else {
      this.controls.use(CONTROL_TYPES.NONE)
      CursorManager.setCursor("auto")
    }
    this.experience.currentPlanetView("IN-CONTINENT")
    this.currentView = VIEWS.IN_CONTINENT
    this.experience.currentExplorationLevel("IN-GATES")
    this.explorationView = EXPLORATION.IN_GATES
    this.currentExplorationLevel = 0
    Object.values(continent.continentalNews).forEach((value) => (value.visible = false))
    continent.departmentLabels.forEach((value) => {
      if (value !== this.currentPlanetInstance.activeDepartment.mainLabel) {
        value.element.firstChild.style.transform = `scale(${0})`
      }
    })
  }

  /**
   * Animates the transition to a brand-specific view.
   * @param {number} current - The current progress of the brand animation.
   * @param {Object} brand - The brand object with relevant properties and methods.
   */
  animateDepartmentTransition(current, department) {
    const latter = Math.max(0, (current - 0.95) * (1 / 0.05))

    if (department.gate) {
      department.gate.setOpacity(latter)
    }
  }

  /**
   * Handles the selection of a gate by the user.
   * This function is triggered when a user interacts with a gate within the 3D world.
   * It navigates to a specific path based on the selected gate.
   *
   * @param {string} url - The url of the gate that has been selected.
   */
  selectGate(url) {
    const currentPlanetConfig = planetsConfig.planets[planetsConfig.currentPlanet]

    if (this.currentView !== VIEWS.IN_CONTINENT || !currentPlanetConfig.isInteractive) {
      console.warn("You can select a gate only inside a continent and inside an interactive planet")
      return
    }

    try {
      if (typeof url !== "string") {
        throw new Error(`Invalid gate url: ${url}`)
      }

      // Construct and navigate to the path corresponding to the selected gate.
      const path = `/${this.currentPlanetInstance.currentBrand}/${this.currentPlanetInstance.activeDepartment.name.split("_")[1]}/${url}`
      // const path = `/${this.currentPlanetInstance.activeDepartment.name}/${url}`

      this.routeTo(path)
    } catch (error) {
      console.error("Error occurred in selectGate:", error)
      this.routeTo("/")
    }
  }

  /**
   * Navigates to a specified path within the 3D world.
   * This method dispatches a custom 'routeChange' event to handle the navigation.
   *
   * @param {string} path - The navigation path to route to.
   */
  routeTo(path) {
    if (!path) {
      console.error("There is no path to go to")
      return
    }
    try {
      if (typeof path !== "string" || path.trim() === "") {
        throw new Error("Invalid navigation path")
      }

      if (this.allowNavigation) {
        // Creating and dispatching a custom 'routeChange' event with the path detail.
        this.event = new CustomEvent("routeChange", { detail: { path: `${path}` } })
        window.dispatchEvent(this.event)
      }
    } catch (error) {
      console.error("Error in routeTo method:", error)
    }
  }

  /**
   * Opens a specific gate within a department.
   * This function is called when the user selects a gate in the department-specific area.
   * It triggers a camera animation to focus on the selected gate and changes the current view to the experience.
   *
   * @param {string} gateUrl - The url of the gate to open.
   * @returns {Promise} A promise that resolves when the gate opening animation is complete.
   */
  openGate(gateUrl) {
    if (this.currentView !== VIEWS.IN_CONTINENT) {
      console.warn("You can open a gate only inside a continent")
      return
    }

    return new Promise((resolve) => {
      try {
        this.currentView = VIEWS.ENTERING_EXPERIENCE
        this.activeGate = gateUrl

        let foundGate = null
        let foundSubItem = null

        this.currentPlanetInstance.activeDepartment?.children.find((child) => {
          if (child.hasSubmenu) {
            const subItem = child.submenuItems.find((subItem) => subItem.url === gateUrl)
            if (subItem) {
              foundGate = child // Set the gate (parent)
              foundSubItem = subItem // Set the subItem
              return true // Indicate that the gate was found
            }
          } else if (child.url === gateUrl) {
            foundGate = child // Set the gate when no submenu
            return true // Indicate that the gate was found
          }
          return false // Continue searching if no match found
        })

        // Ensure the gate is found before proceeding
        if (!foundGate) {
          console.warn("Gate not found:", gateUrl)
          resolve()
          return
        }

        this.controls.use(CONTROL_TYPES.NONE)

        // Proceed with the gate opening, passing the gate and subItem if available
        this.animateGateOpening(foundGate, foundSubItem, resolve)
      } catch (error) {
        console.error("Error occurred in openGate:", error)
        resolve() // Resolve the promise even in case of error to avoid hanging promises.
      }
    })
  }

  /**
   * Handles the animation logic for opening a gate.
   * @param {Object} gate - The gate object to be animated.
   * @param {Function} resolve - The resolve function of the Promise in openGate method.
   */
  animateGateOpening(gate, subItem, resolve) {
    try {
      // Ensure the department and gate objects are valid before proceeding
      if (
        this.currentPlanetInstance.activeDepartment &&
        this.currentPlanetInstance.activeDepartment.gate
      ) {
        this.currentPlanetInstance.activeDepartment.gate.openGate(gate, subItem)
        log("Opening gate", gate.name.toUpperCase())
      } else {
        console.warn("Active department or gate is not defined")
      }

      this.currentView = VIEWS.IN_EXPERIENCE

      resolve() // Resolve the promise when the animation is complete
    } catch (error) {
      console.error("Error in gate opening animation:", error)
      resolve()
    }
  }

  /**
   * Closes a specific gate within a brand view.
   * This function is called when moving away from a gate-specific view to a more general brand view.
   * It triggers a camera animation to zoom out from the gate and updates the current view.
   *
   * @param {string} gateUrl- The url of the gate.
   * @returns {Promise} A promise that resolves when the gate closing animation is complete.
   */
  closeGate(gateUrl) {
    if (this.currentView !== VIEWS.IN_EXPERIENCE) {
      console.warn("You can close a gate only inside an experience")
      return
    }

    return new Promise((resolve) => {
      try {
        this.currentView = VIEWS.ENTERING_CONTINENT

        const gate = this.currentPlanetInstance.activeDepartment?.children.find((child) => {
          if (child.hasSubmenu) {
            return child.submenuItems.some((subItem) => subItem.url === gateUrl)
          } else {
            return child.url === gateUrl
          }
        })

        // Ensure the gate is found before proceeding
        if (!gate) {
          console.warn("Gate not found:", gateUrl)
          resolve()
          return
        }

        this.controls.use(CONTROL_TYPES.NONE)

        this.animateGateClosing(gate, resolve)
      } catch (error) {
        console.error("Error occurred in closeGate:", error)
        resolve() // Resolve the promise even in case of error to avoid hanging promises.
      }
    })
  }

  /**
   * Handles the animation logic for closing a gate.
   * @param {Object} gate - The gate object.
   * @param {Function} resolve - The resolve function of the Promise in closeGate method.
   */
  animateGateClosing(gate, resolve) {
    try {
      // Ensure the department and gate objects are valid before proceeding
      if (
        this.currentPlanetInstance.activeDepartment &&
        this.currentPlanetInstance.activeDepartment.gate
      ) {
        this.currentPlanetInstance.activeDepartment.gate.closeGate(gate)
        log("Closing gate", gate.name.toUpperCase())
      } else {
        console.warn("Active department or gate is not defined")
      }

      this.controls.use(
        CONTROL_TYPES.PAN,
        this.currentPlanetInstance.activeDepartment
          ? this.currentPlanetInstance.activeDepartment
          : null,
      )

      this.currentView = VIEWS.IN_CONTINENT
      resolve() // Resolve the promise when the animation is complete
    } catch (error) {
      console.error("Error in gate closing animation:", error)
      resolve()
    }
  }

  async preload() {
    log("Preload assets")

    try {
      for (const [planetKey, planetConfig] of Object.entries(planetsConfig.planets)) {
        // Load the planet model
        const planetModel = await this.gltfLoader.loadAsync(planetConfig.planetModelPath)
        planetsConfig.planets[planetKey].model = planetModel
      }
    } catch (error) {
      console.error("Error loading models:", error)

      // If the error is related to Draco decoding, get the fallback
      if (error.message.includes("draco")) {
        console.warn("Trying to fall back to Draco JS decoder.")
        this.dracoLoader.setDecoderPath(
          `${import.meta.env.BASE_URL}${IS_DEV ? "assets/webgl/draco/" : "webgl/draco/"}`,
        )
        this.dracoLoader.setDecoderConfig({ type: "js" })
      }
    }
  }

  /**
   * Sets up loading progress and completion callbacks for the LoadingManager.
   *
   * @param {Function} onProgress - Callback function for loading progress.
   * @param {Function} onLoad - Callback function called when loading is complete.
   */
  setLoading(onProgress, onLoad) {
    if (typeof onProgress !== "function") {
      console.error("onProgress must be a function")
      return
    }

    if (typeof onLoad !== "function") {
      console.error("onLoad must be a function")
      return
    }

    this.loadingManager.onProgress = (item, loaded, total) => {
      const progress = Math.round((loaded / total) * 100)

      onProgress(progress)
    }

    this.loadingManager.onLoad = async () => {
      try {
        await this.runCameraIntro()

        onLoad()
      } catch (error) {
        console.error("Error occurred during camera intro:", error)
      }
    }

    this.loadingManager.onError = (url) => {
      console.error("There was an error loading " + url)
    }
  }

  pause() {
    if (this.controls) {
      this.controls.orbit.disable()
      this.controls.gatesPanner.disable()
    }
  }

  resume() {
    if (this.controls) {
      this.controls?.orbit?.enable()
      this.controls.gatesPanner.enable()
    }
  }

  /**
   * Regularly updates the state of the world, including planets, controls, and background.
   * This method is typically called within the rendering loop.
   */
  update() {
    if (this.currentPlanetInstance) {
      this.currentPlanetInstance.update()
    }

    if (this.controls) {
      this.controls.update()
      this.currentUserAction = this.controls.currentUserAction
      this.camera.getWorldInfos(this.controls, this.currentView, this.currentRoute)
    }
  }

  /**
   * Cleans up resources when they are no longer needed.
   * This method helps prevent memory leaks and ensures efficient resource management.
   */
  dispose() {
    if (this.controls) {
      this.controls.orbit?.dispose()
    }

    if (this.background) {
      this.background.dispose()
    }

    if (this.currentPlanetInstance) {
      this.currentPlanetInstance.dispose()
    }
  }
}
