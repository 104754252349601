precision mediump float;

uniform float opacity;

varying vec3 vNormal;


void main() {
	float intensity = pow(0.25 - dot(vNormal, vec3(0, 0, 1.0)), 2.0);
	gl_FragColor = vec4(0.1, 0.6, 1.0, opacity) * intensity;
}