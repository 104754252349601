/**
 * RoomsContext.jsx
 * Authentification process
 * States : logged, consented, profile
 */

import { createContext, useCallback, useEffect, useState } from "react"
import { useSDK } from "@/hooks/metaverse/useSDK"
import { useAuth } from "@/hooks/onboarding/useAuth"
import { useHub } from "@/hooks/metaverse/useHub"
import { useAvatarSetup } from "@/hooks/onboarding/useAvatarSetup"
import { useClaimSetup } from "@/hooks/onboarding/useClaimSetup.jsx"
import { createLogger } from "@/utils/debug/log.js"

const log = createLogger("POWER.XYZ", "#FFF", "#000").log

const RoomsContext = createContext(null)

const RoomsProvider = ({ children }) => {
  const SDK = useSDK()
  const { profile, getUserCollectibles, getProfile } = useAuth()
  const { launchAvatarSetup } = useAvatarSetup()
  const { displayCollectibleMint, setModalClaimVisible } = useClaimSetup()
  const { experiences } = useHub()

  const [current3DExperience, setCurrent3DExperience] = useState(null)
  const [showScene3D, setShowScene3D] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [loadingPercent, setLoadingPercent] = useState(null)
  const [showPOI, setShowPOI] = useState(false)
  const [currentPOI, setCurrentPOI] = useState(null)
  const [showRenaultRooms, setShowRenaultRooms] = useState(false)
  const [expQuests, setExpQuests] = useState(false)
  const [roomsFromPOI, setRoomsFromPOI] = useState(false)
  const [roomReady, setRoomReady] = useState(false)

  const init3DExperience = useCallback(
    (experience) => {
      // console.log('launch 3d experience', experience)

      setCurrent3DExperience(experience)

      /*  @hint launch a 3d experience width launch3DExperience()
      here we already have the experience object from getHub(),
      but you can retrieve it with m.getExperienceInfo(id) if you need */

      SDK.launch3DExperience(experience, profile?.avatar)
        .then((response) => {
          log("Launching 3D exp", response)
          if (experience.type === "generic-room") {
            setShowRenaultRooms(true)
          }
        })
        .catch((message) => {
          console.error("Error launching 3d experiment", message)
        })
    },
    [SDK, profile],
  )

  useEffect(() => {
    /* @hint onDisplay3D(true/false) event
      will fire when the 3d scene needs to be put in front (or removed)
    */
    SDK.onDisplay3D((show) => {
      setShowScene3D(show)
    })

    /* @hint onLoadingUpdate event will notice you when a loading display is needed
      and what percentage to display */
    SDK.onLoadingUpdate((loading) => {
      setShowLoading(loading.display)
      setLoadingPercent(loading.percent)
      if (!loading.display) setRoomReady(true)
    })

    /* @hint onDisplayPOI event will notice you when to display or hide POI content*/
    SDK.onDisplayPOI((poi) => {
      setCurrentPOI(poi)
      let exp = experiences.find((e) => e.eid === poi.button.room)

      if (exp) {
        init3DExperience(exp)
        setShowPOI(false)
      } else {
        setShowPOI(true)
      }
    })

    /* @hint every time the user buys something in the dressing room, this event fires
              returns the new inventory with amount of each possessed */
    SDK.onDressingBuy((inventory) => {
      log("New inventory from dressing room ", inventory)
      setTimeout(() => {
        getUserCollectibles()
      }, 10000)
    })

    /* @hint When the user change an accessory/outfit in the dressing room,
            the new avatar object is sent here if needed */
    SDK.onAvatarUpdate(() => {
      // console.log("Avatar updated ", avatar)
      /* Now we can update profile.avatar with this avatar object,
              or call getProfile() again to sync from API */
      getProfile()
    })
  }, [SDK, getProfile, getUserCollectibles, init3DExperience, experiences])

  // @hint launch 3d room experience
  const launchRoom = (experience) => {
    if (experience) {
      // console.log('launch 3d room with eid', experience.eid)

      /* @hint first, we need to check if the user avatar is correctly set up
      (you need an avatar to explore a 3d experience)
      we can do that with m.getUserAvatarInfo().then((avatar) => ...
      and we will see if avatar.available true or false

      but this info is already send in m.getProfile().avatar we already called at login */

      if (profile?.avatar != null) {
        // we do not have an avatar set up yet
        // we can now launch the 3D experience
        init3DExperience(experience)
      } else {
        // the profile has an avatar
        // we will display an avatar configuration page instead
        launchAvatarSetup()
      }
    }
  }

  //on click on a poi button
  const onPOIButtonClick = (button) => {
    let exp
    switch (button.type) {
      case "link": // link to new tab
        window.open(button.url, "_blank")
        break

      case "room": // teleport to new room
        exp = experiences.find((e) => e.eid === button.room)
        if (exp) init3DExperience(exp)
        setRoomsFromPOI(true)

        if (showRenaultRooms && document.getElementById("canvas-extra")) {
          document.getElementById("canvas-extra").style.display = "block"
        }
        break

      case "collectible": // user found a quest
        displayCollectibleMint(experiences.find((e) => e.eid === button.collectible))
        setModalClaimVisible(true)
        setExpQuests(experiences.find((e) => e.eid === button.collectible))
        break

      default:
        return null
    }
    setShowPOI(false)
  }

  return (
    <RoomsContext.Provider
      value={{
        current3DExperience,
        setCurrent3DExperience,
        showScene3D,
        setShowScene3D,
        showLoading,
        setShowLoading,
        loadingPercent,
        setLoadingPercent,
        showPOI,
        setShowPOI,
        currentPOI,
        setCurrentPOI,
        launchRoom,
        showRenaultRooms,
        setShowRenaultRooms,
        onPOIButtonClick,
        expQuests,
        roomsFromPOI,
        setRoomsFromPOI,
        roomReady,
        setRoomReady,
      }}
    >
      {children}
    </RoomsContext.Provider>
  )
}

export { RoomsContext, RoomsProvider }
