import { AdditiveBlending, Color, FrontSide, ShaderMaterial } from "three"

import vertexShader from "@/webgl/world/materials/shadow/vertex.vs"
import fragmentShader from "@/webgl/world/materials/shadow/fragment.fs"

export default class TerritoryGradient extends ShaderMaterial {
  constructor() {
    const randomStartOffset = Math.random() * 0.1

    super({
      vertexShader,
      fragmentShader,

      uniforms: {
        uTime: { value: 0 },
        uOutlineColor: { value: new Color(1.0, 0.0, 0.0) },
        uFraction: { value: 0.25 },
        uGlowColor: { value: new Color("white") },
        uGlowIntensity: { value: 1.0 },
        uPulseSpeed: { value: 0.5 },
        uPulseIntensity: { value: 0.6 },
        uRandomStartOffset: { value: randomStartOffset },
      },

      side: FrontSide,
      blending: AdditiveBlending,
      transparent: true,
    })
  }
}
