/**
 * useSDK.jsx
 */

import { useContext } from "react"
import { SDKContext } from "@/context/metaverse/SDKContext"

export const useSDK = () => {
  return useContext(SDKContext)
}
