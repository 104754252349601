/**
 * Onboarding.js
 *
 * The Onboarding component manages the entire onboarding process, integrating various components related to collectibles.
 * It handles user interactions and state management throughout the onboarding flow.
 *
 * Key functionalities include:
 * - Managing the display and interactions for avatar setup.
 * - Handling the minting process for collectibles, including loading, success, and error states.
 * - Displaying previews and progress during the minting process.
 *
 * The component returns a structured onboarding flow that includes all necessary steps and components for a smooth user experience.
 */

import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { COLLECTIBLE_COLLECTION_EID } from "@/constants/constants"
import { useSDK } from "@/hooks/metaverse/useSDK.jsx"
import { useAuth } from "@/hooks/onboarding/useAuth"
import { useAvatarSetup } from "@/hooks/onboarding/useAvatarSetup"
import { useClaimSetup } from "@/hooks/onboarding/useClaimSetup"
import Consent from "@/components/Onboarding/Consent/Consent.jsx"
import AvatarSetup from "@/components/Onboarding/Avatar/AvatarSetup.jsx"
import CollectibleMint from "@/components/Onboarding/Mint/CollectibleMint.jsx"
import CollectibleMintSuccess from "@/components/Onboarding/Mint/CollectibleMintSuccess.jsx"
import CollectibleMintError from "@/components/Onboarding/Mint/CollectibleMintError.jsx"
import ChevronLeft from "@/assets/icons/UI/chevron-left.svg"

const Onboarding = ({
  dashboardOpen,
  setDashboardOpen,
  modalOpen,
  setModalOpen,
  activeTab,
  setActiveTab,
}) => {
  const SDK = useSDK()
  const { topic } = useParams()
  const { consented, profile } = useAuth()
  const { modalAvatarSetupVisible } = useAvatarSetup()
  const { modalClaimVisible, mintResult, collectibleToMint } = useClaimSetup()
  const [imageLoaded, setImageLoaded] = useState(false)
  const [isValidSrc, setIsValidSrc] = useState(true)
  const [summaryTitle, setSummaryTitle] = useState("")

  useEffect(() => {
    if (modalAvatarSetupVisible) {
      setSummaryTitle("avatar setup")
    } else if (modalClaimVisible) {
      setSummaryTitle("claim your avatar setup")
    } else {
      setSummaryTitle("profile and collectibles")
    }
  }, [modalAvatarSetupVisible, modalClaimVisible])

  const showMenuChoice = modalAvatarSetupVisible || modalClaimVisible || dashboardOpen

  const styleBodyColumnsAvatar = () => {
    if (modalAvatarSetupVisible) {
      return "body body-2columns avatar"
    }

    if (modalClaimVisible) {
      return "body body-2columns claim-onboarding"
    }

    return "body body-2columns"
  }

  const styleAvatar3D = () => {
    if (modalAvatarSetupVisible) {
      return "avatar3D left hidden"
    }

    if (
      COLLECTIBLE_COLLECTION_EID.includes(collectibleToMint?.collection.eid) ||
      (profile.avatar != null && dashboardOpen)
    ) {
      return "avatar3D left avatar"
    }

    return "avatar3D left quest"
  }

  const styleCollectibeToMintTitle = () => {
    if (
      (imageLoaded && collectibleToMint?.title === "Bold") ||
      collectibleToMint?.title === "Pop" ||
      collectibleToMint?.title === "Rev" ||
      collectibleToMint?.title === "Blue"
    ) {
      return "left-media collectible quest image-visible smooth-image"
    }

    if (imageLoaded) {
      return "left-media collectible image-visible smooth-image"
    }

    if (!imageLoaded) {
      return "image-hidden smooth-image"
    }

    return null
  }

  const styleUserDashboard = () => {
    if (modalAvatarSetupVisible) {
      return "right"
    }

    if (modalOpen) {
      return "right mobile-open"
    }

    return "right mobile-close"
  }

  return !consented ? (
    <Consent />
  ) : (
    <>
      {showMenuChoice && (
        <section className={topic ? "user-profile room" : "user-profile"}>
          <div className="user-onboarding modal">
            <div className="blur-background blur" />
            <div className="wrapper">
              <div className={styleBodyColumnsAvatar()}>
                <div className={styleAvatar3D()}>
                  {SDK.getAvatarThumbnail(profile?.avatar) && !modalClaimVisible ? (
                    <>
                      <img
                        src={SDK.getAvatarThumbnail(profile?.avatar)}
                        onLoad={() => setImageLoaded(true)}
                        onError={() => setIsValidSrc(false)}
                        alt="avatar"
                        className="left-media avatar"
                      />
                      {!isValidSrc && !imageLoaded && (
                        <div className="smooth-preloader">
                          <span className="loader" />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <img
                        src={
                          SDK.getAPIUrl() +
                          collectibleToMint?.thumbnail.path.replace(
                            "asset//",
                            "asset/" + collectibleToMint["asset-eid"] + "/",
                          )
                        }
                        onLoad={() => {
                          setImageLoaded(true)
                        }}
                        onError={() => setIsValidSrc(false)}
                        alt=""
                        className={styleCollectibeToMintTitle()}
                      />
                      {!isValidSrc && !imageLoaded && (
                        <div className="smooth-preloader">
                          <span className="loader" />
                        </div>
                      )}
                    </>
                  )}
                </div>

                {((dashboardOpen && !modalAvatarSetupVisible) || modalClaimVisible) && (
                  <div
                    className={`mobile-summary mobile${modalOpen ? "-open" : "-close"}`}
                    onClick={() => setModalOpen((prev) => !prev)}
                  >
                    {modalOpen ? "Hide " + summaryTitle : "Open " + summaryTitle}
                    <img src={ChevronLeft} alt="Chevron left" />
                  </div>
                )}

                <div className={styleUserDashboard()}>
                  {!dashboardOpen && modalAvatarSetupVisible && <AvatarSetup />}
                  {!dashboardOpen && modalClaimVisible && <CollectibleMint />}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {mintResult && mintResult?.success ? (
        <CollectibleMintSuccess
          dashboardOpen={dashboardOpen}
          setDashboardOpen={setDashboardOpen}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      ) : mintResult && !mintResult?.success ? (
        <CollectibleMintError />
      ) : (
        ""
      )}
    </>
  )
}

export default Onboarding
