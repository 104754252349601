import { Spin } from "antd"
import { useInterface } from "@/hooks/interface/useInterface"
import STOP_RECORD from "@/assets/icons/UI/stopRecord.svg"
import MIC from "@/assets/icons/UI/mic.svg"
import { useSearch } from "@/hooks/interface/useSearch"
import "./search-input.scss"

const SearchInput = ({
  setIsExpanded,
  isExpanded,
  isAuthenticated,
  setDisplaySearchResults,
  setEnterIsPressed,
  setResult,
  setSendValueIsClicked,
  resetStates,
  setViewMode,
}) => {
  const {
    setSendValue,
    inputValue,
    setInputValue,
    mediaCountRef,
    setPdfRender,
    setVideoRender,
    setIsUploadOpen,
    sendValue,
  } = useSearch()
  const {
    setActiveItemMenu,
    setSideMenuOrganizationIsOpen,
    setSideMenuAccountIsOpen,
    setSubMenuIsVisible,
    setStartTransitionAnimation,
    startRecording,
    setStartRecording,
  } = useInterface()

  const handleInputChange = (e) => setInputValue(e.target.value)

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      mediaCountRef.current = {}

      setSendValue(inputValue)
      setEnterIsPressed(true)
      setResult(true)
      setDisplaySearchResults(true)
      setSendValueIsClicked(false)
      setSideMenuOrganizationIsOpen(false)
      setSideMenuAccountIsOpen(false)
      setVideoRender(false)
      setPdfRender(false)
      setActiveItemMenu("")
      setViewMode("search")
      setTimeout(() => {
        setSubMenuIsVisible("item-hidden")
        setStartTransitionAnimation("")
      }, 100)
    }
  }

  const handleStartRecording = () => {
    setStartRecording(true)
  }

  const handleStopRecording = () => {
    setStartRecording(false)
    setDisplaySearchResults(true)
    setIsUploadOpen(false)
    setIsExpanded(true)
    setViewMode("search")
  }

  return (
    <div className={`search-bar__wrapper ${isExpanded ? "search-bar__wrapper--expand" : ""}`}>
      {startRecording ? (
        <Spin size="large" />
      ) : (
        isExpanded && (
          <span className="micro-icon-box" onClick={handleStartRecording}>
            <img src={MIC} alt="micro" className="micro-icon micro" />
          </span>
        )
      )}

      <input
        type="text"
        className="search-bar__input"
        placeholder="Chat PlanetRG"
        maxLength={100}
        value={inputValue}
        onKeyDown={handleKeyDown}
        onChange={handleInputChange}
        onFocus={() => setIsExpanded(true)}
        disabled={!isAuthenticated}
      />

      {isExpanded && startRecording && (
        <span onClick={handleStopRecording} className="stopRecord-icon-box">
          <img src={STOP_RECORD} alt="micro" className="stopRecord-icon micro" />
        </span>
      )}

      {isExpanded && (inputValue || sendValue) && !startRecording && (
        <div className="search-bar__close-icon" onClick={() => resetStates()}></div>
      )}
    </div>
  )
}

export default SearchInput
