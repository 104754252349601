/**
 * Layout.js
 * Overall structure for the application with the header, main content, and aside sections.
 */

import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { ThemeProvider } from "../context/interface/ThemeContext.jsx"
import { useRooms } from "@/hooks/metaverse/useRooms"
import { useInterface } from "@/hooks/interface/useInterface"
import { usePlanet } from "@/hooks/interface/usePlanet"

import { ROUTES } from "@/constants/constants"
import Header from "@/components/Layout/Header/Header"
import Main from "@/components/Layout/Main/Main"
import Aside from "@/components/Layout/Aside/Aside"

const Layout = () => {
  const { showRenaultRooms } = useRooms()
  const [activeTab, setActiveTab] = useState(null)
  const [dashboardOpen, setDashboardOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [activeItem, setActiveItem] = useState(null)
  const location = useLocation()
  const canvasExtra = document.getElementById("canvas-extra")
  const { isDesktop } = useInterface()
  const { isWebGLPaused } = usePlanet()
  // Handles POI display
  useEffect(() => {
    if (
      showRenaultRooms &&
      canvasExtra &&
      (location.pathname !== ROUTES.RENAULT_EXPERIENCES.FLINS ||
        location.pathname !== ROUTES.RENAULT_EXPERIENCES.DESIGN_TWIN)
    ) {
      canvasExtra.style.display = "block"
    } else if (!showRenaultRooms && canvasExtra) {
      canvasExtra.style.display = "none"
    }
  }, [showRenaultRooms, canvasExtra, location.pathname])

  return (
    <>
      <ThemeProvider>
        <Header
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          dashboardOpen={dashboardOpen}
          setDashboardOpen={setDashboardOpen}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />

        <Main
          dashboardOpen={dashboardOpen}
          setDashboardOpen={setDashboardOpen}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
      </ThemeProvider>

      {isWebGLPaused && !isDesktop && <Aside />}
    </>
  )
}

export default Layout
