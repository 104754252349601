/*

logger
v1.0.0
––––––
All-purpose fancy console logger

*/
import { IS_DEV } from "@/utils/utils.jsx"

let logger
const noop = () => {}
const noopObj = { log: noop, warn: noop, error: noop }

logger = function logger(prefix, color, background, mute) {
  if (IS_DEV) {
    if (mute) return noopObj

    const pre = []
    prefix = prefix.toUpperCase()

    pre.push("%c%s")
    let style = "font-weight:bold; line-height: 1.2em;"
    if (color) style += `color:${color};`
    if (background) style += `background-color:${background};`
    style += "border-radius: 4px;padding: 1px 6px 0;"
    pre.push(style)
    pre.push(prefix)

    return {
      info: console.debug.bind(console, ...pre),
      debug: console.debug.bind(console, ...pre),
      log: console.log.bind(console, ...pre),
      warn: console.warn.bind(console, ...pre),
      error: console.error.bind(console, ...pre),
    }
  }

  return {
    log: () => {},
  }
}

const createLogger = logger
export { logger, createLogger }
