/**
 * GatesPanner handles panning interactions within the Gates environment.
 * It listens to pointer events on the specified DOM element and updates the camera's position and rotation accordingly,
 * providing a smooth panning experience.
 */

import { Vector2, Object3D } from "three"
import { USER_ACTIONS } from "@/webgl/world/controls/index.js"
import CursorManager from "@/utils/CursorManager.js"
import ExperienceManager from "@/webgl/ExperienceManager.js"

export default class Panner {
  constructor(camera, domElement, controls) {
    this.experience = new ExperienceManager()

    this.controls = controls

    Object.assign(this, {
      camera,
      domElement,
      enabled: false,
      active: false,
      lerpSpeed: 0.1,
      mouse: new Vector2(),
      dummy: new Object3D(),
    })

    this.initEventHandlers()
  }

  /**
   * Initializes event handlers for pointer interaction
   */
  initEventHandlers() {
    this._onPointerDown = (event) => {
      this.active = true
      this.mouse.set(event.clientX, event.clientY)
      this.onPointerMove?.(event)

      window.addEventListener("pointerup", this._onPointerUp)
    }

    this._onPointerMove = (event) => {
      if (this.active) {
        CursorManager.setCursor("grabbing")

        this.experience.userHasDraggedInContinent = true
        this.onPointerMove?.(event)
        this.mouse.set(event.clientX, event.clientY)
        this.controls.setCurrentUserAction(USER_ACTIONS.DRAGGING_IN_CONTINENT)
      }
    }

    this._onPointerUp = (event) => {
      CursorManager.clearCursor()
      this.active = false
      this.controls.setCurrentUserAction(USER_ACTIONS.NONE)

      this.onPointerUp?.(event)

      window.removeEventListener("pointerup", this._onPointerUp)
    }
  }

  /**
   * Toggles panning functionality on or off
   * @param {boolean} active - Indicates whether to enable or disable panning
   * @returns {GatesPanner} - Returns the instance for chaining
   */
  toggle(active) {
    if (active) {
      this.enable()
    } else {
      this.disable()
    }
    return this
  }

  /**
   * Enables panning functionality and sets up necessary event listeners
   * @returns {GatesPanner} - Returns the instance for chaining
   */
  enable() {
    CursorManager.clearCursor()

    this.enabled = true
    this.dummy.position.copy(this.camera.position)
    this.dummy.quaternion.copy(this.camera.quaternion)
    this.domElement.addEventListener("pointerdown", this._onPointerDown)
    this.domElement.addEventListener("pointermove", this._onPointerMove)
    this.domElement.addEventListener("pointerup", this._onPointerUp)

    return this
  }

  /**
   * Disables panning functionality and removes event listeners
   * @returns {GatesPanner} - Returns the instance for chaining
   */
  disable() {
    CursorManager.clearCursor()
    this.enabled = false
    this.domElement.removeEventListener("pointerdown", this._onPointerDown)
    this.domElement.removeEventListener("pointermove", this._onPointerMove)
    this.domElement.removeEventListener("pointerup", this._onPointerUp)
    return this
  }

  /**
   * Updates the camera's position and rotation based on panning interactions if enabled
   */
  update() {
    if (this.enabled) {
      this.camera.position.lerp(this.dummy.position, this.lerpSpeed)
      this.camera.quaternion.slerp(this.dummy.quaternion, this.lerpSpeed)
    }
  }
}
