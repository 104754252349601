/**
 * Time.js
 * Utility to provide the time, delta time (difference between each frame) and the elapsed time since the app started.
 * Used for animations and time-based calculations.
 * It extends EventEmitter to emit 'update' events every animation frame when not paused.
 */

import EventEmitter from "@/webgl/utils/EventEmitter.js"

export default class Time extends EventEmitter {
  constructor() {
    super()

    this.initTime()

    this.requestUpdate()
  }

  /**
   * Sets initial time values.
   */
  initTime() {
    this.start = Date.now()
    this.current = this.start
    this.elapsed = 0
    this.delta = 16 // default delta to 16ms (60fps)
    this.isPaused = false
  }

  /**
   * Pauses the update loop.
   */
  pause() {
    if (!this.isPaused) {
      cancelAnimationFrame(this.frameId)
      this.isPaused = true
    }
  }

  /**
   * Resumes the update loop.
   */
  resume() {
    if (this.isPaused) {
      this.isPaused = false
      this.current = Date.now()
      this.requestUpdate()
    }
  }

  /**
   * Requests the next frame update if not paused.
   */
  requestUpdate() {
    if (!this.isPaused) {
      this.frameId = window.requestAnimationFrame(() => this.update())
    }
  }

  /**
   * Updates the time values and triggers the 'update' event.
   */
  update() {
    const currentTime = Date.now()
    this.delta = currentTime - this.current
    this.current = currentTime
    this.elapsed = this.current - this.start

    this.trigger("update")

    this.requestUpdate()
  }
}
