import {
  CylinderGeometry,
  Mesh,
  Vector3,
  MeshBasicMaterial,
  PlaneGeometry,
  DoubleSide,
  TextureLoader,
} from "three"
import { raftween } from "@/utils/anim/index.js"
// import { createLogger } from "@/utils/debug/index.js"
import { MOUSE, USER_ACTIONS, raycastFromMouse } from "@/webgl/world/controls/index.js"
import { EXPERIENCE_TYPE, VIEWS, EXPLORATION } from "@/webgl/world/World.js"
import { scaleAndMoveGate } from "@/webgl/world/components/gates/utils/animUtils.js"
import CursorManager from "@/utils/CursorManager.js"
import ExperienceManager from "@/webgl/ExperienceManager.js"
import Submenu from "@/webgl/world/components/gates/Submenu.js"
// // import GroundMaterial from "@/webgl/world/materials/ground/index.js"
import MATERIALS from "@/webgl/world//materials/infos.js"
import GATES_DATA from "@/webgl/world/components/gates/gates.json"
import bakedShadowTexture from "@/assets/webgl/bakedTexture.jpg"
import Label from "./Label.js"

// const log = createLogger("Gates", "#000", "#ffd007").log

export default class Gates {
  constructor(_options) {
    this.setupProperties(_options)
  }

  setupProperties(_options) {
    this.experience = new ExperienceManager()
    this.world = this.experience.world
    this.camera = this.experience.camera
    this.time = this.experience.time
    this.sizes = this.experience.sizes

    this.planetBase = _options.planetBase
    this.departmentGround = _options.departmentGround
    this.gatesInfos = _options.gatesInfos
    this.departmentBase = _options.departmentBase
    this.continentBase = _options.continentBase

    this.gatesPlacementConfig = GATES_DATA.placement
    this.gateTweens = []
    this.isClosing = false
    this.gatesVisible = false
    this.isAnimating = false

    this.textureLoader = new TextureLoader()
    this.bakedShadow = this.textureLoader.load(bakedShadowTexture)
    this.searchBarOpen = false
  }

  /**
   * Initializes the gates and their positioning
   */
  init() {
    this.placeGates()
    this.adjustGatesPosition()
    this.addResizeListener()

    this.labels = new Label(
      this.departmentGround,
      this,
      MATERIALS[this.planetBase.name.split("-")[1].toUpperCase()][
        this.continentBase.name.split("-")[1]
      ].gate.textColor,
    )
    this.industrySubmenu = new Submenu(this.departmentGround, this)
  }

  /**
   * Places gates based on the configuration and gate data
   */
  placeGates() {
    const gatesIsGate = this.gatesInfos.filter((gate) => gate.isGate && !gate.isEasterEgg)
    const totalGates = gatesIsGate.length

    this.setGatesPerRow(totalGates)
    const startZOffset = this.calculateZOffset(totalGates)

    for (const gateData of this.gatesInfos) {
      const gate = this.createGate(gateData)
      this.positionGate(gate, startZOffset, totalGates)
      gate.userData.originalPosition = gate.position.clone()
      this.departmentGround.add(gate)
    }
  }

  /**
   * Creates a new gate mesh and sets its properties
   * @param {Object} gateData - Data for the gate
   * @returns {Mesh} - The created gate mesh
   */
  createGate(gateData) {
    this.gateGeometry = new CylinderGeometry(4, 4, 0, 124)
    this.gateMaterial = new MeshBasicMaterial({
      color:
        MATERIALS[this.planetBase.name.split("-")[1].toUpperCase()][
          this.continentBase.name.split("-")[1]
        ].gate.color,
    })
    this.shadowGeometry = new PlaneGeometry(
      this.gateGeometry.parameters.radiusBottom * 6.4,
      this.gateGeometry.parameters.radiusBottom * 6.4,
    )

    this.shadowMaterial = new MeshBasicMaterial({
      color:
        MATERIALS[this.planetBase.name.split("-")[1].toUpperCase()][
          this.continentBase.name.split("-")[1]
        ].gate.shadowColor,
      transparent: true,
      alphaMap: this.bakedShadow,
      side: DoubleSide,
      depthWrite: false,
    })

    const gate = new Mesh(this.gateGeometry, this.gateMaterial)

    const shadowPlane = new Mesh(this.shadowGeometry, this.shadowMaterial)

    // Don't allow raycasting on shadow plane
    shadowPlane.raycast = () => {}

    Object.assign(gate, {
      index: gateData.index,
      name: gateData.name,
      url: gateData.url,
      isGate: gateData.isGate,
      isEasterEgg: gateData.isEasterEgg,
      is3dExperience: gateData.is3dExperience,
      isExternalExperience: gateData.isExternalExperience,
      is2dExperience: gateData.is2dExperience,
      available: gateData.available,
      tokenGated: gateData.tokenGated,
      offsetX: gateData.offsetX || 0,
      offsetZ: gateData.offsetZ || 0,
      hasSubmenu: gateData.hasSubmenu,
      submenuItems: gateData.subMenuItems,
      shadowPlane: shadowPlane,
      initialShadowPosition: null,
      submenuLabel: null,
      submenuSlider: null,
    })

    shadowPlane.rotation.x = Math.PI / 2

    gate.scale.set(0, 0, 0)
    gate.up.set(0, 1, 0)
    gate.add(shadowPlane)

    return gate
  }

  /**
   * Positions a gate within the scene
   * @param {Mesh} gate - The gate to position
   * @param {number} startZOffset - The Z-axis offset for centering
   * @param {number} totalGates - Total number of gates
   */
  positionGate(gate, startZOffset, totalGates) {
    if (!gate.isGate) return

    let x =
      this.gatesPlacementConfig.startXOffset +
      this.gatesPlacementConfig.currentColumn * this.gatesPlacementConfig.spacingX
    let z = this.gatesPlacementConfig.currentRow * this.gatesPlacementConfig.spacingZ - startZOffset

    // Right above the ground to avoid z-fighting
    gate.position.set(x, 1.8, z)

    // Adjust the Y-position of the shadow plane based on the row
    let shadowYOffset =
      -0.1 - this.gatesPlacementConfig.currentRow * this.gatesPlacementConfig.shadowOffsetPerRow
    gate.shadowPlane.position.set(-7.4, shadowYOffset, 7.4)

    if (gate.isGate && !gate.isEasterEgg) {
      this.updateRowColumn(totalGates)
    } else if (gate.isEasterEgg) {
      gate.position.add(
        new Vector3(this.sizes.width <= 1180 ? gate.offsetX + 15 : gate.offsetX, 0, gate.offsetZ),
      )
    }
  }

  /**
   * Sets the maximum number of gates per row based on the total number of gates
   * @param {number} totalGates - Total number of gates
   */
  setGatesPerRow(totalGates) {
    if (totalGates <= 3) {
      this.gatesPlacementConfig.maxPerRow = 1
    } else if (totalGates <= 6) {
      this.gatesPlacementConfig.maxPerRow = 2
    } else {
      this.gatesPlacementConfig.maxPerRow = 3
    }
  }

  /**
   * Calculates the starting offset on the Z-axis to center the gates vertically
   * @param {number} totalGates - Total number of gates
   * @returns {number} - The starting Z-axis offset
   */
  calculateZOffset(totalGates) {
    const numRows = Math.ceil(totalGates / this.gatesPlacementConfig.maxPerRow)
    return ((numRows - 1) * this.gatesPlacementConfig.spacingZ) / 2
  }

  /**
   * Updates the current row and column configuration for the next gate
   * @param {number} totalGates - Total number of gates
   */
  updateRowColumn(totalGates) {
    this.gatesPlacementConfig.currentColumn++
    if (
      this.gatesPlacementConfig.currentColumn >= this.gatesPlacementConfig.maxPerRow ||
      this.gatesPlacementConfig.currentColumn >=
        totalGates - this.gatesPlacementConfig.currentRow * this.gatesPlacementConfig.maxPerRow
    ) {
      this.gatesPlacementConfig.currentColumn = 0
      this.gatesPlacementConfig.currentRow++
      this.gatesPlacementConfig.startXOffset -= this.gatesPlacementConfig.diagonalOffset
    }
  }
  adjustGatesPosition() {
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    if (windowWidth <= 1024 || windowHeight > windowWidth) {
      // Smaller than tablet landscape or in portrait mode
      this.gatesPlacementConfig.startXOffset = 0
    } else if (windowWidth <= 1200) {
      // Tablet landscape
      this.gatesPlacementConfig.startXOffset = 4 // Adjust as needed for tablet landscape
    } else {
      // Desktop
      if (windowWidth <= 1600) {
        this.gatesPlacementConfig.startXOffset = windowHeight < 1000 ? 8 : 0
      } else if (windowWidth <= 1950) {
        this.gatesPlacementConfig.startXOffset = windowHeight < 1000 ? 12 : 5
      } else if (windowWidth <= 2140) {
        this.gatesPlacementConfig.startXOffset = windowHeight < 1000 ? 9 : 9
      } else {
        // Wider than 2140px
        this.gatesPlacementConfig.startXOffset = 12
      }
    }

    this.handleOrientationChange()
  }

  /**
   * Ensure that gates are repositioned when the screen orientation changes
   */
  handleOrientationChange() {
    this.gatesPlacementConfig.currentRow = 0
    this.gatesPlacementConfig.currentColumn = 0

    const gatesIsGate = this.gatesInfos.filter((gate) => gate.isGate && !gate.isEasterEgg)
    const totalGates = gatesIsGate.length
    const startZOffset = this.calculateZOffset(totalGates)

    this.departmentGround.children.forEach((gate) => {
      this.positionGate(gate, startZOffset, totalGates)
      gate.userData.updatedPosition = gate.position.clone()
    })
  }

  animateGates(
    direction,
    delayed = false,
    enterExplorationProgress,
    targetScale,
    isZoom = false,
    animateText = false,
    onComplete = () => {},
  ) {
    const staggerDelay = 80
    const delay = delayed ? 1800 : 0
    const duration = direction === "in" ? 900 : 150
    this.gatesVisible = direction === "in"

    let completedAnimations = 0
    const totalGates = this.world.currentPlanetInstance.activeDepartment.children.filter(
      (gate) => gate.isGate,
    ).length

    const handleAnimationComplete = () => {
      completedAnimations++
      if (completedAnimations === totalGates) {
        this.gatesVisible = direction !== "out"
        if (onComplete) onComplete()
      }
    }

    this.world.currentPlanetInstance.activeDepartment.children.forEach((gate, index) => {
      if (!gate.isGate) return
      gate.visible = true
      const initialScale = gate.scale.x

      if (gate.isEasterEgg) {
        gate.visible = false
        gate.mainLabel.element.style.opacity = 0
      }

      this.isAnimating = true

      setTimeout(() => {
        const tweenName = `${this.world.currentPlanetInstance.activeDepartment.name}-gate-${index}`
        this.gateTweens[index] = raftween({
          name: tweenName,
          from: initialScale,
          to: direction === "in" ? targetScale : 0,
          duration: duration,
          delay: delay,
          easing: "outCirc",
          onProgress: (_, tweenProgress) => {
            const progress = isZoom ? enterExplorationProgress : tweenProgress
            const interpolatedScale =
              initialScale +
              (direction === "in" ? targetScale - initialScale : -initialScale) * progress

            // Update gate scale
            if (!gate.isEasterEgg) {
              gate.scale.set(interpolatedScale, interpolatedScale, interpolatedScale)
            }

            if (direction === "in") {
              // Entering: labels should scale up and become visible
              const labelOpacity = animateText ? Math.min(progress * 2, 1) : 0
              const labelScale = animateText ? Math.min(interpolatedScale, 1) : 0

              if (gate.mainLabel && gate.mainLabel.element) {
                gate.mainLabel.element.firstChild.style.opacity = `${labelOpacity}`
                gate.mainLabel.element.firstChild.style.transform = `scale(${labelScale})`
              }
            } else if (direction === "out") {
              // Exiting: labels should fade out and scale down
              const labelOpacity = animateText ? Math.max(1 - progress, 0) : 0
              const labelScale = animateText ? Math.max(interpolatedScale * 0.01, 0) : 0

              if (gate.mainLabel && gate.mainLabel.element) {
                gate.mainLabel.element.firstChild.style.opacity = `${labelOpacity}`
                gate.mainLabel.element.firstChild.style.transform = `scale(${labelScale})`
              }
            }
          },
          onComplete: () => {
            this.isAnimating = false
            if (gate.isEasterEgg) {
              if (direction === "in" && this.world.explorationView === EXPLORATION.IN_GATES) {
                gate.visible = true
                gate.mainLabel.element.style.opacity = 1
                gate.scale.set(1, 1, 1)
              } else if (
                direction !== "in" &&
                this.world.explorationView !== EXPLORATION.IN_GATES
              ) {
                gate.visible = false
                gate.mainLabel.element.style.opacity = 0
                gate.scale.set(0, 0, 0)
              }
            } else if (direction === "out") {
              gate.scale.set(0, 0, 0)
            }

            handleAnimationComplete()
            this.gateTweens[index] = null
          },
        })
      }, index * staggerDelay)
    })
  }

  /**
   * Handles the selection and interaction logic for a specific gate
   * @param {Object} gate - The gate that's been interacted with
   */
  handleGateSelection(gate) {
    if (this.world.avatar && gate.tokenGated) {
      gate.tokenGated = false
    }

    // Manage 'disabled' class based on gate's status
    if (this.world.currentView === VIEWS.IN_CONTINENT) {
      if (gate.tokenGated) {
        CursorManager.setCursor("disabled")
      } else if (!gate.available || this.industrySubmenu.submenuIsOpen) {
        CursorManager.setCursor("grab")
      } else {
        CursorManager.setCursor("selecting")

        if (MOUSE.clicked) {
          this.handleGateInteraction(gate)
        }
      }
    }
  }

  /**
   * Processes the click event on a gate
   * @param {Object} gate - The gate that's been clicked
   */
  handleGateInteraction(gate) {
    if (gate.tokenGated || !gate.available) return

    if (this.industrySubmenu.submenuIsOpen) {
      this.industrySubmenu.hideSubmenu()
    } else if (gate.hasSubmenu) {
      this.industrySubmenu.showSubmenu(gate)
    } else {
      this.world.selectGate(gate.url)
    }

    MOUSE.clicked = false
  }

  /**
   * Opens a specified gate by scaling and moving it and its neighbors
   * @param {Object} gate - The gate to open
   */
  openGate(gate, subItem) {
    if (!gate || this.isAnimating) return

    this.isAnimating = true

    if (gate.hasSubmenu) {
      gate.submenuLabel.element.classList.add("hidden-label")
      gate.submenuSlider.element.classList.add("hide")
    }

    this.departmentGround.children.forEach((gate) => {
      if (gate.mainLabel) {
        gate.mainLabel.element.classList.remove("show-label")
        gate.mainLabel.element.classList.add("hidden-label")
      }
    })

    let tweenOpeningGate = scaleAndMoveGate({
      name: `opening gate${gate.name}`,
      experience: this.experience,
      base: this.departmentGround,
      gate,
      scaleFrom: gate.scale.x,
      scaleTo: 100,
      moveAwayDistance: 200,
      duration: 500,
      easing: "inCirc",
      openingSubmenu: false,
      closingSubmenu: false,
      openingExp: true,
      closingExp: false,
      onComplete: () => {
        this.isAnimating = false

        if (gate.is3dExperience) {
          this.world.currentExperienceType = EXPERIENCE_TYPE.IN_3D_EXPERIENCE
          this.experience.currentPlanetView("IN-EXPERIENCE")
          this.experience.currentExperienceType("IN-3D-EXPERIENCE")
        } else if (gate.is2dExperience) {
          this.world.currentExperienceType = EXPERIENCE_TYPE.IN_2D_EXPERIENCE
          this.experience.currentExperienceType("IN-2D-EXPERIENCE")
        } else if (gate.isExternalExperience || (subItem && subItem.isExternalExperience)) {
          this.world.currentExperienceType = EXPERIENCE_TYPE.IN_EXTERNAL_EXPERIENCE
          this.experience.currentExperienceType("IN-EXTERNAL-EXPERIENCE")
        } else {
          this.world.currentExperienceType = EXPERIENCE_TYPE.NONE
          this.experience.currentExperienceType("NONE")
        }
      },
    })

    this.gateTweens.push(tweenOpeningGate)
  }

  /**
   * Closes a specified gate by scaling and moving it and its neighbors back to original positions
   * @param {Object} gate - The gate to close
   */
  closeGate(gate) {
    if (!gate || this.isAnimating) return

    this.isAnimating = true
    this.isClosing = true

    let tweenClosingGate = scaleAndMoveGate({
      name: "closing gate",
      experience: this.experience,
      base: this.departmentGround,
      gate,
      scaleFrom: 20,
      scaleTo: 1,
      moveAwayDistance: 35,
      duration: 500,
      easing: "outCirc",
      openingSubmenu: false,
      closingSubmenu: false,
      openingExp: false,
      closingExp: true,
      onComplete: () => {
        this.isAnimating = false

        this.experience.currentExperienceType("NONE")
        this.departmentGround.children.forEach((gate) => {
          if (gate.mainLabel) {
            gate.mainLabel.element.classList.add("show-label")
            gate.mainLabel.element.classList.remove("hidden-label")
          }
        })

        if (this.industrySubmenu.currentActiveSubMenu) {
          this.industrySubmenu.hideUI()
          this.industrySubmenu.submenuIsOpen = false
          this.industrySubmenu.currentActiveSubMenu = null
        }

        if (tweenClosingGate) {
          tweenClosingGate.destroy()
          tweenClosingGate = null
        }

        setTimeout(() => {
          this.isClosing = false
        }, 1000)
      },
    })

    this.gateTweens.push(tweenClosingGate)
  }

  /**
   * Sets the opacity for gates and related elements
   * @param {number} value - The opacity value (0 to 1)
   */
  setOpacity(value) {
    // Ensure the base and its material are visible or hidden based on the value
    this.departmentGround.visible = value > 0
  }

  addResizeListener() {
    if (this.sizes) {
      this.sizes.on("resize", () => {
        this.adjustGatesPosition()
      })
    }
  }

  /**
   * Update method called on each frame or tick of the application
   */
  update() {
    if (
      this.world.currentView === VIEWS.INTRO ||
      (this.world.currentView === VIEWS.IN_ORBIT &&
        this.world.explorationView === EXPLORATION.IN_ORBIT)
    )
      return

    this.updateTween()

    this.performRaycasting()

    if (this.industrySubmenu) {
      this.industrySubmenu.update()
    }

    if (this.world.currentView === VIEWS.IN_EXPERIENCE) {
      CursorManager.clearCursor()
    }
  }

  /**
   * Performs raycasting to check for intersections with gates
   */
  performRaycasting() {
    if (
      !this.departmentGround ||
      !this.departmentGround.visible ||
      this.world.currentView !== VIEWS.IN_CONTINENT ||
      this.world.explorationView !== EXPLORATION.IN_GATES ||
      this.world.currentUserAction !== USER_ACTIONS.NONE ||
      this.isAnimating
    ) {
      return
    }

    const [intersected] = raycastFromMouse(this.departmentGround, this.camera.instance)

    this.raycastingGates(intersected)
  }

  /**
   * Handles the interaction with gates based on raycasting results
   * @param {Object} intersected - The raycasted object
   */
  raycastingGates(intersected) {
    if (!intersected) {
      CursorManager.clearCursor()
      this.industrySubmenu.hideSubmenu()
      return
    }

    const gate = intersected.object.isGate ? intersected.object : intersected.object.parent

    if (gate && gate.isGate) {
      this.handleGateSelection(gate)
    } else {
      CursorManager.clearCursor()
      if (this.industrySubmenu) {
        this.industrySubmenu.hideSubmenu(gate)
      }
    }
  }

  /**
   * Updates the tween animation if it's active
   */
  updateTween() {
    if (this.gateTweens.length > 0) {
      this.gateTweens.forEach((tween) => {
        if (tween) {
          tween.update(this.time.delta)
        }
      })
    }
  }
}
