import { useEffect } from "react"

const usePreventDefaultDragAndDrop = () => {
  useEffect(() => {
    const handleDragOver = (e) => e.preventDefault()
    const handleDrop = (e) => e.preventDefault()

    window.addEventListener("dragover", handleDragOver)
    window.addEventListener("drop", handleDrop)

    return () => {
      window.removeEventListener("dragover", handleDragOver)
      window.removeEventListener("drop", handleDrop)
    }
  }, [])
}

export default usePreventDefaultDragAndDrop
