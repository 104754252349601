import { useCallback, useEffect, useRef, useState } from "react"
import { Skeleton } from "antd"
import { useSearch } from "@/hooks/interface/useSearch"
import { usePostRequestSearchApi } from "@/data/hooks.js"
import { getDiscussionIdCookie } from "@/utils/utils.jsx"
// import SHARE_ICON from "@/assets/icons/UI/share-arrow.svg"
import "./search-result.scss"

const SearchResult = ({ isResultOpen }) => {
  const {
    sendValue,
    answerWaiting,
    setAnswerWaiting,
    dataResponse,
    setDataResponse,
    receivedAnswer,
  } = useSearch()
  const { mutate: sendSearchRequest } = usePostRequestSearchApi()
  const sendRequestToApiSearch = useCallback(() => {
    if (sendValue.length > 0) {
      const payload = {
        discussion_id: getDiscussionIdCookie(),
        query: sendValue,
      }

      setAnswerWaiting()

      sendSearchRequest(payload, {
        onSuccess: (data) => {
          setDataResponse(data)
          setAnswerWaiting("")
        },
      })
    }
  }, [sendSearchRequest, sendValue, setAnswerWaiting, setDataResponse])
  const [copied, setCopied] = useState()

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 5000)
    }
  }, [copied])

  useEffect(() => {
    sendRequestToApiSearch()
  }, [sendRequestToApiSearch])

  const [maxLines, setMaxLines] = useState(0)
  const parentRef = useRef(null)
  const textRef = useRef(null)

  useEffect(() => {
    const calculateMaxLines = () => {
      if (parentRef.current && textRef.current) {
        const parentHeight = parentRef.current.offsetHeight
        const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight)
        const lines = Math.floor(parentHeight / lineHeight)
        setMaxLines(lines)
      }
    }

    calculateMaxLines()

    window.addEventListener("resize", calculateMaxLines)

    return () => {
      window.removeEventListener("resize", calculateMaxLines)
    }
  }, [dataResponse])

  return (
    <>
      {answerWaiting || receivedAnswer ? (
        <Skeleton active paragraph={{ rows: 2 }} />
      ) : (
        <>
          <div ref={parentRef} className="search-result">
            <p
              ref={textRef}
              className={`search-result search-result__answer ${isResultOpen ? "search-result__answer--open" : ""}`}
              style={{
                display: "-webkit-box",
                WebkitLineClamp: maxLines,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {dataResponse?.answer}
            </p>
          </div>
          <div className="share-container">
            <div className="share" title="Prévu pour le sprint 2 du 04/11 au 29/11">
              <div className="share share__icon">
                <span></span>
              </div>
              Share
            </div>
            <div
              className="copy"
              onClick={(event) => {
                if (copied) return
                navigator.clipboard.writeText(dataResponse.answer)
                event.preventDefault()
                setCopied(true)
              }}
            >
              <div className={`copy copy__icon ${copied ? "copy__icon--checked" : ""}`}>
                <span></span>
                <span></span>
              </div>
              {copied ? "copied" : "copy"}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default SearchResult
