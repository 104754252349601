/**
 * Scene.jsx
 *
 * The Scene component manages the rendering of different experiences based on the current location path.
 * It adjusts the viewport height dynamically and launches the appropriate experience room, except for the dressing room.
 *
 * Key functionalities include:
 * - Defining the experience map for all experiences except the dressing room.
 * - Checking the location pathname specifically for the dressing room.
 * - Launching the appropriate experience room based on the location pathname, with a delay if not in the dressing room.
 * - Adjusting the viewport height on window resize.
 *
 * The component returns a canvas element that renders the experience based on the current experience chosen.
 */

import { useEffect, useRef, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useHub } from "@/hooks/metaverse/useHub"
import { useRooms } from "@/hooks/metaverse/useRooms"
import { ROUTES } from "@/constants/constants"
import SceneLoading from "./SceneLoading/SceneLoading.jsx"
import POI from "./POI/POI.jsx"
import "./scene.scss"
import Aside from "./Aside/Aside.jsx"

const LIBRARY_EID = "-2833397380128748972"

const Scene = () => {
  const {
    current3DExperience,
    showScene3D,
    launchRoom,
    showRenaultRooms,
    setShowScene3D,
    setRoomReady,
    setShowPOI,
    setShowRenaultRooms,
  } = useRooms()
  const { experienceDressingRoom, experienceRenaultRoomNight, experienceKnowledgeRoom } = useHub()
  const [isPointerDown, setIsPointerDown] = useState(false)
  const location = useLocation()
  const scene3dRef = useRef()
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight)
  const { experience } = useParams()

  let currentExperience

  // Define the experience map for all experiences except the dressing room
  const experienceMap = {
    [ROUTES.RENAULT_EXPERIENCES.NFT_SHOWROOM]: experienceRenaultRoomNight,
    [ROUTES.RENAULT_EXPERIENCES.KNOWLEDGE]: experienceKnowledgeRoom,
  }

  // Check location.pathname specifically for the dressing room
  if (location.pathname === ROUTES.DRESSING_ROOM.path) {
    currentExperience = experienceDressingRoom
  } else {
    // Use the experience prop to get the current experience from the map
    currentExperience = experienceMap[experience] || null
  }

  // Launch room based on location pathname with a delay if not in dressing room
  useEffect(() => {
    let timerId

    if (location.pathname === ROUTES.DRESSING_ROOM.path) {
      launchRoom(currentExperience)
    } else {
      timerId = setTimeout(() => {
        launchRoom(currentExperience)
      }, 1000)
    }

    return () => clearTimeout(timerId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // Handles viewport height adjustment on window resize
  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight)
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // Reset when location changes
  useEffect(() => {
    return () => {
      setShowScene3D(false)
      setRoomReady(false)
      setShowPOI(false)
      setShowRenaultRooms(false)
    }
  }, [location, setShowScene3D, setRoomReady, setShowPOI, setShowRenaultRooms])

  // Reset POIs on location change
  useEffect(() => {
    if (!showRenaultRooms && document.getElementById("canvas-extra")) {
      document.getElementById("canvas-extra").style.display = "none"
    }
  }, [location, showRenaultRooms])

  return (
    <>
      {current3DExperience && (
        <>
          {showScene3D && <SceneLoading />}
          {showRenaultRooms && currentExperience?.type === "generic-room" && <POI />}

          <div
            id="scene3d"
            ref={scene3dRef}
            style={{ height: `${viewportHeight}px` }}
            onPointerDown={() => setIsPointerDown(true)}
            onPointerUp={() => setIsPointerDown(false)}
            className={`${showScene3D ? "show" : "hide"} ${isPointerDown ? "moving" : ""}`}
          >
            {currentExperience?.type === "room" && (
              <>
                {/* @hint Ready to go dressing room UI
              (optional: use only is you have a dressing room in tour experiences) */}
                <metavrs-dressing-ui
                  className={showScene3D ? "showDressing" : "hideDressing"}
                ></metavrs-dressing-ui>
              </>
            )}
            {/* @hint this is the main 3d canvas web component
              (mandatory for 3d experiences) */}
            <metavrs-canvas
              class={
                currentExperience?.type === "room"
                  ? "mtvrs mtvrs-canvas show"
                  : currentExperience?.type === "generic-room" && showRenaultRooms
                    ? "mtvrs mtvrs-canvas show"
                    : "mtvrs mtvrs-canvas hide"
              }
            ></metavrs-canvas>
          </div>

          {showRenaultRooms && current3DExperience?.eid === LIBRARY_EID && <Aside />}
        </>
      )}
    </>
  )
}

export default Scene
