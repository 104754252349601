import axios from "axios"
import path from "path-browserify"
import Cookies from "js-cookie"

export const requestWrapper = axios.create({
  baseURL: path.join(import.meta.env.BASE_URL, "/api"),
})

requestWrapper.interceptors.request.use((config) => {
  const jwt = Cookies.get("jwt")

  if (jwt) {
    config.headers["Authorization"] = `Bearer ${jwt}`
  }
  return config
})

// requestWrapper.interceptors.response.use(
//   //if no error you get the response
//   (response) => response,
//   (error) => {
//     const { status } = error.response
//     const originalRequest = error.config
//     const jwt = Cookies.get("jwt")

//     if (!jwt) {
//       logout()
//     }

//     if (
//       // If the request fails with 401 or 422
//       // and is NOT a refresh or a logout request
//       (status === 401 || status === 422) &&
//       !["/refresh", "/logout"].some((x) => originalRequest.url.includes(x))
//     ) {
//       let refreshed = false
//       return requestWrapper
//         .post("/a12n/refresh")
//         .then(() => {
//           // set refreshed to true to allow the original request to fail 401 again, even after a successful refresh.
//           refreshed = true
//           return axios(originalRequest)
//         })
//         .catch((err) => {
//           // If the user authentication can't be refreshed, the user is loged out.
//           // Except if the refresh has been successful already.
//           // This will allow the request to fail 401 again for another reason than an expired token
//           if (!refreshed) {
//             logout()
//           } else {
//             throw err
//           }
//         })
//     }
//     throw error
//   },
// )
