const Infos = {
  RENAULT: {
    background: {
      startColor: 0xf9f9f8,
      centerColor: 0xf9f9f8,
      endColor: 0xf9f9f8,
    },
    sea: {
      planet: {
        color: 0x4e7dee,
        rim: 0x4e7dee,
        rimForce: 0.1,
      },
    },
    alpine: {
      department: {
        textColor: 0x000205,
      },
      planet: {
        color: 0x163048,
        rim: 0x163048,
        rimForce: 0.1,
      },
      background: {
        startColor: 0x163048,
        centerColor: 0x163048,
        endColor: 0x163048,
      },
      gate: {
        color: 0x000000,
        shadowColor: 0x000000,
        textColor: "#ffffff",
      },
      ground: {
        color: 0x163048,
        far: 0x163048,
      },
    },
    dacia: {
      department: {
        textColor: 0x101700,
      },
      planet: {
        color: 0x646c53,
        rim: 0x646c53,
        rimForce: 0.1,
      },
      gate: {
        color: 0x000000,
        shadowColor: 0x101801,
        textColor: "#ffffff",
      },
      background: {
        startColor: 0x646c53,
        centerColor: 0x646c53,
        endColor: 0x646c53,
      },
      ground: {
        color: 0x646c53,
        far: 0x646c53,
      },
    },
    mobilize: {
      department: {
        textColor: 0x430101,
      },
      planet: {
        color: 0xf94301,
        rim: 0xf94301,
        rimForce: 0.4,
      },
      background: {
        startColor: 0xf94301,
        centerColor: 0xf94301,
        endColor: 0xf94301,
      },
      gate: {
        color: 0x000000,
        textColor: "#ffffff",
        shadowColor: 0x3a0e01,
      },
      ground: {
        color: 0xf94301,
        far: 0xf94301,
      },
    },
    renault: {
      department: {
        textColor: 0x5d4f00,
      },
      planet: {
        color: 0xf6ce0c,
        rim: 0xf6ce0c,
        rimForce: 0.5,
      },
      background: {
        startColor: 0xf1de02,
        centerColor: 0xf1de02,
        endColor: 0xf1de02,
      },
      gate: {
        color: 0x000000,
        rim: 0x000000,
        rimForce: 1,
        textColor: "#ffffff",
        shadowColor: 0xef6d05,
      },
      ground: {
        color: 0xffe201,
        far: 0xf1de02,
      },
    },
    rgn: {
      planet: {
        color: 0x000000,
        rim: 0x555555,
        rimForce: 0.9,
      },
      gate: {
        color: 0x000000,
      },
      ground: {
        color: 0xffffff,
        rim: 0x000000,
      },
    },
    rgs: {
      planet: {
        color: 0x000000,
        rim: 0x555555,
        rimForce: 0.9,
      },
      gate: {
        color: 0x000000,
      },
      ground: {
        color: 0xffffff,
        rim: 0x000000,
      },
    },
  },

  RENAULUTION: {
    background: {
      startColor: 0x000000,
      centerColor: 0x000000,
      endColor: 0x000000,
    },

    sea: {
      planet: {
        color: 0x00fffc,
        rim: 0x0062f4,
        rimForce: 1.4,
      },
    },

    alpine: {
      planet: {
        color: 0x163048,
        rim: 0x163048,
        rimForce: 0.95,
      },
    },

    mobilize: {
      planet: {
        color: 0xdf2a00,
        rim: 0xea3e00,
        rimForce: 0.7,
      },
    },

    futureisneutral: {
      planet: {
        color: 0xe3227f,
        rim: 0xb70cd6,
        rimForce: 0.7,
      },
    },

    ampere: {
      planet: {
        color: 0xafb0b5,
        rim: 0x91a2b4,
        rimForce: 0.9,
      },
    },
    horse: {
      planet: {
        color: 0x00000,
        rim: 0xb1221e,
        rimForce: 0.9,
      },
    },
    power: {
      planet: {
        color: 0x00000,
        rim: 0x002e86,
        rimForce: 0.9,
      },
    },
    rgn: {
      planet: {
        color: 0x000000,
        rim: 0x555555,
        rimForce: 0.9,
      },
    },
    rgs: {
      planet: {
        color: 0x000000,
        rim: 0x555555,
        rimForce: 0.9,
      },
    },
  },
}

export default Infos
