import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import {
  getCreateNewDiscussionApi,
  postAudioSiaGptApi,
  postSearchRequestApi,
  postSearchStreamingApi,
} from "./services"

import { useSearch } from "@/hooks/interface/useSearch"

import { handleError } from "../utils/utils"

import {
  GET_CREATE_NEW_DISCUSSION,
  GET_SEARCH_AUDIO,
  GET_SEARCH_REQUEST_RESPONSE_KEY,
  GET_SEARCH_STREAMING_KEY,
} from "./constants"

export const useGetCreateNewDiscussion = (isLogged) => {
  const queryClient = useQueryClient()

  const query = useQuery({
    queryKey: [GET_CREATE_NEW_DISCUSSION],
    queryFn: () => getCreateNewDiscussionApi(),
    enabled: isLogged,
  })

  const refetchData = () => {
    queryClient.invalidateQueries({
      queryKey: [GET_CREATE_NEW_DISCUSSION],
    })
  }

  return {
    ...query,
    refetchData,
  }
}

export const usePostRequestSearchApi = () => {
  const queryClient = useQueryClient()

  const { discussionIdRef } = useSearch()

  return useMutation({
    mutationFn: postSearchRequestApi,
    enabled: !!discussionIdRef.current,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_SEARCH_REQUEST_RESPONSE_KEY],
      })
    },
    onError: (error) => {
      handleError(error)
    },
  })
}

export const usePostAudioFile = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: postAudioSiaGptApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: GET_SEARCH_AUDIO })
    },
    onError: (error) => {
      handleError(error)
    },
  })
}

export const usePostSearchStreaming = (isAudio) => {
  const queryClient = useQueryClient()

  const { discussionIdRef } = useSearch()

  return useMutation({
    mutationFn: postSearchStreamingApi,
    enabled: !isAudio ? !!discussionIdRef.current : true,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GET_SEARCH_STREAMING_KEY],
      })
    },
    onError: (error) => {
      handleError(error)
    },
  })
}
