/**
 * SDKContext.jsx
 * Power.xyz (ex Metav.rs) SDK Main Instance
 */

import { createContext } from "react"

// Create the context
const SDKContext = createContext(null)

const SDKProvider = ({ children }) => {
  const SDK = new window.METAVRS({
    API_KEY: import.meta.env.VITE_METAVRS_SDK_KEY,
  })

  return <SDKContext.Provider value={SDK}>{children}</SDKContext.Provider>
}

export { SDKContext, SDKProvider }
