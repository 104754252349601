/**
 * GatesPanner extends the Panner class to provide a custom panning functionality specifically designed for the Gates environment.
 * It allows for controlled panning within defined boundaries (a bounding box) to ensure the user's view remains within the desired area.
 * The class also handles the translation and scaling of gates and their neighbors based on user interaction to enhance the user's experience.
 *
 * Usage:
 * - Instantiate GatesPanner with a camera and a DOM element.
 * - Call the 'update' method within the animation loop to apply panning effects.
 * - Use 'setGates' to define the bounding area and 'enable' or 'disable' as needed to control panning activation.
 */

import { Box3, MathUtils, Vector3 } from "three"
import Panner from "@/webgl/world/controls/Panner.js"

export default class GatesPanner extends Panner {
  /**
   * Initializes GatesPanner with a camera and DOM element
   * @param {Camera} camera - Three.js Camera used for panning
   * @param {HTMLElement} domElement - The HTML element used for event handling
   */
  constructor(camera, domElement, controls) {
    super(camera, domElement, controls)

    // Define the bounding box for panning limits
    this.boundingBox = new Box3()

    // Set the panning speed
    this.speed = 0.001
  }

  /**
   * Enables the panner and sets up initial state
   */
  enable() {
    super.enable() // Call the enable method from the Panner class

    // Store the initial altitude based on the camera's position
    this.altitude = this.camera.position.length()

    // Copy the camera's position and orientation to the dummy object
    this.dummy.position.copy(this.camera.position)
    this.dummy.quaternion.copy(this.camera.quaternion)
  }

  /**
   * Handles pointer movement to pan the camera
   * @param {Object} event - The pointer move event containing clientX and clientY
   */
  onPointerMove({ clientX, clientY }) {
    // Calculate the change in the pointer's position
    const deltaX = clientX - this.mouse.x
    const deltaY = clientY - this.mouse.y

    // Translate the dummy camera based on pointer movement and speed
    this.dummy.translateX(-deltaX * this.speed)
    this.dummy.translateY(deltaY * this.speed)

    // Clamp the dummy's position to the bounding box to prevent panning outside limits
    const { min, max } = this.boundingBox

    this.dummy.position.x = MathUtils.clamp(this.dummy.position.x, min.x, max.x)
    this.dummy.position.y = MathUtils.clamp(this.dummy.position.y, min.y, max.y)
    this.dummy.position.z = MathUtils.clamp(this.dummy.position.z, min.z, max.z)

    // Restore the original altitude to mimic an orbit-like movement
    this.dummy.position.setLength(this.altitude)
  }

  /**
   * Sets up the bounding box for gates to define panning limits
   * @param {Object} target - The target object containing children to define bounds
   * @returns {GatesPanner} - Returns the instance for chaining
   */
  setGates(target) {
    // Reset the bounding box
    this.boundingBox.makeEmpty()

    const worldPosition = new Vector3()

    // Expand the bounding box to include all children of the target
    if (target) {
      target.children.forEach((child) => {
        if (child.isGate) {
          // Get the world position of the child
          child.getWorldPosition(worldPosition)
          // Expand the bounding box by this world position
          this.boundingBox.expandByPoint(worldPosition)
        }
      })
    }

    this.boundingBox.expandByScalar(0.3)

    return this // Return the instance for method chaining
  }
}
