import { BrowserRouter, Routes, Route } from "react-router-dom"
import Layout from "@/pages/Layout.jsx"
import Homepage from "@/pages/Homepage.jsx"
import NotFound from "@/pages/NotFound.jsx"
import Experience from "@/pages/Experience.jsx"
import SubExperience from "@/pages/SubExperience.jsx"
import Department from "@/pages/Department.jsx"
import News from "@/pages/News.jsx"
import RecordManager from "@/components/Layout/Main/SearchBar/components/SearchInput/RecordManager.jsx"
import usePreventDefaultDragAndDrop from "@/utils/usePreventDefaultDragAndDrop.jsx"
import "@/styles/index.scss"

const App = () => {
  usePreventDefaultDragAndDrop()

  return (
    <>
      <RecordManager />
      <BrowserRouter basename={import.meta.env.BASE_URL}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Homepage />} />
            <Route path=":brand/:department" element={<Department />} />
            <Route path=":brand/:department/:experience" element={<Experience />} />
            <Route
              path=":brand/:department/:experience/:subexperience"
              element={<SubExperience />}
            />
            <Route path="news/:brand" element={<News />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
