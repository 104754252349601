/**
 * Header.jsx
 *
 * The Header component is responsible for displaying the application's header, including navigation elements and branding.
 * It manages the visibility and interaction of the header components based on the current state and user actions.
 *
 * Key functionalities include:
 * - Showing or hiding the arrow button based on various conditions.
 * - Styling the header and managing pointer events to ensure proper user interaction.
 * - Integrating the ArrowButton component for navigation.
 * - Integrating the MenuDesktop component for desktop navigation.
 * - Displaying the site title.
 *
 * The component returns a header element that includes the ArrowButton, MenuDesktop, and SiteTitle components, with dynamic styles and interactions.
 */

import { useEffect, useState } from "react"
import { useLocation, useMatch, useParams } from "react-router-dom"
import { useInterface } from "@/hooks/interface/useInterface"
import { usePlanet } from "@/hooks/interface/usePlanet"
import { useClaimSetup } from "@/hooks/onboarding/useClaimSetup"
import {
  ROUTES,
  CURRENT_PLANET_VIEW,
  CURRENT_EXPERIENCE_TYPE,
  CURRENT_EXPLORATION_LEVEL,
} from "@/constants/constants"
import ArrowButton from "./ArrowButton"
import MenuDesktop from "@/components/Layout/Header/Navigation/MenuDesktop"
import SiteTitle from "@/components/Layout/Header/SiteTitle.jsx"
import "./header.scss"

const Header = ({ setFromTopic, setActiveTab, setDashboardOpen, setModalOpen }) => {
  const location = useLocation()
  const { currentPlanetView, currentExperienceType, currentExplorationLevel } = usePlanet()
  const { mintResult } = useClaimSetup()
  const { sideMenuOrganizationIsOpen, sideMenuAccountIsOpen, showContent } = useInterface()
  const { brand, experience } = useParams()
  const [styleArrowButton, setStyleArrowButton] = useState("header-btn")
  const isBrand = useMatch("/:brand")
  const isDepartment = useMatch("/:brand/:department")
  const isExperience = Object.values(ROUTES.RENAULT_EXPERIENCES).includes(experience)
  const isDressingRoom = location.pathname === ROUTES.DRESSING_ROOM.path
  const isHome = useMatch({
    path: "/",
    exact: true,
  })

  // Shows or hide arrow button
  useEffect(() => {
    const shouldShowArrowButton =
      (isDepartment &&
        currentPlanetView === CURRENT_PLANET_VIEW.IN_CONTINENT &&
        currentExplorationLevel === CURRENT_EXPLORATION_LEVEL.IN_GATES) ||
      (isExperience &&
        showContent &&
        (currentExperienceType === CURRENT_EXPERIENCE_TYPE.IN_2D_EXPERIENCE ||
          currentExperienceType === CURRENT_EXPERIENCE_TYPE.IN_EXTERNAL_EXPERIENCE))

    if (shouldShowArrowButton) {
      setStyleArrowButton("is-visible")
    } else if (isDressingRoom) {
      const timeoutId = setTimeout(() => {
        setStyleArrowButton("is-visible")
      }, 300)

      return () => clearTimeout(timeoutId)
    } else {
      setStyleArrowButton("")
    }
  }, [
    isExperience,
    isDepartment,
    currentPlanetView,
    currentExperienceType,
    isDressingRoom,
    showContent,
    currentExplorationLevel,
  ])

  const styleHeader = () => {
    if (isDressingRoom || isExperience) {
      return "rooms"
    }

    return null
  }

  const headerPointerEvents = () => {
    if ((!sideMenuAccountIsOpen && !sideMenuOrganizationIsOpen) || mintResult) {
      return { pointerEvents: "none" }
    }

    return { pointerEvents: "auto" }
  }

  return (
    <header style={headerPointerEvents()} className={styleHeader()}>
      <ArrowButton
        className={`header-btn ${styleArrowButton}`}
        isHome={isHome}
        isBrand={isBrand}
        isDepartment={isDepartment}
        brand={brand}
        experience={experience}
        setFromTopic={setFromTopic}
      />

      <MenuDesktop
        setActiveTab={setActiveTab}
        setDashboardOpen={setDashboardOpen}
        setModalOpen={setModalOpen}
        isHome={isHome}
        isBrand={isBrand}
        isDepartment={isDepartment}
        brand={brand}
        experience={experience}
      />

      <SiteTitle />
    </header>
  )
}

export default Header
