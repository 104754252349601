/**
 * Controls manages different user interaction modes within the 3D environment.
 * It's designed to handle switching between various control schemes like Orbit, Pan, and potentially Slide (commented out).
 * It provides an easy interface to enable or disable specific control behaviors and ensures that user interactions are reflected in the scene's camera movement.
 *
 * The class also handles the auto-rotation of the view when idle to maintain a dynamic and engaging scene.
 * Additionally, it manages the interaction with gates through raycasting, determining what the user is aiming at or selecting within the 3D environment.
 *
 * Usage:
 * - Instantiate Controls with an ExperienceManager instance.
 * - Call the 'update' method within the animation loop to process user interactions and control updates.
 * - Use 'use' method to switch between different control types based on user interaction or application state.
 */

import { Vector2 } from "three"
import { TrackballControls } from "@/webgl/world/controls/TrackballControls.js"
import { raycastFromObject, raycastFromMouse } from "@/webgl/world/controls/Raycaster.js"
import { createLogger } from "@/utils/debug/log.js"
import CursorManager from "@/utils/CursorManager.js"
import ExperienceManager from "@/webgl/ExperienceManager.js"
import GatesPanner from "@/webgl/world/controls/GatesPanner.js"
import { VIEWS } from "@/webgl/world/World.js"

const log = createLogger("Controls", "#000", "#cccccc").log

export const CONTROL_TYPES = {
  NONE: 0,
  ORBIT: 1,
  PAN: 2,
}
export const USER_ACTIONS = {
  NONE: 0,
  DRAGGING_IN_CONTINENT: 1,
  DRAGGING_IN_ORBIT: 2,
}
export const MOUSE = { active: false, clicked: false, position: new Vector2() }
export { raycastFromObject, raycastFromMouse }

const WHEELING_DIRECTION = {
  NULL: 0,
  IN: 1,
  OUT: 0,
}

export default class Controls {
  constructor() {
    this.init()
    this.initEventListeners()
  }

  init() {
    this.experience = new ExperienceManager()
    this.scene = this.experience.scene
    this.renderer = this.experience.renderer
    this.camera = this.experience.camera
    this.sizes = this.experience.sizes
    this.world = this.experience.world
    this.gatesPanner = null
    this.wheelingDirection = WHEELING_DIRECTION.NONE

    this.currentType = CONTROL_TYPES.NONE
    this.currentUserAction = USER_ACTIONS.NONE

    if (this.renderer.instance != null) {
      const { domElement } = this.renderer.instance
      this.initOrbitControls(domElement)

      this.gatesPanner = new GatesPanner(this.camera.instance, domElement, this)
      this.startAutorotate()
    }
  }

  setCurrentUserAction(action) {
    this.currentUserAction = action
  }
  /**
   * Initializes orbit controls with specified settings
   * @param {HTMLElement} domElement - The DOM element used for the controls
   */
  initOrbitControls(domElement) {
    this.orbit = new TrackballControls(this.camera.instance, domElement)
    Object.assign(this.orbit, {
      noZoom: true,
      noPan: true,
      autoRotate: false,
      autoRotateSpeed: 0.0002,
    })
  }

  /**
   * Sets up event listeners for mouse interaction
   */
  initEventListeners() {
    const { domElement } = this.renderer.instance
    let moved = false
    let allowZoom = true

    domElement.addEventListener("mousedown", (event) => {
      event.preventDefault()

      this.cancelAutorotate()
      moved = false
      MOUSE.active = true

      // Disable text selection
      document.body.style.userSelect = "none"
    })

    domElement.addEventListener("mousemove", (event) => {
      moved = true

      MOUSE.position.set(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1,
      )
    })

    // Handling touch events
    domElement.addEventListener(
      "touchmove",
      (event) => {
        // Prevent the default touch behavior like scrolling
        event.preventDefault()

        // Only consider the first touch point for simplicity
        const touch = event.touches[0]
        moved = true

        MOUSE.position.set(
          (touch.clientX / window.innerWidth) * 2 - 1,
          -(touch.clientY / window.innerHeight) * 2 + 1,
        )
      },
      { passive: false },
    ) // Use passive: false to allow preventDefault

    domElement.addEventListener("mouseup", () => {
      this.startAutorotate()
      MOUSE.active = false

      if (!moved) {
        MOUSE.clicked = true
      }
    })

    domElement.addEventListener("wheel", (event) => {
      if (
        this.world.currentView !== VIEWS.IN_ORBIT &&
        this.world.currentView !== VIEWS.IN_CONTINENT &&
        this.world.isAnimating
      )
        return
      event.preventDefault()
      this.cancelAutorotate()
      this.world.cancelReset = true
      const deltaY = event.deltaY

      const direction = deltaY > 0 ? 1 : -1

      // Handle direction
      if (allowZoom) {
        if (direction !== 0) {
          this.world.enterExploration(direction, false)
          allowZoom = false

          setTimeout(() => {
            allowZoom = true
          }, 1000)
        }
      }
    })
  }

  /**
   * Initiates autorotate functionality after a period of inactivity when in ORBIT mode.
   */
  startAutorotate() {
    if (this.currentType === CONTROL_TYPES.ORBIT) {
      this.cancelAutorotate()
      this.inactiveTimeout = setTimeout(() => {
        log("Launch autorotate")

        this.orbit.autoRotate = true
      }, 2000)
    }
  }

  /**
   * Cancels any ongoing autorotate operation and clears the timeout.
   */
  cancelAutorotate() {
    this.orbit.autoRotate = false
    clearTimeout(this.inactiveTimeout)
  }

  /**
   * Switches control types and handles the enabling and disabling of associated functionality.
   * @param {number} type - The control type from CONTROL_TYPES
   * @param {Object} options - Additional options or configurations
   * @returns {Controls} - Returns the instance for chaining
   */
  use(type, options) {
    this.cancelAutorotate()

    this.currentType = type

    // Disable all controls initially
    this.orbit.disable()
    this.gatesPanner.disable()

    switch (type) {
      case CONTROL_TYPES.ORBIT:
        this.orbit.enable()
        this.startAutorotate()
        CursorManager.setCursor("grab")
        break

      case CONTROL_TYPES.PAN:
        this.gatesPanner.setGates(options)
        this.gatesPanner.enable()
        CursorManager.setCursor("grab")

        break

      case CONTROL_TYPES.NONE:
        CursorManager.clearCursor()
        break

      default:
        return null
    }

    return this
  }

  /**
   * Saves the current state of the orbit controls
   * @returns {Controls} - Returns the instance for chaining
   */
  saveOrbit() {
    if (this.orbit) {
      this.orbit.saveState()
    }

    return this
  }

  /**
   * Sets the target for the GatesPanner to define boundaries and targets.
   * @param {Object} target - The target object for the GatesPanner
   * @returns {Controls} - Returns the instance for chaining
   */
  setGatesPannerTarget(target) {
    if (this.gatesPanner) {
      this.gatesPanner.setGates(target)
    }

    return this
  }

  update() {
    switch (this.currentType) {
      case CONTROL_TYPES.ORBIT:
        this.orbit.update()
        break

      case CONTROL_TYPES.PAN:
        this.gatesPanner.update()
        break

      default:
        return null
    }

    MOUSE.clicked = false
  }
}
