/**
 * Planet3DHub.js
 *
 * The Planet3DHub component creates and manages the WebGL experience, including initialization, user interactions, and lifecycle management.
 *
 * Key functionalities include:
 * - Initializing the WebGL environment.
 * - Managing redirection to the home page.
 * - Handling events like news card clicks.
 * - Managing WebGL route changes and state updates.
 * - Detecting desktop environments and adjusting settings accordingly.
 * - Providing onProgress and onLoad handlers for loading content.
 *
 * The component returns a canvas with the WebGL experience and an interactive button.
 */

import { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useRouteType } from "@/hooks/interface/useRouteType"
import { usePlanet } from "@/hooks/interface/usePlanet"
import { useInterface } from "@/hooks/interface/useInterface"
import { ROUTES, CURRENT_PLANET_VIEW, CURRENT_EXPLORATION_LEVEL } from "@/constants/constants.jsx"
import ExperienceManager from "@/webgl/ExperienceManager.js"
import Preloader from "@/components/Planet3DHub/Preloader/Preloader.jsx"

const Planet3DHub = ({ avatar }) => {
  const {
    setCurrentPlanet,
    currentPlanetView,
    setCurrentPlanetView,
    setCurrentExperienceType,
    setUserHasDragged,
    setGateSubMenuOpen,
    setWebGLVisible,
    webGLExperience,
    setWebGLExperience,
    currentExplorationLevel,
    setCurrentExplorationLevel,
    isWebGLPaused,
    setIsWebGLPaused,
  } = usePlanet()
  const {
    subMenuIsVisible,
    searchBarIsVisible,
    setCurrentNewsId,
    setCurrentNewsInfos,
    setAllNews,
    setCurrentNewsBrand,
    sideMenuOrganizationIsOpen,
    sideMenuAccountIsOpen,
    isDesktop,
    setIsDesktop,
  } = useInterface()
  const navigate = useNavigate()
  const location = useLocation()
  const { routeType } = useRouteType()
  const params = useParams()
  const canvasRef = useRef(null)
  const recenterButtonRef = useRef(null)
  const [progress, setProgress] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)

  // Initialize WebGL Experience
  useEffect(() => {
    if (!canvasRef.current || webGLExperience) return

    let experience = new ExperienceManager(canvasRef.current)
    experience.startExperience()

    if (experience.world) {
      experience.world.setLoading(onProgress, onLoad)
    }

    setWebGLExperience(experience)

    return () => {
      experience.destroy()
      experience = null
    }
  }, [setWebGLExperience, webGLExperience])

  // Manage redirection to home
  useEffect(() => {
    if (location.pathname !== "/") {
      navigate("/")
      window.location.reload()
    }
    // TODO: To refactor with routes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Event handler for news click
  const handleNewsClick = useCallback(
    (event) => {
      setCurrentNewsId(event.detail.currentNewsId)
      setCurrentNewsInfos(event.detail.currentNewsInfos)
      setAllNews(event.detail.allNews)
      setCurrentNewsBrand(event.detail.currentBrand)

      navigate(event.detail.currentNewsPath)
    },
    [navigate, setAllNews, setCurrentNewsBrand, setCurrentNewsId, setCurrentNewsInfos],
  )

  useEffect(() => {
    window.addEventListener("newsClick", handleNewsClick)

    return () => {
      window.removeEventListener("newsClick", handleNewsClick)
    }
  }, [handleNewsClick])

  // Handles WebGL route change
  useEffect(() => {
    if (!webGLExperience) return

    const handleNewRoute = (event) => {
      navigate(event.detail.path)
    }

    webGLExperience.world?.handleRouteChange(routeType, params)
    webGLExperience.world?.handleInterfaceStatus(avatar, subMenuIsVisible, searchBarIsVisible)

    window.addEventListener("routeChange", handleNewRoute)

    return () => {
      if (webGLExperience) {
        window.removeEventListener("routeChange", handleNewRoute)
      }
    }
  }, [navigate, routeType, params, avatar, subMenuIsVisible, searchBarIsVisible, webGLExperience])

  // Retrieves information from WebGL
  useEffect(() => {
    if (webGLExperience) {
      webGLExperience.notifyInterface({
        onCurrentPlanetView: (data) => {
          setCurrentPlanetView(data)
        },
        onCurrentPlanet: (data) => {
          setCurrentPlanet(data)
        },
        onCurrentExperienceType: (data) => {
          setCurrentExperienceType(data)
        },
        onDrag: (data) => {
          setUserHasDragged(data)
        },
        onGateSubMenuOpen: (data) => {
          setGateSubMenuOpen(data)
        },
        onCurrentExplorationLevel: (data) => {
          setCurrentExplorationLevel(data)
        },
      })
    }
  }, [
    webGLExperience,
    setCurrentPlanetView,
    setCurrentPlanet,
    setCurrentExperienceType,
    setUserHasDragged,
    setGateSubMenuOpen,
    setCurrentExplorationLevel,
  ])

  const detectDesktop = useCallback(() => {
    const isMobileDevice = /iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    const isTablet =
      /iPad|tablet|android(?!.*mobile)|kindle|playbook|silk|puffin(?!.*(IP|AP|WP))/i.test(
        navigator.userAgent,
      )

    const hasTouch = "ontouchstart" in window || navigator.maxTouchPoints > 0
    const landscape = isLandscape()

    const isIpadPro =
      hasTouch &&
      window.matchMedia(
        "(width: 1366px) and (height: 1024px)," + // iPad Pro 12.9"
          "(width: 1194px) and (height: 834px)," + // iPad Pro 11"
          "(width: 1112px) and (height: 834px)", // iPad Pro 10.5"
      ).matches

    const isCurrentlyDesktop =
      !isMobileDevice && !isTablet && landscape && window.innerHeight > 500 && !isIpadPro

    setIsDesktop(isCurrentlyDesktop)
  }, [setIsDesktop])

  // Detect desktop on initial mount and add resize event listener
  useEffect(() => {
    detectDesktop() // Initial check

    window.addEventListener("resize", detectDesktop)

    return () => {
      window.removeEventListener("resize", detectDesktop)
    }
  }, [detectDesktop])

  // Handle changes to isWebGLPaused
  useEffect(() => {
    if (!webGLExperience) return

    if (isWebGLPaused) {
      webGLExperience.pause()
      setWebGLVisible(false)
    } else {
      webGLExperience.restart()
      setWebGLVisible(true)
    }
  }, [isWebGLPaused, webGLExperience, setWebGLVisible])

  // Logic for detecting desktop and setting isWebGLPaused
  useEffect(() => {
    if (
      sideMenuOrganizationIsOpen ||
      sideMenuAccountIsOpen ||
      searchBarIsVisible ||
      !isDesktop ||
      currentPlanetView === CURRENT_PLANET_VIEW.IN_EXPERIENCE ||
      routeType === "News"
    ) {
      setIsWebGLPaused(true)
    } else {
      setIsWebGLPaused(false)
    }
  }, [
    isDesktop,
    currentPlanetView,
    setIsWebGLPaused,
    sideMenuOrganizationIsOpen,
    sideMenuAccountIsOpen,
    searchBarIsVisible,
    routeType,
  ])

  const isLandscape = () => {
    if (
      typeof window.matchMedia === "function" &&
      window.matchMedia("(orientation: landscape)").matches
    ) {
      return true
    } else {
      return window.innerWidth > window.innerHeight
    }
  }

  const onProgress = (progress) => {
    setProgress(progress)
  }

  const onLoad = () => {
    setIsLoaded(true)
  }

  return (
    <>
      {!isLoaded && <Preloader progress={progress} />}
      <canvas
        className="webgl planet-hub"
        ref={canvasRef}
        style={{ pointerEvents: isWebGLPaused ? "none" : "auto" }}
      />

      <button
        ref={recenterButtonRef}
        style={{
          color:
            currentPlanetView === CURRENT_PLANET_VIEW.IN_CONTINENT &&
            currentExplorationLevel === CURRENT_EXPLORATION_LEVEL.IN_CONTINENT
              ? ROUTES.BRANDS.RENAULT.path.includes(params.brand)
                ? "#000000"
                : "#ffffff"
              : "",
        }}
        className="center-back-to-gates"
        onClick={() => {
          webGLExperience.camera?.recenter()
        }}
      >
        recenter
      </button>
    </>
  )
}

export default Planet3DHub
