/**
 * Continent.js
 * Creates Renault's  continents : Alpine, Dacia, Mobilize, Renault and the 2 RG poles
 */
import {
  CircleGeometry,
  DoubleSide,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Vector3,
} from "three"
import { CSS2DObject } from "three/addons/renderers/CSS2DRenderer.js"
import { EXPLORATION } from "@/webgl/world/World.js"
import ExperienceManager from "@/webgl/ExperienceManager.js"
import RIMMaterial from "@/webgl/world/materials/RIM/index.js"
import MATERIALS from "@/webgl/world/materials/infos.js"
import News from "@/webgl/world/components/news/News.js"
import Department from "@/webgl/world/components/departments/Department.js"
import Gates from "@/webgl/world/components/gates/Gates.js"

const VECTOR_1 = new Vector3()
const VECTOR_2 = new Vector3()
const VECTOR_3 = new Vector3()
const GROUND_LEVEL = 2.645
const MATRIX = new Matrix4()

let departments = null
let news = null
let gates = null
export default class Continent {
  constructor(_options) {
    this.planetBase = _options.planetBase
    this.planetCore = _options.planetCore
    this.currentPlanetConfig = _options.currentPlanetConfig
    this.name = _options.brandName
    this.safezone = _options.safezones

    this.setupBasicProperties()
    this.setupContinent()
  }

  setupBasicProperties() {
    this.experience = new ExperienceManager()
    this.scene = this.experience.scene
    this.renderer = this.experience.renderer
    this.camera = this.experience.camera
    this.sizes = this.experience.sizes
    this.time = this.experience.time
    this.world = this.experience.world

    this.base = new Object3D()
    this.base.name = `continent-${this.name}`
    this.planetBase.add(this.base)
    this.base.continentalNews = {}

    this.brands = {}
    this.infos = this.currentPlanetConfig.brands[this.name]
  }

  setupContinent() {
    const logoContainer = new Object3D()
    const { continent, safezone, logo, view, ground, departmentsGrounds } =
      this.currentPlanetConfig.brands[this.name]

    if (ground) {
      this.createGround(ground)
      this.setNews()
    }

    if (continent) {
      this.setupLogoMaterials()
      this.setupContinentMaterials(continent, this.name, this.base)
      this.territories = []
      this.outlines = []

      continent.children.forEach((child) => {
        if (child.name.includes("territory")) {
          this.territories.push(child)
          this.base.territories = this.territories
        }

        if (child.name.includes("outline")) {
          this.outlines.push(child)
          this.base.outlines = this.outlines
        }
      })
    }

    if (safezone) {
      this.setupSafezone(safezone, this.name, this.base)
    }

    if (logo && logoContainer && continent) {
      if (
        this.currentPlanetConfig.whiteLogosVisible ||
        logo.name === "Logo_RGS" ||
        logo.name === "Logo_RGN"
      ) {
        this.setupBrandLogos(logo, logoContainer, continent, this.base)
      }
    }

    if (
      ground &&
      departmentsGrounds &&
      this.currentPlanetConfig.brandsWithGates.includes(this.name)
    ) {
      // this.setupGates(continent, ground, this.name, departments)
      this.setupDepartments(continent, ground, this.name, departmentsGrounds)
    } else {
      departments = null
    }

    this.brands[this.name] = {
      ...this.base,
      safezone,
      logoContainer,
      logo,
      view,
      ground,
      gates,
    }

    this.updateLogo(this.brands[this.name], this.name)
  }

  /**
   * Creates and configures the base ground for gates
   */
  createGround(ground) {
    if (!ground) return

    const groundGeometry = new CircleGeometry(30, 32)
    groundGeometry.rotateX(Math.PI / 2)

    // const groundMaterial = new GroundMaterial(this.continentMaterial.ground, 0.0)
    const groundMaterial = new MeshBasicMaterial({
      color: 0xff0000,
      side: DoubleSide,
      transparent: true,
      opacity: 0,
    })

    this.ground = new Mesh(groundGeometry, groundMaterial)
    this.ground.name = `continent-ground`
    this.ground.continentaldepartment = {}
    this.ground.continentalNews = {}
    this.ground.layers.set(2)
    this.base.add(this.ground)
    this.base.ground = this.ground
    this.ground.visible = false
    this.ground.scale.setScalar(0.01)
    this.ground.position.copy(ground.position).setLength(GROUND_LEVEL)
    this.ground.up.copy(ground.up).normalize()

    MATRIX.makeRotationX(Math.PI * 0.5)
    this.ground.matrix
      .lookAt(ground.position, new Vector3(0, 0, 0), new Vector3(0, -1, 0))
      .multiply(MATRIX)
    this.ground.quaternion.setFromRotationMatrix(this.ground.matrix)
  }

  /**
   * Sets labels texts on each gate
   */
  setNews() {
    if (!this.currentPlanetConfig.isInteractive) {
      return
    }

    news = new News({
      planetBase: this.planetBase,
      continentBase: this.base,
      continentGround: this.ground,
      continentName: this.name,
    })

    this.base.cardsNews = news

    news.init()
  }

  /**
   * Sets up the continent mesh for a brand.
   * @param {THREE.Mesh} continent - The continent mesh object.
   * @param {String} brand - The brand identifier.
   * @param {THREE.Group} group - The group to which the continent will be added.
   */
  setupContinentMaterials(continent, brand, group) {
    // Set the material for the continent
    continent.material = new RIMMaterial(MATERIALS[this.currentPlanetConfig.name][brand].planet)

    // Set the directional light properties
    continent.material.uniforms.uDirectionalLightColor1.value.set(this.scene.children[2].color)
    continent.material.uniforms.uDirectionalLightDirection1.value
      .copy(this.scene.children[2].position)
      .normalize()

    continent.material.uniforms.uDirectionalLightColor2.value.set(this.scene.children[3].color)
    continent.material.uniforms.uDirectionalLightDirection2.value
      .copy(this.scene.children[3].position)
      .normalize()

    // Assign a name to the continent mesh
    continent.name = `continent_${brand}_mesh`

    // Add the continent to the group
    group.add(continent)

    // Iterate over the children of the continent and assign random colors

    if (continent.children) {
      continent.children.forEach((child) => {
        child.castShadow = true
        child.receiveShadow = true

        child.material = new RIMMaterial(MATERIALS[this.currentPlanetConfig.name][brand].planet)
        // Set the directional light properties
        child.material.uniforms.uDirectionalLightColor1.value.set(this.scene.children[2].color)
        child.material.uniforms.uDirectionalLightDirection1.value
          .copy(this.scene.children[2].position)
          .normalize()

        child.material.uniforms.uDirectionalLightColor2.value.set(this.scene.children[3].color)
        child.material.uniforms.uDirectionalLightDirection2.value
          .copy(this.scene.children[3].position)
          .normalize()
      })
    }
  }

  /**
   * Initializes the material for the logos on the planet.
   * This method creates a new RIMMaterial with specific color, rim color, and transparency settings.
   * The material is then used for the logos displayed on the planet.
   */
  setupLogoMaterials() {
    this.logoMaterial = new RIMMaterial({
      color: 0xdddddd,
      rimColor: 0xffffff,
      rimForce: 1.0,
      emissiveColor: 0xffffff,
      emissiveIntensity: 0.5,
    })
    this.logoMaterial.toneMapped = false
    this.logoMaterial.transparent = true
    this.logoMaterial.uniforms.opacity.value = 1.0
  }

  /**
   * Sets up the safezone for a brand.
   * @param {Object} safezone - The safezone object containing curve data.
   * @param {string} brand - The brand identifier.
   * @param {THREE.Group} group - The group to which the safezone will be added.
   */
  setupSafezone(safezone, brand, group) {
    if (!safezone) return

    safezone.curve = this.safezone[brand]
    const { line } = safezone.curve
    line.visible = false
    line.name = `safezone-line-${brand}`
    group.add(line)
    group.safezone = safezone
  }

  /**
   * Sets up the logo for a brand.
   * @param {THREE.Mesh} logo - The logo mesh object.
   * @param {THREE.Mesh} continent - The continent mesh object associated with the logo.
   * @param {THREE.Group} group - The group to which the logo will be added.
   */
  setupBrandLogos(logo, logoContainer, continent, group) {
    logo.material.dispose()
    logo.material = this.logoMaterial
    logo.position.set(0, 0, 2.59)

    logoContainer.lookAt(continent.position)
    logoContainer.add(logo)
    logoContainer.name = logo.name
    group.add(logoContainer)
  }

  /**
   * Sets up the departments for a brand.
   * @param {THREE.Mesh} ground - The ground mesh object.
   * @param {string} brand - The brand identifier.
   */

  setupDepartments(continent, ground, brand, departmentGround) {
    if (!this.currentPlanetConfig.isInteractive) {
      return
    }

    departments = new Department({
      brand: brand,
      planetBase: this.planetBase,
      continent: continent,
      continentBase: this.base,
      continentGround: this.ground,
      departmentGround: departmentGround,
      groundLevel: GROUND_LEVEL,
      material: MATERIALS[this.currentPlanetConfig.name][brand],
    })

    this.base.departments = departments

    departments.init()
  }

  /**
   * Sets up the gates for a brand.
   * @param {THREE.Mesh} ground - The ground mesh object.
   * @param {string} brand - The brand identifier.
   */

  setupGates(continent, ground, brand, departments) {
    if (!this.currentPlanetConfig.isInteractive) {
      return
    }

    gates = new Gates(this.planetBase, {
      continent: continent,
      continentBase: this,
      continentGround: this.ground,
      brand: brand,
      department: departments,
      material: MATERIALS[this.currentPlanetConfig.name][brand],
      groundPosition: ground.position,
      groundLevel: GROUND_LEVEL,
    })

    this.base.gates = gates

    gates.init()
  }

  /**
   * Updates the orientation of a brand's logo to face the camera.
   * @param {Object} brand - The brand data including logo and safezone.
   */
  updateLogo(brand) {
    const { safezone, logoContainer } = brand

    const camera = this.camera.instance

    if (safezone && logoContainer) {
      const { curve, line, points } = safezone.curve
      const { point, index } = this.getClosestSafezonePointFrom(safezone, camera)
      VECTOR_1.copy(point)

      safezone.curve.progress += (index / points.length - safezone.curve.progress) / 50
      curve.getPoint(safezone.curve.progress, VECTOR_1)
      line.localToWorld(VECTOR_1)

      //Orient logo towards the camera
      const lookAtTarget = new Vector3().copy(VECTOR_1)
      lookAtTarget.y = logoContainer.position.y // Maintain the same Y level to avoid tilting
      logoContainer.lookAt(lookAtTarget)

      // Align logo's top to be upwards
      const upDirection = new Vector3(1, 0, 0) // World's up direction
      logoContainer.up.copy(upDirection)
      logoContainer.rotateOnWorldAxis(upDirection, Math.PI / 2)
    }
  }

  /**
   * Finds the closest point on a safezone's curve to a given target.
   *
   * @param {Object} safezone - The safezone object with a curve.
   * @param {Object3D} target - The target object to find the closest point to.
   * @returns {Object} An object containing the closest point and its index on the curve.
   */
  getClosestSafezonePointFrom(safezone, target) {
    if (!safezone || !target) {
      console.warn("Invalid safezone or target for getClosestSafezonePointFrom")
      return null
    }

    // Extract the position attribute from the safezone's curve line geometry
    const { position } = safezone.curve.line.geometry.attributes

    let shortestDistance = Infinity
    let shortestIndex = 0
    VECTOR_1.setScalar(Infinity)

    for (let index = 0; index < position.count; index++) {
      VECTOR_2.fromBufferAttribute(position, index)
      safezone.curve.line.localToWorld(VECTOR_2)

      target.getWorldPosition(VECTOR_3)
      const distance = VECTOR_2.distanceTo(VECTOR_3)

      // Update shortest distance and point if a closer point is found
      if (distance < shortestDistance) {
        shortestDistance = distance
        shortestIndex = index
        VECTOR_1.copy(VECTOR_2)
      }
    }

    return {
      point: VECTOR_1.clone(),
      index: shortestIndex,
    }
  }

  isCameraCloseToCardNews(continentalNews, camera) {
    if (!this.labelsVisibilityUpdated) return

    if (
      this.world.explorationView !== EXPLORATION.IN_CONTINENTAL_NEWS &&
      (!continentalNews || Object.keys(continentalNews).length === 0)
    ) {
      console.error(
        "No news cards:",
        continentalNews ? Object.keys(continentalNews).length : "undefined",
      )

      return
    }

    if (this.world.explorationView !== EXPLORATION.IN_CONTINENTAL_NEWS) {
      Object.values(continentalNews).forEach((value) => {
        value.element.firstChild.classList.remove("first-plan", "second-plan", "third-plan")
      })
      return
    }

    // Get camera position
    const cameraPosition = new Vector3()
    camera.getWorldPosition(cameraPosition)

    // Create an array to store news items with their distances
    let newsItems = []

    // Calculate distances for each news item
    Object.keys(continentalNews).forEach((key) => {
      const news = continentalNews[key]
      const newsPosition = new Vector3()
      newsPosition.setFromMatrixPosition(new Matrix4().fromArray(news.matrix))
      const distance = cameraPosition.distanceTo(news.getWorldPosition(newsPosition))
      newsItems.push({ key, distance, news })
    })

    // Sort the news items based on distance
    newsItems.sort((a, b) => a.distance - b.distance)

    // Determine dynamic thresholds
    const secondPlanThreshold = newsItems[Math.floor(newsItems.length * 0.2)].distance
    const thirdPlanThreshold = newsItems[Math.floor(newsItems.length * 0.5)].distance

    // Clean and assign classes based on dynamic thresholds
    newsItems.forEach((item) => {
      const existingLabel = this.base.continentalNews[item.key]
      if (existingLabel) {
        const element = existingLabel.element.firstChild
        // Remove existing plan classes
        element.classList.remove("first-plan", "second-plan", "third-plan")

        // Determine and add the new class
        let className
        if (item.distance <= secondPlanThreshold) {
          className = "first-plan"
        } else if (item.distance <= thirdPlanThreshold) {
          className = "second-plan"
        } else {
          className = "third-plan"
        }

        element.classList.add(className)
      }
    })
  }

  isCameraCloseToDepartments(camera) {
    // Create an array to store news items with their distances
    let newsItems = []

    if (this.world.explorationView !== EXPLORATION.IN_DEPARTMENTS) {
      Object.values(this.base.departmentLabels).forEach((value) => {
        value.element.firstChild.classList.remove("first-plan", "second-plan", "third-plan")
      })
      return
    }

    if (!this.departmentsLabelsVisibilityUpdated) return

    // Get camera position
    const cameraPosition = new Vector3()
    camera.getWorldPosition(cameraPosition)

    // Calculate distances for each news item
    Object.keys(this.base.departments.labels).forEach((key) => {
      const news = this.base.departments.labels[key]
      const newsPosition = new Vector3()
      newsPosition.setFromMatrixPosition(new Matrix4().fromArray(news.matrix))
      const distance = cameraPosition.distanceTo(news.getWorldPosition(newsPosition))
      newsItems.push({ key, distance, news })
    })

    // Sort the news items based on distance
    newsItems.sort((a, b) => a.distance - b.distance)

    // Determine dynamic thresholds
    const secondPlanThreshold = newsItems[Math.floor(newsItems.length * 0.2)].distance
    const thirdPlanThreshold = newsItems[Math.floor(newsItems.length * 0.5)].distance

    // Clean and assign classes based on dynamic thresholds
    newsItems.forEach((item) => {
      const existingLabel = this.base.departments.labels[item.key]

      if (existingLabel) {
        const element = existingLabel.element.firstChild
        // Remove existing plan classes
        element.classList.remove("first-plan", "second-plan", "third-plan")

        // Determine and add the new class
        let className
        if (item.distance <= secondPlanThreshold) {
          className = "first-plan"
        } else if (item.distance <= thirdPlanThreshold) {
          className = "second-plan"
        } else {
          className = "third-plan"
        }

        element.classList.add(className)
      }
    })
  }

  isCameraCloseToContinent(continent, camera) {
    // Check if continent is defined and has children
    if (!continent) {
      console.error("Continent is not properly initialized or has no children:", continent)
      return false
    }

    const threshold = 4.8

    // Get camera position
    const cameraPosition = new Vector3()
    camera.getWorldPosition(cameraPosition)

    // Calculate horizontal distance
    const distance = cameraPosition.distanceTo(continent.ground.position)

    return distance < threshold
  }

  updateNewsLabelsVisibility(continentIsClose) {
    if (this.world.explorationView !== EXPLORATION.IN_CONTINENTAL_NEWS) return

    const opacityChange = this.time.delta * 0.005

    Object.values(this.base.continentalNews).forEach((object) => {
      const currentOpacity = parseFloat(object.element.firstChild.style.opacity) || 0
      const newOpacity = continentIsClose
        ? Math.min(currentOpacity + opacityChange, 1)
        : Math.max(currentOpacity - opacityChange, 0)

      if (newOpacity !== currentOpacity) {
        object.element.firstChild.style.opacity = newOpacity
        object.element.firstChild.style.transform = `scale(${newOpacity})`
        object.element.firstChild.style.pointerEvents = newOpacity > 0 ? "auto" : "none"
      }
    })

    this.labelsVisibilityUpdated = true
  }

  updatDepartmentsLabelsVisibility(continentIsClose) {
    if (this.world.explorationView !== EXPLORATION.IN_DEPARTMENTS) return

    const opacityChange = this.time.delta * 0.05

    Object.values(this.base.departments.labels).forEach((object) => {
      const currentOpacity = parseFloat(object.element.firstChild.style.opacity) || 0
      const newOpacity = continentIsClose
        ? Math.min(currentOpacity + opacityChange, 1)
        : Math.max(currentOpacity - opacityChange, 0)

      if (newOpacity !== currentOpacity) {
        object.element.firstChild.style.opacity = newOpacity
        object.element.firstChild.style.transform = `scale(${newOpacity})`
        object.element.firstChild.style.pointerEvents = newOpacity > 0 ? "auto" : "none"
      }
    })

    this.departmentsLabelsVisibilityUpdated = true
  }

  updateThirdPlanCards() {
    if (this.world.explorationView !== EXPLORATION.IN_CONTINENTAL_NEWS) return

    const distanceThreshold = 4.3

    Object.values(this.base.continentalNews).forEach((item) => {
      const cardPosition = item.getWorldPosition(new Vector3())
      const cameraPosition = new Vector3()
      this.camera.instance.getWorldPosition(cameraPosition)

      // Calculate distance from the camera to the card
      const distance = cameraPosition.distanceTo(cardPosition)

      // Show only cards within the distance threshold
      if (distance < distanceThreshold) {
        item.element.firstChild.classList.remove("hide")
      } else {
        item.element.firstChild.classList.add("hide")
      }
    })
  }

  update() {
    const continentIsClose = this.isCameraCloseToContinent(this.base, this.camera.instance)

    if (this.base.departments) {
      this.base.departments.update()

      this.updatDepartmentsLabelsVisibility(continentIsClose)
      this.isCameraCloseToDepartments(this.camera.instance)
    }

    if (this.world.explorationView === EXPLORATION.IN_ORBIT) return

    this.updateNewsLabelsVisibility(continentIsClose)
    this.isCameraCloseToCardNews(this.base.continentalNews, this.camera.instance)

    this.updateThirdPlanCards()
  }

  /**
   * Cleans up resources when they are no longer needed.
   * This method helps prevent memory leaks and ensures efficient resource management.
   */
  dispose() {
    if (!this.scene) return

    const objectsToRemove = []

    this.scene.traverse((object) => {
      if (object instanceof Mesh) {
        if (object.geometry) object.geometry.dispose()
        if (Array.isArray(object.material)) {
          object.material.forEach((material) => material.dispose())
        } else if (object.material) {
          object.material.dispose()
        }
        objectsToRemove.push(object)
      } else if (object instanceof CSS2DObject) {
        if (object.element && object.element.parentNode) {
          object.element.parentNode.removeChild(object.element)
        }
        object.element = null
        objectsToRemove.push(object)
      }
    })

    objectsToRemove.forEach((object) => {
      if (object.parent) {
        object.parent.remove(object)
      } else {
        this.scene.remove(object)
      }
    })
  }
}
