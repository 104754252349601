import { MathUtils, Sphere } from "three"
import { CSS2DObject } from "three/addons/renderers/CSS2DRenderer.js"
import { raftween } from "@/utils/anim/index.js"
import { createLogger } from "@/utils/debug/index.js"
import { EXPERIENCE_TYPE } from "@/webgl/world/World.js"
import { scaleAndMoveGate } from "@/webgl/world/components/gates/utils/animUtils.js"
import ExperienceManager from "@/webgl/ExperienceManager.js"
import CursorManager from "@/utils/CursorManager.js"

const log = createLogger("Gate SubMenu", "#000", "#ffd067").log

export default class Submenu {
  constructor(base, gate) {
    this.experience = new ExperienceManager()
    this.world = this.experience.world
    this.time = this.experience.time
    this.camera = this.experience.camera
    this.renderer = this.experience.renderer
    this.sizes = this.experience.sizes

    this.labelTweens = []
    this.base = base
    this.currentActiveSubMenu = null
    this.submenuToExperience = false
    this.gate = gate
    this.initialDistanceFromCamera = null
    this.submenuIsOpen = false
    this.addResizeListener()
  }

  /**
   * Initializes the gates and their positioning
   */
  init() {
    log("Preload submenu")
  }

  showSubmenu(gate) {
    if (!gate || !gate.hasSubmenu) return

    this.openSubmenu(gate)
  }

  openSubmenu(gate) {
    if (!gate || this.isDragging) return

    const tweenOpeningSubmenu = scaleAndMoveGate({
      name: "opening submenu",
      experience: this.experience,
      base: this.base,
      gate,
      scaleFrom: 1,
      scaleTo: 2.5,
      moveAwayDistance: 5,
      duration: 500,
      easing: "inCirc",
      openingSubmenu: true,
      closingSubmenu: false,
      openingExp: false,
      closingExp: false,
      onComplete: () => {
        this.currentActiveSubMenu = gate
        this.createSubmenu()

        if (this.currentActiveSubMenu) {
          this.currentActiveSubMenu.submenuLabel.element.classList.remove("hidden-label")
          this.currentActiveSubMenu.submenuSlider.element.children[1].classList.remove("hide")
          this.currentActiveSubMenu.submenuSlider.element.children[1].classList.add("show")
          this.currentActiveSubMenu.mainLabel.element.classList.add("hidden-label")
          this.currentActiveSubMenu.mainLabel.element.classList.remove("show-label")
        }
        this.world.controls.gatesPanner.disable()
        this.experience.isGateSubMenuOpen(true)
        this.submenuIsOpen = true
      },
    })

    this.labelTweens.push(tweenOpeningSubmenu)
  }

  /**
   * Shows the submenu for a specific gate
   * @param {Object} gate - The gate for which to show the submenu
   */
  createSubmenu() {
    if (!this.currentActiveSubMenu || this.currentActiveSubMenu.submenuLabel) {
      return
    }

    // Define continent order and initialize containers
    const continentOrder = {
      favorites: 0,
      null: 1, // No continent has the highest priority
      America: 2,
      "AMI - Pacific": 3,
    }

    // Main menu container

    const menuContainer = document.createElement("div")
    menuContainer.className = "gate-submenus"

    const submenuContainer = document.createElement("div")
    submenuContainer.className = "gate-submenus-container"

    const submenuWrapper = document.createElement("div")
    submenuWrapper.className = "gate-submenus-wrapper"

    const sliderContainer = document.createElement("div")
    sliderContainer.className = "gate-submenus-slider"

    const specialContainer = document.createElement("div")
    specialContainer.className = "gate-submenus-special"

    const favoritesContainer = document.createElement("div")
    favoritesContainer.className = "gate-submenus-favorite"

    const nonFavoritesContainer = document.createElement("div")
    nonFavoritesContainer.className = "gate-submenus-non-favorite"

    const { favoritesItems, nonFavoritesItems, specialItems } =
      this.handleSubmenuItems(continentOrder)

    specialItems.forEach((item) => this.addMenuItem(specialContainer, item))
    if (specialItems.length > 0) {
      submenuContainer.appendChild(specialContainer)
    }

    favoritesItems.forEach((item) => this.addMenuItem(favoritesContainer, item))
    if (favoritesItems.length > 0) {
      submenuContainer.appendChild(favoritesContainer)
    }

    this.filterNonFavoritesByContinent(nonFavoritesItems, nonFavoritesContainer, continentOrder)
    submenuContainer.appendChild(nonFavoritesContainer)

    submenuWrapper.appendChild(submenuContainer)
    menuContainer.appendChild(submenuWrapper)
    menuContainer.appendChild(sliderContainer)

    const submenuLabel = new CSS2DObject(menuContainer)
    submenuLabel.position.set(0, this.currentActiveSubMenu.geometry.parameters.height / 2, 0)

    const submenuSliderHTML = this.createSubMenuSlider(menuContainer, sliderContainer)
    const submenuSlider = new CSS2DObject(submenuSliderHTML)
    submenuSlider.position.set(0, this.currentActiveSubMenu.geometry.parameters.height / 2, 0)

    this.handleSubMenuSlider(submenuLabel, submenuSlider)

    sliderContainer.classList.add("show")
    submenuContainer.classList.add("expanded")

    this.currentActiveSubMenu.add(submenuLabel, submenuSlider)
    this.currentActiveSubMenu.submenuLabel = submenuLabel
    this.currentActiveSubMenu.submenuSlider = submenuSlider
  }

  handleSubmenuItems(continentOrder) {
    const specialItems = this.currentActiveSubMenu.submenuItems
      .filter((item) => item.special)
      .slice(0, 5)
      .sort((a, b) => a.name.localeCompare(b.name))

    const favoritesItems = this.currentActiveSubMenu.submenuItems
      .filter((item) => item.favorite)
      .slice(0, 5)
      .sort((a, b) => a.name.localeCompare(b.name))

    const nonFavoritesItems = this.currentActiveSubMenu.submenuItems.sort((a, b) => {
      const orderA = continentOrder[a.continent] ?? continentOrder.null
      const orderB = continentOrder[b.continent] ?? continentOrder.null
      if (orderA !== orderB) return orderA - orderB
      return a.name.localeCompare(b.name)
    })

    return { favoritesItems, nonFavoritesItems, specialItems }
  }

  filterNonFavoritesByContinent(nonFavorites, container) {
    let lastContinent = null
    nonFavorites.forEach((item) => {
      if (item.continent !== lastContinent) {
        lastContinent = item.continent
        if (lastContinent) {
          const continentLabel = document.createElement("div")
          continentLabel.className = "continent-label gate-submenu-item"
          continentLabel.textContent = lastContinent || "No Continent"
          container.appendChild(continentLabel)
        }
      }
      this.addMenuItem(container, item)
    })
  }

  addMenuItem(container, item) {
    const menuItem = document.createElement("div")
    menuItem.className = `gate-submenu-item ${item.available ? "available" : "disabled"}`
    menuItem.textContent = item.name

    menuItem.onclick = () => {
      if (item.available) {
        this.world.selectGate(item.url)
        if (item.isExternalExperience) {
          this.world.currentExperienceType = EXPERIENCE_TYPE.IN_EXTERNAL_EXPERIENCE
          this.experience.currentExperienceType("IN-EXTERNAL-EXPERIENCE")
        } else {
          this.world.currentExperienceType = EXPERIENCE_TYPE.IN_2D_EXPERIENCE
          this.experience.currentExperienceType("IN-2D-EXPERIENCE")
        }

        this.submenuToExperience = true
      }
    }

    container.appendChild(menuItem)
  }

  createSubMenuSlider(menuContainer, sliderContainer) {
    const slider = document.createElement("div")
    slider.className = "slider"

    // Define SVG
    const svgNS = "http://www.w3.org/2000/svg"
    const svg = document.createElementNS(svgNS, "svg")
    svg.setAttribute("width", "100%")
    svg.setAttribute("height", "100%")
    svg.setAttribute("viewBox", "50 10 77 300")

    // Create a path element for the arc
    const path = document.createElementNS(svgNS, "path")
    path.setAttribute("id", "sliderArc")
    path.setAttribute("d", "M 70,30 A 200,200 0 0,1 60,300")
    path.setAttribute("stroke", "#ffffff")
    path.setAttribute("stroke-width", "3")
    path.setAttribute("fill", "none")

    // Append the path to the SVG container
    svg.appendChild(path)

    // Create circle element for the slider
    this.circle = document.createElementNS(svgNS, "circle")
    this.circle.setAttribute("id", "sliderCircle")
    this.circle.setAttribute("cx", "70")
    this.circle.setAttribute("cy", "30")
    this.circle.setAttribute("r", "7")
    this.circle.setAttribute("fill", "#ffffff")
    svg.appendChild(this.circle)

    // Append the SVG to the document
    slider.appendChild(svg)
    sliderContainer.appendChild(slider)
    menuContainer.appendChild(sliderContainer)

    return menuContainer
  }

  handleSubMenuSlider(subMenuLabel, submenuSlider) {
    if (!this.currentActiveSubMenu) return
    this.subMenuLabel = subMenuLabel.element
    this.submenuSlider = submenuSlider
    this.sliderCircle = submenuSlider.element.querySelector("#sliderCircle")
    this.arcPath = submenuSlider.element.querySelector("#sliderArc")
    this.svgElement = submenuSlider.element.querySelector("svg")
    this.pathLength = this.arcPath.getTotalLength()
    this.listItems = subMenuLabel.element.querySelectorAll(".gate-submenu-item")
    this.subMenuLabelElement = subMenuLabel.element.firstChild.firstChild

    this.updateListItems(0, false)

    this.attachEventListeners()
  }

  attachEventListeners() {
    this.sliderCircle.addEventListener("pointerdown", this.handlePointerDown.bind(this))
    this.subMenuLabelElement.addEventListener("pointerdown", this.handlePointerDown.bind(this))

    document.addEventListener("pointermove", this.handlePointerMove.bind(this))
    document.addEventListener("pointerup", this.handlePointerUp.bind(this))

    if (!this.isTablet) {
      this.subMenuLabelElement.addEventListener("scroll", this.handleSliderScroll.bind(this), {
        passive: true,
      })
    }
  }

  handlePointerDown(event) {
    if (event.target === this.sliderCircle) {
      this.isDragging = true
    } else if (event.target === this.subMenuLabelElement || this.subMenuLabelElement.children) {
      this.isSliding = true
      this.initialTouchY = event.clientY
      this.initialScrollTop = this.subMenuLabelElement.scrollTop
    }

    this.subMenuLabelElement.classList.add("grabbing")
    this.sliderCircle.classList.add("grabbing")
  }

  handlePointerMove(event) {
    if (this.isDragging) {
      const percentage = this.calculatePercentage(event)
      this.updateUI(percentage, false)

      CursorManager.setCursor("grabbing")
    } else if (this.isSliding) {
      const currentPointerY = event.clientY
      const deltaY = currentPointerY - this.initialTouchY
      const newScrollTop = this.initialScrollTop - deltaY

      const totalScrollHeight =
        this.subMenuLabelElement.scrollHeight - this.subMenuLabelElement.clientHeight
      const clampedScrollTop = Math.max(0, Math.min(newScrollTop, totalScrollHeight))
      const scrollPercentage = clampedScrollTop / totalScrollHeight

      this.updateUI(scrollPercentage, false)

      CursorManager.setCursor("grabbing")
    }
  }

  handlePointerUp() {
    this.isDragging = false
    this.isSliding = false
    this.subMenuLabelElement.classList.remove("grabbing")
    this.sliderCircle.classList.remove("grabbing")
  }

  handleSliderScroll() {
    if (this.isDragging || this.isSliding) return

    this.isScrolling = true

    this.subMenuLabelElement.classList.add("grabbing")

    const newScrollTop = this.subMenuLabelElement.scrollTop
    const totalScrollHeight =
      this.subMenuLabelElement.scrollHeight - this.subMenuLabelElement.clientHeight
    const scrollPercentage = newScrollTop / totalScrollHeight
    // this.smoothScroll(this.subMenuLabelElement, newScrollTop)
    this.updateUI(scrollPercentage)

    clearTimeout(this.scrollTimeout)
    this.scrollTimeout = setTimeout(() => {
      this.isScrolling = false
      this.subMenuLabelElement.classList.remove("grabbing")
    }, 100)
  }

  smoothScroll(element, targetScrollTop) {
    this.tweenScroll = raftween({
      name: "submenu scroll",
      from: element.scrollTop,
      to: targetScrollTop,
      duration: 1000,
      easing: "ease",
      onProgress: (progress, _) => {
        element.scrollTop = progress
      },
      onComplete: () => {
        if (this.tweenScroll) {
          this.tweenScroll.destroy()
          this.tweenScroll = null
        }
      },
    })
    this.labelTweens.push(this.tweenScroll)
  }

  calculatePercentage(event) {
    const clientY = event.type.includes("touch") ? event.touches[0].clientY : event.clientY
    const svgRect = this.svgElement.getBoundingClientRect()
    const relativeY = clientY - svgRect.top
    return Math.max(0, Math.min(relativeY / svgRect.height, 1))
  }

  updateUI(percentage) {
    const sliderPosition = Math.round(percentage * (this.listItems.length - 1))

    this.updateListItems(sliderPosition)

    // Ensure percentage is a finite number between 0 and 1
    if (isNaN(percentage) || !isFinite(percentage)) {
      return
    }

    // Clamp the percentage to the range [0, 1] to avoid errors
    const clampedPercentage = Math.max(0, Math.min(1, percentage))

    const point = this.arcPath.getPointAtLength(this.pathLength * clampedPercentage)
    this.sliderCircle.setAttribute("cx", point.x.toString())
    this.sliderCircle.setAttribute("cy", point.y.toString())
  }

  updateListItems(sliderPosition) {
    this.listItems.forEach((item, index) => {
      if (index === sliderPosition && !this.isScrolling) {
        const itemOffsetTop = item.offsetTop
        const containerHalfHeight = this.subMenuLabelElement.clientHeight / 2
        const itemCenterOffset = item.offsetHeight / 2
        const newScrollTop = itemOffsetTop + itemCenterOffset - containerHalfHeight

        this.subMenuLabelElement.scrollTop = newScrollTop
      }
    })
  }

  hideSubmenu() {
    if (!this.currentActiveSubMenu || this.isDragging) return

    this.closeSubmenu()
    this.hideUI()

    this.currentActiveSubMenu = null
    this.submenuIsOpen = false
    this.world.controls.gatesPanner.enable()
    this.experience.isGateSubMenuOpen(false)
  }

  hideUI() {
    // Reset submenu container back at the top
    this.subMenuLabelElement.scrollTop = 0

    // Reset circle back to top
    this.circle.setAttribute("cx", "70")
    this.circle.setAttribute("cy", "30")

    this.currentActiveSubMenu.submenuSlider.element.children[1].classList.remove("show")
    this.currentActiveSubMenu.submenuSlider.element.children[1].classList.add("hide")

    // Handles hidden or show classes
    this.currentActiveSubMenu.submenuLabel.element.classList.add("hidden-label")
    this.base.children.forEach((gate) => {
      if (gate.mainLabel) {
        gate.mainLabel.element.classList.add("show-label")
        gate.mainLabel.element.classList.remove("hidden-label")
      }
    })

    if (this.currentActiveSubMenu.submenuSlider) {
      this.currentActiveSubMenu.submenuSlider.element.children[1].classList.add("hide")
    }
  }

  isSubmenuOpen() {
    return this.submenuIsOpen === true
  }

  closeSubmenu() {
    if (!this.currentActiveSubMenu || this.gate.isClosing) return

    const tweenClosingSubmenu = scaleAndMoveGate({
      name: "closing submenu",
      experience: this.experience,
      base: this.base,
      gate: this.currentActiveSubMenu,
      scaleFrom: 2.5,
      scaleTo: 1,
      moveAwayDistance: 5,
      duration: 500,
      easing: "outCirc",
      openingSubmenu: false,
      closingSubmenu: true,
      openingExp: false,
      closingExp: false,
      onComplete: () => {
        this.currentActiveSubMenu = null
        this.world.controls.gatesPanner.enable()
        this.experience.isGateSubMenuOpen(false)
      },
    })

    this.labelTweens.push(tweenClosingSubmenu)
  }

  updateSubMenuDivSize() {
    if (!this.currentActiveSubMenu || !this.currentActiveSubMenu.submenuLabel) return

    const boundingSphere = new Sphere()
    this.currentActiveSubMenu.geometry.computeBoundingSphere()
    boundingSphere
      .copy(this.currentActiveSubMenu.geometry.boundingSphere)
      .applyMatrix4(this.currentActiveSubMenu.matrixWorld)

    if (this.initialDistanceFromCamera === null) {
      this.initialDistanceFromCamera = this.camera.instance.position
        .clone()
        .distanceTo(boundingSphere.center)
    }

    const canvas = this.renderer.instance.domElement
    const heightScale =
      canvas.offsetHeight /
      (2 *
        Math.tan(MathUtils.degToRad(this.camera.instance.fov) / 2) *
        this.initialDistanceFromCamera)

    // Calculate diameter using the height scale factor
    const diameter = boundingSphere.radius * 2 * heightScale

    this.currentActiveSubMenu.submenuLabel.element.firstChild.style.width = `${diameter - 30}px`
    this.currentActiveSubMenu.submenuLabel.element.firstChild.style.height = `${diameter - 30}px`

    this.currentActiveSubMenu.submenuLabel.element.style.width = `${diameter}px`
    this.currentActiveSubMenu.submenuLabel.element.style.height = `${diameter}px`
  }

  addResizeListener() {
    if (this.sizes) {
      this.sizes.on("resize", () => {
        this.updateSubMenuDivSize()
      })
    }
  }

  /**
   * Update method called on each frame or tick of the application
   */
  update() {
    this.updateTween()

    this.updateSubMenuDivSize()
  }

  /**
   * Updates the tween animation if it's active
   */
  updateTween() {
    if (this.labelTweens.length > 0) {
      this.labelTweens.forEach((tween) => {
        if (tween) {
          tween.update(this.time.delta)
        }
      })
    }
  }
}
