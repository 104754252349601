import { Vector3 } from "three"
import { raftween } from "@/utils/anim/index.js"
import {
  calculateGateMovement,
  calculateMoveDirection,
  calculateMoveDistance,
  calculateTargetPosition,
} from "./mathUtils.js"

/**
 * Scales and moves a gate and its neighbors
 * @param {Object} gate - The gate to manipulate
 * @param {number} scaleFrom - Initial scale
 * @param {number} scaleTo - Final scale
 * @param {number} moveAwayDistance - Distance to move the neighboring gates
 * @param {number} duration - Animation duration in milliseconds
 * @param {string} easing - Easing function for the transition
 * @param {boolean} openingSubmenu - If true, showing the submenu
 * @param {boolean} openingExp - If true, entering an experience
 * @param {Function|null} onCompleteCallback - Optional callback to execute on completion
 */

export const scaleAndMoveGate = ({
  name,
  experience,
  base,
  gate,
  scaleFrom,
  scaleTo,
  moveAwayDistance,
  duration,
  easing,
  openingSubmenu = false,
  closingSubmenu = false,
  openingExp = false,
  closingExp = false,
  onComplete,
}) => {
  try {
    gate.getWorldPosition(new Vector3())

    if (openingExp) {
      experience.currentPlanetView("ENTERING-EXPERIENCE")
    } else if (closingExp) {
      experience.currentPlanetView("ENTERING-CONTINENT")
    }

    let tween = raftween({
      name: name,
      from: scaleFrom,
      to: scaleTo,
      duration: duration,
      easing: easing,
      onProgress: (progress, value) => {
        gate.scale.set(value, gate.position.y, value)

        base.children.forEach((child) => {
          if (child.isGate && child !== gate) {
            const moveDirection = calculateMoveDirection({ child, gate })
            const moveDistance = calculateMoveDistance({
              progress,
              moveAwayDistance,
              openingExp,
              openingSubmenu,
              child,
            })
            const targetPosition = calculateTargetPosition({ child, moveDirection, moveDistance })

            calculateGateMovement({ gate: child, targetPosition, progress })
          }
        })
      },
      onComplete: () => {
        if (openingExp) {
          experience.currentPlanetView("IN-EXPERIENCE")
        } else if (closingExp || closingSubmenu) {
          experience.currentPlanetView("IN-CONTINENT")
          experience.currentExperienceType("NONE")
        }

        if (onComplete) {
          onComplete()
        }

        if (tween) {
          tween.destroy()
          tween = null
        }
      },
    })

    return tween
  } catch (error) {
    console.error("Raftween error", error)
  }
}
