/**
 * SceneLoading.jsx
 *
 * The SceneLoading component displays a loading screen for the scene, showing the current loading progress.
 * It updates dynamically based on the loading percentage and visibility state managed by the useRooms hook.
 *
 * Key functionalities include:
 * - Fetching and displaying the loading percentage.
 * - Updating the displayed loading value as the loading progresses.
 * - Conditionally rendering the loading screen based on the showLoading state.
 *
 * The component returns a div element containing the loading screen, which includes the current loading progress and other loading-related elements.
 */

import { useEffect, useState } from "react"
import { useRooms } from "@/hooks/metaverse/useRooms"
import "./scene-loading.scss"

const SceneLoading = () => {
  const { showLoading, loadingPercent } = useRooms()
  const [value, setValue] = useState(0)

  // Handles updating the value based on loading percentage
  useEffect(() => {
    if (value < loadingPercent) {
      setValue(loadingPercent)
    }
  }, [loadingPercent, value])

  if (showLoading) {
    return (
      <>
        <div id="sceneLoading" className="full">
          <div className="sceneLoading-background"></div>
          <div className="blur-background blur"></div>
          <div className="sceneLoading-modal">
            <div className="sceneLoading-modal-ring">
              <div className="sceneLoading-ring lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="sceneLoading-percent">{value} %</div>
            <h4 className="sceneLoading-modal-label">Your experience is loading</h4>
            <div className="sceneLoading-modal-icons">
              <div className="loading-icons desktop">
                <img src="https://sdk.metav.rs/static/icons/mouse_moove.svg" alt="" />
                <img
                  className="small"
                  src="https://sdk.metav.rs/static/icons/plus_petit.svg"
                  alt=""
                />
                <img src="https://sdk.metav.rs/static/icons/key_moove.svg" alt="" />
                <img
                  className="small"
                  src="https://sdk.metav.rs/static/icons/plus_petit.svg"
                  alt=""
                />
                <img src="https://sdk.metav.rs/static/icons/swipe.svg" alt="" />
              </div>
              <div className="loading-icons mobile">
                <img src="https://sdk.metav.rs/static/icons/swipe.svg" alt="" />
              </div>
            </div>
            <div className="sceneLoading-modal-subtitle">
              <p className="desktop">Use your keyboard arrows or your mouse to turn your avatar</p>
              <p className="mobile">Use your finger to turn your avatar</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default SceneLoading
