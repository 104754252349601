import { useEffect, useState } from "react"
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core"
import { toolbarPlugin } from "@react-pdf-viewer/toolbar"
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight"

import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/toolbar/lib/styles/index.css"
import "@react-pdf-viewer/highlight/lib/styles/index.css"

const PdfViewer = (mediaContent) => {
  const toolbarPluginInstance = toolbarPlugin()
  const { Toolbar } = toolbarPluginInstance

  const [areas, setAreas] = useState([])

  useEffect(() => {
    if (mediaContent && mediaContent?.source && mediaContent?.source?.bounding_box) {
      const boundingBoxes = Object.values(mediaContent.source.bounding_box?.areas || {}).flatMap(
        (area) =>
          area.bounding_boxes.map((box) => {
            const { Height, Left, Top, Width, ...rest } = box.bounding_box

            return {
              pageIndex: parseInt(box.page_number, 10),
              height: Math.round(Height * 100),
              left: Math.round(Left * 100),
              top: Math.round(Top * 100) - 0.09,
              width: Math.round(Width * 100) + 1,
              ...rest,
            }
          }),
      )

      setAreas(boundingBoxes)
    }
  }, [mediaContent])

  const renderHighlights = (props) => (
    <div>
      {areas
        ?.filter((area) => area.pageIndex === props.pageIndex)
        .map((area, idx) => {
          return (
            <div
              key={idx}
              className="highlight-area"
              style={Object.assign(
                {},
                {
                  background: "yellow",
                  opacity: 0.4,
                },
                props.getCssProperties(area, props.rotation),
              )}
            />
          )
        })}
    </div>
  )

  const highlightPluginInstance = highlightPlugin({
    trigger: Trigger.None,
    renderHighlights: mediaContent && renderHighlights,
  })

  if (mediaContent)
    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div style={{ height: "70vh", width: "100%" }}>
          <Toolbar />
          <Viewer
            fileUrl={mediaContent?.source?.url}
            defaultScale={SpecialZoomLevel.PageFit}
            plugins={[toolbarPluginInstance, highlightPluginInstance]}
          />
        </div>
      </Worker>
    )
}

export default PdfViewer
