/**
 * External.jsx
 *
 * The External component manages the display and navigation for external content, either in an iframe or a new popup window.
 * It handles specific interactions based on whether the content is shown in an iframe or a popup, and navigates back to the previous page if not in an iframe.
 *
 * Key functionalities include:
 * - Opening a link in a new popup window after a delay when in experience view and not in an iframe.
 * - Navigating back to the previous page after a delay when not in an iframe.
 * - Conditionally rendering an iframe or a link based on the showContent and isIframe props.
 *
 * The component returns either an iframe or a link to external content, with appropriate navigation and display logic.
 */

import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { usePlanet } from "@/hooks/interface/usePlanet"
import { CURRENT_PLANET_VIEW } from "@/constants/constants.jsx"

const External = ({ showContent, isIframe, link }) => {
  const navigate = useNavigate()
  const { currentPlanetView } = usePlanet()

  // Open a link in a new popup window after a delay when in experience view and not in an iFrame
  useEffect(() => {
    if (currentPlanetView === CURRENT_PLANET_VIEW.IN_EXPERIENCE && !isIframe) {
      setTimeout(() => {
        window.open(link, "popup")
      }, 1500)
    }
  }, [currentPlanetView, link, isIframe])

  // Navigate back after a delay when not in an iframe
  useEffect(() => {
    let timer

    if (!isIframe) {
      timer = setTimeout(() => {
        navigate(-1)
      }, 2000)
    }

    return () => clearTimeout(timer)
  }, [isIframe, navigate])

  return (
    <>
      {showContent && isIframe ? (
        <iframe id={`iframe-${Date.now()}`} title={`iframe-${Date.now()}`} src={link}></iframe>
      ) : showContent && !isIframe ? (
        <a href={link} target="_blank" rel="noreferrer">
          <div className="section-wrapper"></div>
        </a>
      ) : null}
    </>
  )
}

export default External
