/**
 * InterfaceContext.jsx
 * Manages and provides the application's UI state, handling elements like menus, submenus, search bars, and news content.
 * Key states : subMenuIsVisible, searchBarIsVisible, isNewsOpen...
 */

import { createContext, useState } from "react"
import { CATEGORY_ITEMS } from "@/constants/constants"

const InterfaceContext = createContext()

const InterfaceProvider = ({ children }) => {
  const [sideMenuOrganizationIsOpen, setSideMenuOrganizationIsOpen] = useState(false)
  const [sideMenuAccountIsOpen, setSideMenuAccountIsOpen] = useState(false)
  const [subMenuIsVisible, setSubMenuIsVisible] = useState("item-hidden")
  const [activeItemMenu, setActiveItemMenu] = useState(null)
  const [menuSelected, setMenuSelected] = useState("")
  const [subTopicMenuIsVisible, setSubTopicMenuPublicIsVisible] = useState(false)
  const [startTransitionAnimation, setStartTransitionAnimation] = useState("")
  const [categorySelected, setCategorySelected] = useState(CATEGORY_ITEMS.COLLECTIBLES)
  const [searchBarIsVisible, setSearchBarIsVisible] = useState(false)
  const [searchBarResultVisible, setSearchBarResultsVisible] = useState(false)
  const [userIsScrolling, setUserIsScrolling] = useState(false)
  const [currentNewsId, setCurrentNewsId] = useState(null)
  const [currentNewsInfos, setCurrentNewsInfos] = useState(null)
  const [allNews, setAllNews] = useState(null)
  const [isNewsOpen, setIsNewsOpen] = useState(false)
  const [currentNewsBrand, setCurrentNewsBrand] = useState(null)
  const [showContent, setShowContent] = useState(false)
  const [startRecording, setStartRecording] = useState(false)
  const [responseAfterSendingAudio, setResponseAfterSendingAudio] = useState(null)
  const [transcript, setTranscript] = useState("")
  const [responseAudioIsPending, setResponseAudioIsPending] = useState(true)
  const [clickOutsideSearchBar, setClickOutsideSearchBar] = useState(false)
  const [isDesktop, setIsDesktop] = useState(true)

  return (
    <InterfaceContext.Provider
      value={{
        sideMenuOrganizationIsOpen,
        setSideMenuOrganizationIsOpen,
        sideMenuAccountIsOpen,
        setSideMenuAccountIsOpen,
        subMenuIsVisible,
        setSubMenuIsVisible,
        activeItemMenu,
        setActiveItemMenu,
        subTopicMenuIsVisible,
        setSubTopicMenuPublicIsVisible,
        startTransitionAnimation,
        setStartTransitionAnimation,
        categorySelected,
        setCategorySelected,
        searchBarIsVisible,
        setSearchBarIsVisible,
        menuSelected,
        setMenuSelected,
        searchBarResultVisible,
        setSearchBarResultsVisible,
        userIsScrolling,
        setUserIsScrolling,
        currentNewsId,
        setCurrentNewsId,
        currentNewsInfos,
        setCurrentNewsInfos,
        allNews,
        setAllNews,
        currentNewsBrand,
        setCurrentNewsBrand,
        showContent,
        setShowContent,
        isNewsOpen,
        setIsNewsOpen,
        startRecording,
        setStartRecording,
        responseAfterSendingAudio,
        setResponseAfterSendingAudio,
        transcript,
        setTranscript,
        responseAudioIsPending,
        setResponseAudioIsPending,
        clickOutsideSearchBar,
        setClickOutsideSearchBar,
        isDesktop,
        setIsDesktop,
      }}
    >
      {children}
    </InterfaceContext.Provider>
  )
}

export { InterfaceContext, InterfaceProvider }
