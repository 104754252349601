import { createContext, useRef, useState } from "react"

const SearchContext = createContext()

const SearchProvider = ({ children }) => {
  // *************************************************************************//
  // discussion_id used with search queries to maintain conversation context  //
  // *************************************************************************//
  const discussionIdRef = useRef("")

  const mediaCountRef = useRef({})

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [firstContentIsDefault, setFirstContentIsDefault] = useState(true)
  const [secondContentIsDefault, setSecondContentIsDefault] = useState(true)
  const [firstContentIsCollapsed, setFirstContentIsCollapsed] = useState(false)
  const [secondContentIsCollapsed, setSecondContentIsCollapsed] = useState(false)
  const [thirdContentIsCollapsed, setThirdContentIsCollapsed] = useState(false)
  const [pdfRender, setPdfRender] = useState(false)
  const [videoRender, setVideoRender] = useState(false)
  const [displaySearchResults, setDisplaySearchResults] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [previousInputValue, setPreviousInputValue] = useState(null)
  const [mediaClicked, setMediaClicked] = useState(false)
  const [sendValue, setSendValue] = useState("")
  const [answerWaiting, setAnswerWaiting] = useState("")
  const [dataResponse, setDataResponse] = useState({})
  const [viewMode, setViewMode] = useState(null)
  const [isUploadOpen, setIsUploadOpen] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [mediaContent, setMediaContent] = useState({})
  const receivedMediaContent = dataResponse?.medias?.length > 0
  const receivedAnswer = dataResponse?.answer?.length === undefined

  return (
    <SearchContext.Provider
      value={{
        discussionIdRef,
        isAuthenticated,
        setIsAuthenticated,
        firstContentIsDefault,
        setFirstContentIsDefault,
        secondContentIsDefault,
        setSecondContentIsDefault,
        firstContentIsCollapsed,
        setFirstContentIsCollapsed,
        secondContentIsCollapsed,
        setSecondContentIsCollapsed,
        thirdContentIsCollapsed,
        setThirdContentIsCollapsed,
        pdfRender,
        setPdfRender,
        videoRender,
        setVideoRender,
        displaySearchResults,
        setDisplaySearchResults,
        sendValue,
        setSendValue,
        answerWaiting,
        setAnswerWaiting,
        dataResponse,
        setDataResponse,
        inputValue,
        setInputValue,
        mediaClicked,
        setMediaClicked,
        mediaCountRef,
        viewMode,
        setViewMode,
        isUploadOpen,
        setIsUploadOpen,
        isExpanded,
        setIsExpanded,
        mediaContent,
        setMediaContent,
        receivedMediaContent,
        receivedAnswer,
        previousInputValue,
        setPreviousInputValue,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export { SearchContext, SearchProvider }
