/**
 * AvatarSetup.jsx
 *
 * The AvatarSetup component manages the avatar selection process, displaying available avatars and handling user interactions.
 * It integrates with avatar and claim setup hooks to manage state and visibility of modals.
 *
 * Key functionalities include:
 * - Displaying a list of available avatars for selection.
 * - Handling avatar selection and updating the selected avatar state.
 * - Enabling or disabling the continue button based on avatar selection.
 * - Managing the visibility of the avatar setup and claim modals.
 *
 * The component returns a modal with a list of avatar previews and a continue button to proceed with the setup process.
 */

import { useAvatarSetup } from "@/hooks/onboarding/useAvatarSetup"
import { useClaimSetup } from "@/hooks/onboarding/useClaimSetup.jsx"
import AvatarPreview from "./AvatarPreview.jsx"
import "./avatar.scss"

const AvatarSetup = () => {
  const { setModalAvatarSetupVisible, avatars, selectedAvatar, setSelectedAvatar, expAvatars } =
    useAvatarSetup()
  const { setModalClaimVisible, displayCollectibleMint } = useClaimSetup()

  const avatarsArray = []

  avatars?.avatars?.forEach((singleAvatar, index) => {
    avatarsArray.push(
      <AvatarPreview
        key={index}
        avatar={singleAvatar}
        selected={selectedAvatar === singleAvatar ? true : false}
        onSelect={(singleAvatar) => {
          setSelectedAvatar(singleAvatar)
        }}
        selectedAvatar={selectedAvatar}
      />,
    )
  })

  const canSubmit = selectedAvatar != null

  const handleOnClick = () => {
    setModalAvatarSetupVisible(false)
    setModalClaimVisible(true)
    displayCollectibleMint(expAvatars)
  }

  return (
    <>
      <div className="modal-content choose-your-avatar">
        <h1>Choose your avatar</h1>
        <div className="avatar-form">
          <ul className="avatars-list">{avatarsArray}</ul>
          <button className="button primary" disabled={!canSubmit} onClick={() => handleOnClick()}>
            Continue
          </button>
        </div>
      </div>
    </>
  )
}

export default AvatarSetup
