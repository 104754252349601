/**
 * Overlay.jsx
 *
 * The Overlay component is used across various files to create an overlay over a video or image.
 * This overlay can smoothly transition in and out of view using CSS animations.
 *
 * Key functionalities include:
 * - Showing the overlay with a fade-in effect, customizable by duration and delay.
 * - Hiding the overlay with a fade-out effect, also customizable by duration and delay.
 * - Utilizing the useCssTweens hook to manage CSS animations.
 * - Providing imperative handle methods (show, hide) to control the overlay's visibility from parent components.
 *
 * The component returns a div element with an overlay class that covers the target content.
 */

import { forwardRef, useImperativeHandle, useRef } from "react"
import { useCssTweens } from "@/utils/csstweens/useCssTweens.js"

const Overlay = forwardRef(function Overlay(props, overlayRef) {
  const { csstween } = useCssTweens()
  const overlayDiv = useRef(null)

  const show = (duration = 1000, delay = 0) => {
    return csstween({
      duration,
      delay,
      target: overlayDiv?.current,
      ease: "outSwift",
      opacity: [0, 1],
      autoClear: false,
    }).finished
  }

  const hide = (duration = 1000, delay = 0) => {
    return csstween({
      duration,
      delay,
      target: overlayDiv?.current,
      ease: "outSwift",
      opacity: [1, 0],
      autoClear: false,
    }).finished
  }

  useImperativeHandle(overlayRef, () => ({
    show,
    hide,
  }))

  return <div className="overlay" ref={overlayDiv} />
})

export default Overlay
