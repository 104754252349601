/**
 * MenuData.js
 *
 * The getMenuDropdownData function provides the configuration for the dropdown menu items in the application.
 * It includes the item properties such as id, label, value, style classes, and onClick event handlers.
 *
 * Key functionalities include:
 * - Defining the dropdown menu items with their respective properties.
 * - Assigning event handler functions for menu item clicks.
 * - Returning the configured dropdown items for use in the menu component.
 *
 * This function returns an object containing the configured dropdown menu items.
 */

import { ITEMS_MENU } from "@/constants/constants"

const getMenuDropdownData = (
  handleClickOrganizationMenuCollapse,
  handleClickAccountMenuCollapse,
  handleClickDressingRoom,
  handleClickLogout,
) => {
  const DROPDOWN_ITEMS = [
    {
      id: 1,
      label: "my organization",
      value: ITEMS_MENU.ORGANIZATION,
      styleClass: "",
      activeStyleClass: "active",
      onClick: (e) => handleClickOrganizationMenuCollapse(e),
    },
    {
      id: 2,
      label: "my account",
      value: ITEMS_MENU.ACCOUNT,
      styleClass: "",
      activeStyleClass: "active",
      onClick: (e) => handleClickAccountMenuCollapse(e),
    },
    {
      id: 3,
      label: "my dressing room",
      value: ITEMS_MENU.DRESSING,
      styleClass: "",
      activeStyleClass: "",
      onClick: () => handleClickDressingRoom(),
    },
    {
      id: 4,
      label: "logout",
      styleClass: "",
      activeStyleClass: "",
      onClick: () => handleClickLogout(),
    },
  ]
  return { DROPDOWN_ITEMS }
}
export default getMenuDropdownData
