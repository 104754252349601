/**
 * News.js
 * Displays news cards for each continent during exploration
 */
import { CSS2DObject } from "three/examples/jsm/Addons.js"
import { IS_DEV, truncateWords } from "@/utils/utils.jsx"
import NEWS_DATA from "@/webgl/world/components/news/news.json"

export default class News {
  constructor(_options) {
    this.continentBase = _options.continentBase
    this.continentGround = _options.continentGround
    this.continentName = _options.continentName

    this.setupProperties()
  }

  setupProperties() {
    this.continentalNews = NEWS_DATA.brands[this.continentName]?.news || []
    this.continentalNewsPosition = NEWS_DATA.brands[this.continentName]?.positions || []
    this.continentalNewsColor = NEWS_DATA.brands[this.continentName]?.color || []
  }

  init() {
    this.createNewsCards()
  }

  /**
   * Creates a div HTML element for each news
   */
  createNewsCards() {
    if (!this.continentalNews || !this.continentalNewsPosition || !this.continentalNewsColor) return

    const newsArray = Object.values(this.continentalNews)
      .filter((news) => news.featured === true)
      .sort((a, b) => new Date(b.date) - new Date(a.date))

    const latestNews = newsArray.slice(0, 4)

    latestNews.forEach((news, index) => {
      const position = this.continentalNewsPosition[index]
      const wrapper = document.createElement("div")
      const newsLabel = this.createLabelForNews(news)

      wrapper.className = `news-article news-article--wrapper article-${news.index}`
      wrapper.appendChild(newsLabel)

      news.mainLabel = new CSS2DObject(wrapper)
      news.mainLabel.position.set(position.x, position.y, position.z)
      news.mainLabel.element.style.transformOrigin = "center"

      this.continentGround.add(news.mainLabel)
      this.continentBase.continentalNews[`news-${news.index}`] = news.mainLabel

      news.mainLabel.element.addEventListener("click", () => {
        const event = new CustomEvent("newsClick", {
          detail: {
            currentNewsId: news.index,
            currentNewsInfos: news,
            currentNewsPath: `news/${this.continentName}`,
            currentBrand: this.continentName,
            allNews: NEWS_DATA,
          },
        })
        window.dispatchEvent(event)
      })
    })
  }

  /**
   * Creates an article element for the news cards
   * @param {Object} news - Data for each data
   * @returns {HTMLElement} - The created article element
   */
  createLabelForNews(news) {
    if (!news) return

    const image = news.media[0]
    const imageSource = `${import.meta.env.VITE_BASE_URL}${IS_DEV ? `assets/${image.url}` : image.url}`
    const div = document.createElement("article")

    div.className = `news-article--preview  deep-blur  ${this.continentName}`
    div.innerHTML = `
    <div class="visual">
      <img src=${imageSource} alt="${image.alt}"  />
    </div>
    <div class="text">
     <div class="metadata"><span>${news.category}</span><time class="news-article__date" datetime="${news.date}">${news.date}</time></div>
      <h2 class="news-article__title">${truncateWords(news.title, 11)}</h2>
    </div>`
    div.style.color = this.continentalNewsColor

    return div
  }
}
