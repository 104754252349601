/**
 * PlanetContext.jsx
 * User navigation inside the planet
 * Key states : webGLExperience, currentPlanet, currentPlanetView, currentExplorationLevel
 */

import { createContext, useState } from "react"

const PlanetContext = createContext(null)

const PlanetProvider = ({ children }) => {
  const [currentPlanet, setCurrentPlanet] = useState("IN-RENAULT")
  const [currentPlanetView, setCurrentPlanetView] = useState("INTRO")
  const [currentExplorationLevel, setCurrentExplorationLevel] = useState("IN-ORBIT")
  const [currentExperienceType, setCurrentExperienceType] = useState("NONE")
  const [userHasDragged, setUserHasDragged] = useState(false)
  const [isGateSubMenuOpen, setGateSubMenuOpen] = useState(false)
  const [isWebGLVisible, setWebGLVisible] = useState(true)
  const [webGLExperience, setWebGLExperience] = useState(null)
  const [isWebGLPaused, setIsWebGLPaused] = useState(false)

  return (
    <PlanetContext.Provider
      value={{
        webGLExperience,
        setWebGLExperience,
        isWebGLVisible,
        setWebGLVisible,
        currentPlanet,
        setCurrentPlanet,
        currentPlanetView,
        setCurrentPlanetView,
        currentExperienceType,
        setCurrentExperienceType,
        userHasDragged,
        setUserHasDragged,
        isGateSubMenuOpen,
        setGateSubMenuOpen,
        currentExplorationLevel,
        setCurrentExplorationLevel,
        isWebGLPaused,
        setIsWebGLPaused,
      }}
    >
      {children}
    </PlanetContext.Provider>
  )
}

export { PlanetContext, PlanetProvider }
