/**
 * useHub.jsx
 */

import { useContext } from "react"
import { HubContext } from "@/context/metaverse/HubContext"

export const useHub = () => {
  return useContext(HubContext)
}
