/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from "react"
import { useInterface } from "@/hooks/interface/useInterface"
import { useSearch } from "@/hooks/interface/useSearch.jsx"
import { usePostAudioFile } from "@/data/hooks"
import { getWaveBlob } from "webm-to-wav-converter"
import { getDiscussionIdCookie } from "@/utils/utils"

const MIME_TYPE = {
  WEBM: "audio/webm;codecs=opus",
  WAV: "audio/wav",
}

const RecordManager = () => {
  const {
    startRecording,
    transcript,
    setTranscript,
    // setResponseAfterSendingAudio,
    setResponseAudioIsPending,
    clickOutsideSearchBar,
  } = useInterface()

  const { setAnswerWaiting, setDataResponse } = useSearch()

  const { mutate: getResponse, isPending } = usePostAudioFile()

  useEffect(() => {
    setResponseAudioIsPending(isPending)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending])

  const mediaRecorder = useRef(null)
  let recognition
  let _transcript = ""

  const startRecordingVoice = async () => {
    if ("mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices) {
      try {
        const stream = await navigator.mediaDevices?.getUserMedia({ audio: true })

        if (stream) {
          const media = new MediaRecorder(stream, { mimeType: MIME_TYPE.WEBM })
          mediaRecorder.current = media

          recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)()
          recognition.lang = "fr-FR"
          recognition.interimResults = false
          recognition.continuous = false

          recognition.onresult = (event) => {
            _transcript = event.results[0][0].transcript
            setTranscript(_transcript)
          }

          recognition.start()
          mediaRecorder.current.start()
        }
      } catch (err) {
        console.error("getUserPermission error ==>", err)
      }
    } else {
      alert("Unfortunately, the MediaRecorder API is not supported in your browser")
    }
  }

  const stopRecordingVoice = async () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop()

      if (!clickOutsideSearchBar) {
        mediaRecorder.current.ondataavailable = async (event) => {
          if (event.data.size > 0) {
            mediaRecorder.current.onstop = async () => {
              const wavChunks = await getWaveBlob(event.data, true)

              if (wavChunks.size > 0) {
                const audioBlob = new Blob([wavChunks], { type: MIME_TYPE.WAV })
                const formDataBlob = new FormData()

                formDataBlob.append("discussion_id", getDiscussionIdCookie())
                formDataBlob.append(
                  "requestAudio",
                  audioBlob,
                  `recording_${new Date().getTime()}.wav`,
                )

                getAudioResponse(formDataBlob)
              }
            }
          }
        }
      }
    }
  }

  const getAudioResponse = (formData) => {
    if (transcript.length > 0 && formData) {
      setAnswerWaiting(`${transcript}<br />Searching relevant information, please hold on...`)
    }

    getResponse(formData, {
      onSuccess: (res) => {
        const textDecoder = new TextDecoder("utf-8")
        const jsonString = textDecoder.decode(res.data)
        const jsonResponse = JSON.parse(jsonString)
        setDataResponse(jsonResponse)
        if (jsonResponse) setAnswerWaiting("")
        // if (jsonResponse) setResponseAfterSendingAudio(jsonResponse)
      },
      onError: (error) => {
        console.error("[RecordManager getAudioResponse error]: ", error)

        if (error.response.status === 400) {
          alert("Sorry, I didn't understand your request. Please try again.")
        }
      },
    })
  }

  useEffect(() => {
    if (startRecording) {
      startRecordingVoice()
    } else {
      stopRecordingVoice()
    }
  }, [startRecording])

  return null
}

export default RecordManager
