export const easings = {
  linear: [0.25, 0.25, 0.75, 0.75],
  inSine: [0.47, 0.0, 0.745, 0.715],
  outSine: [0.39, 0.575, 0.565, 1.0],
  inOutSine: [0.445, 0.05, 0.55, 0.95],
  inQuad: [0.55, 0.085, 0.68, 0.53],
  outQuad: [0.25, 0.46, 0.45, 0.94],
  inOutQuad: [0.455, 0.03, 0.515, 0.955],
  inCubic: [0.55, 0.055, 0.675, 0.19],
  outCubic: [0.215, 0.61, 0.355, 1.0],
  inOutCubic: [0.645, 0.045, 0.355, 1.0],
  inQuart: [0.895, 0.03, 0.685, 0.22],
  outQuart: [0.165, 0.84, 0.44, 1.0],
  inOutQuart: [0.77, 0.0, 0.175, 1.0],
  inQuint: [0.755, 0.05, 0.855, 0.06],
  outQuint: [0.23, 1.0, 0.32, 1.0],
  inOutQuint: [0.86, 0.0, 0.07, 1.0],
  inExpo: [0.95, 0.05, 0.795, 0.035],
  outExpo: [0.19, 1.0, 0.22, 1.0],
  inOutExpo: [1.0, 0.0, 0.0, 1.0],
  inCirc: [0.6, 0.04, 0.98, 0.335],
  outCirc: [0.075, 0.82, 0.165, 1.0],
  inOutCirc: [0.785, 0.135, 0.15, 0.86],

  outSwift: [0.55, 0, 0.1, 1],
  snap2: [0, 0.975, 0, 1],
  bounce: [0.18, 0.89, 0.34, 1.76],
  bounce2: [0.865, -0.005, 0, 1.47],
  bounce3: [0.865, -0.005, 0, 1.64],
  bounce4: [0, 1.49, 0.105, 0.94],
  hardBounce: [0.07, 1.525, 0.36, 0.935],
  longKeyframe: [0.49, 0.05, 0.32, 1.04],
  elastic: [0, 0.49, 0.205, 1],
  majestic: [0.47, 1.64, 0.41, 0.8],
}
