/**
 * SubExperience.js
 *
 * The SubExperience component handles the display of sub-experiences based on the current route parameters.
 * It manages the state and visibility of video content and overlays.
 *
 * Key functionalities include:
 * - Setting the route type to "SubExperience" based on the route parameters.
 * - Managing the visibility and type of content to be displayed (e.g., video, coming soon).
 * - Controlling the overlay display when the component mounts.
 *
 * The component returns a section element containing an overlay and the appropriate content based on the sub-experience type.
 */

import { useEffect, useRef, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useRouteType } from "@/hooks/interface/useRouteType"
import Overlay from "../components/Common/Overlay.jsx"
import VideoPlayer from "../components/Common/VideoPlayer.jsx"

const SubExperience = () => {
  const location = useLocation()
  const { routeType, setRouteType } = useRouteType()
  const { subexperience } = useParams()
  const [showVideo, setShowVideo] = useState(false)
  const [pageType, setPageType] = useState("coming-soon")
  const sectionRef = useRef(null)
  const overlayRef = useRef(null)

  // Set the route type to "SubExperience" when the subexperience changes
  useEffect(() => {
    if (subexperience && routeType !== "subexperience") {
      setRouteType("SubExperience")
    }
  }, [subexperience, routeType, setRouteType])

  // Show and hide the overlay on location change to handle transitions
  useEffect(() => {
    const overlay = overlayRef.current

    overlay?.show(0)
    setShowVideo(true)
    overlay?.hide(0)
  }, [location])

  // Update the page type based on the subexperience
  useEffect(() => {
    switch (subexperience) {
      case "meeting":
        setPageType("video")
        break

      default:
        setPageType("coming-soon")
        break
    }
  }, [subexperience])

  return (
    <section
      ref={sectionRef}
      className={`page subexperience experience-${subexperience} type-${pageType} `}
    >
      <Overlay ref={overlayRef} />
      {pageType === "video" ? (
        showVideo && <VideoPlayer media="meta" loop={true} />
      ) : (
        <div>
          <h1>Coming soon</h1>
        </div>
      )}
    </section>
  )
}

export default SubExperience
