import { useState, useEffect } from "react"
import { useRooms } from "@/hooks/metaverse/useRooms"
import { useInterface } from "@/hooks/interface/useInterface"
import { useSearch } from "@/hooks/interface/useSearch"
import VIDEO_PLAYER from "@/assets/icons/UI/videoPlayer.svg"
import VIDEO_R5 from "@/assets/images/library/video-r5.jpg"
import VIDEO_AI from "@/assets/images/library/video-ai.jpg"
import VIDEO_CREATIVE from "@/assets/images/library/video-creative.jpg"
import VIDEO_CYBER from "@/assets/images/library/video-cyber.jpg"
import VIDEO_EMPLOYEES from "@/assets/images/library/video-employees.jpg"
import VIDEO_HACKATHON from "@/assets/images/library/video-hackathon.jpg"
import VIDEO_HUMANOIDE from "@/assets/images/library/video-humanoide.jpg"
import VIDEO_MANAGERS from "@/assets/images/library/video-managers.jpg"
import VIDEO_METAVERSE from "@/assets/images/library/video-metaverse.jpg"
import VIDEO_SOFTSKILLS from "@/assets/images/library/video-softskills.jpg"
import VIDEO_ART from "@/assets/images/library/video-art.jpg"
import "./aside.scss"

const Aside = () => {
  const { showLoading, showScene3D } = useRooms()
  const { subMenuIsVisible } = useInterface()
  const [isVisible, setIsVisible] = useState(false)
  const [isTitleVisible, setIsTitleVisible] = useState(false)
  const isSubmenuVisible = subMenuIsVisible === "item-visible"
  const { displaySearchResults } = useSearch()

  useEffect(() => {
    setIsTitleVisible(true)
  }, [])

  useEffect(() => {
    setIsVisible(!isSubmenuVisible)
  }, [isSubmenuVisible, showLoading, isVisible])

  return (
    <>
      <div
        className={`library-room-name ${showScene3D && !showLoading && isTitleVisible ? "library-room-name--show" : ""}`}
      >
        Library
      </div>
      <div
        className={`library-room-lateral ${showScene3D && isVisible && !showLoading && !displaySearchResults ? "library-room-lateral--show" : ""}`}
      >
        <div className="library-room-lateral__container">
          <div className="tabs">
            <div className="tabs__item ">Trending</div>
            <div className="tabs__item">New</div>
            <div className="tabs__item tabs__item--active">For you</div>
            <div className="tabs__item ">all</div>
          </div>
          <ul className="card-list">
            <li
              className={`card-list__card card-list__card--featured ${isVisible ? "video-playing" : ""}`}
            >
              <div
                className="card-list__card-visual"
                style={{ backgroundImage: `url(${VIDEO_R5})` }}
              >
                {/* <div className="progress-bar">
                  <div className="progress"></div>
                </div> */}
              </div>
              <h3 className="card-list__card-title no-transform">
                <span>Renaulution Teaser R5</span> <span>1:30</span>
              </h3>
            </li>
            <li className="card-list__card">
              <div
                className="card-list__card-visual"
                style={{ backgroundImage: `url(${VIDEO_ART})` }}
              >
                <img src={VIDEO_PLAYER} alt="video player" />
              </div>
              <h3 className="card-list__card-title no-transform">
                <span>Art & Science avec Justine Emard</span> <span>45:00</span>
              </h3>
            </li>
            <li className="card-list__card">
              <div
                className="card-list__card-visual"
                style={{ backgroundImage: `url(${VIDEO_METAVERSE})` }}
              >
                <img src={VIDEO_PLAYER} alt="video player" />
              </div>
              <h3 className="card-list__card-title no-transform">
                <span>Forum Metaverse #02</span> <span>32:00</span>
              </h3>
            </li>
            <li className="card-list__card">
              <div
                className="card-list__card-visual"
                style={{ backgroundImage: `url(${VIDEO_AI})` }}
              >
                <img src={VIDEO_PLAYER} alt="video player" />
              </div>
              <h3 className="card-list__card-title no-transform">
                <span>AI for all</span> <span>2:00:00</span>
              </h3>
            </li>
            <li className="card-list__card">
              <div
                className="card-list__card-visual"
                style={{ backgroundImage: `url(${VIDEO_CYBER})` }}
              >
                <img src={VIDEO_PLAYER} alt="video player" />
              </div>
              <h3 className="card-list__card-title no-transform">
                <span>Cyber & Me</span> <span>30:00</span>
              </h3>
            </li>
            <li className="card-list__card">
              <div
                className="card-list__card-visual"
                style={{ backgroundImage: `url(${VIDEO_EMPLOYEES})` }}
              >
                <img src={VIDEO_PLAYER} alt="video player" />
              </div>
              <h3 className="card-list__card-title no-transform">
                <span>SHIFT for employees</span> <span>8:00:00</span>
              </h3>
            </li>
            <li className="card-list__card">
              <div
                className="card-list__card-visual"
                style={{ backgroundImage: `url(${VIDEO_MANAGERS})` }}
              >
                <img src={VIDEO_PLAYER} alt="video player" />
              </div>
              <h3 className="card-list__card-title no-transform">
                <span>SHIFT for managers</span> <span>6:19:00</span>
              </h3>
            </li>
            <li className="card-list__card">
              <div
                className="card-list__card-visual"
                style={{ backgroundImage: `url(${VIDEO_CREATIVE})` }}
              >
                <img src={VIDEO_PLAYER} alt="video player" />
              </div>
              <h3 className="card-list__card-title no-transform">
                <span>Maimize your creative power</span> <span>31:00</span>
              </h3>
            </li>
            <li className="card-list__card">
              <div
                className="card-list__card-visual"
                style={{ backgroundImage: `url(${VIDEO_SOFTSKILLS})` }}
              >
                <img src={VIDEO_PLAYER} alt="video player" />
              </div>
              <h3 className="card-list__card-title no-transform">
                <span>Softskills Best of for Summer</span> <span>10:00</span>
              </h3>
            </li>
            <li className="card-list__card">
              <div
                className="card-list__card-visual"
                style={{ backgroundImage: `url(${VIDEO_HACKATHON})` }}
              >
                <img src={VIDEO_PLAYER} alt="video player" />
              </div>
              <h3 className="card-list__card-title no-transform">
                <span>Chat&apos; Hackademy 01 – How to start</span> <span>56:40</span>
              </h3>
            </li>
            <li className="card-list__card">
              <div
                className="card-list__card-visual"
                style={{ backgroundImage: `url(${VIDEO_HUMANOIDE})` }}
              >
                <img src={VIDEO_PLAYER} alt="video player" />
              </div>
              <h3 className="card-list__card-title no-transform">
                <span>The 5 most advanced humanoid robots in the world</span> <span>5:42</span>
              </h3>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Aside
