/**
 * News.js
 *
 * The News component manages and displays news-related content, handling interactions and visibility.
 *
 * Key functionalities include:
 * - Generating a random class for each month.
 * - Handling the visibility of the news section based on side menus and search bar visibility.
 * - Highlighting the clicked news and corresponding month on mount.
 * - Grouping news by month and year.
 * - Handling month filter clicks.
 * - Handling news card clicks.
 * - Handling click outside to close the news.
 * - Handling scroll to update the current month.
 */

import { useEffect, useRef, useState } from "react"
import { useRouteType } from "@/hooks/interface/useRouteType"
import { useNavigate } from "react-router-dom"
import { useInterface } from "@/hooks/interface/useInterface"
import { IS_DEV, truncateWords } from "@/utils/utils.jsx"

const News = () => {
  const navigate = useNavigate()
  const { setRouteType } = useRouteType()
  const {
    sideMenuOrganizationIsOpen,
    sideMenuAccountIsOpen,
    searchBarResultVisible,
    currentNewsId,
    setCurrentNewsId,
    allNews,
    currentNewsBrand,
  } = useInterface()
  const newsRef = useRef(null)
  const newsWrapperRef = useRef(null)
  const monthsRef = useRef(null)
  const [filteredNews, setFilteredNews] = useState([])
  const [currentMonth, setCurrentMonth] = useState("")
  const [currentYear, setCurrentYear] = useState("")
  const [openNewsId, setOpenNewsId] = useState(currentNewsId)
  const [years, setYears] = useState([])
  const [isShown, setIsShown] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const [isNewsPageOpen, setIsNewsPageOpen] = useState(false)
  const [selectedYear, setSelectedYear] = useState(null)
  const [randomClasses, setRandomClasses] = useState([])

  // Generate a random class for each month
  useEffect(() => {
    const classes = [...Array(12).keys()].map(() => Math.floor(Math.random() * 5) + 1)
    setRandomClasses(classes)
  }, [])

  // Set Route type
  useEffect(() => {
    setRouteType("News")
  }, [setRouteType])

  // Handle visibility of news section based on side menus and search bar visibility
  useEffect(() => {
    let timeoutId
    if (searchBarResultVisible || sideMenuOrganizationIsOpen || sideMenuAccountIsOpen) {
      setIsHidden(true)
    } else {
      timeoutId = setTimeout(() => setIsHidden(false), 600)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [searchBarResultVisible, sideMenuOrganizationIsOpen, sideMenuAccountIsOpen])

  // Show news section with delay on mount
  useEffect(() => {
    setTimeout(() => setIsShown(true), 100)
    return () => setIsShown(false)
  }, [])

  // Filter and sort news based on the current brand
  useEffect(() => {
    if (currentNewsBrand && allNews.brands[currentNewsBrand]) {
      const brandNews = allNews.brands[currentNewsBrand].news

      // Sort the news by date in descending order
      const sortedNews = brandNews.sort((a, b) => new Date(b.date) - new Date(a.date))

      setYears(getUniqueYears(sortedNews))
      setFilteredNews(sortedNews)
    }
  }, [currentNewsBrand, allNews])

  // Highlight the clicked news and corresponding month on mount
  useEffect(() => {
    setIsNewsPageOpen(true)
    if (currentNewsId >= 0) {
      const timeoutId = setTimeout(() => {
        setOpenNewsId(currentNewsId)
        const element = document.getElementById(`news-${currentNewsId}`)
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" })
        }
      }, 1000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [currentNewsId, filteredNews])

  // Extract unique years from articles
  const getUniqueYears = (articles) => {
    const years = articles.map((article) => new Date(article.date).getFullYear())
    return [...new Set(years)]
  }

  // Group news by month and year
  const groupedNews = filteredNews.reduce((acc, article) => {
    const articleDate = new Date(article.date)
    const monthYear = `${articleDate.toLocaleString("en", { month: "long" })} ${articleDate.getFullYear()}`
    if (!acc[monthYear]) acc[monthYear] = []
    acc[monthYear].push(article)
    return acc
  }, {})

  // Handle month filter click
  const handleMonthFilter = (month, year) => {
    // Reset and refresh YouTube iframes
    document.querySelectorAll("iframe.youtube-iframe").forEach((video) => {
      const videoSource = video.src
      video.src = videoSource
    })

    // Update current month and year
    setCurrentMonth(month.toString())
    setCurrentYear(year.toString())

    // Find the first article of the selected month and year
    let targetArticle = filteredNews.find((article) => {
      const articleDate = new Date(article.date)
      return (
        articleDate.getMonth().toString() === month && articleDate.getFullYear().toString() === year
      )
    })

    // If no article is found for the selected month and year, find the first article of the selected year
    if (!targetArticle) {
      targetArticle = filteredNews.find((article) => {
        const articleDate = new Date(article.date)
        return articleDate.getFullYear().toString() === year
      })
    }

    // Set openNewsId to the target article's ID if found
    if (targetArticle) {
      setOpenNewsId(targetArticle.index)
    }

    // Scroll the article into view after a delay
    setTimeout(() => {
      if (targetArticle) {
        const element = document.getElementById(`news-${targetArticle.index}`)
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
        }
      }
    }, 600)
  }

  // Handle news card click
  const handleCardClick = (id, month, year) => {
    setOpenNewsId(openNewsId === id ? null : id)
    document.querySelectorAll("iframe.youtube-iframe").forEach((video) => {
      const videoSource = video.src
      video.src = videoSource
    })
    setTimeout(() => {
      const element = document.getElementById(`news-${id}`)
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
        setTimeout(() => {
          setCurrentMonth(month.toString())
          setCurrentYear(year.toString())
          const monthElement = document.querySelector(`.month[data-month='${month}-${year}']`)
          if (monthElement)
            monthElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
        }, 600)
      }
    }, 500)
  }

  // Handle click outside to close the news
  useEffect(() => {
    if (searchBarResultVisible) return
    const handleClickOutside = (event) => {
      if (
        newsRef.current &&
        !newsRef.current.contains(event.target) &&
        !event.target.closest(".timeline-filter") &&
        !event.target.closest("#global-menu-id") &&
        !event.target.closest(".search-bar-container")
      ) {
        setIsShown(false)
        setTimeout(() => navigate("/"), 100)
        setOpenNewsId(null)
        setCurrentNewsId(null)
        setCurrentMonth(null)
        setCurrentYear(null)
        setIsNewsPageOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [navigate, setCurrentNewsId, searchBarResultVisible])

  // Limit content to a certain word count
  const limitContent = (content, wordLimit) => {
    const words = content.split(" ")
    return words.length > wordLimit ? words.slice(0, wordLimit).join(" ") + "..." : content
  }

  // Get CSS class based on the number of articles in a month
  const getCircleClass = (count) => {
    if (count === 0) return ""
    if (count === 1) return "circle small"
    if (count > 1 && count < 4) return "circle medium"
    return "circle large"
  }

  const handleScroll = () => {
    document.querySelectorAll("iframe.youtube-iframe").forEach((video) => {
      const videoSource = video.src
      video.src = videoSource
    })

    setCurrentMonth(null)
    setCurrentYear(null)

    const header = document.querySelector(".header-container")
    const targetPosition = newsRef.current.getBoundingClientRect().top

    if (targetPosition < 0) {
      header.classList.add("hide")
    } else {
      header.classList.remove("hide")
    }
  }

  // Hides header on scroll
  useEffect(() => {
    const header = document.querySelector(".header-container")
    const targetPosition = newsRef.current.getBoundingClientRect().top

    if (targetPosition < 0) {
      header.classList.add("hide")
    }
    if (isHidden) {
      header.classList.remove("hide")
    }
  }, [isHidden, newsRef])

  // Handle scroll to update current month
  useEffect(() => {
    const timelineWrapper = newsWrapperRef.current

    const header = document.querySelector(".header-container")
    header.classList.remove("hide")

    const handleTouchMove = () => {
      handleScroll()
    }

    if (timelineWrapper) {
      timelineWrapper.addEventListener("wheel", handleScroll, true)
      timelineWrapper.addEventListener("touchmove", handleTouchMove, true)
    }
    return () => {
      if (timelineWrapper) {
        timelineWrapper.removeEventListener("wheel", handleScroll, true)
        timelineWrapper.removeEventListener("touchmove", handleTouchMove, true)
      }
    }
  }, [])

  useEffect(() => {
    if (sideMenuOrganizationIsOpen || sideMenuAccountIsOpen || isNewsPageOpen) {
      const header = document.querySelector(".header-container")
      header.classList.remove("hide")
    }
  }, [sideMenuOrganizationIsOpen, sideMenuAccountIsOpen, isNewsPageOpen])

  const handleYearClick = (year) => {
    setSelectedYear((prevYear) => (prevYear === year ? null : year))
  }

  const getColor = (year) => {
    const mostRecentYear = Math.max(...years)
    const oldestYear = Math.min(...years)
    const minGrayScaleValue = 50
    const maxGrayScaleValue = 200
    const range = maxGrayScaleValue - minGrayScaleValue
    const yearRange = mostRecentYear - oldestYear

    if (yearRange === 0) {
      return "black"
    }

    const grayScaleValue = minGrayScaleValue + (range * (mostRecentYear - year)) / yearRange
    return year === mostRecentYear
      ? "black"
      : `rgb(${grayScaleValue}, ${grayScaleValue}, ${grayScaleValue})`
  }

  const isExpanded = openNewsId !== null

  useEffect(() => {
    // Create and style the debug element for visualizing the central rectangle
    const debugElement = document.createElement("div")
    debugElement.style.position = "fixed"
    debugElement.style.top = "10%"
    debugElement.style.height = "90%"
    debugElement.style.width = "100%"
    debugElement.style.backgroundColor = "rgba(0, 255, 0, 0.2)"
    debugElement.style.pointerEvents = "none"
    debugElement.style.zIndex = "999"
    // document.body.appendChild(debugElement)
    let timeout = null

    const updateCardOpacity = () => {
      const viewportHeight = window.innerHeight
      const centralRect = {
        top: viewportHeight * 0.1,
        bottom: viewportHeight * 0.9,
      }

      const scrollPosition = window.scrollY
      const cards = document.querySelectorAll(".news-card")

      cards.forEach((card) => {
        const cardRect = card.getBoundingClientRect()
        const cardTop = cardRect.top + scrollPosition
        const cardBottom = cardRect.bottom + scrollPosition
        const isInsideCentralRect = cardTop >= centralRect.top && cardBottom <= centralRect.bottom

        if (card.classList.contains("expanded")) {
          // Check if the card is beyond the central rectangle
          const isTopBeyondCentralRect = cardTop + 200 < centralRect.top
          const isBottomBeyondCentralRect = cardBottom - 200 > centralRect.bottom

          if (isTopBeyondCentralRect || isBottomBeyondCentralRect) {
            // Clear previous timeout if it exists
            if (timeout) clearTimeout(timeout)

            // Set new timeout to close the card
            timeout = setTimeout(() => setOpenNewsId(null), 500) // Delay to allow visual feedback
          } else {
            // Card is within the central rectangle, set full opacity
            card.style.opacity = "1"
          }
        } else {
          // Card is not expanded, adjust opacity based on its position
          card.style.opacity = isInsideCentralRect ? "1" : "0.5"
        }
      })
    }

    // Update card opacity on load, on scroll, and on resize
    updateCardOpacity()
    window.addEventListener("wheel", updateCardOpacity)
    window.addEventListener("resize", updateCardOpacity)

    // Cleanup event listeners and debug element on component unmount
    return () => {
      window.removeEventListener("wheel", updateCardOpacity)
      window.removeEventListener("resize", updateCardOpacity)
      clearTimeout(timeout)
      // document.body.removeChild(debugElement)
    }
  }, [setOpenNewsId])

  return (
    <section
      className={`type-news deep-blur ${isShown ? "show" : ""} ${isHidden ? "hide" : ""} ${isExpanded ? "expanded" : ""}`}
    >
      <div className="wrapper" ref={newsWrapperRef}>
        <div className="timeline-content" ref={newsRef}>
          {Object.keys(groupedNews).map((monthYear) => (
            <div key={monthYear}>
              <h2>{monthYear}</h2>
              {groupedNews[monthYear].map((article) => (
                <article
                  id={`news-${article.index}`}
                  className={`news-card ${openNewsId === article.index ? "expanded" : ""}`}
                  key={article.index}
                  onClick={() =>
                    handleCardClick(
                      article.index,
                      new Date(article.date).getMonth(),
                      new Date(article.date).getFullYear(),
                    )
                  }
                >
                  <div className={`visual ${openNewsId === article.index ? "hide" : ""}`}>
                    <img
                      src={`${import.meta.env.VITE_BASE_URL}${IS_DEV ? `assets/${article.media[0].url}` : `${article.media[0].url}`}`}
                      alt={article.media[0].alt}
                    />
                  </div>
                  <div className="summary-infos">
                    <div className="meta-data">
                      <span>{article.category}</span>
                      <span>{article.date}</span>
                    </div>
                    <h1 className="title">
                      {openNewsId === article.index
                        ? article.title
                        : truncateWords(article.title, 10)}
                    </h1>
                  </div>
                  <div className="content">
                    {article.media[0].type === "image" ? (
                      <img
                        src={`${import.meta.env.VITE_BASE_URL}${IS_DEV ? `assets/${article.media[0].url}` : `${article.media[0].url}`}`}
                        alt={article.media[0].alt}
                      />
                    ) : (
                      <iframe
                        width="100%"
                        height="315"
                        src={article.media[0].url}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={article.media[0].alt}
                        className="youtube-iframe"
                      ></iframe>
                    )}
                    <p>
                      {article.external_link ? limitContent(article.content, 50) : article.content}
                    </p>
                    {article.external_link && (
                      <a
                        role="button"
                        href={article.external_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Read more
                      </a>
                    )}
                  </div>
                </article>
              ))}
            </div>
          ))}
        </div>
        <div className="timeline-filter" ref={monthsRef}>
          {years.map((year) => (
            <div key={year} className="date-filter">
              <h3
                onClick={() => handleYearClick(year)}
                style={{
                  color: getColor(year),
                }}
              >
                {year}
              </h3>
              <div className={`months ${selectedYear === year ? "show" : "hide"}`}>
                {[...Array(12).keys()].map((month, index) => {
                  const monthYear = `${new Date(0, month).toLocaleString("en", { month: "long" })} ${year}`
                  const count = groupedNews[monthYear]?.length || 0

                  return (
                    <button
                      key={month}
                      onClick={() => handleMonthFilter(month.toString(), year.toString())}
                    >
                      <div
                        className={`month ${currentMonth === month.toString() && currentYear === year.toString() ? "active" : ""}`}
                        data-month={`${month}-${year}`}
                      >
                        {new Date(0, month).toLocaleString("en", { month: "short" })}
                        <div className="count">
                          {count > 0 ? (
                            <span className={getCircleClass(count)}></span>
                          ) : (
                            <span className={getCircleClass(randomClasses[index])}></span>
                          )}
                        </div>
                      </div>
                    </button>
                  )
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default News
