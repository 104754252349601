/**
 * AvatarPreview.jsx
 *
 * The AvatarPreview component displays a preview of an avatar, handling image loading and selection.
 * It dynamically styles the image thumbnail based on its loading state and integrates with the SDK to fetch the image URL.
 *
 * Key functionalities include:
 * - Styling the image thumbnail based on whether it has loaded.
 * - Constructing the image URL using the SDK.
 * - Handling avatar selection via an onClick event.
 *
 * The component returns a list item element containing the avatar image and information, with conditional styling based on selection and loading state.
 */

import { useState } from "react"
import { useSDK } from "@/hooks/metaverse/useSDK"

const AvatarPreview = ({ onSelect, selected, avatar }) => {
  const SDK = useSDK()
  const [imageLoaded, setImageLoaded] = useState(false)

  const styleImgThumbnail = () => {
    if (imageLoaded) {
      return "card-thumb smooth-image image-visible "
    }

    return "card-thumb smooth-image image-loading"
  }

  const srcImageUrl = () => {
    return (
      SDK.getAPIUrl() +
      avatar.thumbnail.path.replace("asset//", "asset/" + avatar["asset-eid"] + "/")
    )
  }

  return (
    <li
      className={"avatar-preview card " + (selected ? "selected" : "")}
      onClick={() => {
        onSelect(avatar)
      }}
    >
      <img
        className={styleImgThumbnail()}
        src={srcImageUrl()}
        alt="Card thumbnail"
        onLoad={() => setImageLoaded(true)}
      />

      <div className="card-info">
        <p className="avatar-name">{avatar.name}</p>
      </div>
    </li>
  )
}

export default AvatarPreview
