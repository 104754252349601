import { useState } from "react"
import { useSearch } from "@/hooks/interface/useSearch"
import SearchResult from "@/components/Layout/Main/SearchBar/components/SearchDashboard/modules/SearchResult/SearchResult.jsx"
import SearchRelatedQuestions from "@/components/Layout/Main/SearchBar/components/SearchDashboard/modules/SearchRelatedQuestions/SearchRelatedQuestions.jsx"
import SearchMedia from "@/components/Layout/Main/SearchBar/components/SearchDashboard/modules/SearchMedia/SearchMedia.jsx"
import SearchMenu from "@/components/Layout/Main/SearchBar/components/SearchDashboard/modules/SearchMenu/SearchMenu.jsx"
import "./search-dashboard.scss"

const SearchDashboard = () => {
  const { displaySearchResults, sendValue, receivedMediaContent } = useSearch()
  const [isResultOpen, setIsResultOpen] = useState(false)
  const [isMediaOpen, _setIsMediaOpen] = useState(false)

  const handleResultClick = (event) => {
    // console.log(event.target)
    if (event.target.closest(".share-container")) return

    event.preventDefault()
    setIsResultOpen((prevState) => !prevState)
  }

  // const handleMediaClick = () => {
  //   setIsMediaOpen((prevState) => !prevState)
  // }

  return (
    <>
      <div className="search-dashboard__inner">
        <div className="search-dashboard__outer-column search-dashboard__outer-column--left">
          <nav
            className="search-dashboard__inner-column--first search-dashboard__section search-dashboard__section--menu"
            title="Prévu pour le sprint 3 du 02/12 au 03/01"
          >
            <SearchMenu />
          </nav>
        </div>
        <div
          className={`search-dashboard__outer-column search-dashboard__outer-column--right ${isMediaOpen && "search-dashboard__outer-column--right--no-gap"}`}
        >
          <div
            className={`search-dashboard__inner-column--second ${(isMediaOpen || isResultOpen) && "search-dashboard__inner-column--second--no-gap"}`}
          >
            {/* Result section */}
            <div
              className={`search-dashboard__section search-dashboard__section--result ${displaySearchResults ? "search-dashboard__section--result--visible" : ""} ${isResultOpen ? "search-dashboard__section--result--open" : isMediaOpen ? "search-dashboard__section--result--closed" : ""}`}
              onClick={handleResultClick}
            >
              <div className="search-dashboard__section__title">{sendValue}</div>

              <SearchResult isResultOpen={isResultOpen} />
            </div>

            {/* Gates section */}
            <div
              className={`search-dashboard__section search-dashboard__section--gates  ${displaySearchResults && receivedMediaContent ? "search-dashboard__section--gates--visible" : ""} ${isResultOpen || isMediaOpen ? "search-dashboard__section--gates--closed" : ""}`}
              title="Prévu pour le sprint 4 du 06/01 au 10/01"
            >
              <div className="search-dashboard__section__title">gates</div>
              <div className="search-dashboard__section__pill-container">
                <div className="search-dashboard__section__pill-container__pill">design twin</div>
                <div className="search-dashboard__section__pill-container__pill">market cx</div>
                <div className="search-dashboard__section__pill-container__pill">industry</div>
              </div>
            </div>

            {/* Media section */}
            <div
              className={`search-dashboard__section search-dashboard__section--media  ${displaySearchResults && receivedMediaContent ? "search-dashboard__section--media--visible" : ""} ${isMediaOpen ? "search-dashboard__section--media--open" : isResultOpen ? "search-dashboard__section--media--closed" : ""}`}
              // onClick={handleMediaClick}
              title="Prévu pour le sprint 1 du 25/10 au 30/10"
            >
              <div className="search-dashboard__section__card-container">
                <SearchMedia />
              </div>
            </div>
          </div>

          <div
            className={`search-dashboard__inner-column--third search-dashboard__section search-dashboard__section--related-questions  ${displaySearchResults ? "search-dashboard__section--related-questions--visible" : ""} ${isMediaOpen && "search-dashboard__section--related-questions--closed"}`}
          >
            <div className="search-dashboard__section__title">related questions</div>
            <SearchRelatedQuestions />
          </div>
        </div>
      </div>
      <aside className="search-dashboard__outer-column search-dashboard__outer-column--aside">
        <div className="search-dashboard__section"></div>
      </aside>
    </>
  )
}

export default SearchDashboard
