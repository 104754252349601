/**
 * POI.jsx
 *
 * The POI component manages and displays information about a specific point of interest (POI) within the application.
 * It handles user interactions with the POI and dynamically renders the content based on the current POI state.
 *
 * Key functionalities include:
 * - Displaying the title and content of the current POI.
 * - Rendering a button for POI interaction if applicable, with an event handler for button clicks.
 * - Managing the visibility of the POI panel based on the showPOI state.
 * - Providing a close button to hide the POI panel.
 *
 * The component leverages the useRooms hook to manage the state and actions related to POIs.
 *
 * The component returns a div element containing the POI information and controls, with conditional rendering based on the current POI state.
 */

import { useRooms } from "@/hooks/metaverse/useRooms"
import iconClose from "@/assets/icons/UI/times.svg"
import "./poi.scss"

const PointOfInterest = () => {
  const { showPOI, currentPOI, onPOIButtonClick, setShowPOI } = useRooms()

  const handleClick = () => {
    onPOIButtonClick(currentPOI.button)
  }

  let content = null
  if (currentPOI != null && showPOI) {
    content = (
      <div className="content">
        <h2 id="POI-title">{currentPOI.title}</h2>

        <div id="POI-content" dangerouslySetInnerHTML={{ __html: currentPOI.content }}></div>

        {currentPOI.button.type !== "none" && (
          <button id="POI-button" className="panelButton" onClick={() => handleClick()}>
            {currentPOI.button.caption}
          </button>
        )}
      </div>
    )
  }

  return (
    <>
      <div id="POI" className={showPOI ? "open" : "close"}>
        <div
          id="POI-close"
          onClick={() => {
            setShowPOI(false)
          }}
        >
          <div className="arrowicon close">
            <img src={iconClose} alt="" />
          </div>
        </div>
        {content}
      </div>
    </>
  )
}

export default PointOfInterest
