import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useInterface } from "@/hooks/interface/useInterface"
import getTopicData from "./TopicData.jsx"
import "./topic-menu.scss"

const TopicMenu = () => {
  const navigateTo = useNavigate()
  const {
    subTopicMenuIsVisible,
    setSubTopicMenuPublicIsVisible,
    sideMenuOrganizationIsOpen,
    sideMenuAccountIsOpen,
  } = useInterface()
  const [classAnimation, setClassAnimation] = useState("")
  const [menuBlurStyle, setMenuBlurStyle] = useState("")

  // Updates the menu blur style based on subTopicMenuIsVisible state
  useEffect(() => {
    setMenuBlurStyle(subTopicMenuIsVisible ? "menu-blur" : "")
  }, [subTopicMenuIsVisible])

  // Adds a class to trigger animation and cleans up on unmount
  useEffect(() => {
    const timeout = setTimeout(() => {
      setClassAnimation("is-visible")
    }, 10)

    return () => {
      clearTimeout(timeout)
      setClassAnimation("")
    }
  }, [])

  const onNavigateToSubmenu = (url) => {
    navigateTo(url)
  }

  const styleDropdownItem = () => {
    let styleRender = ""

    return styleRender
  }

  const handleClickMenu = (e) => {
    const clickedItemId = e.currentTarget.id

    if (clickedItemId === "menu") {
      setSubTopicMenuPublicIsVisible(!subTopicMenuIsVisible)
    }
  }

  const { DROPDOWN_PUBLIC_ITEMS } = getTopicData(styleDropdownItem, onNavigateToSubmenu)

  const DropdownPublicRender = () => {
    return (
      <div className="dropdown-topic-menu">
        <div className={`topic-menu ${menuBlurStyle} ${classAnimation}`}>
          <ul>
            <span id="menu" className="first-item-list" onClick={(e) => handleClickMenu(e)}>
              Menu
            </span>

            {subTopicMenuIsVisible &&
              DROPDOWN_PUBLIC_ITEMS.map(({ id, label, value, styleClass, onClick }, index) => (
                <li
                  key={id}
                  value={value}
                  className={`dropdown-item ${styleClass}`}
                  style={{ animationDelay: `${index * 0.2}s` }}
                  onClick={onClick}
                >
                  {label}
                </li>
              ))}
          </ul>
        </div>
      </div>
    )
  }

  return (
    <>
      {!sideMenuOrganizationIsOpen && !sideMenuAccountIsOpen && (
        <>
          <div>{DropdownPublicRender()}</div>
        </>
      )}
    </>
  )
}

export default TopicMenu
