/**
 * Camera.js
 * Manages the camera for the 3D scene, controlling its perspective, position, and field of view.
 * The camera is a central component in rendering the scene from a particular viewpoint.
 * This class includes setup for the camera instance and various utilities for moving and controlling the camera's position and orientation.
 */

import {
  Object3D,
  PerspectiveCamera,
  Quaternion,
  Vector3,
  MathUtils,
  Spherical,
  Group,
} from "three"
import { bezier, raftween } from "@/utils/anim/index.js"
import { createLogger } from "@/utils/debug/index.js"
import { mixPrecise } from "@/webgl/utils/maths/index.js"
import { VIEWS } from "@/webgl/world/World.js"
import { USER_ACTIONS } from "@/webgl/world/controls/index.js"
import ExperienceManager from "@/webgl/ExperienceManager.js"

const log = createLogger("Camera", "#000", "#FFFFFF").log

const DUMMY = new Object3D()
const DUMMY_RESET = new Object3D()
const DUMMY_ORIGINAL = new Object3D()
const DUMMY_EASTER_EGG_ORIGINAL = new Object3D()
const VECTOR_1 = new Vector3()
const ORIGINAL_VECTOR = new Vector3()
const ORIGINAL_QUATERNION = new Quaternion()
const QUATERNION_1 = new Quaternion()
const QUATERNION_2 = new Quaternion()
const QUATERNION_3 = new Quaternion()
const QUATERNION_4 = new Quaternion()
const ORBIT_DISTANCE = 9
const SKY_DISTANCE = 3

const interpolationSteps = [
  { name: "continent", distance: SKY_DISTANCE, progress: 0.0 },
  { name: "gates", distance: ORBIT_DISTANCE * 0.6, progress: 0.6 },
  { name: "typos", distance: ORBIT_DISTANCE * 0.7, progress: 0.7 },
  { name: "orbit", distance: ORBIT_DISTANCE, progress: 1 },
]
export default class Camera {
  constructor() {
    this.setupBasicProperties()

    this.init()
  }

  setupBasicProperties() {
    this.experience = new ExperienceManager()
    this.sizes = this.experience.sizes
    this.time = this.experience.time
    this.scene = this.experience.scene
    this.controls = null
    this.gatesPanner = null
    this.tweens = []

    this.inContinent = false
    this.isFirstTimeOnContinent = true
    this.isRecentering = false
    this.isMagneticEffectActive = false
    this.gates = null
    this.easterEggGate = null
    this.allowMagneticEffect = true
    this.isMovingAwayActive = false
    this.canSnapBack = false

    this.previousDistanceToEasterEggGate = Infinity
    this.previousDistanceToGate = Infinity

    this.currentUserAction = null
    this.userIsFarEnough = false
    this.recenterCameraButton = document.querySelector(".center-back-to-gates")
    this.inContinent = false
    this.savedPosition = null
    this.relativePosition = new Vector3(-5, 0, -5)
  }

  /**
   * Initializes the camera with default settings and positions.
   */
  init() {
    this.instance = new PerspectiveCamera(55, this.sizes.width / this.sizes.height, 0.01, 30)
    this.instance.layers.enable(2)
    this.instance.position.set(0, 0, ORBIT_DISTANCE)
  }

  /**
   * Gets all the important information (where is the view and what the user is doing) from World.js which is instanciated later.
   * @param {Controls} controls - The control object managing user input
   * @param {Number} currentView - The current view or state of the application
   */
  getWorldInfos(controls, currentView, currentRoute) {
    this.controls = controls
    this.gatesPanner = this.controls.gatesPanner
    this.currentView = currentView
    this.currentUserAction = this.controls.currentUserAction
    this.inContinent = currentRoute.brand

    // this.controls.orbit.addEventListener("change", () => {
    //   console.log("change")
    // })
  }

  /**
   * Get all gates informations from Gates.js which is instanciated later.
   * @param {Object3D} ground - The ground on which are all the gates
   */
  getGatesInfo(ground) {
    this.gates = ground
    this.mainGates = new Group() // Initialize as an empty array to hold all main gates

    ground.children.forEach((gate) => {
      if (gate.isEasterEgg) {
        this.easterEggGate = gate // Assigns the Easter egg gate
      } else {
        // this.mainGates.add(gate) // Adds each non-Easter egg gate to the array
      }
    })
  }
  /**
   * Adjusts the camera's aspect ratio and projection matrix on window resize.
   */
  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height
    this.instance.updateProjectionMatrix()
  }

  /**
   * Resets the camera's "up" direction to the positive Y-axis.
   */
  resetUp() {
    this.instance.up.set(0, 1, 0)
    this.instance.updateProjectionMatrix()
    DUMMY.up.set(0, 1, 0)
  }

  /**
   * Runs the introductory animation for the camera.
   * @param {Function} onProgress - Callback function for progress updates
   * @param {Function} onComplete - Callback function to execute upon completion of the intro
   */
  runIntro(onProgress, onComplete) {
    if (this.tweenRunIntro) return

    log("runIntro")

    // Save start position & orientation
    VECTOR_1.copy(this.instance.position)
    QUATERNION_1.copy(this.instance.quaternion)

    this.tweenRunIntro = raftween({
      name: "runIntro",
      from: 0,
      to: 1,
      duration: 2000,
      delay: 1200,
      easing: "outCirc",
      selfdestruct: true,
      onProgress: (current, progress) => this.handleRunIntroProgress(onProgress, progress),
      onComplete: () => this.completeRunIntro(onComplete),
    })

    this.tweens.push(this.tweenRunIntro)
  }

  /**
   * Handles Run Intro progress animation
   * @param {Function} onProgress - Callback function for progress updates
   * @param {Number} progress - Tween progress
   */
  handleRunIntroProgress(onProgress, progress) {
    onProgress(progress)

    DUMMY.position.setScalar(0)
    DUMMY.lookAt(0, -1 + progress, progress)
    DUMMY.translateZ(ORBIT_DISTANCE)

    this.instance.position.copy(DUMMY.position)
    this.instance.lookAt(0, 0, 0)
    this.instance.updateProjectionMatrix()

    ORIGINAL_VECTOR.copy(this.instance.position).setLength(ORBIT_DISTANCE)
    ORIGINAL_QUATERNION.copy(this.instance.quaternion)
    DUMMY_ORIGINAL.position.copy(this.instance.position)
    DUMMY_ORIGINAL.position.setLength(ORBIT_DISTANCE)
    DUMMY_ORIGINAL.quaternion.copy(this.instance.quaternion)
  }

  /**
   * Handles the callback after the animation is finished
   * @param {Function} onComplete - Callback after animation is done
   */
  completeRunIntro(onComplete) {
    this.resetUp()

    if (onComplete) {
      onComplete()
    }

    if (this.tweenRunIntro) {
      this.tweenRunIntro.destroy()
      this.tweenRunIntro = null
    }
  }

  /**
   * Transition the camera to an orbit view around a target or scene.
   * @param {Function} onProgress - Callback function for progress updates
   * @param {Function} onComplete - Callback function to execute upon completion
   */
  enterOrbit(onProgress, onComplete) {
    if (this.tweenEnterOrbit) return
    this.inContinent = false

    // Save start position & rotation
    VECTOR_1.copy(this.instance.position)
    QUATERNION_1.copy(this.instance.quaternion)

    // Create end position & rotation
    DUMMY.position.copy(this.instance.position)
    DUMMY.position.setLength(ORBIT_DISTANCE)
    DUMMY.quaternion.copy(this.instance.quaternion)
    DUMMY.up.set(0, 1, 0)
    DUMMY.lookAt(0, 0, 0)
    DUMMY.rotateY(Math.PI)

    // Make sure the camera is looking up
    this.instance.up.set(0, 1, 0)
    this.instance.updateProjectionMatrix()

    this.tweenEnterOrbit = raftween({
      name: "enterOrbit",
      from: 0,
      to: 1,
      duration: 1000,
      easing: "inOutQuart",
      selfdestruct: true,
      onProgress: (current, progress) => this.handleEnterOrbitProgress(onProgress, progress),
      onComplete: () => this.completeEnterOrbit(onComplete),
    })

    this.tweens.push(this.tweenEnterOrbit)
  }

  /**
   * Handles Enter Orbit progress animation
   * @param {Function} onProgress - Callback to handle progress
   * @param {Number} progress - Tween progress
   */
  handleEnterOrbitProgress(onProgress, progress) {
    onProgress(progress)

    this.instance.position.copy(VECTOR_1).lerp(DUMMY.position, progress)
    this.instance.quaternion.copy(QUATERNION_1).slerp(DUMMY.quaternion, progress)
    this.instance.updateProjectionMatrix()
  }

  /**
   * Handles the callback after the animation is finished
   * @param {Function} onComplete - Callback after animation is done
   */
  completeEnterOrbit(onComplete) {
    this.resetUp()

    if (this.tweenEnterOrbit) {
      this.tweenEnterOrbit.destroy()
      this.tweenEnterOrbit = null
    }

    this.instance.currentZ = ORBIT_DISTANCE

    if (onComplete) {
      onComplete()
    }
  }

  /**
   * Transitions the camera to zoom in on the continent.
   * @param {Object} brand - The brand or area to focus on
   * @param {String} brandName - The name of the brand for logging purposes
   * @param {Function} onProgress - Callback function for progress updates
   * @param {Function} onComplete - Callback function to execute upon completion
   */
  enterExploration(brand, currentZoomLevel, onStart, onProgress, onComplete, isClicked) {
    if (!brand) {
      return
    }

    if (currentZoomLevel === 0) {
      this.savedPosition = this.instance.position.clone()
    }

    const target = isClicked
      ? brand
      : brand.selectedDepartment
        ? brand.selectedDepartment
        : brand.defaultDepartment
    this.inContinent = false
    this.controls.cancelAutorotate()

    const step = interpolationSteps[currentZoomLevel]

    // Resetting DUMMY and ensuring it uses correct up direction
    DUMMY.position.setScalar(0)
    DUMMY.up.set(0, 0, 1)

    // Save start globe orientation
    DUMMY.lookAt(this.instance.position)
    QUATERNION_1.copy(DUMMY.quaternion)

    // Store end globe orientation
    DUMMY.lookAt(
      currentZoomLevel === 1 && this.savedPosition
        ? this.savedPosition
        : currentZoomLevel <= 1 && target
          ? target.position
          : isClicked
            ? brand.position
            : brand.ground.position,
    )
    QUATERNION_2.copy(DUMMY.quaternion)

    // Save original camera orientation
    QUATERNION_3.copy(this.instance.quaternion)

    // Orient camera from top down
    this.instance.position.set(
      currentZoomLevel === 1 && this.savedPosition
        ? this.savedPosition
        : currentZoomLevel <= 1 && target
          ? target.position
          : isClicked
            ? brand.position
            : brand.ground.position,
    )
    this.instance.lookAt(
      currentZoomLevel === 1 && this.savedPosition
        ? this.savedPosition
        : currentZoomLevel <= 1 && target
          ? target.position
          : isClicked
            ? brand.position
            : brand.ground.position,
    )
    this.instance.up.set(0, 1, 0) // Ensuring the camera's up direction remains global up
    this.instance.updateProjectionMatrix()

    //Store end camera orientation
    DUMMY.position.setScalar(0)
    DUMMY.quaternion.copy(QUATERNION_2)
    DUMMY.translateZ(SKY_DISTANCE)
    DUMMY.up.copy(DUMMY.position).normalize()
    DUMMY.lookAt(0, 0, 0)
    DUMMY.rotateY(Math.PI)
    QUATERNION_4.copy(DUMMY.quaternion)

    const easeTest = bezier("inOutQuad")

    if (onStart) onStart()

    // Determine the easing function based on the zoom level
    const easingFunction =
      currentZoomLevel === 0 ? "outSwift" : currentZoomLevel === 1 ? "outSwift" : "outCubic"

    this.tweenEnterExploration = raftween({
      name: "enterExploration",
      from: 0,
      to: 1,
      duration: 1000,
      easing: easingFunction,

      onProgress: (current, progress) => {
        progress = easeTest(progress)
        if (onProgress) onProgress(progress)

        const altitude = mixPrecise(
          this.instance.currentZ ? this.instance.currentZ : ORBIT_DISTANCE,
          step.distance,
          progress,
          0.00001,
        )

        this.instance.quaternion.slerpQuaternions(QUATERNION_1, QUATERNION_2, progress)

        // Orbit camera to end position
        DUMMY.position.setScalar(0)
        DUMMY.quaternion.copy(QUATERNION_1).slerp(QUATERNION_2, progress)
        DUMMY.translateZ(altitude)
        this.instance.position.copy(DUMMY.position)

        // Orient camera to end lookAt
        this.instance.quaternion.copy(QUATERNION_3).slerp(QUATERNION_4, progress)
        this.instance.updateProjectionMatrix()
      },
      onComplete: () => {
        this.instance.currentZ = step.distance
        this.instance.updateProjectionMatrix()

        // TODO : This needs to be avoided
        this.instance.up.set(0, 0, 1)
        this.instance.updateProjectionMatrix()

        if (currentZoomLevel === 0) {
          this.savedPosition = this.instance.position.clone()
          this.initialPosition = this.instance.position.clone()
          this.inContinent = true
          setTimeout(() => {
            this.isFirstTimeOnContinent = false
          }, 1000)
        }

        if (currentZoomLevel >= 2) {
          this.savedPosition = null
        }

        // this.resetUp()

        if (onComplete) onComplete()
      },
    })

    this.tweens.push(this.tweenEnterExploration)
  }

  /**
   * Reset the camera to focus on the front or back side of the planets.
   * @param {Function} onComplete - Callback function to execute upon completion
   */
  resetPlanet(onComplete) {
    if (this.tweenReset) return

    // Convert the camera's position to spherical coordinates
    const spherical = new Spherical().setFromVector3(
      this.instance.position.setLength(ORBIT_DISTANCE),
    )
    const isCameraAtBack = Math.abs(spherical.theta) > Math.PI / 2
    const targetSpherical = new Spherical().setFromVector3(
      DUMMY_ORIGINAL.position.setLength(ORBIT_DISTANCE),
    )

    this.instance.up.set(0, 1, 0)

    this.tweenReset = raftween({
      name: "resetPlanet",
      from: 0,
      to: 1,
      duration: 400,
      easing: "inOutExpo",
      onProgress: (current, _) =>
        this.handleResetPlanetProgress(spherical, isCameraAtBack, targetSpherical, current),
      onComplete: () => this.completeResetPlanet(onComplete),
    })

    this.tweens.push(this.tweenReset)
  }

  /**
   * Handles Reset planet progress animation
   * @param {Number} spherical - Gets the camera's position to spherical coordinates
   * @param {Number} isCameraAtBack - Is the camera at the front orbit or back orbit ? Return a number
   * @param {Number} targetSpherical - Targeted spherical coordinates to reset planets
   * @param {Number} progress - Tween progress
   */
  handleResetPlanetProgress(spherical, isCameraAtBack, targetSpherical, current) {
    // Interpolate the spherical coordinates including the full rotation
    let targetTheta = isCameraAtBack ? (spherical.theta > 0 ? Math.PI : -Math.PI) : 0

    spherical.theta = MathUtils.lerp(spherical.theta, targetTheta, current)
    spherical.phi = MathUtils.lerp(spherical.phi, targetSpherical.phi, current)

    // Convert back to Cartesian coordinates while maintaining orbit distance
    this.instance.position.setFromSpherical(spherical).setLength(ORBIT_DISTANCE)

    // Interpolate the up vector
    const currentUp = this.instance.up.clone()
    const targetUp = new Vector3(0, 1, 0)
    this.instance.up.lerpVectors(currentUp, targetUp, current)

    this.instance.lookAt(new Vector3(0, 0, 0))
    this.instance.updateProjectionMatrix()
  }

  /**
   * Handles the callback after the animation is finished
   * @param {Function} onComplete - Callback after animation is done
   */
  completeResetPlanet(onComplete) {
    this.instance.lookAt(new Vector3(0, 0, 0))
    this.resetUp()

    if (onComplete) {
      onComplete()
    }

    if (this.tweenReset) {
      this.tweenReset.destroy()
      this.tweenReset = null
    }
  }

  /**
   * Centers the camera on the gates, useful for returning to a default view.
   * @param {Function} onComplete - Callback function to execute upon completion
   */
  async recenter(onComplete) {
    if (
      !this.experience.userHasDraggedInContinent &&
      this.currentUserAction !== USER_ACTIONS.DRAGGING_IN_CONTINENT
    ) {
      return
    }
    log("Recentering")

    // Flags and diable the possibility to drag on continent
    this.isRecentering = true
    // if (!this.gatesPanner) this.gatesPanner.disable()

    // Save start position
    const cameraStartPosition = this.instance.position.clone()

    // Save end position
    const targetPosition = DUMMY_RESET.position.copy(DUMMY.position)

    if (this.tweenRecenter) return

    await new Promise((resolve) => {
      this.tweenRecenter = raftween({
        name: "recenter",
        from: 0,
        to: 1,
        duration: 600,
        easing: "majestic",
        selfdestruct: true,
        onProgress: (current, progress) =>
          this.handleRecenterProgress(cameraStartPosition, targetPosition, progress),
        onComplete: () => this.completeRecenter(resolve, onComplete),
      })

      this.tweens.push(this.tweenRecenter)
    })
  }

  /**
   * Handles Reset planet progress animation
   * @param {Number} cameraStartPosition - Camera start position
   * @param {Number} cameraStartPosition - Camera target position
   * @param {Number} progress - Tween progress
   */
  handleRecenterProgress(cameraStartPosition, targetPosition, progress) {
    this.instance.position.lerpVectors(cameraStartPosition, targetPosition, progress)
    this.instance.updateProjectionMatrix()
  }

  /**
   * Handles the callback after the animation is finished
   * @param {Function} resolve - Callback to resolve promise
   * @param {Function} onComplete - Callback after animation is done
   */
  completeRecenter(resolve, onComplete) {
    this.isRecentering = false

    if (this.gatesPanner) {
      this.gatesPanner.enable()
    }

    if (this.tweenRecenter) {
      this.tweenRecenter.destroy()
      this.tweenRecenter = null
    }

    if (onComplete) {
      onComplete()
    }

    resolve()
  }

  /**
   * Creates a magnetic effect when user is near a gate
   * @param {Object3D} targetGate - The gate nearest to the camera
   */
  startMagneticEffect(targetGate) {
    if (
      !this.inContinent ||
      this.isMagneticEffectActive ||
      !this.experience.userHasDraggedInContinent ||
      this.currentUserAction !== USER_ACTIONS.DRAGGING_IN_CONTINENT
    ) {
      return
    }
    log("Magnetic Effect")

    // Set flags and disable the possibility to drag on continent when the magnetic effect starts
    this.isMagneticEffectActive = true

    // Store initial and target position
    const startPosition = this.instance.position.clone()
    const targetPosition = targetGate.position.clone()

    // Adjusted duration based on distance
    const distance = startPosition.distanceTo(targetPosition)
    const minDuration = 100
    const distanceFactor = Math.max(0, 1 - distance / 0.15)
    const adjustedDuration = Math.max(minDuration, 100 * (1 - distanceFactor))

    // // Set the correct up vector
    this.instance.up.set(0, 0, 1)
    this.instance.updateProjectionMatrix()

    if (this.tweenMagnetic) return

    this.tweenMagnetic = raftween({
      name: "magneticEffect",
      from: 0,
      to: 1,
      duration: adjustedDuration,
      easing: "snap2",
      onProgress: (current, _) =>
        this.handleMagneticEffectProgress(targetGate, startPosition, targetPosition, current),
      onComplete: () => this.completeMagneticEffect(),
    })

    this.tweens.push(this.tweenMagnetic)
  }

  /**
   * Handles Reset planet progress animation
   * @param {Object3D} targetGate - The nearest gate from the user/camera
   * @param {Vector3} startPosition - Camera intiial position
   * @param {Vector3} targetPosition - Camera targeted position
   * @param {Number} progress - Tween progress
   */
  handleMagneticEffectProgress(targetGate, startPosition, targetPosition, progress) {
    // Interpolate x and z positions
    let targetX = MathUtils.lerp(startPosition.x, targetPosition.x, progress)
    let targetZ = MathUtils.lerp(startPosition.z, targetPosition.z, progress)

    if (targetGate.name === this.easterEggGate?.name) {
      targetX -= 0.124 // Adjust for the easter egg gate
    }
    // Set the interpolated positions
    this.instance.position.set(targetX, startPosition.y, targetZ)

    // Ensure the camera's up vector is correct
    this.instance.up.set(0, 1, 0)

    // Scale the position to maintain the correct distance
    this.instance.position.setLength(startPosition.length())

    // Update the projection matrix to ensure the camera view is correct
    this.instance.updateProjectionMatrix()
  }

  completeMagneticEffect() {
    // Reset flags and enable back the possibility to drag on continent
    this.isMagneticEffectActive = false
    this.isMovingTowardsTarget = false

    if (this.gatesPanner) {
      this.gatesPanner.enable()
    }

    if (this.tweenMagnetic) {
      this.tweenMagnetic.destroy()
      this.tweenMagnetic = null
    }

    this.canSnapBack = true

    this.allowMagneticEffect = false
    setTimeout(() => {
      this.allowMagneticEffect = true
    }, 3000)
  }

  /**
   * Checks if the user has moved the camera enough from the center of the scene inside continent
   */
  checkDistanceToNearGates() {
    if (
      !this.initialPosition ||
      this.isRecentering ||
      !this.inContinent ||
      this.isFirstTimeOnContinent ||
      (!this.experience.userHasDraggedInContinent &&
        this.currentUserAction !== USER_ACTIONS.DRAGGING_IN_CONTINENT)
    )
      return

    if (this.checkCameraMovedSignificantDistance(0.4)) {
      this.hasUserMovedEnough = true
    }

    if (!this.hasUserMovedEnough) return

    // Save the nearest gate world position
    const easterEggGateWorldPosition = new Vector3()

    if (this.easterEggGate) {
      this.easterEggGate.updateMatrixWorld(true)
      this.easterEggGate.getWorldPosition(easterEggGateWorldPosition)
    }

    // Save the camera intiial position
    const startingPosition = this.instance.position.clone()

    // Give the initial position a name for better readability
    DUMMY.name = "6 gates"
    DUMMY.target = this.mainGates

    // Save the easter egg original position
    DUMMY_EASTER_EGG_ORIGINAL.position.copy(
      new Vector3(easterEggGateWorldPosition.x, startingPosition.y, easterEggGateWorldPosition.z),
    )
    DUMMY_EASTER_EGG_ORIGINAL.name = "Easter Eggs"
    DUMMY_EASTER_EGG_ORIGINAL.target = this.easterEggGate

    // Calculate the distance betweent he camera and nearest gate
    const currentDistanceToEasterEgg = this.instance.position.distanceTo(
      DUMMY_EASTER_EGG_ORIGINAL.position,
    )

    // Calculate the distance between the camera and the center of the scene were are the 6 gates
    const currentDistanceToGatesCenter = this.instance.position.distanceTo(DUMMY.position)

    this.evaluateMagneticEffect(
      currentDistanceToEasterEgg,
      DUMMY_EASTER_EGG_ORIGINAL,
      0.2,
      this.previousDistanceToEasterEggGate,
    )

    this.evaluateMagneticEffect(
      currentDistanceToGatesCenter,
      DUMMY,
      0.2,
      this.previousDistanceToGate,
    )

    // Store the previous distance from the camera to the easter egg and gates center
    this.previousDistanceToEasterEggGate = currentDistanceToEasterEgg
    this.previousDistanceToGate = currentDistanceToGatesCenter
  }

  /**
   * Initiates magnetic effect if moving closer to target within threshold.
   * @param {Number} currentDistance Current distance to target.
   * @param {Object3D} target Target object for magnetic effect.
   * @param {Number} startThreshold Distance threshold to start effect.
   * @param {Number} previousDistanceToTarget Distance to target in previous frame.
   */
  evaluateMagneticEffect(currentDistance, target, startThreshold, previousDistanceToTarget) {
    const isMovingTowardsTarget = currentDistance < previousDistanceToTarget
    const isWithinThreshold = currentDistance < startThreshold

    // Ensure targetOriginalPosition is only set once and not modified
    if (!target.originalPosition) {
      target.originalPosition = target.position.clone()
      // console.log(`Original position set for ${target.name}: ${target.originalPosition.toArray()}`)
    }

    if (isMovingTowardsTarget && isWithinThreshold && this.allowMagneticEffect) {
      // console.log(`Activating magnetic effect near ${target.name}`)

      this.hasExitedThreshold = false // Reset on entering

      this.startMagneticEffect(target)
    } else if (
      !isMovingTowardsTarget &&
      isWithinThreshold &&
      this.currentUserAction === USER_ACTIONS.DRAGGING_IN_CONTINENT
    ) {
      // console.log(`Camera moving away but still near ${target.name}`)
      // this.updateGatePosition(target)
      this.controls.gatesPanner.speed = 0.0002
      // this.controls.gatesPanner.speed = Math.max(0.0002, this.controls.gatesPanner.speed * 0.5) // Slow down gradually
    } else if (
      !isMovingTowardsTarget &&
      !isWithinThreshold &&
      this.currentUserAction === USER_ACTIONS.DRAGGING_IN_CONTINENT
    ) {
      this.controls.gatesPanner.speed = 0.001
      if (!this.hasExitedThreshold) {
        // console.log(`Camera moving further away from ${target.name}, triggering snapback.`)

        // this.snapBackEffect(target, this.newPos) // Use the preserved original position for snapback
        this.hasExitedThreshold = true // Ensure snapback triggers only once
      }
    }
  }

  snapBackEffect(target, newPos) {
    if (this.tweenSnapBack || !newPos) return // Prevent overlapping animations

    this.tweenSnapBack = raftween({
      name: "snapbackEffect",
      from: 0,
      to: 1,
      duration: 100, // Duration in milliseconds
      easing: "snap2", // Replace "elastic.out" with your actual available elastic easing
      onProgress: (current, progress) => {
        // console.log("current position", target.position)

        target.position.lerpVectors(newPos, target.originalPosition, progress)
      },
      onComplete: () => {
        // console.log("Snapback to original position completed.")
        // console.log("Original Position:", target.originalPosition)
        // console.log("FInal Position:", target.position)
        this.tweenSnapBack = null // Clear the animation handle
        this.allowMagneticEffect = true
      },
    })

    this.tweens.push(this.tweenSnapBack) // A
  }

  getCameraDirection(camera) {
    if (
      !this.initialPosition ||
      this.isRecentering ||
      !this.inContinent ||
      this.isFirstTimeOnContinent ||
      (!this.experience.userHasDraggedInContinent &&
        this.currentUserAction !== USER_ACTIONS.DRAGGING_IN_CONTINENT)
    )
      return

    let direction = new Vector3()
    camera.getWorldDirection(direction)
    return direction // Returns the normalized forward direction vector of the camera
  }

  updateGatePosition(target) {
    // Calculate the direction vector from the target to the camera
    const direction = this.getDirectionVector(target, this.instance.position)
    const pullStrength = 0.01 // Very slight pull towards the camera

    // Apply the directional vector scaled by pullStrength, but only affect X and Z coordinates
    target.position.x += direction.x * pullStrength
    target.position.z += direction.z * pullStrength

    // Optionally save the new position if needed elsewhere
    target.newPosition = new Vector3(target.position.x, target.position.y, target.position.z)
  }

  getDirectionVector(target, cameraPosition) {
    let direction = new Vector3()
    direction.subVectors(cameraPosition, target.position) // Ensure direction is from target to camera
    direction.y = 0 // Zero out the Y component so it doesn't affect vertical position
    // direction.z = target.position.z
    direction.normalize() // Normalize to get the unit vector
    return direction
  }

  /**
   * Manages the visibility of the recenter button based on the camera's current position.
   */
  recenterButtonVisibility() {
    if (
      (!this.experience.userHasDraggedInContinent &&
        this.currentUserAction !== USER_ACTIONS.DRAGGING_IN_CONTINENT) ||
      !this.inContinent ||
      this.isRecentering ||
      this.currentView !== VIEWS.IN_CONTINENT
    ) {
      this.recenterCameraButton?.classList.remove("show")

      return
    }

    // Reset the current user action
    this.currentUserAction = USER_ACTIONS.DRAGGING_IN_CONTINENT

    if (this.currentView === VIEWS.IN_CONTINENT) {
      this.instance.up.set(0, 1, 0)
      this.instance.updateProjectionMatrix()

      const distance = this.instance.position.distanceTo(DUMMY.position)
      const threshold = 0.001

      if (this.recenterCameraButton && distance > threshold) {
        this.recenterCameraButton?.classList.add("show")
      } else {
        this.recenterCameraButton?.classList.remove("show")
      }
    }
  }

  /**
   * Checks if the user has moved the camera enough from the center of the scene inside onctinent
   */
  checkUserMovedEnoughInContinent() {
    if (this.checkCameraMovedSignificantDistance(0.05, 0.1)) {
      this.userIsFarEnough = true
    }
  }

  /**
   * Checks if the camera has moved a significant distance from its initial position.
   * @param {Number} minDistance - The minimum distance considered significant.
   * @param {Number} maxDistance - The maximum distance to check, optional.
   */
  checkCameraMovedSignificantDistance(minDistance, maxDistance = Infinity) {
    if (
      !this.initialPosition ||
      this.currentView !== VIEWS.IN_CONTINENT ||
      this.currentUserAction !== USER_ACTIONS.DRAGGING_IN_CONTINENT
    ) {
      return false
    }

    const currentDistance = this.instance.position.distanceTo(this.initialPosition)
    const hasMovedEnough = currentDistance > minDistance && currentDistance < maxDistance

    if (hasMovedEnough) {
      this.experience.hasUserDragged(true)
      return true
    }

    return false
  }

  /**
   * Makes a 360 full rotation around the planet
   * @param {Boolean} isTogglingForward - is the 360 rotation going forward or backward
   * @param {Function} onProgress - Callback function for progress updates
   * @param {Function} onComplete - - Callback after animation is done
   */
  rotateCamera360(isTogglingForward, onProgress, onComplete) {
    if (this.tweenRotation) return

    this.controls.orbit.disable()
    this.controls.cancelAutorotate()

    const initialSpherical = new Spherical().setFromVector3(this.instance.position)
    const deltaTheta = Math.PI * 2 * (isTogglingForward ? 1 : -1)
    const endTheta = initialSpherical.theta + deltaTheta

    this.instance.up.set(0, 1, 0)

    this.tweenRotation = raftween({
      name: "resetPlanet",
      from: 0,
      to: endTheta,
      duration: 500,
      easing: "inOutExpo",
      selfdestruct: true,
      onProgress: (current, _) =>
        this.handleRotateCamera360Progress(
          initialSpherical,
          deltaTheta,
          onProgress,
          current,
          isTogglingForward,
        ),

      onComplete: () => this.completeRotateCamera360(onComplete),
    })
    this.tweens.push(this.tweenRotation)
  }

  /**
   * Handles Reset planet progress animation
   * @param {Object3D} nitialSpherical - The nearest gate from the user/camera
   * @param {Vector3} deltaTheta - Camera intiial position
   * @param {Function} onProgress - Callback function for progress updates
   * @param {Number} current- Tween progress
   */
  handleRotateCamera360Progress(
    initialSpherical,
    deltaTheta,
    onProgress,
    current,
    isTogglingForward,
  ) {
    onProgress(current, isTogglingForward)

    const currentTheta = initialSpherical.theta + deltaTheta * current

    const newSpherical = new Spherical(initialSpherical.radius, initialSpherical.phi, currentTheta)

    this.instance.position.setFromSpherical(newSpherical)
    this.instance.lookAt(new Vector3(0, 0, 0))
    this.instance.up.set(0, 1, 0)
  }

  /**
   * Handles the callback after the animation is finished
   * @param {Function} onComplete - Callback after animation is done
   */
  completeRotateCamera360(onComplete) {
    if (this.tweenRotation) {
      this.tweenRotation.destroy()
      this.tweenRotation = null
    }

    this.resetPlanet(() => {
      this.controls.orbit.enable()
      this.controls.startAutorotate()
    })

    if (onComplete) {
      onComplete()
    }
  }

  /**
   * Regularly updates the camera's position and orientation.
   */
  update() {
    if (this.tweens.length > 0) {
      this.tweens.forEach((tween) => {
        if (tween) {
          tween.update(this.time.delta)
        }
      })
    }

    this.checkDistanceToNearGates()
    this.checkUserMovedEnoughInContinent()
    this.recenterButtonVisibility()
  }
}
