export default class UserDeviceConfig {
  constructor() {
    this.hardwareConcurrency = navigator.hardwareConcurrency
    this.deviceMemory = navigator.deviceMemory
    this.userAgent = navigator.userAgent
    this.connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
  }

  isLikelyLowEndDevice() {
    if (this.hardwareConcurrency && this.hardwareConcurrency < 4) {
      return true
    }

    if (this.deviceMemory && this.deviceMemory < 2) {
      return true
    }

    if (this.connection && ["slow-2g", "2g", "3g"].includes(this.connection.effectiveType)) {
      return true
    }

    return false
  }

  getNetworkType() {
    return this.connection ? this.connection.effectiveType : "unknown"
  }

  async getBatteryStatus() {
    if ("getBattery" in navigator) {
      try {
        const battery = await navigator.getBattery()
        return {
          level: battery.level,
          charging: battery.charging,
        }
      } catch (error) {
        console.error("Error getting battery status:", error)
        return null
      }
    } else {
      return null
    }
  }

  isMobileDevice() {
    return /mobile|tablet|ipad|iphone|android/i.test(this.userAgent)
  }
}
