/**
 * CollectibleMint.jsx
 *
 * The CollectibleMint component manages the minting process for NFTs, checking if the user already owns the collectible and if minting is allowed.
 * It handles loading states and user interactions for claiming the collectible.
 *
 * Key functionalities include:
 * - Checking if the user already owns one or more of the specific NFTs.
 * - Determining if minting is allowed.
 * - Handling loading states during the minting process.
 * - Managing user interactions for closing the modal and claiming the collectible.
 *
 * The component returns a modal prompting the user to claim a collectible. If the user already owns it, a different message is displayed.
 */

import { useAuth } from "@/hooks/onboarding/useAuth"
import { useAvatarSetup } from "@/hooks/onboarding/useAvatarSetup"
import { useClaimSetup } from "@/hooks/onboarding/useClaimSetup.jsx"
import { useRooms } from "@/hooks/metaverse/useRooms"
import CollectibleMintLoading from "./CollectibleMintLoading.jsx"
import "./collectibleMint.scss"

const CollectibleMint = () => {
  const { profile, collectibles, collections } = useAuth()
  const {
    setModalAvatarSetupVisible,
    launchAvatarSetup,
    selectedAvatar,
    setSelectedAvatar,
    onAvatarCreate,
    expAvatars,
  } = useAvatarSetup()
  const { collectibleToMint, mintLoading, onClaim, resetModals, setModalClaimVisible } =
    useClaimSetup()
  const { expQuests } = useRooms()

  const createAvatar = () => {
    if (selectedAvatar != null) {
      onAvatarCreate("", selectedAvatar)
      setModalAvatarSetupVisible(false)
      setModalClaimVisible(true)
    }
  }

  if (collectibleToMint) {
    //checking if the user already has one or many of this NFT
    /*  @hint, we want to know if the user already possess one or more of this NFT
        we could use m.getWalletTokens(experience.eid).then((result) => ... and get the .length of the result
        ... but we already have this info on the user collectibles list we got at login
        .. so we're just gonna check if some collectibles
        in user.collectibles are from this collection : c.collection.eid

        knowing we already got the user collectibles before
        we avoid an API request, with the cost of two props here*/

    const nbAlreadyPossessed = collectibles?.filter((n) => {
      return n.collections.some((i) => {
        return collections[i]["eid"] === collectibleToMint?.collection?.eid
      })
    }).length

    const plural = nbAlreadyPossessed > 1 ? "s" : ""

    // is mint allowed ?
    let allowMint =
      collectibles != null && // we have to check if the user already have it
      (collectibleToMint.allowmultiple || nbAlreadyPossessed === 0) // we already have one and cannot have multiple

    let warnAltreadyPossess = null
    if (nbAlreadyPossessed > 0)
      warnAltreadyPossess = (
        <div className={["mint-warn-possess", allowMint ? "warn" : "error"].join(" ")}>
          You already have {nbAlreadyPossessed} NFT{plural} from this collection in your wallet
        </div>
      )

    let loading = mintLoading ? <CollectibleMintLoading /> : null

    const styleAvatarName = () => {
      if (
        collectibleToMint?.title === "Bold" ||
        collectibleToMint?.title === "Pop" ||
        collectibleToMint?.title === "Rev" ||
        collectibleToMint?.title === "Blue"
      ) {
        return "avatar-name"
      }

      return null
    }

    const handleClickCloseBtn = () => {
      if (!allowMint) {
        resetModals()
        setModalClaimVisible(false)
      } else {
        if (collectibles.length <= 0 && collections.length <= 0) {
          launchAvatarSetup()
          setModalAvatarSetupVisible(true)
          setSelectedAvatar(null)
        }
        setModalClaimVisible(false)
      }
    }

    const renderTextBackBtn = () => {
      if (collectibles.length <= 0 && collections.length <= 0) {
        return "Back"
      }

      return "Do it later"
    }

    const handleClickClaimBtn = () => {
      if (profile?.avatar === null) {
        onClaim(expAvatars)
      } else {
        onClaim(expQuests)
      }
      createAvatar()
    }

    return (
      <>
        {loading}
        <h1 className={styleAvatarName()}>{collectibleToMint.title}</h1>
        <p className="description">{collectibleToMint.description}</p>
        {warnAltreadyPossess}
        <div className="buttons">
          {!allowMint ? (
            <button className="button secondary" onClick={() => handleClickCloseBtn()}>
              Close
            </button>
          ) : (
            <>
              <button className="button secondary" onClick={() => handleClickCloseBtn()}>
                {renderTextBackBtn()}
              </button>
              <button
                disabled={!allowMint}
                className="button primary"
                onClick={() => handleClickClaimBtn()}
              >
                Claim
              </button>
            </>
          )}
        </div>
      </>
    )
  }
}

export default CollectibleMint
