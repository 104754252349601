import LOGO from "@/assets/images/logo.png"

const CustomVideoPlayer = ({ videoSource }) => {
  if (videoSource) {
    return (
      <>
        <video
          width="100%"
          height="auto"
          poster={LOGO}
          style={{ borderRadius: "16px" }}
          controls
          autoPlay
          loop
          muted
        >
          <source src={videoSource?.url} type="video/mp4" />
          Your browser does not support the video format
        </video>
      </>
    )
  }

  return "No source found"
}

export default CustomVideoPlayer
