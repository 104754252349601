import { useState } from "react"
import { Card, Spin } from "antd"
import "./my-account.scss"

const CardItem = ({ item, isQuestCard, backgroundColor, customImgStyle, className = "" }) => {
  const { image, name } = item
  const [displayImage, setDisplayImage] = useState(false)

  const LoaderRender = () => {
    return <Spin style={{ marginTop: "2rem", height: "100%" }} size="large" />
  }

  const ImageRenderingDelay = () => {
    if (image) {
      setTimeout(() => {
        setDisplayImage(true)
      }, 500)

      if (displayImage) {
        return <img alt={name} src={image} style={customImgStyle} />
      } else {
        return LoaderRender()
      }
    }
  }

  return (
    <Card
      hoverable
      bordered={false}
      className={`card-item ${className}`}
      style={backgroundColor}
      cover={ImageRenderingDelay()}
    >
      {!isQuestCard && <div className="description-container">{name}</div>}
    </Card>
  )
}

export default CardItem
