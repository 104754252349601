/**
 * Video.jsx
 *
 * The Video component manages the display of video content and an associated topic menu.
 * It utilizes the VideoPlayer component to play video content and the TopicMenu component for displaying additional content.
 *
 * Key functionalities include:
 * - Displaying a video player with configurable properties such as media source, looping, and auto-stop time.
 * - Displaying a topic menu when the showContent prop is true.
 * - Managing references for the video player and the wrapper div.
 *
 * The component returns a div containing the video player and the topic menu, with conditional rendering based on the showContent and showVideo props.
 */

import { useRef } from "react"
import { useParams } from "react-router-dom"
import VideoPlayer from "@/components/Common/VideoPlayer.jsx"
import TopicMenu from "@/components/Layout/Header/Navigation/modules/Topic/TopicMenu"

const Video = ({ showContent, showVideo }) => {
  const { experience } = useParams()

  const wrapperRef = useRef(null)

  const isExperienceFlins = experience === "flins"
  const isExpeienceDesignTwin = experience === "design-twin"

  const autoStop = isExperienceFlins ? 10 : 16

  return (
    <div className="section-wrapper" ref={wrapperRef}>
      {showVideo && (
        <VideoPlayer
          media={`${isExperienceFlins ? "flins" : isExpeienceDesignTwin ? "design-twin" : "media"}`}
          autoplaysUntil={autoStop}
          loop={false}
        />
      )}
      {isExperienceFlins && showContent && <TopicMenu />}
    </div>
  )
}

export default Video
