import { useEffect, useRef, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useAuth } from "@/hooks/onboarding/useAuth"
import { ROUTES, QUESTS_EID, CURRENT_PLANET } from "@/constants/constants"
import { useInterface } from "@/hooks/interface/useInterface"
import { usePlanet } from "@/hooks/interface/usePlanet"
import iconSecretCard from "@/assets/icons/quests/user-secret-solid.svg"
import iconLibrary from "@/assets/icons/quests/book-open-solid.svg"
import iconGem from "@/assets/icons/quests/gem-solid.svg"
import "./quest-manager.scss"

const QuestManager = () => {
  const location = useLocation()
  const { profile, collectibles, collections } = useAuth()
  const { currentPlanet } = usePlanet()
  const { categorySelected } = useInterface()
  const { department, experience, subexperience } = useParams()
  const [headerPosition, setHeaderPosition] = useState({})
  const [librarySuccess, setLibrarySuccess] = useState("")
  const [explorerSuccess, setExplorerSuccess] = useState("")
  const [isWaiting, setIsWaiting] = useState(true)
  const [userIsScrolling, setUserIsScrolling] = useState(false)
  const questsResultsRef = useRef()
  const isRenaulution = currentPlanet === CURRENT_PLANET.IN_RENAULUTION
  const isExplorerSuccess = collections?.find((collectible) => {
    return collectible?.["eid"] === QUESTS_EID.EXPLORER
  })
  const isLibrarySuccess = collections?.find((collectible) => {
    return collectible?.["eid"] === QUESTS_EID.LIBRARY
  })

  // Handles scroll events to update user scrolling state with a delay
  useEffect(() => {
    let scrollTimeout = null
    const questsResults = questsResultsRef.current
    if (!questsResults) return

    const handleScroll = () => {
      clearTimeout(scrollTimeout)
      scrollTimeout = setTimeout(() => {
        setUserIsScrolling(false)
      }, 150)

      setUserIsScrolling(questsResults.scrollHeight > questsResults.clientHeight)
    }

    questsResults.addEventListener("scroll", handleScroll)

    return () => {
      questsResults.removeEventListener("scroll", handleScroll)
      clearTimeout(scrollTimeout)
    }
  }, [])

  // Handles quest status updates based on success flags
  useEffect(() => {
    if (isExplorerSuccess) {
      setExplorerSuccess("quest-done")
    }

    if (isLibrarySuccess) {
      setLibrarySuccess("quest-done")
    }
  }, [isExplorerSuccess, isLibrarySuccess])

  // Handles header position based on URL path and other conditions
  useEffect(() => {
    const darkGreyGroupUrlColor = [ROUTES.HOME, ROUTES.RENAULT_EXPERIENCES.KNOWLEDGE]

    if (darkGreyGroupUrlColor.includes(location.pathname) || isRenaulution) {
      setHeaderPosition({ paddingLeft: "10px" })
    } else {
      setHeaderPosition({})
    }
  }, [isRenaulution, location.pathname, experience, subexperience, department])

  // Handles waiting state based on category selection with a delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsWaiting(false)

      return () => clearTimeout(timer)
    }, 200)
  }, [categorySelected])

  const DescriptionRender = () => {
    return (
      <div className={`description `}>
        Complete our quests to earn Planet RG&apos;s exclusive collectibles!
      </div>
    )
  }

  const ExplorerRender = () => {
    return (
      <li className={`card-quest ${explorerSuccess} `}>
        <img src={iconSecretCard} alt="secret card" />
        <div className="text">
          <span className="title-card">Explorer secret card</span>
          <div className="description-card">
            Find the mystery door in the Planet RG to collect an exclusive NFT card !
          </div>
        </div>
      </li>
    )
  }

  const LibraryRender = () => {
    return (
      <li className={`card-quest ${librarySuccess} `}>
        <img src={iconLibrary} alt="library" />
        <div className="text">
          <span className="title-card">Library exclusive card collectibles</span>
          <div className="description-card">
            Explore the library, follow the Web3 workshops and claim your exclusive NFT card !
          </div>
        </div>
      </li>
    )
  }

  const WorkshopRender = () => {
    return (
      <li className={"card-quest"}>
        <img src={iconGem} alt="gem icon" />
        <div>
          <span className="title-card">IRL workshop card</span>
          <div className="description-card">
            Take part in the Web3 Renault workshop and scan the dedicated QR code to get your proof
            of attendance !
          </div>
        </div>
      </li>
    )
  }

  const QuestsRender = () => {
    return (
      <div
        ref={questsResultsRef}
        className={`quest-manager  ${userIsScrolling ? "grabbing" : ""} ${isRenaulution ? "renaulution" : ""}`}
        style={headerPosition}
      >
        {DescriptionRender()}

        <div style={{ marginBottom: "2rem" }}>
          {(isExplorerSuccess || isLibrarySuccess) && <div className={`title `}>Active quests</div>}
          <ul className={`quests-cards-container`}>
            {isExplorerSuccess && ExplorerRender()}
            {isLibrarySuccess && LibraryRender()}
          </ul>
        </div>

        <div className={`title `}>Ongoing quests</div>

        <ul className={`quests-cards-container`}>
          {!isExplorerSuccess && ExplorerRender()}
          {!isLibrarySuccess && LibraryRender()}
          {WorkshopRender()}
        </ul>
      </div>
    )
  }

  const noCollectiblesRender = (
    <div style={{}}>
      You don&apos;t have any collectibles yet. Please create and claim your avatar to start looking
      for the quests
    </div>
  )

  if (profile?.avatar != null && collectibles?.length >= 1) {
    return QuestsRender()
  }

  return !isWaiting && noCollectiblesRender
}

export default QuestManager
