/**
 * Planet.js
 *
 * This module defines the Planet class responsible for creating and managing different aspects
 * of a 3D planet in a WebGL environment. It handles the setup of various components like continents,
 * safe zones, logos, and gates specific to each brand on the planet. The class also includes methods
 * for managing the visibility and interactions of these components.
 *
 * Key functionalities include:
 * - Initializing the core structure and materials of the planet.
 * - Dynamically creating and positioning continents and other related elements based on the provided configuration.
 * - Managing interactive elements like gates for specific brands.
 * - Controlling the visibility and appearance of various elements on the planet.
 * - Ensuring that the planet's state and interactions are updated in response to user actions or other changes.
 *
 * The class works in tandem with other modules like World.js and Gates.js, integrating closely with the broader 3D experience.
 */

import {
  BufferGeometry,
  CatmullRomCurve3,
  IcosahedronGeometry,
  Line,
  LineBasicMaterial,
  Mesh,
  Object3D,
  Vector2,
  Vector3,
  Quaternion,
} from "three"
import { CSS2DObject, CSS2DRenderer } from "three/addons/renderers/CSS2DRenderer.js"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js"
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js"
import { SMAAPass } from "three/addons/postprocessing/SMAAPass.js"
import { OutputPass } from "three/addons/postprocessing/OutputPass.js"
import { createLogger } from "@/utils/debug/log.js"
import { raftween } from "@/utils/anim/index.js"
import { MOUSE, raycastFromMouse } from "@/webgl/world/controls/index.js"
import { PLANET_TYPE, VIEWS, EXPLORATION } from "@/webgl/world/World.js"
import CursorManager from "@/utils/CursorManager.js"
import ExperienceManager from "@/webgl/ExperienceManager.js"
import RIMMaterial from "@/webgl/world//materials/RIM/index.js"
import MATERIALS from "@/webgl/world/materials/infos.js"
import AtmosphereMaterial from "@/webgl/world//materials/atmosphere/index.js"
// eslint-disable-next-line import/no-unresolved
import safezonesURL from "@/assets/webgl/planets_rails.json?url"
import Continent from "@/webgl/world/components/continents/Continent.js"

const log = createLogger("Planet", "#FFF", "#0000FF").log

const VECTOR_1 = new Vector3()
const SEA_LEVEL = 2.609
const ATMOSPHERE_LEVEL = 3.0
const QUATERNION_1 = new Quaternion()

let SAFEZONES = {}

export default class Planet {
  constructor(planetConfig) {
    this.setupBasicProperties(planetConfig)
  }

  setupBasicProperties(planetConfig) {
    this.experience = new ExperienceManager()
    this.scene = this.experience.scene
    this.renderer = this.experience.renderer
    this.camera = this.experience.camera
    this.sizes = this.experience.sizes
    this.time = this.experience.time
    this.world = this.experience.world

    this.currentPlanetConfig = planetConfig
    this.composer = null
    this.continents = []
    this.tweens = []

    this.base = new Object3D()
    this.base.name = `planet-${this.currentPlanetConfig.name.toLowerCase()}`
    this.scene.add(this.base)
  }

  /**
   * Initializes the planet by setting up materials, creating the planet core,
   * and configuring each brand on the planet.
   * This method is responsible for assembling the planet based on the configuration
   * specified in the planetsConfig.
   */
  init() {
    log("Init", this.currentPlanetConfig.name)

    this.setupLabelRenderer()

    // Create planet sphere
    this.createPlanetCore()

    // Destroy bloom by default
    this.destroyBloom()

    // Create continents for each brand
    Object.keys(this.currentPlanetConfig.brands).forEach((brand) => {
      this.continent = new Continent({
        planetBase: this.base,
        planetCore: this.core,
        currentPlanetConfig: this.currentPlanetConfig,
        brandName: brand,
        safezones: SAFEZONES,
      })
      this.continents.push(this.continent)
    })
  }

  setupLabelRenderer() {
    if (
      !this.currentPlanetConfig.isInteractive ||
      this.world.currentPlanetType !== PLANET_TYPE.IN_RENAULT
    ) {
      return
    }

    this.labelRenderer = new CSS2DRenderer()
    this.labelRenderer.domElement.className = "labels-container"
    this.labelRenderer.setSize(window.innerWidth, window.innerHeight)
    this.labelRenderer.domElement.style.position = "absolute"
    this.labelRenderer.domElement.style.top = "0"
    this.labelRenderer.raycast = () => {} //
    const webGLcontainer = document.querySelector("aside.webgl-container")
    if (webGLcontainer) {
      webGLcontainer.appendChild(this.labelRenderer.domElement)
    } else {
      console.warn("WebGL container not found")
    }

    if (this.sizes) {
      this.sizes.on("resize", () => {
        this.labelRenderer.setSize(this.sizes.width, this.sizes.height)
      })
    }
  }

  /**
   * Creates the core of the planet using Three.js Mesh.
   * This method creates a 3D mesh representing the core of the planet using an Icosahedron geometry and a custom RIMMaterial.
   * The core is then added to the main group of the planet (`this.base`).
   */
  createPlanetCore() {
    const geometry = new IcosahedronGeometry(SEA_LEVEL, 12)
    const material = new RIMMaterial(MATERIALS[this.currentPlanetConfig.name].sea.planet)

    this.core = new Mesh(geometry, material)
    this.core.name = `planet-core-${this.currentPlanetConfig.name.toLowerCase()}`

    this.base.add(this.core)
  }

  createPlanetAtmosphere() {
    if (!this.currentPlanetConfig.isGlowing) {
      return
    }

    const geometry = new IcosahedronGeometry(ATMOSPHERE_LEVEL, 12)
    const material = new AtmosphereMaterial()
    material.uniforms.opacity.value = 0.0

    this.atmosphere = new Mesh(geometry, material)
    this.atmosphere.name = "planet-atmosphere"

    this.base.add(this.atmosphere)

    this.smoothlyTransitionAtmosphereOpacity(this.atmosphere.material)
  }

  smoothlyTransitionAtmosphereOpacity(material) {
    const targetOpacity = 1.0
    this.tweenAtmosphere = raftween({
      name: "atmosphere",
      from: 0,
      to: targetOpacity,
      duration: 500,
      easing: "inOutSine",
      onProgress: (progress, value) => {
        material.uniforms.opacity.value = value
        if (value !== 1) material.transparent = true
      },
      onComplete: () => {
        this.tweenAtmosphere = null
        const index = this.tweens.indexOf(this.tweenAtmosphere)
        if (index > -1) {
          this.tweens.splice(index, 1)
        }
      },
    })

    this.tweens.push(this.tweenAtmosphere)
  }

  /**
   *  Makes the core of the planet visible.
   */
  showCore() {
    this.core.visible = true
  }

  /**
   *  Hides the core of the planet.
   */
  hideCore() {
    this.core.visible = false
  }

  /**
   * Checks if the entire planet (including continents, logos, etc.) is visible.
   * @returns {Boolean} - True if the planet is visible, false otherwise.
   */
  isPlanetVisible() {
    return this.base.visible
  }

  /**
   * Sets the visibility of the entire planet.
   * @param {Boolean} isVisible - Whether the planet should be visible or not.
   */
  setPlanetVisibility(isVisible) {
    if (typeof isVisible !== "boolean") {
      console.warn("setPlanetVisibility: isVisible should be a boolean.")
      return
    }

    this.base.visible = isVisible
  }

  /**
   * Set the post-processing UnrealBloom for the glow effect on the second planet
   * This method helps prevent memory leaks and ensures efficient resource management.
   */
  setBloom() {
    if (
      !this.currentPlanetConfig.isGlowing ||
      !this.renderer.instance.extensions.get("EXT_color_buffer_float")
    ) {
      return
    }

    if (!this.composer) {
      this.composer = this.experience.composer
    }

    // Mandatory first pass
    let renderPass = this.composer.passes.find((pass) => pass instanceof RenderPass)
    if (renderPass) {
      renderPass.enabled = true
    } else {
      this.renderPass = new RenderPass(this.scene, this.camera.instance)
      this.composer.addPass(this.renderPass)
    }

    // Glow effect pass
    let unrealBloomPass = this.composer.passes.find((pass) => pass instanceof UnrealBloomPass)

    if (!unrealBloomPass) {
      unrealBloomPass = new UnrealBloomPass(
        new Vector2(this.sizes.width, this.sizes.height),
        0,
        0,
        1.0,
      )
      this.composer.addPass(unrealBloomPass)
    }
    unrealBloomPass.enabled = true
    unrealBloomPass.strength = 0
    unrealBloomPass.radius = 0
    unrealBloomPass.threshold = 1.0

    this.smoothlyTransitionBloom(unrealBloomPass)

    // Performance optimization pass
    let smaaPass = this.composer.passes.find((pass) => pass instanceof SMAAPass)
    if (smaaPass) {
      smaaPass.enabled = true
    } else {
      this.smaaPass = new SMAAPass(
        this.sizes.width * this.renderer.instance.getPixelRatio(),
        this.sizes.height * this.renderer.instance.getPixelRatio(),
      )
      this.composer.addPass(this.smaaPass)
    }

    // Last pass that performs sRGB Color space conversion and optional tone mapping
    let outputPass = this.composer.passes.find((pass) => pass instanceof OutputPass)
    if (outputPass) {
      outputPass.enabled = true
    } else {
      this.outputPass = new OutputPass()
      this.composer.addPass(this.outputPass)
    }
  }

  /**
   * Handling smooth transition when bloom appears or disappear
   * @returns {UnrealBloomPass} The bloom pass from post-processing
   */
  smoothlyTransitionBloom(bloomPass) {
    const targetStrength = 0.15
    const targetRadius = 0.05

    this.tweenBloomStrength = raftween({
      name: "bloom strength",
      from: 0,
      to: targetStrength,
      duration: 500,
      easing: "inOutSine",
      onProgress: (progress, value) => {
        bloomPass.strength = value
      },
      onComplete: () => {
        this.tweenBloomStrength = null
      },
    })

    this.tweenBloomRadius = raftween({
      name: "bloom radius",
      from: 0,
      to: targetRadius,
      duration: 500,
      easing: "inOutSine",
      onProgress: (progress, value) => {
        bloomPass.radius = value
      },
      onComplete: () => {
        this.tweenBloomRadius = null
        const index = this.tweens.indexOf(this.tweenBloomRadius)
        if (index > -1) {
          this.tweens.splice(index, 1)
        }
      },
    })

    this.tweens.push(this.tweenBloomStrength, this.tweenBloomRadius)
  }

  /**
   * Rotates the logo to always face the camera.
   * @param {Object} brand - The continent object
   */
  rotateLogoToFaceCamera(brand) {
    const { logoContainer, logo } = brand
    const camera = this.camera.instance

    if (logoContainer && logo) {
      // Create a quaternion that will rotate the logo to face the camera
      QUATERNION_1.copy(logoContainer.quaternion).invert()
      QUATERNION_1.multiply(camera.quaternion)

      // Apply the rotation to the logo
      logo.quaternion.copy(QUATERNION_1)

      // Reset unwanted rotations to keep the logo upright
      logo.rotation.x = 0
      logo.rotation.y = 0
    }
  }

  /**
   * Updates the planet's state on each frame render.
   * This includes rotating logos and handling user interactions.
   */
  update() {
    // Only proceed if the planet's core is visible
    if (this.core && this.core.visible) {
      CursorManager.toggleCursor("grabbing", MOUSE.active)

      if (this.composer && this.currentPlanetConfig.isGlowing) {
        this.composer.render()
      }

      if (this.tweens.length > 0) {
        this.tweens.forEach((tween) => {
          if (tween) {
            tween.update(this.time.delta)
          }
        })
      }

      if (this.labelRenderer) this.labelRenderer.render(this.scene, this.camera.instance)

      // Rotates logo based on camera rotation
      if (this.continents.length > 0) {
        this.continents.forEach((continent) => {
          this.rotateLogoToFaceCamera(continent.brands[continent.name])
        })
      }

      if (!this.currentPlanetConfig.isInteractive) return

      this.handleInteractions()

      if (this.continents.length > 0) {
        this.continents.forEach((continent) => {
          if (
            this.currentPlanetConfig.brandsWithGates.includes(continent.name) ||
            this.currentPlanetConfig.nonInteractiveBrands.includes(continent.name)
          ) {
            if (
              (this.currentPlanetConfig.brandsWithGates.includes(continent.name) ||
                this.currentPlanetConfig.nonInteractiveBrands.includes(continent.name)) &&
              this.world.currentView === VIEWS.IN_ORBIT
            ) {
              continent.update()
            } else if (
              this.world.currentPlanetInstance.activeDepartment &&
              this.world.currentPlanetInstance.activeDepartment.parent.parent.name.includes(
                continent.name,
              ) &&
              this.world.currentView !== VIEWS.IN_ORBIT &&
              (this.world.explorationView !== EXPLORATION.IN_ORBIT ||
                this.world.explorationView !== EXPLORATION.IN_GATES)
            ) {
              continent.update()
              continent.base.departmentLabels.forEach((value) => {
                value.element.firstChild.classList.remove("first-plan", "second-plan", "third-plan")
              })
            }
          }
        })
      }

      if (MOUSE.clicked && this.world.currentView === VIEWS.IN_ORBIT) {
        MOUSE.clicked = false
      }
    }
  }

  /**
   * Handles user interactions including raycasting and clicking on continents.
   */
  handleInteractions() {
    if (this.world.currentView !== VIEWS.IN_ORBIT) return

    if (
      !this.world.avatar ||
      !this.currentPlanetConfig.isInteractive ||
      this.world.currentPlanetType !== PLANET_TYPE.IN_RENAULT
    ) {
      return
    }

    // Perform raycasting to detect mouse-over on continents
    const intersectedObject = this.handleRaycasting()

    const isContinent =
      intersectedObject !== null &&
      this.currentPlanetConfig.brandsWithGates.includes(intersectedObject) &&
      intersectedObject === this.world.user.brand

    if (
      this.world.currentView === VIEWS.IN_CONTINENT &&
      !this.currentPlanetConfig.brandsWithGates.includes(intersectedObject)
    ) {
      CursorManager.clearCursor()
      CursorManager.toggleCursor("grabbing", MOUSE.active)
      return
    }

    if (isContinent && this.world.currentView !== VIEWS.IN_CONTINENT) {
      CursorManager.setCursor("selecting")
      CursorManager.toggleCursor("grabbing", MOUSE.active)
    } else {
      CursorManager.clearCursor()
      CursorManager.toggleCursor("grabbing", MOUSE.active)
    }

    this.handleClickOnContinent(isContinent, intersectedObject)
  }

  /**
   * Handling raycasting to check if the mouse is over a continent.
   * @returns {Object|null} The intersected object if a continent is under the mouse, null otherwise.
   */
  handleRaycasting() {
    const intersects = raycastFromMouse(this.base, this.camera.instance)
    if (intersects.length === 0) {
      return null
    }

    const firstIntersectedObject = intersects[0].object
    const intersectedBrandName = firstIntersectedObject?.name?.split("_")[1]?.toLowerCase()

    const intersectedContinent = intersectedBrandName in this.currentPlanetConfig.brands

    // Exclude non-interactive brands
    if (this.currentPlanetConfig.nonInteractiveBrands.includes(intersectedBrandName)) {
      return null
    }

    if (!intersectedContinent) {
      return null
    }

    return intersectedBrandName
  }

  /**
   * Handles the logic for when a user clicks on a continent.
   * @param {Boolean} isContinent - Whether the intersected object is a continent
   * @param {Object} intersectedObject - The object that was intersected by raycasting
   */

  handleClickOnContinent(isContinent, intersectedObject) {
    if (!isContinent || !intersectedObject) return

    if (isContinent && MOUSE.clicked) {
      // this.world.routeTo(`/${intersectedObject}`)
      this.world.routeTo(`${intersectedObject}/design`)

      this.world.lastInteractionType = "click"
      CursorManager.clearCursor()
    }
  }

  async preload() {
    try {
      const zonesResponse = await fetch(safezonesURL)
      if (!zonesResponse.ok) {
        throw new Error(`Failed to fetch safezones: ${zonesResponse.statusText}`)
      }
      const zones = await zonesResponse.json()

      for (const name in zones) {
        const { points } = zones[name]
        points.sort((a, b) => a[1] - b[1])

        for (let index = 0; index < points.length; index++) {
          const [x, y, z] = points[index]
          points[index] = new Vector3(x, y, z)
        }

        const curve = new CatmullRomCurve3(points)
        const generatedPoints = curve.getPoints(points.length / 5)
        const line = new Line(
          new BufferGeometry().setFromPoints(generatedPoints),
          new LineBasicMaterial({ color: 0x0000ff }),
        )
        line.lookAt(VECTOR_1.set(0, 1, 0))
        line.layers.set(2)
        line.layers.enable(2)

        SAFEZONES[name] = {
          curve,
          line,
          progress: 0,
          points: generatedPoints,
        }
      }
    } catch (error) {
      console.error("Error during preload of safezones:", error)
    }
  }

  /**
   * Destroys post-processing Unreal Bloom when no longer needed.
   * This method helps prevent memory leaks and ensures efficient resource management.
   */
  destroyBloom() {
    if (this.composer) {
      if (this.composer.passes) {
        for (const pass of this.composer.passes) {
          if (pass) {
            pass.dispose()
          }
        }
      }

      this.composer = null

      // Reset the renderer
      this.renderer.instance.clear()
      this.renderer.update()
    }
  }

  /**
   * Cleans up resources when they are no longer needed.
   * This method helps prevent memory leaks and ensures efficient resource management.
   */
  dispose() {
    if (!this.scene) return

    const objectsToRemove = []

    this.scene.traverse((object) => {
      if (object instanceof Mesh) {
        if (object.geometry) object.geometry.dispose()
        if (Array.isArray(object.material)) {
          object.material.forEach((material) => material.dispose())
        } else if (object.material) {
          object.material.dispose()
        }
        objectsToRemove.push(object)
      } else if (object instanceof CSS2DObject) {
        if (object.element && object.element.parentNode) {
          object.element.parentNode.removeChild(object.element)
        }
        object.element = null
        objectsToRemove.push(object)
      }
    })

    objectsToRemove.forEach((object) => {
      if (object.parent) {
        object.parent.remove(object)
      } else {
        this.scene.remove(object)
      }
    })
  }
}
