/**
 * ThemeProvider.jsx
 * Manages and provides theme-related state and context based on the current route and view.
 */

import { createContext, useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { usePlanet } from "@/hooks/interface/usePlanet"
import { ROUTES, TRANSITIONS, CURRENT_PLANET, CURRENT_PLANET_VIEW } from "@/constants/constants"
import { flattenRoutesPath } from "../../utils/utils.jsx"

const ThemeContext = createContext()

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("gray")
  const [brand, setBrand] = useState("default")
  const { experience } = useParams()
  const location = useLocation()
  const { currentPlanetView, currentExplorationLevel, currentPlanet } = usePlanet()
  const isRenaulution = currentPlanet === CURRENT_PLANET.IN_RENAULUTION

  // Retrieve themes based on route
  useEffect(() => {
    const routes = flattenRoutesPath(ROUTES)

    const matchedRoute = routes.find((route) => {
      return location.pathname === route.path
    })

    if (
      matchedRoute &&
      !Object.values(TRANSITIONS).includes(
        currentPlanet || currentPlanetView || currentExplorationLevel,
      )
    ) {
      setTheme(matchedRoute.theme)
      setBrand(matchedRoute.brand)
    }

    if (location.pathname.includes(experience) || location.pathname === ROUTES.DRESSING_ROOM.path)
      setTheme("room")

    if (isRenaulution) setTheme("white")
  }, [
    location.pathname,
    currentPlanetView,
    currentExplorationLevel,
    currentPlanet,
    experience,
    isRenaulution,
  ])

  return (
    <ThemeContext.Provider value={{ theme, brand }}>
      <div
        className={`theme-${theme} ${currentPlanetView !== CURRENT_PLANET_VIEW.IN_EXPERIENCE ? `brand-${brand}` : ""}`}
      >
        {children}
      </div>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeProvider }
