/**
 * This module provides utility functions for raycasting in a 3D environment using Three.js.
 * Raycasting is a technique used to determine the line of sight from a given point in space
 * (in this case, either from an object or the mouse's position on the screen) and find out
 * what objects in the scene are intersected by this line. This is particularly useful for
 * features like mouse picking (selecting or interacting with objects in the 3D space using the mouse)
 * and collision detection.
 *
 * - raycastFromObject: Performs raycasting from any given object's position and orientation in the scene.
 *   It's useful for determining what lies in the path of the object, for example, what a character is looking at.
 *
 * - raycastFromMouse: Performs raycasting from the camera through a point on the screen (usually the mouse position).
 *   It's useful for determining what objects the user is pointing at with their cursor.
 *
 * The module uses a single instance of Raycaster and Vector3 for performance optimization
 * and exposes these functions for use in other parts of the application.
 */

import { Raycaster, Vector3 } from "three"
import { MOUSE } from "@/webgl/world/controls/index.js"

// Initialize a single instance of Raycaster and Vector3 to be reused for performance
const RAYCASTER = new Raycaster()
const VECTOR = new Vector3()

/**
 * Performs raycasting from an object's current position and orientation to find intersections with a target object.
 * @param {Object3D} object - The Three.js object from which the ray originates
 * @param {Object3D} target - The target object to check for intersections
 * @returns {Intersection[]} - An array of intersection objects
 */
export function raycastFromObject(object, target) {
  // Set the ray's direction to the object's forward direction
  object.getWorldDirection(VECTOR)

  // Set the raycaster's origin and direction
  RAYCASTER.set(object.position, VECTOR)

  // Perform the raycasting and return the intersections
  return RAYCASTER.intersectObject(target, true)
}

/**
 * Performs raycasting from the camera's position through the mouse's position in screen space to find intersections with a target object.
 * @param {Object3D} target - The target object to check for intersections
 * @param {Camera} camera - The Three.js camera through which the mouse coordinates are interpreted
 * @returns {Intersection[]} - An array of intersection objects
 */
export function raycastFromMouse(target, camera) {
  // Convert the mouse's screen position to normalized device coordinates and set the raycaster
  RAYCASTER.setFromCamera(MOUSE.position, camera)

  // Perform the raycasting and return the intersections
  return RAYCASTER.intersectObject(target, true)
}

/**
 * Performs raycasting from the camera's position through the mouse's position in screen space
 * to find intersections with the children of a parent object.
 * @param {Object3D} parent - The parent object whose children to check for intersections
 * @param {Camera} camera - The Three.js camera through which the mouse coordinates are interpreted
 * @returns {Intersection[]} - An array of intersection objects
 */
export function raycastChildrenFromMouse(parent, camera) {
  // Convert the mouse's screen position to normalized device coordinates and set the raycaster
  RAYCASTER.setFromCamera(MOUSE.position, camera)

  // Collect all children of the parent
  const objectsToIntersect = []
  parent.traverse((child) => {
    if (child.isMesh) objectsToIntersect.push(child)
  })

  // Perform the raycasting and return the intersections
  return RAYCASTER.intersectObjects(objectsToIntersect, true) // true ensures it checks all descendants
}

/**
 * Performs raycasting from the camera's position through the mouse's position in screen space
 * to find intersections with all children (nested deeply) of a parent object.
 * @param {Object3D} parent - The parent object whose children to check for intersections
 * @param {Camera} camera - The Three.js camera through which the mouse coordinates are interpreted
 * @returns {Intersection[]} - An array of intersection objects
 */
export function raycastAllChildren(parent, camera) {
  // Convert the mouse's screen position to normalized device coordinates and set the raycaster
  RAYCASTER.setFromCamera(MOUSE.position, camera)

  // Collect all mesh children of the parent object, including nested children
  const objectsToIntersect = collectMeshes(parent)

  // Perform the raycasting and return the intersections
  return RAYCASTER.intersectObjects(objectsToIntersect, true) // true ensures it checks all descendants
}

/**
 * Helper function to collect all mesh children of an object, including nested children.
 * @param {Object3D} object - The parent object
 * @returns {Object3D[]} - An array of mesh objects
 */
function collectMeshes(object) {
  const meshes = []
  object.traverse((child) => {
    if (child.isMesh) {
      meshes.push(child)
    }
  })
  return meshes
}
