/**
 * useRooms.jsx
 */

import { useContext } from "react"
import { RoomsContext } from "@/context/metaverse/RoomsContext"

export const useRooms = () => {
  return useContext(RoomsContext)
}
