/**
 * PreviousLocation.jsx
 * Custom hook to get the previous route type
 */

import { useEffect, useRef } from "react"
import { useRouteType } from "@/hooks/interface/useRouteType"

const usePreviousRouteType = () => {
  const ref = useRef()
  const currentRouteType = useRouteType()

  useEffect(() => {
    ref.current = currentRouteType
  }, [currentRouteType])

  return ref.current
}

export { usePreviousRouteType }
