import { useCallback, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { Spin } from "antd"
import { useAuth } from "@/hooks/onboarding/useAuth"
import { useInterface } from "@/hooks/interface/useInterface"
import { ROUTES, TIMER_TIMEOUT, CATEGORY_ITEMS } from "@/constants/constants"
import QuestManager from "./QuestManager/QuestManager.jsx"
import CardItem from "./CardItem"
import getCategoriesData from "./CategoriesData.jsx"
import "./my-account.scss"

const SideAccountMenu = ({
  rightMenuId,
  storeRightMenuTimestampRef,
  siderIscollapsed,
  handleOverallVisibilityMenu,
}) => {
  const location = useLocation()
  const { collectibles, loadingCollectible } = useAuth()
  const { categorySelected, setCategorySelected } = useInterface()
  const [fadeClass, setFadeClass] = useState("")
  const [hideMenuInlineStyle, setHideMenuInlineStyle] = useState({})
  const [userIsScrolling, setUserIsScrolling] = useState(false)
  const accountDivRef = useRef()
  const userCollectibles = collectibles?.length ? collectibles : []
  const isDepartmentRoute = Object.values(ROUTES.DEPARTMENTS).some((brand) =>
    Object.values(brand).includes(location.pathname),
  )

  // Handles scrolling inside content for the collectibles category
  useEffect(() => {
    if (categorySelected !== CATEGORY_ITEMS.COLLECTIBLES) return

    const accountContent = accountDivRef.current
    let scrollTimeout = null

    const handleScroll = () => {
      clearTimeout(scrollTimeout)
      scrollTimeout = setTimeout(() => {
        setUserIsScrolling(false)
      }, 150)

      setUserIsScrolling(accountContent?.scrollHeight > accountContent?.clientHeight)
    }

    accountContent?.addEventListener("scroll", handleScroll)

    return () => {
      accountContent?.removeEventListener("scroll", handleScroll)
      clearTimeout(scrollTimeout)
    }
  }, [categorySelected])

  const setActiveCategory = useCallback(
    (event) => {
      const clickedCategory = event ? event.currentTarget.id : CATEGORY_ITEMS.COLLECTIBLES
      setCategorySelected(clickedCategory)
    },
    [setCategorySelected],
  )

  // Restore active color when sider is not collapsed
  useEffect(() => {
    if (!siderIscollapsed) {
      setActiveCategory()
    }
  }, [siderIscollapsed, setActiveCategory])

  // Handles animation for side menu based on siderIscollapsed state
  useEffect(() => {
    let timer

    if (!siderIscollapsed) {
      setFadeClass("fade-out")
      timer = setTimeout(() => {
        setHideMenuInlineStyle({ display: "none" })
      }, TIMER_TIMEOUT.FADE_SIDE_MENU)
    } else {
      setHideMenuInlineStyle({ display: "block" })
      timer = setTimeout(() => {
        setFadeClass("fade-in")
      }, TIMER_TIMEOUT.FADE_SIDE_MENU)
    }

    return () => clearTimeout(timer)
  }, [siderIscollapsed])

  const styleAccountPositionSideMenu = () => {
    if (
      location.pathname !== ROUTES.HOME ||
      location.pathname !== ROUTES.BRANDS.RENAULT ||
      isDepartmentRoute
    ) {
      return {
        paddingTop: "10vh",
        ...hideMenuInlineStyle,
      }
    }

    return { marginTop: "10vh", ...hideMenuInlineStyle }
  }

  const handleClick = (e, category) => {
    if (category === categorySelected) return
    setActiveCategory(e)
  }

  const CollectiblesRender = () => {
    if (collectibles.length > 0 && userCollectibles) {
      return (
        <div
          ref={accountDivRef}
          className={`card-container  ${userIsScrolling ? "grabbing" : ""} `}
        >
          {userCollectibles.map((item, index) => {
            if (item.animation_url) {
              return (
                <CardItem
                  key={index}
                  className="avatar"
                  id={index}
                  item={item}
                  isQuestCard={false}
                />
              )
            }

            if (item.name === "Explorer Quest Card" || item.name === "Library Quest Card") {
              return (
                <CardItem
                  key={index}
                  id={index}
                  item={item}
                  className="quest-card"
                  customImgStyle={{ objectFit: "cover", height: "100%", borderRadius: "1rem" }}
                  isQuestCard={true}
                />
              )
            }

            return <CardItem key={index} id={index} item={item} isQuestCard={false} />
          })}
        </div>
      )
    }

    return (
      <div>
        You don&apos;t have any collectibles yet. Please create and claim your avatar to start
        looking for the quests
      </div>
    )
  }

  const QuestManagerRender = () => {
    return <QuestManager />
  }

  const { CATEGORIES_ITEMS } = getCategoriesData(categorySelected, handleClick)

  const CategoriesRender = () => {
    return (
      <>
        {CATEGORIES_ITEMS.map(({ id, title, styleClass, customStyle, onClick }, index) => (
          <div
            key={index}
            id={id}
            className={styleClass}
            onClick={(e) => onClick(e)}
            style={customStyle}
          >
            {title}
          </div>
        ))}
      </>
    )
  }

  const ContentsCategoriesRender = () => {
    const style = { height: "100%", textAlign: "center", paddingTop: "1rem" }

    if (categorySelected === CATEGORY_ITEMS.COLLECTIBLES) {
      if (loadingCollectible) {
        return (
          <div style={style}>
            <Spin size="large" />
          </div>
        )
      }
      return CollectiblesRender()
    }

    if (categorySelected === CATEGORY_ITEMS.QUESTS) {
      if (loadingCollectible) {
        return (
          <div style={style}>
            <Spin size="large" />
          </div>
        )
      }
      return QuestManagerRender()
    }
  }

  const handleClickMenuVisibility = (e) => {
    handleOverallVisibilityMenu(e)
    storeRightMenuTimestampRef.current.timestamp = Date.now()
  }

  return (
    <div
      className={`sideMenu-account ${fadeClass}    ${
        categorySelected === CATEGORY_ITEMS.QUESTS ? "quest-manager" : ""
      }`}
      id={rightMenuId}
      onClick={(event) => handleClickMenuVisibility(event)}
      style={styleAccountPositionSideMenu()}
    >
      <div className="categories-container">{CategoriesRender()}</div>

      {ContentsCategoriesRender()}
    </div>
  )
}

export default SideAccountMenu
