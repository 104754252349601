uniform float time;
uniform float timeStart;
uniform float fadeProgress;
uniform float duration;
uniform vec3 startColorA;
uniform vec3 startColorB;
uniform vec3 centerColorA;
uniform vec3 centerColorB;
uniform vec3 endColorA;
uniform vec3 endColorB;


varying vec2 vUv;

void main() {

	 float fade = clamp(fadeProgress, 0.0, 1.0);

    vec3 startColor = mix(startColorA, startColorB, fade);
    vec3 centerColor = mix(centerColorA, centerColorB, fade);
    vec3 endColor = mix(endColorA, endColorB, fade);

    vec3 outputColor = mix(startColor, endColor, vUv.y);
    float center = smoothstep(0.0, 1.0, 1.0 - abs(vUv.y * 2.0 - 1.0) * 2.0);
    outputColor = mix(outputColor, centerColor, center);

    gl_FragColor = vec4(outputColor, 1.0);



}