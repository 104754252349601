/**
 * Renderer.js
 * Sets up and manages the WebGLRenderer for the application. It's responsible for rendering the visual content
 * on the canvas element. The renderer works with the provided scene and camera to create the final image.
 * It includes initialization of various renderer settings, handling of window resizing, and a method to update the rendering.
 */

import * as THREE from "three"
import ExperienceManager from "@/webgl/ExperienceManager.js"

export default class Renderer {
  constructor() {
    this.experience = new ExperienceManager()
    this.canvas = this.experience.canvas
    this.sizes = this.experience.sizes
    this.scene = this.experience.scene
    this.camera = this.experience.camera

    this.initRenderer()
  }

  /**
   * Initializes the WebGLRenderer with necessary settings.
   */
  initRenderer() {
    // Disable color management for more direct control over color space
    THREE.ColorManagement.enabled = false

    this.instance = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,
      antialias: true,
      depth: true,
      powerPreference: "high-performance",
      premultipliedAlpha: false,
      preserveDrawingBuffer: false,
      stencil: false,
    })

    this.instance.outputColorSpace = THREE.SRGBColorSpace
    this.instance.autoClear = false
    this.instance.shadowMap.enabled = true
    this.instance.shadowMap.type = THREE.PCFSoftShadowMap
    this.instance.shadowMap.needsUpdate = true
    this.instance.gammaOutput = true
    this.instance.info.autoReset = false
    this.instance.toneMappingExposure = Math.pow(0.9, 5.0)

    this.resize()
  }

  /**
   * Adjusts the renderer size and pixel ratio based on the current window size.
   */
  resize() {
    this.instance.setSize(this.sizes.width, this.sizes.height)
    this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))
  }

  /**
   * Updates the scene and camera, rendering the next frame.
   */
  update() {
    if (this.instance) {
      this.instance.render(this.scene, this.camera.instance)
    }
  }

  /**
   * Cleans up the renderer instance.
   */
  dispose() {
    this.instance.dispose()
    this.instance = null
  }
}
