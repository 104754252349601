/**
 * AuthContext.jsx
 * Authentification process
 * States : logged, consented, profile, collectibles, collections, modalConsentVisible, loadingCollectible
 */

import { createContext, useCallback, useEffect, useState } from "react"
import { useSDK } from "@/hooks/metaverse/useSDK"
import { createLogger } from "@/utils/debug/log.js"
import Cookies from "js-cookie"

const log = createLogger("POWER.XYZ", "#FFF", "#000").log

const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
  const SDK = useSDK()

  const [logged, setLogged] = useState(false)
  const [consented, setConsent] = useState(false)
  const [userEmail, setUserEmail] = useState(false)
  const [profile, setProfile] = useState(null)
  const [collectibles, setCollectibles] = useState(null)
  const [collections, setCollections] = useState(null)
  const [modalConsentVisible, setModalConsentVisible] = useState(false)
  const [loadingCollectible, setLoadingCollectible] = useState(false)
  const [loadingUserProfile, setLoadingUserProfile] = useState(false)

  useEffect(() => {
    /**
     * SDK.onUserEnterEmail()
     * When user types in email, SDK remembers it before creating profile
     * Infos retrieved : adress email
     */

    SDK.onUserEnterEmail((email) => {
      setUserEmail(email)
    })

    /**
     * SDK.onConnect()
     * When user successfully logs in after typing code, user is created
     * Infos retrieved : id, eid, status, consented, wallet address, wallet type, company-eid, jwt, permissions
     */

    SDK.onConnect((user) => {
      let copyUser = { ...user }
      Cookies.set("jwt", copyUser.jwt)
      delete copyUser.jwt
      delete copyUser["company-eid"]
      delete copyUser["wallet-type"]
      delete copyUser["user-eid"]
      log("User ready", copyUser)
      setLogged(true)
      setConsent(user.consented)
    })
  }, [SDK])

  const getProfile = useCallback(() => {
    /* @hint getProfile() will provide you user detailed info
       (name, avatar, email, wallet ...) */
    setLoadingUserProfile(true)
    SDK.getProfile()
      .then((profile) => {
        log("User has an avatar", profile.avatar)
        setProfile(profile)
        setTimeout(() => {
          setLoadingUserProfile(false)
        }, 800)
      })
      .catch((message) => {
        console.error("Error getting profile", message)
        setLoadingUserProfile(false)
        SDK.logout()
        localStorage.clear()
        window.location.reload()
      })
  }, [SDK])

  const getUserCollectibles = useCallback(() => {
    /* @hint getUserCollectibles() will list you the collectibles held by the user
       (from his wallet) */
    setLoadingCollectible(true)
    SDK.getUserCollectibles()
      .then((nfts) => {
        log(
          `User has ${nfts.collectibles.length} ${
            nfts.collectibles.length > 1 ? "collectibles" : "collectible"
          } `,
          nfts,
        )
        setLoadingCollectible(false)
        setCollections(nfts.collections)
        setCollectibles(nfts.collectibles)
      })
      .catch((message) => {
        console.error("Error getting nfts", message)
      })
  }, [SDK])

  useEffect(() => {
    if (logged && !consented) {
      setModalConsentVisible(true)
    }
    if (logged && consented) {
      getProfile()
      getUserCollectibles()
    }
  }, [logged, consented, getProfile, getUserCollectibles])

  const onConsent = (consented) => {
    setConsent({ consented: consented })
  }

  return (
    <AuthContext.Provider
      value={{
        logged,
        setLogged,
        consented,
        setConsent,
        profile,
        setProfile,
        collectibles,
        setCollectibles,
        collections,
        setCollections,
        modalConsentVisible,
        setModalConsentVisible,
        onConsent,
        getUserCollectibles,
        getProfile,
        userEmail,
        loadingCollectible,
        loadingUserProfile,
        setLoadingUserProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
