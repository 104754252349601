/**
 * Consent.jsx
 *
 * The Consent component manages user consent for specific actions using an SDK function to handle the consent logic.
 * It renders a modal with a checkbox for users to give or deny their consent.
 *
 * Key functionalities include:
 * - Utilizing the SDK function to handle consent.
 * - Displaying a modal with a checkbox for user consent.
 * - Managing the state of the checkbox and user consent.
 *
 * The component returns a modal with a consent message and a checkbox for user interaction.
 */

import { useState } from "react"
import { useSDK } from "@/hooks/metaverse/useSDK"
import { useAuth } from "@/hooks/onboarding/useAuth.jsx"
import { useAvatarSetup } from "@/hooks/onboarding/useAvatarSetup"
import codeOfConduct from "@/assets/documents/code-of-conduct.pdf"
import termsOfUse from "@/assets/documents/terms-of-use.pdf"
import LogoSDK from "@/assets/icons/brands/logo_sdk.svg"
import WhiteLink from "@/assets/icons/UI/white-link.svg"
import LogoRG from "@/assets/icons/brands/logo_RG.svg"
import "./consent.scss"

import { createLogger } from "@/utils/debug/log.js"

const log = createLogger("POWER.xyz", "#FFF", "#000").log

const Consent = () => {
  const SDK = useSDK()
  const { logged, consented, userEmail, modalConsentVisible, setModalConsentVisible, onConsent } =
    useAuth()
  const { setModalAvatarSetupVisible, launchAvatarSetup } = useAvatarSetup()
  const [checkConsentConduct, setCheckConsentConduct] = useState(false)
  const [checkConsentTerms, setCheckConsentTerms] = useState(false)

  if (logged && !consented) {
    const acceptConsent = () => {
      /**
       * SDK.consent()
       * Will update user consent. The user has consented the usage of his data (mandatory for SDK usage). You won't be able to make any API protected request without user consent
       * Infos retrieved :  user-eid, company-eid, status, consented?, suscribed?, created-at, updated-at,
       */

      SDK.consent(checkConsentTerms && checkConsentConduct, false)
        .then((message) => {
          log("Consent updated !", message)
          onConsent(checkConsentTerms && checkConsentConduct, false)
          setModalConsentVisible(false)
          setModalAvatarSetupVisible(true)
          launchAvatarSetup()
        })
        .catch((message) => {
          console.error("Error updating consent", message)
        })
    }

    return (
      <>
        {modalConsentVisible && (
          <div id="modal-consent" className="modal-wrapper">
            <div className="blur"></div>
            <div className="modal-popup">
              <div className="consent-body">
                <div className="consent-connection-container">
                  <div className="consent-connection">
                    <div className="consent-logo">
                      <img src={LogoSDK} alt="Logo Power.xyz (ex Metav.rs)" />
                    </div>
                    <div className="consent-link"></div>
                    <div className="consent-link-logo">
                      <img src={WhiteLink} alt="White link" />
                    </div>
                    <div className="consent-link"></div>
                    <div className="consent-company-logo">
                      {" "}
                      <img src={LogoRG} alt="Logo Renault PlanetRG" />
                    </div>
                  </div>
                </div>

                <div className="consent-title">
                  <p>
                    Link your Power.xyz (ex Metav.rs) account to access <strong>Planet RG</strong>
                  </p>
                </div>
                <div className="consent-email">{userEmail ? userEmail : "user@email.com"}</div>
                <div className="consent-checkout">
                  <p className="consent-inputs">
                    <input
                      type="checkbox"
                      id="consentConduct"
                      name="consentConduct"
                      checked={checkConsentConduct}
                      onChange={() => setCheckConsentConduct((prev) => !prev)}
                    />
                    <label htmlFor="consentConduct">
                      <span>
                        I have read and agree to the{" "}
                        <a
                          href={codeOfConduct}
                          target="_blank"
                          rel="noreferrer"
                          className="bold-link"
                        >
                          code of conduct
                        </a>
                      </span>
                    </label>
                  </p>
                  <p className="consent-inputs">
                    <input
                      type="checkbox"
                      id="consentTerms"
                      name="consentTerms"
                      checked={checkConsentTerms}
                      onChange={() => setCheckConsentTerms((prev) => !prev)}
                    />
                    <label htmlFor="consentTerms">
                      <span>
                        I have read and agree to the{" "}
                        <a href={termsOfUse} target="_blank" rel="noreferrer" className="bold-link">
                          terms of use
                        </a>
                      </span>
                    </label>
                  </p>
                </div>

                <p className="consent-buttons">
                  <button
                    type="button"
                    className="button secondary"
                    onClick={() => setModalConsentVisible(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="button primary"
                    disabled={!checkConsentConduct || !checkConsentTerms}
                    onClick={() => {
                      acceptConsent()
                    }}
                  >
                    Allow access
                  </button>
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default Consent
