import { FontLoader } from "three/addons/loaders/FontLoader.js"
import { CSS2DObject } from "three/addons/renderers/CSS2DRenderer.js"
import ExperienceManager from "@/webgl/ExperienceManager.js"

export default class Label {
  constructor(base, gate, textColor) {
    this.experience = new ExperienceManager()
    this.renderer = this.experience.renderer
    this.camera = this.experience.camera
    this.scene = this.experience.scene
    this.sizes = this.experience.sizes
    this.world = this.experience.world
    this.labelRenderer = null
    this.base = base
    this.gate = gate
    this.textColor = textColor
    this.fontLoader = new FontLoader()

    this.setLabelOnGates()
  }

  /**
   * Sets labels texts on each gate
   */
  setLabelOnGates() {
    if (!this.base) return

    this.base.children.forEach((gate) => {
      if (!gate) return

      const wrapper = document.createElement("div")
      const gateLabel = this.createLabelForGate(gate)
      wrapper.className = "gate-label-wrapper"
      wrapper.appendChild(gateLabel)
      gate.mainLabel = new CSS2DObject(wrapper)
      gate.mainLabel.position.set(0, gate.geometry.parameters.height / 2, 0)
      gate.mainLabel.element.style.transformOrigin = "center"
      gate.mainLabel.element.style.color = this.textColor

      gate.add(gate.mainLabel)
    })
  }

  /**
   * Creates a label element for a given gate
   * @param {Object} gate - The gate object to create a label for
   * @returns {HTMLElement} - The created label element
   */
  createLabelForGate(gate) {
    if (!gate || !this.gate) return

    const div = document.createElement("div")
    div.className = `gate-label ${gate.url}`
    div.textContent = gate.name

    return div
  }
}
