import videoFlins from "@/assets/video/flins.mp4"
import videoMeta from "@/assets/video/meta.mp4"
import videoDesignTwin from "@/assets/video/design-twin.mp4"

const VideoPlayer = ({ media, loop }) => {
  const handleTimeUpdate = (event) => {
    const video = event.target
    if (media === "flins" && video.currentTime >= 10) {
      video.pause() // Pause the flins video at 10 seconds
    }
  }

  const videoSrc =
    media === "flins" ? videoFlins : media === "design-twin" ? videoDesignTwin : videoMeta

  return (
    <video
      className="background-video"
      src={videoSrc}
      autoPlay
      loop={loop}
      muted
      playsInline
      onTimeUpdate={handleTimeUpdate} // Check the video progress to pause flins at 10s
    />
  )
}

export default VideoPlayer
