/**
 * ArrowButton.jsx
 *
 * The ArrowButton component handles all back and exit navigation within the application.
 * It controls the button's appearance and behavior based on the current state and route conditions.
 *
 * Key functionalities include:
 * - Initializing the button color based on various state and route conditions.
 * - Setting the button text based on the current experience type, planet view, subexperience, and route.
 * - Navigating to the home page and updating state if transitioning from Dressing Room to Experience view.
 * - Providing functions to handle button clicks, toggle rendering states, and reset UI states.
 *
 * The component returns a button element that manages navigation and state updates.
 */

import { useEffect, useState } from "react"
import { useLocation, useMatch, useNavigate, useParams } from "react-router-dom"
import { usePreviousLocation } from "@/hooks/interface/usePreviousLocation"
import { useSDK } from "@/hooks/metaverse/useSDK"
import { useRooms } from "@/hooks/metaverse/useRooms"
import { useInterface } from "@/hooks/interface/useInterface"
import { usePlanet } from "@/hooks/interface/usePlanet"
import {
  CURRENT_PLANET_VIEW,
  CURRENT_EXPERIENCE_TYPE,
  CURRENT_EXPLORATION_LEVEL,
  ROUTES,
} from "@/constants/constants.jsx"

const ArrowButton = ({ className }) => {
  const SDK = useSDK()
  const { currentPlanetView, currentExperienceType, currentExplorationLevel, webGLExperience } =
    usePlanet()
  const {
    showScene3D,
    setShowScene3D,
    setShowPOI,
    showRenaultRooms,
    setShowRenaultRooms,
    setRoomsFromPOI,
    setRoomReady,
    roomReady,
  } = useRooms()
  const {
    subMenuIsVisible,
    setSubMenuIsVisible,
    setSideMenuOrganizationIsOpen,
    setSideMenuAccountIsOpen,
    setStartTransitionAnimation,
    setSearchBarIsVisible,
  } = useInterface()

  const navigate = useNavigate()
  const location = useLocation()
  const isBrand = useMatch("/:brand")
  const isDepartment = useMatch("/:brand/:department")
  const previousLocation = usePreviousLocation()
  const { brand, experience, subexperience } = useParams()
  const [styleArrowBtn, setStyleArrowBtn] = useState("")
  const [BtnText, setBtnTxt] = useState("Back")

  // Initialize button color based on various state and route conditions
  useEffect(() => {
    const setArrowBtnStyle = (style) => setStyleArrowBtn(style)
    const handleColorInit = () => {
      if (
        currentExperienceType === CURRENT_EXPERIENCE_TYPE.IN_3D_EXPERIENCE ||
        currentExperienceType === CURRENT_EXPERIENCE_TYPE.IN_2D_EXPERIENCE ||
        currentPlanetView === CURRENT_PLANET_VIEW.ENTERING_CONTINENT ||
        location.pathname === ROUTES.DRESSING_ROOM.path
      ) {
        setArrowBtnStyle("is-black")
        return
      }

      if (
        experience === ROUTES.RENAULT_EXPERIENCES.FLINS ||
        Object.values(ROUTES.RENAULT_SUBEXPERIENCES).includes(subexperience) ||
        experience === ROUTES.RENAULT_EXPERIENCES.DESIGN_TWIN ||
        experience === ROUTES.RENAULT_EXPERIENCES.KNOWLEDGE ||
        experience === ROUTES.RENAULT_EXPERIENCES.MEETING ||
        experience === ROUTES.RENAULT_EXPERIENCES.ENGINEERING ||
        experience === ROUTES.RENAULT_EXPERIENCES.CAR_WALK ||
        experience === ROUTES.RENAULT_EXPERIENCES.CONTROL_ROOM
      ) {
        setTimeout(() => {
          setArrowBtnStyle("is-white")
        }, 300)
        return
      }

      if (currentPlanetView === CURRENT_PLANET_VIEW.IN_CONTINENT) {
        let color = "is-black"
        switch (brand) {
          case "alpine":
          case "dacia":
          case "mobilize":
            color = "is-white big-screen"
            break
          default:
            break
        }
        setArrowBtnStyle(color)
      }
    }

    handleColorInit()
  }, [
    experience,
    subexperience,
    currentPlanetView,
    currentExperienceType,
    location.pathname,
    brand,
  ])

  // Set button text based on the current experience type, planet view, subexperience, and route
  useEffect(() => {
    const shouldSetExit =
      Object.values(ROUTES.RENAULT_SUBEXPERIENCES).includes(subexperience) ||
      currentExperienceType === CURRENT_EXPERIENCE_TYPE.IN_3D_EXPERIENCE ||
      currentExperienceType === CURRENT_EXPERIENCE_TYPE.IN_2D_EXPERIENCE ||
      currentExperienceType === CURRENT_EXPERIENCE_TYPE.IN_EXTERNAL_EXPERIENCE ||
      currentPlanetView === CURRENT_PLANET_VIEW.ENTERING_EXPERIENCE ||
      (location.pathname === ROUTES.DRESSING_ROOM.path && roomReady)

    if (shouldSetExit) {
      setBtnTxt("Exit")
    } else if (currentPlanetView === CURRENT_PLANET_VIEW.IN_CONTINENT) {
      setBtnTxt("Back")
    } else if (currentPlanetView === CURRENT_PLANET_VIEW.IN_ORBIT) {
      setBtnTxt("")
    }
  }, [location.pathname, currentPlanetView, roomReady, currentExperienceType, subexperience])

  // Navigate to the home page and update state if transitioning from Dressing Room to Experience view
  useEffect(() => {
    if (
      previousLocation?.pathname === ROUTES.DRESSING_ROOM.path &&
      currentPlanetView === CURRENT_PLANET_VIEW.IN_EXPERIENCE
    ) {
      navigate("/")
      setShowRenaultRooms(false)
    }
  }, [
    previousLocation,
    currentPlanetView,
    navigate,
    showRenaultRooms,
    setShowRenaultRooms,
    setShowScene3D,
    webGLExperience,
  ])

  const handleClickBtn = () => {
    if (isBrand || isDepartment) {
      navigate("/")
      if (
        currentPlanetView === CURRENT_PLANET_VIEW.IN_CONTINENT ||
        currentExplorationLevel === CURRENT_EXPLORATION_LEVEL.IN_CONTINENT
      ) {
        webGLExperience.world?.enterOrbit()
      }
    } else {
      navigate(-1)
      setShowScene3D((prev) => !prev)
    }

    handleToggleRender()
    resetUIStates()
  }

  const handleToggleRender = () => {
    // Toggle the render state in the SDK
    SDK.toggleRender(!showScene3D)
  }

  const resetUIStates = () => {
    // Reset various UI states
    setShowScene3D(false) // Hide metaverse canvas
    setRoomsFromPOI(false) // Make it impossible to travel to another metaverse experience through POI
    setShowPOI(false) // Hide all POI
    setShowRenaultRooms(false) // Set showRenaultRooms to false
    if (subMenuIsVisible) setSubMenuIsVisible("item-hidden") // Hide menu if visible
    setSideMenuOrganizationIsOpen(false) // Hide organization side menu if open
    setSideMenuAccountIsOpen(false) // Hide account side menu if open
    setStartTransitionAnimation("") // Reset all transition
    setSearchBarIsVisible(false) // Hide search bar
    setRoomReady(false) // Make rooms not ready
  }

  return (
    <button className={className} onClick={() => handleClickBtn()}>
      <div className={`back ${styleArrowBtn}`}>
        <span>{BtnText}</span>
      </div>
    </button>
  )
}

export default ArrowButton
