export const BRANDS = {
  RENAULT: "renault",
  ALPINE: "alpine",
  DACIA: "dacia",
  MOBILIZE: "mobilize",
  RGN: "rgn",
  RGS: "rgs",
}

export const ITEMS_MENU = {
  ORGANIZATION: "organization",
  ACCOUNT: "account",
  COLLECTIBLES: "collectibles",
  QUEST: "quest",
  DRESSING: "dressing",
  SEARCH: "search",
}

export const ITEMS_MENU_TOPIC = {
  LIVE_ACTIVITY: "live-activity",
  MEETING: "meeting",
  QUALITY: "quality",
  PROJECTS: "projects",
  REFACTORY: "refactory",
  EMPLOYEES: "employees",
}

export const COLLECTIBLE_COLLECTION_EID = [
  "2370700401633038340",
  "-3115682645428743599",
  "920913008036098220",
  "9116379488898961608",
]

export const QUESTS_EID = {
  EXPLORER: "-6393900363527702246",
  LIBRARY: "3343894857939121434",
}

export const CARD_TYPE = {
  FAVORITE: "favorite",
  EXPERIENCE: "experience",
  SEARCH: "search",
}

export const ROUTES = {
  HOME: { path: "/", theme: "gray", brand: "default" },
  DRESSING_ROOM: { path: "/dressing-room", theme: "dark", brand: "default" },
  BRANDS: {
    RENAULT: { path: "/renault", theme: "dark", brand: "renault" },
    MOBILIZE: { path: "/mobilize", theme: "white", brand: "mobilize" },
    ALPINE: { path: "/alpine", theme: "white", brand: "alpine" },
    DACIA: { path: "/dacia", theme: "white", brand: "dacia" },
  },
  DEPARTMENTS: {
    RENAULT: {
      DESIGN: { path: "/renault/design", theme: "dark", brand: "renault" },
      ENGINEERING: { path: "/renault/engineering", theme: "dark", brand: "renault" },
      HUMAN_RESOURCES: { path: "/renault/human-resources", theme: "dark", brand: "renault" },
      IS_IT: { path: "/renault/is-it", theme: "dark", brand: "renault" },
      MANUFACTURING: { path: "/renault/manufacturing", theme: "dark", brand: "renault" },
      MARKETING: { path: "/renault/marketing", theme: "dark", brand: "renault" },
      PROCUREMENT: { path: "/renault/procurement", theme: "dark", brand: "renault" },
      PRODUCT_PLANNING: { path: "/renault/product-planning", theme: "dark", brand: "renault" },
      QUALITY: { path: "/renault/quality", theme: "dark", brand: "renault" },
      SALES: { path: "/renault/sales", theme: "dark", brand: "renault" },
    },
    ALPINE: {
      DESIGN: { path: "/alpine/design", theme: "white", brand: "alpine" },
      ENGINEERING: { path: "/alpine/engineering", theme: "white", brand: "alpine" },
      HUMAN_RESOURCES: { path: "/alpine/human-resources", theme: "white", brand: "alpine" },
      IS_IT: { path: "/alpine/is-it", theme: "white", brand: "alpine" },
      MANUFACTURING: { path: "/alpine/manufacturing", theme: "white", brand: "alpine" },
      MARKETING: { path: "/alpine/marketing", theme: "white", brand: "alpine" },
      PROCUREMENT: { path: "/alpine/procurement", theme: "white", brand: "alpine" },
      PRODUCT_PLANNING: { path: "/alpine/product-planning", theme: "white", brand: "alpine" },
      QUALITY: { path: "/alpine/quality", theme: "white", brand: "alpine" },
      SALES: { path: "/alpine/sales", theme: "white", brand: "alpine" },
    },
    DACIA: {
      DESIGN: { path: "/dacia/design", theme: "white", brand: "dacia" },
      ENGINEERING: { path: "/dacia/engineering", theme: "white", brand: "dacia" },
      HUMAN_RESOURCES: { path: "/dacia/human-resources", theme: "white", brand: "dacia" },
      IS_IT: { path: "/dacia/is-it", theme: "white", brand: "dacia" },
      MANUFACTURING: { path: "/dacia/manufacturing", theme: "white", brand: "dacia" },
      MARKETING: { path: "/dacia/marketing", theme: "white", brand: "dacia" },
      PROCUREMENT: { path: "/dacia/procurement", theme: "white", brand: "dacia" },
      PRODUCT_PLANNING: { path: "/dacia/product-planning", theme: "white", brand: "dacia" },
      QUALITY: { path: "/dacia/quality", theme: "white", brand: "dacia" },
      SALES: { path: "/dacia/sales", theme: "white", brand: "dacia" },
    },
    MOBILIZE: {
      DESIGN: { path: "/mobilize/design", theme: "white", brand: "mobilize" },
      ENGINEERING: { path: "/mobilize/engineering", theme: "white", brand: "mobilize" },
      HUMAN_RESOURCES: { path: "/mobilize/human-resources", theme: "white", brand: "mobilize" },
      IS_IT: { path: "/mobilize/is-it", theme: "white", brand: "mobilize" },
      MANUFACTURING: { path: "/mobilize/manufacturing", theme: "white", brand: "mobilize" },
      MARKETING: { path: "/mobilize/marketing", theme: "white", brand: "mobilize" },
      PROCUREMENT: { path: "/mobilize/procurement", theme: "white", brand: "mobilize" },
      PRODUCT_PLANNING: { path: "/mobilize/product-planning", theme: "white", brand: "mobilize" },
      QUALITY: { path: "/mobilize/quality", theme: "white", brand: "mobilize" },
      SALES: { path: "/mobilize/sales", theme: "white", brand: "mobilize" },
    },
  },
  RENAULT_EXPERIENCES: {
    CAR_WALK: "car-walk",
    FLINS: "flins",
    CONTROL_ROOM: "control-room",
    KNOWLEDGE: "knowledge",
    MEETING: "meeting",
    ENGINEERING: "engineering",
    DESIGN_TWIN: "design-twin",
    NFT_SHOWROOM: "nft-showroom",
  },
  RENAULT_SUBEXPERIENCES: {
    LIVE_ACTIVITY: "live-activity",
    MEETING: "meeting",
    QUALITY: "quality",
    PROJECTS: "projects",
    REFACTORY: "refactory",
    EMPLOYEES: "employees",
  },
}

export const CONTINENTS_COLORS = {
  DEFAULT: "#D9D9D9",
  RENAULT: "#FFE041",
  ALPINE: "#a9c0d6",
  MOBILIZE: "#ffa88d",
  DACIA: "#c6d4aa",
  RENAULT_SEARCH: "#FFFA7A",
  ALPINE_SEARCH: "#a9c0d6",
  DACIA_SEARCH: "#c6d4aa",
  MOBILIZE_SEARCH: "#ffa88d",
}

export const BRANDS_COLORS = {
  RENAULT: {
    TEXT_COLOR: "#bd9700",
  },
  ALPINE: {
    TEXT_COLOR: "#071d32",
  },
  DACIA: {
    TEXT_COLOR: "#4f5b34",
  },
  MOBILIZE: {
    TEXT_COLOR: "#9f2600",
  },
}

export const CATEGORY_ITEMS = {
  COLLECTIBLES: "collectibles",
  QUESTS: "quests",
}

export const CURRENT_PLANET_VIEW = {
  INTRO: "INTRO",
  ENTERING_ORBIT: "ENTERING-ORBIT",
  IN_ORBIT: "IN-ORBIT",
  ENTERING_CONTINENT: "ENTERING-CONTINENT",
  IN_CONTINENT: "IN-CONTINENT",
  ENTERING_EXPERIENCE: "ENTERING-EXPERIENCE",
  IN_EXPERIENCE: "IN-EXPERIENCE",
}

export const CURRENT_EXPERIENCE_TYPE = {
  NONE: "NONE",
  IN_2D_EXPERIENCE: "IN-2D-EXPERIENCE",
  IN_3D_EXPERIENCE: "IN-3D-EXPERIENCE",
  IN_EXTERNAL_EXPERIENCE: "IN-EXTERNAL-EXPERIENCE",
}

export const CURRENT_PLANET = {
  ENTERING_RENAULT: "ENTERING-RENAULT",
  ENTERING_RENAULUTION: "ENTERING-RENAULUTION",
  IN_RENAULT: "IN-RENAULT",
  IN_RENAULUTION: "IN-RENAULUTION",
}

export const CURRENT_EXPLORATION_LEVEL = {
  NONE: "NONE",
  ENTERING_ORBIT: "ENTERING-ORBIT",
  IN_ORBIT: "IN-ORBIT",
  ENTERING_CONTINENTAL_NEWS: "ENTERING-CONTINENTAL-NEWS",
  IN_CONTINENTAL_NEWS: "IN-CONTINENTAL-NEWS",
  ENTERING_DEPARTMENTS: "ENTERING-DEPARTMENTS",
  IN_DEPARTMENTS: "IN-DEPARTMENTS",
  ENTERING_GATES: "ENTERING-GATES",
  IN_GATES: "IN-GATES",
}

export const TRANSITIONS = {
  ENTERING_RENAULT: "ENTERING-RENAULT",
  ENTERING_RENAULUTION: "ENTERING-RENAULUTION",
  ENTERING_ORBIT: "ENTERING-ORBIT",
  ENTERING_CONTINENTAL_NEWS: "ENTERING-CONTINENTAL-NEWS",
  ENTERING_DEPARTMENTS: "ENTERING-DEPARTMENTS",
  ENTERING_GATES: "ENTERING-GATES",
  ENTERING_CONTINENT: "ENTERING-CONTINENT",
  ENTERING_EXPERIENCE: "ENTERING-EXPERIENCE",
}

export const TIMER_TIMEOUT = {
  RESTORE_CATEGORY: 100,
  FADE_SIDE_MENU: 200,
  DISPLAY_INFO_BULL: 3000,
  PLANET_RG_TITLE: 300,
}

export const FAVORITES_ITEMS = [
  {
    id: 1,
    title: "ingénierie",
    circleClassStyle: "black-circle",
    onClick: () => {},
  },
  {
    id: 2,
    title: "knowledge",
    circleClassStyle: "black-circle",
    onClick: () => {},
  },
]

export const EXPERIENCE_ITEMS = [
  {
    id: 1,
    title: "experience 1",
    circleClassStyle: "white-circle",
    onClick: () => {},
  },
  {
    id: 2,
    title: "experience 2",
    circleClassStyle: "white-circle",
    onClick: () => {},
  },
  {
    id: 3,
    title: "experience 3",
    circleClassStyle: "white-circle",
    onClick: () => {},
  },
  {
    id: 4,
    title: "experience 4",
    circleClassStyle: "white-circle",
    onClick: () => {},
  },
  {
    id: 5,
    title: "experience 5",
    circleClassStyle: "white-circle",
    onClick: () => {},
  },
  {
    id: 6,
    title: "experience 6",
    circleClassStyle: "white-circle",
    onClick: () => {},
  },
]

export const MEDIA_TYPES = {
  VIDEO: "video",
  PDF: "pdf",
}

export const VIDEO_TYPES = ["mp3", "mp4"]
