import { useEffect, useState, useRef } from "react"
import { useSearch } from "@/hooks/interface/useSearch.jsx"
import Cookies from "js-cookie"
import ICON_UPLOAD from "@/assets/icons/UI/icon-upload.svg"
import "./upload.scss"

const UploadRender = ({ submitForm, setSubmitForm }) => {
  const { isUploadOpen, setIsUploadOpen, setViewMode, setIsExpanded, setDisplaySearchResults } =
    useSearch()

  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [fileSize, setFileSize] = useState(null)
  const [fileType, setFileType] = useState(null)

  const [title, setTitle] = useState(null)
  const [authors, setAuthors] = useState(null)
  const [description, setDescription] = useState(null)

  const [isUploading, setIsUploading] = useState(false)
  const [isUploaded, setIsUploaded] = useState(false)
  const [uploadPercentage, setUploadPercentage] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [uploadText, setUploadText] = useState("")
  const uploadFormRef = useRef()

  const radius = 359 / 2 - 10 / 2 // Radius of the circle
  const circumference = 2 * Math.PI * radius // Circumference of the circle

  useEffect(() => {
    if (!isUploadOpen && !submitForm) {
      return
    }

    if (!file && submitForm) {
      return
    }

    if (!submitForm) {
      return
    }

    const formData = new FormData()

    if (file) {
      formData.append("file", file)
    } else {
      // Fallback to using uploadFormRef if the file is set through input
      const formElement = uploadFormRef.current
      for (let field of formElement.elements) {
        if (field.name && field.files) {
          formData.append(field.name, field.files[0])
        }
      }
    }

    if (title) {
      formData.append("title", title)
    }

    if (authors) {
      formData.append("authors", authors)
    }

    if (description) {
      formData.append("description", description)
    }

    const xhr = new XMLHttpRequest()

    xhr.upload.addEventListener("progress", function (e) {
      if (e.lengthComputable) {
        const percentComplete = (e.loaded / e.total) * 100

        setIsUploading(true)
        setUploadPercentage(percentComplete)
        setSubmitForm(false)
      }
    })

    xhr.addEventListener("load", function () {
      if (xhr.status === 200) {
        console.info(`${fileName} file uploaded successfully.`)
        setIsUploading(false)
        setIsUploaded(true)
        setIsSuccess(true)
        setUploadText("your documents have been imported")
      } else {
        console.error(`${fileName} file upload failed.`)
        setIsUploading(false)
        setIsUploaded(true)
        setIsError(true)
        setUploadText("there was an error during the importation of your file, please try again")
        // setTimeout(() => {
        //   setIsUploading(false)
        //   setIsUploaded(false)
        // }, 5000)
      }
    })

    xhr.addEventListener("error", function () {
      console.error(`An error occurred while uploading ${file.name}.`)
      setIsUploading(false)
      setIsUploaded(false)
    })

    xhr.open("POST", "/api/c13s/back/single_upload")

    const jwt = Cookies.get("jwt")

    if (jwt) {
      xhr.setRequestHeader("Authorization", `Bearer ${jwt}`)
    }
    
    xhr.send(formData)
  }, [file, isUploadOpen, fileName, submitForm, setSubmitForm, authors, title, description])

  const handlePrevious = () => {
    // Reset input value
    if (uploadFormRef.current) {
      uploadFormRef.current.querySelector("#file").value = ""
    }
    setSubmitForm(false)
    setFile(null)
    setFileName(null)
    setFileSize(null)
    setFileType(null)
    setTitle(null)
    setAuthors(null)
    setDescription(null)

    if (isError) {
      setIsUploading(false)
      setIsUploaded(false)
      setIsError(false)
      setIsSuccess(false)
      setUploadText(false)
    } else {
      setDisplaySearchResults(false)
      setIsUploadOpen(false)
      setViewMode(null)
      setIsExpanded(false)
      setIsUploading(false)
      setIsUploaded(false)
      setIsError(false)
      setIsSuccess(false)
      setUploadText(false)
    }
  }

  const handleDrop = (event) => {
    event.preventDefault()

    const files = event.dataTransfer.files
    setFile(files[0])
    setFileName(files[0].name)
    setFileSize(files[0].size)
    setFileType(files[0].type)

    setSubmitForm(false)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <div className={`results-panel results-panel__upload`}>
      <div className="results-panel__content results-panel__content--upload">
        {/* Close Icon */}
        <div className="results-panel__content--upload__close-container">
          <div
            className="results-panel__upload__close-icon"
            onClick={() => {
              handlePrevious()
            }}
          />
        </div>
        <form id="uploadForm" ref={uploadFormRef}>
          <label
            htmlFor="file"
            className={`results-panel__content--upload__label results-panel__content--upload__label--file ${isUploading || isUploaded ? "results-panel__content--upload__label--file--hide" : "results-panel__content--upload__label--file--show"}`}
          >
            <input
              type="file"
              name="file"
              id="file"
              accept=".pdf, .mp4"
              required
              disabled={isUploading || isUploaded}
              className="results-panel__content--upload__input results-panel__content--upload__input--file"
              onChange={(event) => {
                setFile(event.target.files[0])
                setFileName(event.target.files[0].name)
                setFileSize(event.target.files[0].size)
                setFileType(event.target.files[0].type)
                setSubmitForm(false)
              }}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            />

            <div className={`hint ${submitForm && !fileName && "error"}`}>
              {!isUploading && !isUploaded ? (
                <img src={ICON_UPLOAD} alt="upload a document" />
              ) : null}
              <div className="text">
                <span>
                  {fileName ? fileName : "click or drag the file into this area to download it"}
                </span>
                <span>
                  {fileSize && fileType
                    ? (() => {
                        const units = ["bytes", "kB", "Mo", "Go", "To"]
                        let i = 0
                        let size = fileSize
                        while (size && size >= 1024 && i < units.length - 1) {
                          size /= 1024
                          i++
                        }
                        return (
                          <>
                            {size && size.toFixed(2)} {units[i]}{" "}
                            {fileType &&
                              ` - ${(() => {
                                if (fileType.startsWith("video/")) {
                                  // For video types, take the first part
                                  return fileType.split("/")[0]
                                } else {
                                  // For other types, take the last part
                                  return fileType.split("/").pop()
                                }
                              })()}`}
                          </>
                        )
                      })()
                    : "only pdf and mp4 files are accepted"}
                </span>

                <span className={`validation ${submitForm && !fileName && "show"}`}>
                  Please upload a file
                </span>
              </div>
            </div>
          </label>

          {isUploading || isUploaded ? (
            <>
              <div className={`result ${isUploading && !isUploaded ? "breathing-effect" : ""}`}>
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 359 359"
                  preserveAspectRatio="xMidYMid meet"
                >
                  {/* Definitions */}
                  <defs>
                    <linearGradient id="linearGradient">
                      <stop offset="20%" stopColor="#000000a4" />
                      <stop offset="80%" stopColor="#e4e4e4" />
                    </linearGradient>
                    <linearGradient id="linearGradient2" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset="10%" stopColor="#e4e4e4" />
                      <stop offset="90%" stopColor="#e9e9e9" />
                    </linearGradient>
                  </defs>

                  {/* Background Circle */}
                  <circle
                    fill="url(#linearGradient2)"
                    strokeWidth={4}
                    cx={179.5}
                    cy={179.5}
                    r={radius}
                  />

                  {/* Progress Circle */}
                  <circle
                    stroke="url(#linearGradient)"
                    fill="none"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeDasharray={circumference}
                    strokeDashoffset={(1 - uploadPercentage / 100) * circumference}
                    cx={179.5}
                    cy={179.5}
                    r={radius}
                    transform={`rotate(-90 179.5 179.5)`}
                    className={`progress-circle ${isUploaded ? "fade-out" : ""}`}
                  />

                  {/* Checkmark for Success */}
                  {isSuccess && (
                    <path
                      d="M110 185 L150 225 L240 135"
                      fill="none"
                      stroke="rgba(0, 0, 0,0.5)"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="checkmark"
                    />
                  )}

                  {/* Cross for Error */}
                  {isError && (
                    <>
                      <line
                        x1="130"
                        y1="130"
                        x2="230"
                        y2="230"
                        stroke="rgba(0, 0, 0,0.5)"
                        strokeWidth="4"
                        strokeLinecap="round"
                        className="cross-line"
                      />
                      <line
                        x1="230"
                        y1="130"
                        x2="130"
                        y2="230"
                        stroke="rgba(0, 0, 0,0.5)"
                        strokeWidth="4"
                        strokeLinecap="round"
                        className="cross-line"
                      />
                    </>
                  )}

                  {/* Percentage Text */}
                  {!isSuccess && !isError && (
                    <text x="50%" y="50%" dy=".3em" textAnchor="middle" fontSize="20px" fill="#333">
                      {`${Math.floor(uploadPercentage)}%`}
                    </text>
                  )}
                </svg>
                <div>{uploadText}</div>
              </div>
            </>
          ) : (
            <>
              <label
                htmlFor="title"
                className="results-panel__content--upload__label results-panel__content--upload__label--default"
              >
                <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Document title"
                  required
                  disabled={isUploading || isUploaded}
                  className="results-panel__content--upload__label__input results-panel__content--upload__input--default"
                  onChange={(event) => {
                    setTitle(event.target.value)
                  }}
                />
              </label>

              <label
                htmlFor="author"
                className="results-panel__content--upload__label results-panel__content--upload__label--default"
              >
                <input
                  type="text"
                  name="author"
                  id="author"
                  disabled={isUploading || isUploaded}
                  placeholder="Document authors"
                  className="results-panel__content--upload__label__input results-panel__content--upload__input--default"
                  onChange={(event) => {
                    setAuthors(event.target.value)
                  }}
                />
              </label>

              <label
                htmlFor="description"
                className="results-panel__content--upload__label results-panel__content--upload__label--default"
              >
                <textarea
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Document description"
                  disabled={isUploading || isUploaded}
                  className="results-panel__content--upload__label__input results-panel__content--upload__input--default"
                  onChange={(event) => {
                    setDescription(event.target.value)
                  }}
                />
              </label>
            </>
          )}
        </form>
      </div>
    </div>
  )
}

export default UploadRender
