import { ShaderMaterial, Color, DoubleSide } from "three"

import vertexShader from "@/webgl/world/materials/background/vertex.glsl"
import fragmentShader from "@/webgl/world/materials/background/fragment.glsl"

export default class BackgroundMaterial extends ShaderMaterial {
  constructor({ startColor, centerColor, endColor }) {
    super({
      vertexShader,
      fragmentShader,

      uniforms: {
        time: { value: 0 },
        timeStart: { value: 0 },
        fadeProgress: { value: 0 },
        duration: { value: 5000 },
        startColorA: { value: new Color(startColor) },
        startColorB: { value: new Color(startColor) },
        centerColorA: { value: new Color(centerColor) },
        centerColorB: { value: new Color(centerColor) },
        endColorA: { value: new Color(endColor) },
        endColorB: { value: new Color(endColor) },
      },
    })

    this.side = DoubleSide
  }

  switchColors(colors, progress) {
    const { startColor, centerColor, endColor } = colors

    this.uniforms.timeStart.value = this.uniforms.time.value
    this.uniforms.fadeProgress.value = progress

    this.uniforms.startColorA.value.copy(this.uniforms.startColorB.value)
    this.uniforms.startColorB.value.set(startColor)

    this.uniforms.centerColorA.value.copy(this.uniforms.centerColorB.value)
    this.uniforms.centerColorB.value.set(centerColor)

    this.uniforms.endColorA.value.copy(this.uniforms.endColorB.value)
    this.uniforms.endColorB.value.set(endColor)

    this.needsUpdate = true
  }
}
