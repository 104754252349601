import { Color, ShaderMaterial, Vector2, Vector3 } from "three"

import vertexShader from "@/webgl/world/materials/RIM/vertex.vs"
import fragmentShader from "@/webgl/world/materials/RIM/fragment.fs"

export default class RIMMaterial extends ShaderMaterial {
  constructor({ color, rim, rimForce, emissiveColor, emissiveIntensity }, opacity = 1) {
    super({
      vertexShader,
      fragmentShader,

      uniforms: {
        color: { value: new Color(color) },
        rimColor: { value: new Color(rim) },
        rimForce: { value: rimForce },
        opacity: { value: opacity },
        uTime: { value: 0 },
        uDirectionalLightColor1: { value: new Color(0xffffff) },
        uEmissiveColor: { value: new Color(emissiveColor || 0x000000) },
        uEmissiveIntensity: { value: emissiveIntensity || 0.0 },
        uDirectionalLightDirection1: { value: new Vector3() },
        uDirectionalLightColor2: { value: new Color(0xffffff) },
        uDirectionalLightDirection2: { value: new Vector3() },
        uHemisphereLightColor: { value: new Color(0xffffff) },
        uHemisphereLightDirection: { value: new Vector3() },
        uAmbientLightColor: { value: new Color(0xffffff) },
        uShadowGradientFactor: { value: 0.2 },
        uSelectedTerritoryPosition: { value: new Vector3() },
        uMaxDistance: { value: 0.0 },
        uMaxDistanceY: { value: 0.0 },
        uIsAdjacent: { value: false },
        uBorderThickness: { value: 0.02 },
        uIsSelectedTerritory: { value: false },
        uOutlineThickness: { value: 2.0 },
        uOutlineColor: { value: new Color(0xffffff) },
        uSelectedTerritorySize: { value: new Vector2() },
        uEdgeDistanceFactor: { value: 0 },
        uOffset: { value: new Vector2(0.9, -0.9) },
      },
    })

    if (opacity !== 1) this.transparent = true
  }
}
