/**
 * CollectibleMintSuccess.jsx
 *
 * The CollectibleMintSuccess component displays a success message after the user successfully mints a collectible.
 * It checks if the user already has any of these collectibles and renders a congratulatory modal.
 *
 * Key functionalities include:
 * - Checking if the user already owns the minted collectible.
 * - Rendering a modal with a congratulatory message.
 *
 * The component returns a modal that congratulates the user
 *
 * */

import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useClaimSetup } from "@/hooks/onboarding/useClaimSetup.jsx"
import { useInterface } from "@/hooks/interface/useInterface"
import { ROUTES, ITEMS_MENU, CATEGORY_ITEMS } from "@/constants/constants"
import iconClose from "@/assets/icons/UI/times.svg"
import "./collectibleMint.scss"

// TODO: To refactor
const CollectibleMintSuccess = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { collectibleToMint, onMintResultClose } = useClaimSetup()
  const {
    setSideMenuAccountIsOpen,
    setSubMenuIsVisible,
    setStartTransitionAnimation,
    setCategorySelected,
    setActiveItemMenu,
    setMenuSelected,
  } = useInterface()

  const [collectibleIsAvatar, setcCollectibleIsAvatar] = useState(false)

  // Checks if user has any of these collectibles
  useEffect(() => {
    // TODO: add to constant
    if (
      collectibleToMint?.collection.eid === "2370700401633038340" ||
      collectibleToMint?.collection.eid === "-3115682645428743599" ||
      collectibleToMint?.collection.eid === "920913008036098220" ||
      collectibleToMint?.collection.eid === "9116379488898961608"
    ) {
      setcCollectibleIsAvatar(true)
    } else {
      setcCollectibleIsAvatar(false)
    }
  }, [collectibleToMint])

  return (
    <>
      <section className="mint-success modal-wrapper">
        <div className="blur deep-blur"></div>
        <div className="modal-popup mint-result">
          <div className="modal-body mint-result-body">
            <div className="modal-close">
              <button
                id="congrats-exit"
                className="button exit modal-exit-button"
                type="button"
                onClick={() => {
                  onMintResultClose()
                }}
              >
                <img className="exit" src={iconClose} alt="" />
              </button>
            </div>

            <h1>Congratulations !</h1>
            <p>
              {collectibleIsAvatar
                ? "Your claim is complete! You can now fully access the Planet RG Experience !"
                : !collectibleIsAvatar
                  ? "Your quest is complete! You can now access your collectible in your profile !"
                  : "Your claim is almost there..."}
            </p>
            <div className="buttons">
              {!collectibleIsAvatar && (
                <button
                  className="button secondary"
                  onClick={() => {
                    onMintResultClose()
                  }}
                >
                  Back to experience
                </button>
              )}
              <button
                className="button primary"
                onClick={() => {
                  // redirect to dressing room on the first connection
                  if (
                    location.pathname === ROUTES.HOME.path ||
                    location.pathname === ROUTES.BRANDS.RENAULT.path
                  ) {
                    collectibleIsAvatar && navigate("/dressing-room")
                    !collectibleIsAvatar && props.setDashboardOpen(true)
                    !collectibleIsAvatar && props.setActiveTab("collectibles")
                    onMintResultClose()
                  } else {
                    // opens the new collectibles menu when an item is found
                    onMintResultClose()
                    setSubMenuIsVisible("item-visible")
                    setMenuSelected("my account")
                    setActiveItemMenu(ITEMS_MENU.ACCOUNT)
                    setStartTransitionAnimation("start-transition")
                    setSideMenuAccountIsOpen(true)
                    setCategorySelected(CATEGORY_ITEMS.COLLECTIBLES)
                  }
                }}
              >
                {collectibleIsAvatar ? (
                  "Dressing Room"
                ) : !collectibleIsAvatar ? (
                  "Check my card now"
                ) : (
                  <span>Claim in progress...</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CollectibleMintSuccess
