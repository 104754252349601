import { SDKProvider } from "@/context/metaverse/SDKContext.jsx"
import { VideoStateProvider } from "@/context/interface/VideoStateContext.jsx"
import { AuthProvider } from "@/context/onboarding/AuthContext.jsx"
import { ClaimProvider } from "@/context/onboarding/ClaimContext.jsx"
import { HubProvider } from "@/context/metaverse/HubContext.jsx"
import { CollectiblesProvider } from "@/context/metaverse/CollectiblesContext.jsx"
import { AvatarProvider } from "@/context/onboarding/AvatarContext.jsx"
import { RoomsProvider } from "@/context/metaverse/RoomsContext.jsx"

import { ConfigProvider } from "antd"
import { QueryClientProvider } from "@tanstack/react-query"
import { InterfaceProvider } from "@/context/interface/InterfaceContext.jsx"
import { PlanetProvider } from "@/context/interface/PlanetContext.jsx"
import { SearchProvider } from "@/context/interface/SearchContext.jsx"
import { RouteTypeProvider } from "@/context/interface/RouteTypeContext.jsx"
import queryClient from "@/config/reactQueryConfig"

const providers = [
  SDKProvider,
  VideoStateProvider,
  AuthProvider,
  HubProvider,
  AvatarProvider,
  ClaimProvider,
  CollectiblesProvider,
  RoomsProvider,
  InterfaceProvider,
  PlanetProvider,
  SearchProvider,
  RouteTypeProvider,
]

const AppProviders = ({ children }) => {
  const ComposedProviders = providers.reduceRight(
    (AccumulatedProviders, CurrentProvider) => (
      <CurrentProvider>{AccumulatedProviders}</CurrentProvider>
    ),
    children,
  )

  return (
    <ConfigProvider theme={{ token: { fontFamily: "RenaultGroupAH" } }}>
      <QueryClientProvider client={queryClient}>{ComposedProviders}</QueryClientProvider>
    </ConfigProvider>
  )
}

export default AppProviders
