uniform vec3 color;
uniform float opacity;

varying vec2 vUv;

void main() {
   // Calculate the distance from the center of the UV map
    float distance = length(vUv - vec2(0.5, 0.5));

    // Normalize the distance to the range you want the gradient to occur
    float normalizedDistance = distance * 2.0; 

    // Calculate the alpha value (0.0 at the edge and 1.0 at the center)
    float alpha = 1.0 - smoothstep(0.0, 1.0, normalizedDistance);

    alpha *= opacity;

    gl_FragColor = vec4(color, alpha);
}