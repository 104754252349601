/**
 * UseInterface.jsx
 */

import { useContext } from "react"
import { InterfaceContext } from "@/context/interface/InterfaceContext"

export const useInterface = () => {
  return useContext(InterfaceContext)
}
