/**
 * useClaimSetup.jsx
 */

import { useContext } from "react"
import { ClaimContext } from "@/context/onboarding/ClaimContext"

export const useClaimSetup = () => {
  return useContext(ClaimContext)
}
