import { csstween as _csstween, cssanimate as _cssanimate } from '../anim/index.js'

function useCssTweens() {
  const tweens = []
  // if (getCurrentInstance()) onUnmounted(() => clear())

  return { csstween, cssanimate, clear }

  function basetween(props, method) {
    const tween = method(props)
    const autoClear = props.autoClear == null || props.autoClear
    if (autoClear) tweens.push(tween)
    return tween
  }

  function csstween(props) {
    return basetween(props, _csstween)
  }

  function cssanimate(props) {
    return basetween(props, _cssanimate)
  }

  function clear(target) {
    for (let i = tweens.length - 1; i >= 0; i--) {
      const tween = tweens[i]
      if (!tween || !tween.destroy) {
        tweens.splice(i, 1)
      } else if (!target || tween.target === target) {
        tween.destroy()
        tweens.splice(i, 1)
      }
    }
  }
}

export { useCssTweens }
export default useCssTweens
