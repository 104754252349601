import { AdditiveBlending, ShaderMaterial, BackSide } from "three"

import vertexShader from "@/webgl/world/materials/atmosphere/shader.vs"
import fragmentShader from "@/webgl/world/materials/atmosphere/shader.fs"

export default class AtmosphereMaterial extends ShaderMaterial {
  constructor(opacity = 1) {
    super({
      vertexShader,
      fragmentShader,

      uniforms: {
        opacity: { value: opacity },
      },
    })

    this.side = BackSide
    this.blending = AdditiveBlending
    if (opacity !== 1) this.transparent = true
  }
}
