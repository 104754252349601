/**
 * useAvatarSetup.jsx
 */

import { useContext } from "react"
import { AvatarContext } from "@/context/onboarding/AvatarContext"

export const useAvatarSetup = () => {
  return useContext(AvatarContext)
}
