import {
  Box3,
  CircleGeometry,
  FrontSide,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Vector3,
} from "three"
import { FontLoader } from "three/addons/loaders/FontLoader.js"
import { TextGeometry } from "three/addons/geometries/TextGeometry.js"
import GATES_DATA from "@/webgl/world/components/gates/gates.json"
import Gates from "../gates/Gates.js"
import { EXPLORATION, VIEWS } from "@/webgl/world/World.js"
import { IS_DEV } from "@/utils/utils.jsx"
import { MOUSE } from "@/webgl/world/controls/index.js"
import CursorManager from "@/utils/CursorManager.js"
import { raycastAllChildren } from "@/webgl/world/controls/Raycaster.js"
import ExperienceManager from "@/webgl/ExperienceManager.js"
import GroundMaterial from "@/webgl/world/materials/ground/index.js"
import MATERIALS from "@/webgl/world//materials/infos.js"
import { CSS2DObject } from "three/examples/jsm/Addons.js"
import TerritoryGradient from "../../materials/shadow/index.js"

const MATRIX = new Matrix4()
let gates = null

export default class Department {
  constructor(_options) {
    this.setupProperties(_options)
  }

  setupProperties(_options) {
    this.experience = new ExperienceManager()
    this.camera = this.experience.camera
    this.world = this.experience.world
    this.scene = this.experience.scene
    this.time = this.experience.time
    this.brand = _options.brand
    this.planetBase = _options.planetBase
    this.continent = _options.continent
    this.continentBase = _options.continentBase
    this.continentGround = _options.continentGround
    this.departmentGround = _options.departmentGround
    this.continentGroundLevel = _options.groundLevel
    this.material = _options.material

    this.grounds = []
    this.departments = this.extractDepartmentNames(this.departmentGround)
    this.departmentsInfo = this.getDepartmentsInfo(this.brand, this.departments)

    this.labels = []

    this.base = new Object3D()
    this.base.name = `departments-${this.brand}`
    this.continentBase.add(this.base)

    this.fontLoader = new FontLoader()
    this.previousIntersectedObject = null

    this.initialY = []
    this.elevationComplete = []
    this.elevationStarted = false
    this.lastIntersectedObject = null // Store the last intersected object
    this.allTerritories = []
    this.previousAdjacentTerritories = []
  }

  /**
   * Initializes the gates and their positioning
   */
  init() {
    this.createGrounds()
    this.setLabels2D()
    this.createGates()
  }

  extractDepartmentNames(departments) {
    return departments
      .map((department) => {
        const match = /department_\w+_([\w-]+)/i.exec(department.name)
        if (match) {
          return {
            name: match[1].toLowerCase(),
            position: department.position.clone(),
          }
        } else {
          return null
        }
      })
      .filter((department) => department !== null)
  }

  getDepartmentsInfo(continentName, departments) {
    if (!departments) return

    const continent = GATES_DATA.continents.find((cont) => cont.name === continentName)

    if (continent) {
      return departments
        .map(({ name: departmentName, position: departmentPosition }) => {
          const matchingDepartment = continent.departments.find(
            (dept) => dept.name.toLowerCase() === departmentName,
          )
          if (matchingDepartment) {
            return {
              title: matchingDepartment.title,
              name: departmentName,
              position: departmentPosition,
              gates: matchingDepartment.gates,
            }
          } else {
            return null
          }
        })
        .filter((department) => department !== null)
    }

    return []
  }

  getMatchingDepartment(continentName, selectedDepartmentName) {
    const continent = GATES_DATA.continents.find((cont) => cont.name === continentName)

    if (continent) {
      const matchingDepartment = continent.departments.find(
        (dept) => dept.name.toLowerCase() === selectedDepartmentName.toLowerCase(),
      )

      if (matchingDepartment) {
        const departmentPosition = this.departments.find(
          (dept) => dept.name.toLowerCase() === selectedDepartmentName.toLowerCase(),
        ).position

        return {
          title: matchingDepartment.title,
          name: selectedDepartmentName,
          position: departmentPosition,
          gates: matchingDepartment.gates,
        }
      }
    }

    return null
  }

  createGrounds() {
    this.departmentsInfo.forEach((department) => {
      const geometry = new CircleGeometry(30, 32)
      geometry.rotateX(Math.PI / 2)

      // For debug
      // const material = new MeshBasicMaterial({
      //   color: 0x0000ff,
      //   side: BackSide,
      //   transparent: true,
      //   opacity: 0,
      // })
      const material = new GroundMaterial(this.material.ground, 0.0)

      const gatesInfos = this.getMatchingDepartment(this.brand, department.name)

      this.ground = new Mesh(geometry, material)
      this.ground.name = `department_${department.name}_ground`
      this.ground.title = gatesInfos.title
      this.ground.gates = gatesInfos.gates
      this.ground.visible = true
      this.ground.originalRaycast = this.ground.raycast
      this.ground.raycast = () => {}
      this.ground.scale.setScalar(0.01)
      this.ground.position.set(department.position.x, department.position.y, department.position.z)
      this.ground.position.copy(department.position).setLength(this.continentGroundLevel)
      this.ground.up.copy(this.planetBase.position).normalize()
      this.ground.isDepartment = true
      MATRIX.makeRotationX(Math.PI * 0.5)
      this.ground.matrix
        .lookAt(department.position, new Vector3(0, 0, 0), new Vector3(0, 0, 1))
        .multiply(MATRIX)
      this.ground.quaternion.setFromRotationMatrix(this.ground.matrix)

      this.grounds.push(this.ground)
      this.continentBase.departmentsGrounds = this.grounds

      this.base.add(this.ground)

      if (department.name.includes(this.world.user.department)) {
        this.continentBase.defaultDepartment = this.ground
        this.defaultDepartment = this.ground
      }
    })
  }

  createGates() {
    if (!this.ground) return
    this.base.children.forEach((department) => {
      // Creates gates for each department

      gates = new Gates({
        departmentBase: this.base,
        planetBase: this.planetBase,
        departmentGround: department,
        gatesInfos: department.gates,
        continentBase: this.continentBase,
      })

      gates.init()

      department.gate = gates
      department.department = this
      this.base.gate = gates
    })
  }

  setLabels2D() {
    this.base.children.forEach((department) => {
      const wrapper = document.createElement("div")
      const departmentLabel = this.createLabelForDepartment(department)
      wrapper.className = `department--wrapper`
      wrapper.appendChild(departmentLabel)
      department.mainLabel = new CSS2DObject(wrapper)
      department.mainLabel.position.set(
        department.position.x,
        department.position.y,
        department.position.z,
      )
      department.mainLabel.element.style.transformOrigin = "center"
      this.labels.push(department.mainLabel)
      this.continentBase.add(department.mainLabel)
      this.continentBase.departmentLabels = this.labels
    })
  }
  setLabels() {
    if (!this.base) return

    this.fontLoader.load(
      `${import.meta.env.VITE_BASE_URL}${IS_DEV ? "assets/fonts/Renault_Group AH_Bold.json" : "fonts/Renault_Group AH_Bold.json"}`,
      (font) => {
        this.base.children.forEach((department) => {
          const textGeometry = new TextGeometry(department.title.toLowerCase(), {
            font: font,
            size: 7,
            depth: 0,
            curveSegments: 12,
          })

          textGeometry.rotateX(-Math.PI / 2)

          // Center the text geometry
          textGeometry.computeBoundingBox()
          const boundingBox = textGeometry.boundingBox
          const center = new Vector3()
          boundingBox.getCenter(center)
          textGeometry.translate(-center.x, -center.y, -center.z)

          const textMaterial = new MeshBasicMaterial({
            color:
              MATERIALS[this.planetBase.name.split("-")[1].toUpperCase()][
                this.continentBase.name.split("-")[1]
              ].department.textColor,
            side: FrontSide,
            transparent: true,
            opacity: 0,
          })

          const textMesh = new Mesh(textGeometry, textMaterial)
          textMesh.name = `label-${department.title}`

          textMesh.position.copy(department.position)
          textMesh.position.y = 0.6
          textMesh.up.set(0, 1, 0)
          textMesh.visible = true
          textMesh.raycast = () => {} // Don't allow raycasting

          this.labels.push(textMesh)
          department.departmentLabel = textMesh
          department.add(textMesh)
        })
      },
    )
    this.base.departmentLabels = this.labels
  }

  /**
   * Creates a label element for a given gate
   * @param {Object} gate - The gate object to create a label for
   * @returns {HTMLElement} - The created label element
   */
  createLabelForDepartment(department) {
    if (!department) return

    const div = document.createElement("div")
    div.className = `department--title ${this.brand} ${department.title.toLowerCase()}`
    div.innerHTML = `<span>${department.title.toLowerCase()}</span></div>`

    return div
  }

  setOpacity(value) {
    if (!value) return
    // Ensure the ground visibility is updated appropriately
    this.ground.visible = value > 0

    // Set the opacity of each label to the given value
    this.labels.forEach((label) => {
      label.material.opacity = value
    })
  }

  update() {
    if (!this.world.avatar) return

    if (this.world.currentPlanetInstance.activeDepartment) {
      this.world.currentPlanetInstance.activeDepartment.gate.update()
      if (this.world.currentView === VIEWS.IN_CONTINENT)
        this.camera.getGatesInfo(this.world.currentPlanetInstance.activeDepartment)
    }

    if (this.world.explorationView === EXPLORATION.IN_ORBIT) return

    this.handleInteractions()
  }

  /**
   * Handles user interactions including raycasting and clicking on continents.
   */
  handleInteractions() {
    CursorManager.toggleCursor("grabbing", MOUSE.active)

    this.ground.raycast = this.ground.originalRaycast

    const intersectedObject = this.handleRaycasting()

    this.continentBase.selectedDepartment = this.continentBase.departmentsGrounds.find((dept) =>
      dept.name.toLowerCase().includes(intersectedObject?.name.toLowerCase().split("_")[2]),
    )

    this.handleTerritoryInteraction(intersectedObject)
    this.handleCursor(intersectedObject)
    this.handleClickOnTerritory(intersectedObject)
  }

  handleCursor(intersectedObject) {
    if (this.world.explorationView === EXPLORATION.IN_DEPARTMENTS && intersectedObject) {
      CursorManager.setCursor("selecting")
    } else if (this.world.explorationView === EXPLORATION.IN_DEPARTMENTS && !intersectedObject) {
      CursorManager.toggleCursor("grabbing", MOUSE.active)
    } else {
      CursorManager.toggleCursor("grabbing", MOUSE.active)
    }
  }

  handleTerritoryInteraction(intersectedObject) {
    if (this.world.explorationView !== EXPLORATION.IN_DEPARTMENTS) {
      if (this.lastIntersectedObject) {
        this.lastIntersectedObject.scale.set(1, 1, 1)

        this.resetAdjacentTerritories()
        this.resetGlowyOutline(this.lastIntersectedObject)

        this.elevationStarted = false
        this.elevationComplete.fill(false)
        this.lastIntersectedObject = null
      }

      return
    }

    if (intersectedObject) {
      CursorManager.setCursor("selecting")
      CursorManager.toggleCursor("grabbing", MOUSE.active)
      this.world.controls.cancelAutorotate()

      if (this.lastIntersectedObject && this.lastIntersectedObject !== intersectedObject) {
        this.elevationComplete = new Array(
          intersectedObject.geometry.attributes.position.count,
        ).fill(false)
        this.initialY = [] // Reset initialY when a new object is intersected
        this.elevationStarted = false
        this.lastIntersectedObject.scale.set(1, 1, 1)
        this.resetAdjacentTerritories()
        this.resetGlowyOutline(this.lastIntersectedObject)
      }

      this.lastIntersectedObject = intersectedObject

      intersectedObject.scale.set(1.005, 1.005, 1.005)
      this.applyGlowyOutline(intersectedObject)

      const adjacentTerritories = this.findAdjacentTerritories(
        intersectedObject,
        this.continentBase.territories,
      )

      adjacentTerritories.forEach((territory) => {
        if (territory === intersectedObject) return

        if (!territory.userData.originalMaterial) {
          territory.userData.originalMaterial = territory.material
        }

        this.applyGradientEffect(intersectedObject, adjacentTerritories)
      })

      this.previousAdjacentTerritories = adjacentTerritories
    } else {
      if (this.lastIntersectedObject) {
        this.lastIntersectedObject.scale.set(1, 1, 1)

        this.resetAdjacentTerritories()
        this.resetGlowyOutline(this.lastIntersectedObject)

        this.elevationStarted = false
        this.elevationComplete.fill(false)
        this.lastIntersectedObject = null
      }
    }
  }

  applyGlowyOutline(selectedTerritory) {
    selectedTerritory.material.uniforms.uIsSelectedTerritory.value = true

    selectedTerritory.children.forEach((child) => {
      if (!child.name.includes("outline")) return
      child.visible = true

      child.material = new TerritoryGradient()

      child.material.uniforms.uTime.value = this.time.elapsed * 0.001
      child.material.needsUpdate = true
    })
  }

  resetGlowyOutline(selectedTerritory) {
    selectedTerritory.children.forEach((child) => {
      if (!child.name.includes("outline")) return

      child.material = selectedTerritory.material
      child.material.needsUpdate = true
      child.visible = false
    })
  }

  applyGradientEffect(targetTerritory, adjacentTerritories) {
    const boundingBox = new Box3().setFromObject(targetTerritory)
    const size = boundingBox.getSize(new Vector3())

    adjacentTerritories.forEach((territory) => {
      // Skip the target territory itself to avoid applying the gradient
      if (territory === targetTerritory) return
      const targetCenter = this.computeWorldCenter(targetTerritory)
      boundingBox.getCenter(targetCenter)

      if (territory.material.uniforms && territory.material.uniforms.uShadowGradientFactor) {
        territory.material.uniforms.uIsAdjacent.value = true
        territory.material.uniforms.uSelectedTerritoryPosition.value.copy(targetCenter)
        territory.material.uniforms.uTime.value += this.time.elapsed * 0.02
        territory.material.uniforms.uSelectedTerritorySize.value.copy(size)

        territory.material.uniforms.uShadowGradientFactor.value = 0.6
        territory.material.uniforms.uMaxDistance.value = 1.1
        territory.material.uniforms.uMaxDistanceY.value = 1.0
        territory.material.uniforms.uEdgeDistanceFactor.value = 0.2
        territory.material.needsUpdate = true
      } else {
        console.warn(`Material for territory does not have uShadowGradientFactor:`, territory)
      }
    })
  }

  findAdjacentTerritories(selectedObject, allTerritories) {
    const selectedCenter = this.computeWorldCenter(selectedObject)
    const distances = []

    allTerritories.forEach((territory) => {
      if (territory !== selectedObject) {
        const territoryCenter = this.computeWorldCenter(territory)
        const distance = selectedCenter.distanceTo(territoryCenter)
        distances.push({ territory, distance })
      }
    })

    // Sort by distance
    distances.sort((a, b) => a.distance - b.distance)

    // Determine a reasonable threshold, for example, average distance of the closest 5 territories
    const numClosest = Math.min(6, distances.length)
    const threshold =
      distances.slice(0, numClosest).reduce((acc, val) => acc + val.distance, 0) / numClosest

    // Select territories within this threshold
    const adjacentTerritories = distances
      .filter((d) => d.distance <= threshold)
      .map((d) => d.territory)

    return adjacentTerritories
  }

  computeWorldCenter(object) {
    const center = new Vector3()
    object.geometry.computeBoundingBox()
    object.geometry.boundingBox.getCenter(center)
    object.localToWorld(center) // Convert to world coordinates
    return center
  }

  resetAdjacentTerritories() {
    this.previousAdjacentTerritories.forEach((territory) => {
      territory.material.uniforms.uIsAdjacent.value = false
      territory.material.needsUpdate = true
      if (territory.userData.originalMaterial) {
        territory.material = territory.userData.originalMaterial
        territory.material.needsUpdate = true
        delete territory.userData.originalMaterial // Clean up
      }
    })
    this.previousAdjacentTerritories = [] // Clear the list
  }

  /**
   * Handling raycasting to check if the mouse is over a departmebt.
   * @returns {Object|null} The intersected object if a continent is under the mouse, null otherwise.
   */
  handleRaycasting() {
    const intersections = raycastAllChildren(this.continentBase, this.camera.instance)
    if (intersections.length === 0) {
      return null
    }

    for (const intersect of intersections) {
      if (intersect.object.name.includes("territory")) {
        return intersect.object
      }
    }

    return null
  }

  /**
   * Handles the logic for when a user clicks on a continent.
   * @param {Boolean} isContinent - Whether the intersected object is a continent
   * @param {Object} intersectedObject - The object that was intersected by raycasting
   */

  handleClickOnTerritory(intersectedObject) {
    if (!intersectedObject) return

    if (MOUSE.clicked && this.world.explorationView === EXPLORATION.IN_DEPARTMENTS) {
      MOUSE.clicked = false

      intersectedObject.scale.set(1, 1, 1)
      this.world.routeTo(`${this.brand}/${intersectedObject.name.split("_")[2]?.toLowerCase()}`)
      this.world.lastInteractionType = "click"
    }
  }
}
