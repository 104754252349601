/**
 * AvatarContext.jsx
 * Authentification process
 * States : logged, consented, profile
 */

import { createContext, useCallback, useEffect, useState } from "react"
import { useSDK } from "@/hooks/metaverse/useSDK"
import { useAuth } from "@/hooks/onboarding/useAuth.jsx"
import { useHub } from "@/hooks/metaverse/useHub"
import { createLogger } from "@/utils/debug/log.js"

const log = createLogger("POWER.XYZ", "#FFF", "#000").log

const AvatarContext = createContext(null)

const AvatarProvider = ({ children }) => {
  const SDK = useSDK()
  const { profile, getProfile } = useAuth()
  const { experiences } = useHub()
  const [modalAvatarSetupVisible, setModalAvatarSetupVisible] = useState(false)
  const [avatars, setAvatars] = useState(null)
  const [nickname, setNickname] = useState(null)
  const [selectedAvatar, setSelectedAvatar] = useState(null)
  const [bodyType, setBodyType] = useState(null)
  const [expAvatars, setExpAvatars] = useState(null)

  const launchAvatarSetup = useCallback(() => {
    /* @hint before creating and setup your avatar,
    we need to get the list of available avatars for the user to choose from
    with m.getAvatars() */

    SDK.getAvatars()
      .then((avatars) => {
        // console.log("We got the list of avatars", avatars)
        setAvatars(avatars)
        setModalAvatarSetupVisible(true)
      })
      .catch(() => {
        // console.error("error getting available avatars", message)
      })
  }, [SDK])

  useEffect(() => {
    if (profile?.avatar !== null) {
      setModalAvatarSetupVisible(false)
    } else {
      setModalAvatarSetupVisible(true)
      launchAvatarSetup()
    }
  }, [profile, launchAvatarSetup])

  useEffect(() => {
    setExpAvatars(experiences?.find((e) => e?.configuration?.title === selectedAvatar?.name))
  }, [selectedAvatar, experiences])

  const onAvatarCreate = (name, avatar) => {
    log("Creating avatar " + name, avatar)

    // @hint call cre ateAvatar(name, avatar) to setup the user avatar
    SDK.createAvatar(name, avatar)
      .then(() => {
        // console.log("createAvatar() response", response)
        getProfile() // update profile info
        setModalAvatarSetupVisible(false)
      })
      .catch((message) => {
        console.error("Error setting up user avatar", message)
      })
  }

  return (
    <AvatarContext.Provider
      value={{
        modalAvatarSetupVisible,
        setModalAvatarSetupVisible,
        avatars,
        setAvatars,
        nickname,
        setNickname,
        launchAvatarSetup,
        onAvatarCreate,
        selectedAvatar,
        setSelectedAvatar,
        bodyType,
        setBodyType,
        expAvatars,
      }}
    >
      {children}
    </AvatarContext.Provider>
  )
}

export { AvatarContext, AvatarProvider }
