/**
 * CollectibleMintLoading.jsx
 *
 * The CollectibleMintLoading component displays a loading animation during the NFT minting process.
 *
 * Key functionalities include:
 * - Showing a loading spinner to indicate that the minting process is in progress.
 *
 * The component returns a div containing the loading animation.
 */

import "./collectibleMint.scss"

const CollectibleMintLoading = () => {
  return (
    <div className="mint-state mint-loading full">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default CollectibleMintLoading
