precision highp float;

uniform float uOutlineThickness;
uniform vec2 uSelectedTerritorySize;


varying vec3 vViewPosition;
varying vec3 vNormal;
varying vec3 vWorldPosition;
varying vec3 vPosition;
varying vec2 vUv; 
varying vec2 vTerritoryPosition;


void main() {

  vPosition = position;

	vec3 offsetPosition = position + vec3(0.0, 0.0, 0.01); // Offset Z slightly
    vTerritoryPosition = (offsetPosition.xy / uSelectedTerritorySize.xy) * 2.0 - 1.0;

	vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
	vec4 worldPosition = modelMatrix * vec4(position, 1.0);
 	vec3 expandedPosition = position + vNormal * uOutlineThickness;

 	vWorldPosition = (modelMatrix * vec4(expandedPosition, 1.0)).xyz;
	vViewPosition = -mvPosition.xyz;
	vNormal = normalMatrix * normal;
  vWorldPosition = worldPosition.xyz; 

	gl_Position = projectionMatrix * mvPosition;

	vUv = uv;

}