/**
 * TrackballControls provides interactive camera controls similar to a trackball.
 * It allows users to rotate, zoom, and pan the scene intuitively using mouse and touch events.
 * This class extends Three.js's EventDispatcher, enabling it to dispatch events like 'change', 'start', and 'end'.
 *
 * Usage:
 * - Instantiate TrackballControls with a camera and a DOM element.
 * - Call the 'update' method within the animation loop to apply the controls' effects.
 * - Add event listeners as needed to interact with the controls.
 */

import { EventDispatcher, MOUSE, Quaternion, Vector2, Vector3 } from "three"

const _changeEvent = { type: "change" }
const _startEvent = { type: "start" }
const _endEvent = { type: "end" }

class TrackballControls extends EventDispatcher {
  constructor(camera, domElement) {
    super()

    const scope = this
    const STATE = { NONE: -1, ROTATE: 0, ZOOM: 1, PAN: 2, TOUCH_ROTATE: 3, TOUCH_ZOOM_PAN: 4 }

    this.camera = camera
    this.domElement = domElement
    this.domElement.style.touchAction = "none" // disable touch scroll

    // API

    this.enabled = false
    this.autoRotate = false

    this.screen = { left: 0, top: 0, width: 0, height: 0 }

    this.rotateSpeed = 2.2
    this.zoomSpeed = 1.2
    this.panSpeed = 0.3

    this.noRotate = false
    this.noZoom = false
    this.noPan = false

    this.staticMoving = false
    this.dynamicDampingFactor = 0.05

    this.minDistance = 0
    this.maxDistance = Infinity

    this.keys = ["KeyA" /*A*/, "KeyS" /*S*/, "KeyD" /*D*/]

    this.mouseButtons = { LEFT: MOUSE.ROTATE, MIDDLE: MOUSE.DOLLY, RIGHT: MOUSE.PAN }

    // internals

    this.target = new Vector3()

    const EPS = 0.000001

    const lastPosition = new Vector3(2, 0, 0)
    let lastZoom = 1

    let _state = STATE.NONE,
      _keyState = STATE.NONE,
      _touchZoomDistanceStart = 0,
      _touchZoomDistanceEnd = 0,
      _lastAngle = 0

    const _eye = new Vector3(),
      _movePrev = new Vector2(),
      _moveCurr = new Vector2(),
      _lastAxis = new Vector3(),
      _zoomStart = new Vector2(),
      _zoomEnd = new Vector2(),
      _panStart = new Vector2(),
      _panEnd = new Vector2(),
      _pointers = [],
      _pointerPositions = {}

    // for reset

    this.target0 = this.target.clone()
    this.position0 = this.camera.position.clone()
    this.up0 = this.camera.up.clone()
    this.zoom0 = this.camera.zoom

    // methods

    this.enable = function () {
      // scope.reset();
      scope.enabled = true
    }

    this.disable = function () {
      // scope.save();
      scope.enabled = false
    }

    this.handleResize = function () {
      const box = scope.domElement.getBoundingClientRect()
      // adjustments come from similar code in the jquery offset() function
      const d = scope.domElement.ownerDocument.documentElement
      scope.screen.left = box.left + window.pageXOffset - d.clientLeft
      scope.screen.top = box.top + window.pageYOffset - d.clientTop
      scope.screen.width = box.width
      scope.screen.height = box.height
    }

    const getMouseOnScreen = (function () {
      const vector = new Vector2()

      return function getMouseOnScreen(pageX, pageY) {
        vector.set(
          (pageX - scope.screen.left) / scope.screen.width,
          (pageY - scope.screen.top) / scope.screen.height,
        )

        return vector
      }
    })()

    const getMouseOnCircle = (function () {
      const vector = new Vector2()

      return function getMouseOnCircle(pageX, pageY) {
        vector.set(
          (pageX - scope.screen.width * 0.5 - scope.screen.left) / (scope.screen.width * 0.5),
          (scope.screen.height + 2 * (scope.screen.top - pageY)) / scope.screen.width, // screen.width intentional
        )

        return vector
      }
    })()

    this.rotateCamera = (function () {
      const axis = new Vector3(),
        quaternion = new Quaternion(),
        eyeDirection = new Vector3(),
        cameraUpDirection = new Vector3(),
        cameraSidewaysDirection = new Vector3(),
        moveDirection = new Vector3()

      return function rotateCamera() {
        moveDirection.set(_moveCurr.x - _movePrev.x, _moveCurr.y - _movePrev.y, 0)

        let angle = moveDirection.length()

        if (angle) {
          _eye.copy(scope.camera.position).sub(scope.target)

          eyeDirection.copy(_eye).normalize()
          cameraUpDirection.copy(scope.camera.up).normalize()
          cameraSidewaysDirection.crossVectors(cameraUpDirection, eyeDirection).normalize()

          cameraUpDirection.setLength(_moveCurr.y - _movePrev.y)
          cameraSidewaysDirection.setLength(_moveCurr.x - _movePrev.x)

          moveDirection.copy(cameraUpDirection.add(cameraSidewaysDirection))

          axis.crossVectors(moveDirection, _eye).normalize()

          angle *= scope.rotateSpeed
          quaternion.setFromAxisAngle(axis, angle)

          _eye.applyQuaternion(quaternion)
          scope.camera.up.applyQuaternion(quaternion)

          _lastAxis.copy(axis)
          _lastAngle = angle
        } else if (!scope.staticMoving && _lastAngle) {
          _lastAngle *= Math.sqrt(1.0 - scope.dynamicDampingFactor)
          _eye.copy(scope.camera.position).sub(scope.target)
          quaternion.setFromAxisAngle(_lastAxis, _lastAngle)
          _eye.applyQuaternion(quaternion)
          scope.camera.up.applyQuaternion(quaternion)
        }

        _movePrev.copy(_moveCurr)
      }
    })()

    this.zoomCamera = function () {
      let factor

      if (_state === STATE.TOUCH_ZOOM_PAN) {
        factor = _touchZoomDistanceStart / _touchZoomDistanceEnd
        _touchZoomDistanceStart = _touchZoomDistanceEnd

        if (scope.camera.isPerspectiveCamera) {
          _eye.multiplyScalar(factor)
        } else if (scope.camera.isOrthographicCamera) {
          scope.camera.zoom /= factor
          scope.camera.updateProjectionMatrix()
        } else {
          console.warn("THREE.TrackballControls: Unsupported camera type")
        }
      } else {
        factor = 1.0 + (_zoomEnd.y - _zoomStart.y) * scope.zoomSpeed

        if (factor !== 1.0 && factor > 0.0) {
          if (scope.camera.isPerspectiveCamera) {
            _eye.multiplyScalar(factor)
          } else if (scope.camera.isOrthographicCamera) {
            scope.camera.zoom /= factor
            scope.camera.updateProjectionMatrix()
          } else {
            console.warn("THREE.TrackballControls: Unsupported camera type")
          }
        }

        if (scope.staticMoving) {
          _zoomStart.copy(_zoomEnd)
        } else {
          _zoomStart.y += (_zoomEnd.y - _zoomStart.y) * this.dynamicDampingFactor
        }
      }
    }

    this.panCamera = (function () {
      const mouseChange = new Vector2(),
        cameraUp = new Vector3(),
        pan = new Vector3()

      return function panCamera() {
        mouseChange.copy(_panEnd).sub(_panStart)

        if (mouseChange.lengthSq()) {
          if (scope.camera.isOrthographicCamera) {
            const scale_x =
              (scope.camera.right - scope.camera.left) /
              scope.camera.zoom /
              scope.domElement.clientWidth
            const scale_y =
              (scope.camera.top - scope.camera.bottom) /
              scope.camera.zoom /
              scope.domElement.clientWidth

            mouseChange.x *= scale_x
            mouseChange.y *= scale_y
          }

          mouseChange.multiplyScalar(_eye.length() * scope.panSpeed)

          pan.copy(_eye).cross(scope.camera.up).setLength(mouseChange.x)
          pan.add(cameraUp.copy(scope.camera.up).setLength(mouseChange.y))

          scope.camera.position.add(pan)
          scope.target.add(pan)

          if (scope.staticMoving) {
            _panStart.copy(_panEnd)
          } else {
            _panStart.add(
              mouseChange.subVectors(_panEnd, _panStart).multiplyScalar(scope.dynamicDampingFactor),
            )
          }
        }
      }
    })()

    this.checkDistances = function () {
      if (!scope.noZoom || !scope.noPan) {
        if (_eye.lengthSq() > scope.maxDistance * scope.maxDistance) {
          scope.camera.position.addVectors(scope.target, _eye.setLength(scope.maxDistance))
          _zoomStart.copy(_zoomEnd)
        }

        if (_eye.lengthSq() < scope.minDistance * scope.minDistance) {
          scope.camera.position.addVectors(scope.target, _eye.setLength(scope.minDistance))
          _zoomStart.copy(_zoomEnd)
        }
      }
    }

    this.update = function () {
      _eye.subVectors(scope.camera.position, scope.target)

      if (this.autoRotate && this.enabled) {
        this.autoRotateFactor = Math.min(1, this.autoRotateFactor + 0.01)
        _moveCurr.set(_moveCurr.x - this.autoRotateSpeed * this.autoRotateFactor, _moveCurr.y)
      } else {
        this.autoRotateFactor = 0
      }

      if (!scope.noRotate) {
        scope.rotateCamera()
      }

      if (!scope.noZoom) {
        scope.zoomCamera()
      }

      if (!scope.noPan) {
        scope.panCamera()
      }

      scope.camera.position.addVectors(scope.target, _eye)

      if (scope.camera.isPerspectiveCamera) {
        scope.checkDistances()

        scope.camera.lookAt(scope.target)

        if (lastPosition.distanceToSquared(scope.camera.position) > EPS) {
          scope.dispatchEvent(_changeEvent)

          lastPosition.copy(scope.camera.position)
        }
      } else if (scope.camera.isOrthographicCamera) {
        scope.camera.lookAt(scope.target)

        if (
          lastPosition.distanceToSquared(scope.camera.position) > EPS ||
          lastZoom !== scope.camera.zoom
        ) {
          scope.dispatchEvent(_changeEvent)

          lastPosition.copy(scope.camera.position)
          lastZoom = scope.camera.zoom
        }
      } else {
        console.warn("THREE.TrackballControls: Unsupported camera type")
      }
    }

    this.reset = function () {
      _state = STATE.NONE
      _keyState = STATE.NONE

      scope.target.copy(scope.target0)
      scope.camera.position.copy(scope.position0)
      scope.camera.up.copy(scope.up0)
      scope.camera.zoom = scope.zoom0

      scope.camera.updateProjectionMatrix()

      _eye.subVectors(scope.camera.position, scope.target)

      scope.camera.lookAt(scope.target)

      scope.dispatchEvent(_changeEvent)

      lastPosition.copy(scope.camera.position)
      lastZoom = scope.camera.zoom
    }

    this.save = function () {
      scope.target0.copy(scope.target)
      scope.position0.copy(scope.camera.position)
      scope.up0.copy(scope.camera.up)
      scope.zoom0 = scope.camera.zoom
    }

    // listeners

    function onPointerDown(event) {
      if (scope.enabled === false) return

      if (_pointers.length === 0) {
        scope.domElement.setPointerCapture(event.pointerId)

        scope.domElement.addEventListener("pointermove", onPointerMove)
        scope.domElement.addEventListener("pointerup", onPointerUp)
      }

      //

      addPointer(event)

      if (event.pointerType === "touch") {
        onTouchStart(event)
      } else {
        onMouseDown(event)
      }
    }

    function onPointerMove(event) {
      if (scope.enabled === false) return

      if (event.pointerType === "touch") {
        onTouchMove(event)
      } else {
        onMouseMove(event)
      }
    }

    function onPointerUp(event) {
      if (scope.enabled === false) return

      if (event.pointerType === "touch") {
        onTouchEnd(event)
      } else {
        onMouseUp()
      }

      //

      removePointer(event)

      if (_pointers.length === 0) {
        scope.domElement.releasePointerCapture(event.pointerId)

        scope.domElement.removeEventListener("pointermove", onPointerMove)
        scope.domElement.removeEventListener("pointerup", onPointerUp)
      }
    }

    function onPointerCancel(event) {
      removePointer(event)
    }

    function keydown(event) {
      if (scope.enabled === false) return

      window.removeEventListener("keydown", keydown)

      if (_keyState !== STATE.NONE) {
        return
      } else if (event.code === scope.keys[STATE.ROTATE] && !scope.noRotate) {
        _keyState = STATE.ROTATE
      } else if (event.code === scope.keys[STATE.ZOOM] && !scope.noZoom) {
        _keyState = STATE.ZOOM
      } else if (event.code === scope.keys[STATE.PAN] && !scope.noPan) {
        _keyState = STATE.PAN
      }
    }

    function keyup() {
      if (scope.enabled === false) return

      _keyState = STATE.NONE

      window.addEventListener("keydown", keydown)
    }

    function onMouseDown(event) {
      if (_state === STATE.NONE) {
        switch (event.button) {
          case scope.mouseButtons.LEFT:
            _state = STATE.ROTATE
            break

          case scope.mouseButtons.MIDDLE:
            _state = STATE.ZOOM
            break

          case scope.mouseButtons.RIGHT:
            _state = STATE.PAN
            break

          default:
            _state = STATE.NONE
        }
      }

      const state = _keyState !== STATE.NONE ? _keyState : _state

      if (state === STATE.ROTATE && !scope.noRotate) {
        _moveCurr.copy(getMouseOnCircle(event.pageX, event.pageY))
        _movePrev.copy(_moveCurr)
      } else if (state === STATE.ZOOM && !scope.noZoom) {
        _zoomStart.copy(getMouseOnScreen(event.pageX, event.pageY))
        _zoomEnd.copy(_zoomStart)
      } else if (state === STATE.PAN && !scope.noPan) {
        _panStart.copy(getMouseOnScreen(event.pageX, event.pageY))
        _panEnd.copy(_panStart)
      }

      scope.dispatchEvent(_startEvent)
    }

    function onMouseMove(event) {
      const state = _keyState !== STATE.NONE ? _keyState : _state

      if (state === STATE.ROTATE && !scope.noRotate) {
        _movePrev.copy(_moveCurr)
        _moveCurr.copy(getMouseOnCircle(event.pageX, event.pageY))
      } else if (state === STATE.ZOOM && !scope.noZoom) {
        _zoomEnd.copy(getMouseOnScreen(event.pageX, event.pageY))
      } else if (state === STATE.PAN && !scope.noPan) {
        _panEnd.copy(getMouseOnScreen(event.pageX, event.pageY))
      }
    }

    function onMouseUp() {
      _state = STATE.NONE

      scope.dispatchEvent(_endEvent)
    }

    function onMouseWheel(event) {
      if (scope.enabled === false) return

      if (scope.noZoom === true) return

      event.preventDefault()

      switch (event.deltaMode) {
        case 2:
          // Zoom in pages
          _zoomStart.y -= event.deltaY * 0.025
          break

        case 1:
          // Zoom in lines
          _zoomStart.y -= event.deltaY * 0.01
          break

        default:
          // undefined, 0, assume pixels
          _zoomStart.y -= event.deltaY * 0.00025
          break
      }

      scope.dispatchEvent(_startEvent)
      scope.dispatchEvent(_endEvent)
    }

    function onTouchStart(event) {
      trackPointer(event)
      let dx
      let dy
      let x
      let y
      switch (_pointers.length) {
        case 1:
          _state = STATE.TOUCH_ROTATE
          _moveCurr.copy(getMouseOnCircle(_pointers[0].pageX, _pointers[0].pageY))
          _movePrev.copy(_moveCurr)
          break

        default: // 2 or more
          _state = STATE.TOUCH_ZOOM_PAN
          dx = _pointers[0].pageX - _pointers[1].pageX
          dy = _pointers[0].pageY - _pointers[1].pageY
          _touchZoomDistanceEnd = _touchZoomDistanceStart = Math.sqrt(dx * dx + dy * dy)

          x = (_pointers[0].pageX + _pointers[1].pageX) / 2
          y = (_pointers[0].pageY + _pointers[1].pageY) / 2
          _panStart.copy(getMouseOnScreen(x, y))
          _panEnd.copy(_panStart)
          break
      }

      scope.dispatchEvent(_startEvent)
    }

    function onTouchMove(event) {
      trackPointer(event)

      let position
      let dx
      let dy
      let x
      let y

      switch (_pointers.length) {
        case 1:
          _movePrev.copy(_moveCurr)
          _moveCurr.copy(getMouseOnCircle(event.pageX, event.pageY))
          break

        default: // 2 or more
          position = getSecondPointerPosition(event)

          dx = event.pageX - position.x
          dy = event.pageY - position.y
          _touchZoomDistanceEnd = Math.sqrt(dx * dx + dy * dy)

          x = (event.pageX + position.x) / 2
          y = (event.pageY + position.y) / 2
          _panEnd.copy(getMouseOnScreen(x, y))
          break
      }
    }

    function onTouchEnd(event) {
      switch (_pointers.length) {
        case 0:
          _state = STATE.NONE
          break

        case 1:
          _state = STATE.TOUCH_ROTATE
          _moveCurr.copy(getMouseOnCircle(event.pageX, event.pageY))
          _movePrev.copy(_moveCurr)
          break

        case 2:
          _state = STATE.TOUCH_ZOOM_PAN

          for (let i = 0; i < _pointers.length; i++) {
            if (_pointers[i].pointerId !== event.pointerId) {
              const position = _pointerPositions[_pointers[i].pointerId]
              _moveCurr.copy(getMouseOnCircle(position.x, position.y))
              _movePrev.copy(_moveCurr)
              break
            }
          }
          break

        default:
          _state = STATE.NONE
      }

      scope.dispatchEvent(_endEvent)
    }

    function contextmenu(event) {
      if (scope.enabled === false) return

      event.preventDefault()
    }

    function addPointer(event) {
      _pointers.push(event)
    }

    function removePointer(event) {
      delete _pointerPositions[event.pointerId]

      for (let i = 0; i < _pointers.length; i++) {
        if (_pointers[i].pointerId === event.pointerId) {
          _pointers.splice(i, 1)
          return
        }
      }
    }

    function trackPointer(event) {
      let position = _pointerPositions[event.pointerId]

      if (position === undefined) {
        position = new Vector2()
        _pointerPositions[event.pointerId] = position
      }

      position.set(event.pageX, event.pageY)
    }

    function getSecondPointerPosition(event) {
      const pointer = event.pointerId === _pointers[0].pointerId ? _pointers[1] : _pointers[0]

      return _pointerPositions[pointer.pointerId]
    }

    this.dispose = function () {
      scope.domElement.removeEventListener("contextmenu", contextmenu)

      scope.domElement.removeEventListener("pointerdown", onPointerDown)
      scope.domElement.removeEventListener("pointercancel", onPointerCancel)
      scope.domElement.removeEventListener("wheel", onMouseWheel)

      scope.domElement.removeEventListener("pointermove", onPointerMove)
      scope.domElement.removeEventListener("pointerup", onPointerUp)

      window.removeEventListener("keydown", keydown)
      window.removeEventListener("keyup", keyup)
    }

    this.domElement.addEventListener("contextmenu", contextmenu)

    this.domElement.addEventListener("pointerdown", onPointerDown)
    this.domElement.addEventListener("pointercancel", onPointerCancel)
    this.domElement.addEventListener("wheel", onMouseWheel, { passive: false })

    window.addEventListener("keydown", keydown)
    window.addEventListener("keyup", keyup)

    this.handleResize()

    // force an update at start
    this.update()
  }
}

export { TrackballControls }
