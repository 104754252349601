/**
 * CollectibleMintError.jsx
 *
 * The CollectibleMintError component handles the display of error messages during the NFT minting process.
 * It provides an option for the user to go back and attempt to claim the collectible again.
 *
 * Key functionalities include:
 * - Displaying the error message from the minting process.
 * - Providing a button to go back and retry the minting process.
 * - Managing the visibility of the claim modal and resetting the minting state.
 *
 * The component returns a modal with the error message and a button to navigate back to the claim process.
 */

import { useRooms } from "@/hooks/metaverse/useRooms"
import { useAvatarSetup } from "@/hooks/onboarding/useAvatarSetup"
import { useClaimSetup } from "@/hooks/onboarding/useClaimSetup.jsx"
import "./collectibleMint.scss"

const CollectibleMintError = () => {
  const { setModalClaimVisible, mintResult, onMintResultClose, displayCollectibleMint } =
    useClaimSetup()
  const { expAvatars } = useAvatarSetup()
  const { expQuests } = useRooms()

  const handleClickGoBack = () => {
    onMintResultClose()
    setModalClaimVisible(true)
    displayCollectibleMint(expAvatars || expQuests)
  }

  return (
    <>
      <section className="mint-error modal-wrapper">
        <div className="blur deep-blur"></div>
        <div className="modal-popup mint-result">
          <div className="modal-body mint-result-body">
            <div className="modal-close"></div>
            <h1>Error !</h1>
            {mintResult?.message}
            <button className="button primary" onClick={() => handleClickGoBack()}>
              Go back to claim
            </button>
          </div>
        </div>
      </section>
    </>
  )
}

export default CollectibleMintError
