/**
 * SearchBar.js
 * The SearchBar component is serving as the central hub for search functionality enhanced with AI
 */

import { useCallback, useEffect, useRef, useState } from "react"
import { ROUTES, CURRENT_PLANET_VIEW, CURRENT_EXPERIENCE_TYPE } from "@/constants/constants"
import { useLocation } from "react-router-dom"
import { useInterface } from "@/hooks/interface/useInterface"
import { usePlanet } from "@/hooks/interface/usePlanet"
import { useRooms } from "@/hooks/metaverse/useRooms"
import { useSearch } from "@/hooks/interface/useSearch"
import SearchInput from "./components/SearchInput/SearchInput.jsx"
// import SearchResults from "./components/SearchResults/SearchResults.jsx"
import "./searchbar.scss"
import SearchDashboard from "./components/SearchDashboard/SearchDashboard.jsx"

import UploadRender from "./components/Upload/UploadRender.jsx"

const SearchBar = () => {
  const location = useLocation()
  const { currentExperienceType, currentPlanetView } = usePlanet()
  const { showPOI, roomReady } = useRooms()
  const {
    isAuthenticated,
    setFirstContentIsDefault,
    setSecondContentIsDefault,
    setFirstContentIsCollapsed,
    setSecondContentIsCollapsed,
    setThirdContentIsCollapsed,
    setPdfRender,
    setVideoRender,
    displaySearchResults,
    setDisplaySearchResults,
    setInputValue,
    setMediaClicked,
    viewMode,
    setViewMode,
    isUploadOpen,
    setIsUploadOpen,
    isExpanded,
    setIsExpanded,
    setDataResponse,
    setPreviousInputValue,
    sendValue,
    previousInputValue,
  } = useSearch()
  const {
    setSearchBarIsVisible,
    setActiveItemMenu,
    sideMenuOrganizationIsOpen,
    setSideMenuOrganizationIsOpen,
    sideMenuAccountIsOpen,
    setSideMenuAccountIsOpen,
    setSubMenuIsVisible,
    setStartTransitionAnimation,
    startRecording,
    setStartRecording,
    transcript,
    setTranscript,
    // responseAfterSendingAudio,
    // responseAudioIsPending,
  } = useInterface()

  const searchDashboardRef = useRef(null)
  const searchInputsRef = useRef(null)

  const [_enterIsPressed, setEnterIsPressed] = useState(false)
  const [_sendValueIsClicked, setSendValueIsClicked] = useState(false)
  const [_, setResult] = useState(false)

  const [submitForm, setSubmitForm] = useState(null)
  const resetStates = useCallback(() => {
    setPreviousInputValue(sendValue)

    setDisplaySearchResults(false)
    setSearchBarIsVisible(false)
    setIsExpanded(false)
    setInputValue("")
    setIsUploadOpen(false)
    setFirstContentIsDefault(true)
    setSecondContentIsDefault(true)
    setFirstContentIsCollapsed(false)
    setSecondContentIsCollapsed(false)
    setThirdContentIsCollapsed(false)
    setMediaClicked(false)
    setViewMode("")
    setTranscript("")
    setPdfRender(false)
    setVideoRender(false)

    if (startRecording) setStartRecording(false)
  }, [
    setDisplaySearchResults,
    setSearchBarIsVisible,
    setInputValue,
    setFirstContentIsDefault,
    setSecondContentIsDefault,
    setFirstContentIsCollapsed,
    setSecondContentIsCollapsed,
    setThirdContentIsCollapsed,
    setTranscript,
    setPdfRender,
    setVideoRender,
    startRecording,
    setStartRecording,
    setMediaClicked,
    setIsUploadOpen,
    setIsExpanded,
    setViewMode,
    setPreviousInputValue,
    sendValue,
  ])

  // Effect to compare values after state updates
  useEffect(() => {
    const normalizeString = (str) =>
      str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/[^\w\s]|_/g, "")
        .replace(/\s+/g, " ")
        .trim()

    const areStringsSimilar = (str1, str2) =>
      normalizeString(str1 || "") === normalizeString(str2 || "")

    const sameInputEntered = areStringsSimilar(
      sendValue?.toString() || "",
      previousInputValue?.toString() || "",
    )

    if (!sameInputEntered) {
      setDataResponse(null)
    }
  }, [previousInputValue, sendValue, setDataResponse])

  // Handles click outside the dashboard
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchDashboardRef.current &&
        !searchDashboardRef.current.contains(event.target) &&
        searchInputsRef.current &&
        !searchInputsRef.current.contains(event.target)
      ) {
        resetStates()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [displaySearchResults, resetStates])

  // Handle upload (publish) button opening
  const handleUploadOpen = () => {
    setDisplaySearchResults(true)
    setViewMode("upload")
    setIsUploadOpen(true)
    setIsExpanded(false)
  }

  const shouldShowSearch = () => {
    const isMenuExpanded = !sideMenuOrganizationIsOpen && !sideMenuAccountIsOpen
    const isSubexperience = location.pathname.includes(Object.values(ROUTES.RENAULT_SUBEXPERIENCES))
    const isInExperience =
      currentPlanetView === CURRENT_PLANET_VIEW.IN_EXPERIENCE &&
      (location.pathname.includes("engineering") ||
        (currentExperienceType === CURRENT_EXPERIENCE_TYPE.IN_3D_EXPERIENCE &&
          !showPOI &&
          roomReady) ||
        location.pathname.includes(ROUTES.RENAULT_EXPERIENCES.FLINS) ||
        location.pathname.includes(ROUTES.RENAULT_EXPERIENCES.DESIGN_TWIN) ||
        location.pathname.includes(ROUTES.RENAULT_EXPERIENCES.CONTROL_ROOM) ||
        isSubexperience)

    const isInOrbit = currentPlanetView === CURRENT_PLANET_VIEW.IN_ORBIT
    const isInContinent = currentPlanetView === CURRENT_PLANET_VIEW.IN_CONTINENT

    return {
      shouldShow: isMenuExpanded,
      shouldShowBar: isInExperience || isInOrbit || isInContinent,
    }
  }

  const { shouldShow, shouldShowBar } = shouldShowSearch()

  // set voice transcription to search field
  useEffect(() => {
    if (transcript) {
      setInputValue(transcript)
    }
  }, [setInputValue, transcript])

  return (
    <section id="search-bar" style={!isAuthenticated ? { display: "none" } : { display: "flex" }}>
      <div className={`wrapper ${displaySearchResults ? "wrapper--open" : ""}`}>
        <div
          className={`search-bar__container ${isExpanded ? "search-bar__container--expanded" : ""} ${isUploadOpen ? "search-bar__container--upload-open" : ""} ${shouldShowBar ? "show-bar" : "hide-bar"} ${shouldShow ? "show" : "hide"}`}
        >
          <div
            ref={searchInputsRef}
            className={`search-bar__box ${isUploadOpen ? "search-bar__box--upload-open" : ""}`}
          >
            {/* Search Input */}
            <SearchInput
              setIsExpanded={setIsExpanded}
              isExpanded={isExpanded}
              isAuthenticated={isAuthenticated}
              setDisplaySearchResults={setDisplaySearchResults}
              setEnterIsPressed={setEnterIsPressed}
              setResult={setResult}
              setSendValueIsClicked={setSendValueIsClicked}
              setSideMenuOrganizationIsOpen={setSideMenuOrganizationIsOpen}
              setSideMenuAccountIsOpen={setSideMenuAccountIsOpen}
              setActiveItemMenu={setActiveItemMenu}
              setSubMenuIsVisible={setSubMenuIsVisible}
              setStartTransitionAnimation={setStartTransitionAnimation}
              resetStates={resetStates}
              setViewMode={setViewMode}
            />

            {/* Publish Button */}
            <button
              className="search-bar__publish-button"
              onClick={() =>
                viewMode !== "upload" && !isUploadOpen ? handleUploadOpen() : setSubmitForm(true)
              }
            >
              Publish
            </button>
          </div>

          <div
            ref={searchDashboardRef}
            className={`search-dashboard ${viewMode === "search" || viewMode === "upload" ? "search-dashboard--open" : ""}`}
          >
            {viewMode === "search" && <SearchDashboard />}

            {viewMode === "upload" && (
              <UploadRender submitForm={submitForm} setSubmitForm={setSubmitForm} />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SearchBar
