/**
 * CustomCard.jsx
 *
 * The CustomCard component is designed to display a customizable card within the application.
 * It manages various visual effects and state updates to enhance the user experience.
 *
 * Key functionalities include:
 * - Triggering a stacking effect when the search bar result becomes visible.
 * - Updating the card's visibility class and box enlargement style based on specific states.
 * - Adjusting the social icon style with a delay for visibility changes.
 *
 * The component renders a div containing all elements of the card, including the title, date, description, and avatar image, with styles and behaviors that adapt based on the provided props.
 */
import { CARD_TYPE } from "@/constants/constants"
import "./custom-card.scss"

const CustomCard = ({ id, title, date, description, circleClassStyle, cardType, avatarImg }) => {
  const customCardStyle = () => {
    let className = `custom-card item-${id}`

    if (cardType === CARD_TYPE.FAVORITE || cardType === CARD_TYPE.EXPERIENCE) {
      className += " organization-card"
    }

    return className
  }

  return (
    <div className={customCardStyle()}>
      <div className={`content-box `}>
        <div
          className={`box ${cardType === CARD_TYPE.FAVORITE || cardType === CARD_TYPE.EXPERIENCE ? "organization-card-box" : ""}`}
        >
          {avatarImg ? (
            <img src={avatarImg} alt="avatar" className="img-circle" />
          ) : (
            <div className={`circle ${circleClassStyle}`} />
          )}

          <div className="content-card">
            <div className="title">{title}</div>
            <div className="date">{date}</div>
            <div className="description">{description}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CustomCard
