/**
 * UsePreviousLocation.jsx
 *
 * Custom hook to keep track of the previous location using React hooks
 * @returns The previous location
 */

import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"

export const usePreviousLocation = () => {
  const location = useLocation()
  const prevLocations = useRef([location])

  useEffect(() => {
    if (location !== prevLocations.current[0]) {
      prevLocations.current.unshift(location)
      if (prevLocations.current.length > 2) {
        prevLocations.current.pop()
      }
    }
  }, [location])

  return prevLocations.current[1]
}
