/**
 * MenuDesktop.jsx
 *
 * The MenuDesktop component manages the desktop menu functionality within the application.
 * It controls the visibility, animation, and interactions of the menu and its submenus.
 *
 * Key functionalities include:
 * - Managing submenu visibility and animations based on various states and conditions.
 * - Handling user interactions such as clicks and hovering, including automatic submenu closure after inactivity.
 * - Setting the menu's position, status, and animations based on the current experience, location path, and route type.
 * - Updating the menu's appearance and behavior when points of interest (POI) are visible.
 * - Providing multiple functions for handling user actions like login, logout, submenu collapses, and overall visibility.
 * - Rendering additional elements like user information, login states, and background blur effects.
 *
 * The component returns a comprehensive navigation element that includes various menu items and handles complex user interactions.
 */

import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Cookies from "js-cookie"
import { Layout, Spin } from "antd"
import { useRouteType } from "@/hooks/interface/useRouteType"
import { useSDK } from "@/hooks/metaverse/useSDK"
import { useAuth } from "@/hooks/onboarding/useAuth"
import { useInterface } from "@/hooks/interface/useInterface"
import { useClaimSetup } from "@/hooks/onboarding/useClaimSetup.jsx"
import { usePlanet } from "@/hooks/interface/usePlanet"
import { useRooms } from "@/hooks/metaverse/useRooms"
import { useSearch } from "@/hooks/interface/useSearch.jsx"
import { useGetCreateNewDiscussion } from "@/data/hooks.js"

import {
  ITEMS_MENU,
  ROUTES,
  CATEGORY_ITEMS,
  TIMER_TIMEOUT,
  CURRENT_PLANET,
  CURRENT_PLANET_VIEW,
  CURRENT_EXPERIENCE_TYPE,
  CURRENT_EXPLORATION_LEVEL,
} from "@/constants/constants"
import SideOrganizationMenu from "./modules/Organization/SideOrganizationMenu"
import SideAccountMenu from "./modules/Account/SideAccountMenu"
import getMenuDropdownData from "./MenuData.jsx"
import "./menu.scss"

const { Sider } = Layout

const MenuDesktop = ({ isBrand, isDepartment, setDashboardOpen }) => {
  const location = useLocation()
  const SDK = useSDK()
  const navigate = useNavigate()
  const { routeType } = useRouteType()
  const {
    logged,
    consented,
    profile,
    setModalConsentVisible,
    loadingUserProfile,
    setLoadingUserProfile,
  } = useAuth()
  const {
    sideMenuOrganizationIsOpen,
    setSideMenuOrganizationIsOpen,
    sideMenuAccountIsOpen,
    setSideMenuAccountIsOpen,
    subMenuIsVisible,
    setSubMenuIsVisible,
    activeItemMenu,
    setActiveItemMenu,
    setSubTopicMenuPublicIsVisible,
    startTransitionAnimation,
    setStartTransitionAnimation,
    setCategorySelected,
    menuSelected,
    setMenuSelected,
    showContent,
  } = useInterface()
  const { currentPlanetView, currentExperienceType, currentExplorationLevel, currentPlanet } =
    usePlanet()
  const { setModalClaimVisible } = useClaimSetup()
  const {
    showScene3D,
    showPOI,
    setShowScene3D,
    setShowPOI,
    setShowRenaultRooms,
    setRoomsFromPOI,
    setRoomReady,
    roomReady,
  } = useRooms()

  const { data: createNewDiscussion } = useGetCreateNewDiscussion(logged)

  const { experience, subexperience } = useParams()
  const [infoBull, setInfoBull] = useState(null)
  const [infoBullVisible, setInfoBullVisible] = useState(false)
  const [menuAnimation, setMenuAnimation] = useState("")
  const [dropdownMenuPosition, setDropdownMenuPosition] = useState("")
  const [expandBlur, setExpandBlur] = useState("")
  const [isExperienceRoom, setIsExperienceRoom] = useState(false)
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false)
  const [reverseAnimation, setReverseAnimation] = useState("")
  const isRenaulution = currentPlanet === CURRENT_PLANET.IN_RENAULUTION
  const isSideMenuOpen = sideMenuOrganizationIsOpen || sideMenuAccountIsOpen
  const menuId = "global-menu-id"
  const usernameId = "username-id"
  const leftMenuId = "left-menu-id"
  const rightMenuId = "right-menu-id"
  const firstBlurMenuAnimationRef = useRef(true)
  const storeGlobalMenuTimestampRef = useRef({
    id: null,
    timestamp: null,
  })
  const storeRightMenuTimestampRef = useRef({
    id: null,
    timestamp: null,
  })
  const timeoutId = useRef(null)
  const menuRef = useRef(null)
  const openSubMenu = () => setSubMenuIsVisible("item-visible")
  const closeSubMenu = useCallback(() => {
    setSubMenuIsVisible("item-hidden")
    if (isSubMenuVisible) {
      setStartTransitionAnimation("decrease-blur-submenu")
    } else {
      setStartTransitionAnimation("")
    }
  }, [setSubMenuIsVisible, setStartTransitionAnimation, isSubMenuVisible])
  const { displaySearchResults, setIsAuthenticated } = useSearch()
  // Set submenu visibility based on subMenuIsVisible state
  useEffect(() => {
    setIsSubMenuVisible(subMenuIsVisible === "item-visible")
  }, [subMenuIsVisible])

  // Set reverse animation based on submenu visibility
  useEffect(() => {
    setReverseAnimation(isSubMenuVisible ? "" : "reverse")
  }, [isSubMenuVisible])

  const resetTimer = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
    timeoutId.current = setTimeout(() => {
      closeSubMenu()
    }, 2000)
  }, [closeSubMenu])

  // Close submenu if no interaction for 2s
  useEffect(() => {
    if (isSideMenuOpen) return

    const handleUserInteraction = (event) => {
      if (menuRef.current && menuRef.current.contains(event.target)) {
        resetTimer()
      }
    }

    const events = ["mousemove", "mousedown", "touchstart", "keydown"]
    events.forEach((event) => document.addEventListener(event, handleUserInteraction))
    resetTimer()

    return () => {
      events.forEach((event) => document.removeEventListener(event, handleUserInteraction))
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
    }
  }, [resetTimer, isSideMenuOpen])

  // Set reverse animation and hide submenu based on specific conditions
  useEffect(() => {
    const shouldSetReverseAnimation =
      isSubMenuVisible &&
      (currentPlanetView === CURRENT_PLANET_VIEW.ENTERING_CONTINENT ||
        currentPlanetView === CURRENT_PLANET_VIEW.ENTERING_ORBIT ||
        currentExplorationLevel === CURRENT_EXPLORATION_LEVEL.ENTERING_ORBIT ||
        currentExplorationLevel === CURRENT_EXPLORATION_LEVEL.ENTERING_CONTINENTAL_NEWS ||
        currentExplorationLevel === CURRENT_EXPLORATION_LEVEL.ENTERING_DEPARTMENTS ||
        currentExplorationLevel === CURRENT_EXPLORATION_LEVEL.ENTERING_GATES ||
        currentPlanet === CURRENT_PLANET.ENTERING_XXXX ||
        currentPlanet === CURRENT_PLANET.ENTERING_YYYY)

    if (shouldSetReverseAnimation) {
      setReverseAnimation("reverse")
      setSubMenuIsVisible("item-hidden")
      setStartTransitionAnimation("")
    }
  }, [
    isSubMenuVisible,
    currentPlanetView,
    currentPlanet,
    currentExplorationLevel,
    setSubMenuIsVisible,
    setStartTransitionAnimation,
  ])

  // Set status when POI (Point of Interest) is visible
  useEffect(() => {
    if (showPOI) {
      closeSubMenu()
      setStartTransitionAnimation("")
      setActiveItemMenu("")
    }
  }, [showPOI, setStartTransitionAnimation, setActiveItemMenu, closeSubMenu])

  // Set dropdown menu position based on current experience and location path
  useEffect(() => {
    const experiences = [
      ROUTES.RENAULT_EXPERIENCES.FLINS,
      ROUTES.RENAULT_EXPERIENCES.DESIGN_TWIN,
      ROUTES.RENAULT_EXPERIENCES.NFT_SHOWROOM,
      ROUTES.RENAULT_EXPERIENCES.KNOWLEDGE,
      ROUTES.RENAULT_EXPERIENCES.CONTROL_ROOM,
    ]

    const isExperienceIncluded = experiences.includes(experience)
    const isSubexperienceIncluded = location.pathname.includes(
      Object.values(ROUTES.RENAULT_SUBEXPERIENCES),
    )

    if (isExperienceIncluded || isSubexperienceIncluded) {
      setDropdownMenuPosition("room-position")
    } else {
      setDropdownMenuPosition("vertical-centering")
    }
  }, [experience, location.pathname])

  // Set Experience Room based on current planet view, experience, and subexperience
  useEffect(() => {
    const ROUTES_RENAULT_EXPERIENCES = [
      ROUTES.RENAULT_EXPERIENCES.CAR_WALK,
      ROUTES.RENAULT_EXPERIENCES.CONTROL_ROOM,
      ROUTES.RENAULT_EXPERIENCES.FLINS,
      ROUTES.RENAULT_EXPERIENCES.MEETING,
      ROUTES.RENAULT_EXPERIENCES.DESIGN_TWIN,
      ROUTES.RENAULT_EXPERIENCES.KNOWLEDGE,
      ROUTES.RENAULT_EXPERIENCES.NFT_SHOWROOM,
    ]

    const isExperienceIncluded = ROUTES_RENAULT_EXPERIENCES.includes(experience)
    const isSubexperienceIncluded = Object.values(ROUTES.RENAULT_SUBEXPERIENCES).includes(
      subexperience,
    )

    if (
      currentPlanetView === CURRENT_PLANET_VIEW.IN_EXPERIENCE &&
      (isExperienceIncluded || isSubexperienceIncluded)
    ) {
      setIsExperienceRoom(true)
    } else {
      setIsExperienceRoom(false)
    }
  }, [currentPlanetView, experience, subexperience])

  // Set blur expansion based on submenu visibility, side menu status, and route type
  useEffect(() => {
    const isFirstOpening = firstBlurMenuAnimationRef.current === true

    if (isFirstOpening && isSubMenuVisible) {
      firstBlurMenuAnimationRef.current = false
      setExpandBlur("")
    }

    if (!isFirstOpening) {
      if (isSubMenuVisible && isSideMenuOpen && routeType !== "News") {
        setExpandBlur("expand-side-menu")
      } else if (isSubMenuVisible && !isSideMenuOpen && routeType !== "News") {
        setExpandBlur("decrease-side-menu")
      } else if (!isSubMenuVisible && !isSideMenuOpen) {
        setExpandBlur("")
        firstBlurMenuAnimationRef.current = true
      }
    }
  }, [isSideMenuOpen, isSubMenuVisible, firstBlurMenuAnimationRef, routeType])

  // Set menu animation based on various state and route conditions
  useEffect(() => {
    const is3DExperienceVisible =
      currentExperienceType === CURRENT_EXPERIENCE_TYPE.IN_3D_EXPERIENCE && roomReady

    const isExperienceOrSubexperienceVisible =
      ((experience === ROUTES.RENAULT_EXPERIENCES.FLINS ||
        experience === ROUTES.RENAULT_EXPERIENCES.DESIGN_TWIN ||
        experience === ROUTES.RENAULT_EXPERIENCES.CONTROL_ROOM) &&
        showContent) ||
      Object.values(ROUTES.RENAULT_SUBEXPERIENCES).includes(subexperience)

    if (is3DExperienceVisible || isExperienceOrSubexperienceVisible) {
      setMenuAnimation("is-visible")
    } else {
      setMenuAnimation("")
    }
  }, [currentExperienceType, roomReady, experience, showContent, subexperience])

  const displayUsername = () => {
    if (profile?.email) {
      return profile?.email.split("@")[0].split("+")[0].split(".")[0]
    }

    return "username"
  }

  const infoBullIsVisible = () => {
    if (infoBullVisible) {
      return "info-bull is-visible"
    }

    return "info-bull is-hidden"
  }

  const closeSideMenu = () => {
    setSideMenuOrganizationIsOpen(false)
    setSideMenuAccountIsOpen(false)
    setActiveItemMenu("")
    setMenuSelected("")
  }

  const handleClickLogin = () => {
    if (!logged) {
      SDK.displayLogin()
    } else if (logged && !consented) {
      setModalConsentVisible(true)
    } else {
      alert("An error has occured, please reload")
    }
  }

  useEffect(() => {
    if (logged) {
      setIsAuthenticated(true)
      return
    }
    setIsAuthenticated(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged])

  useMemo(() => {
    if (createNewDiscussion) {
      Cookies.set("discussionId", createNewDiscussion?.discussion_id)
    }
  }, [createNewDiscussion])

  const handleClickDressingRoom = () => {
    if (profile?.avatar != null) {
      if (isSubMenuVisible) setSubMenuIsVisible("item-hidden")
      setSideMenuAccountIsOpen(false)
      setSideMenuOrganizationIsOpen(false)
      navigate(ROUTES.DRESSING_ROOM.path)
      setModalClaimVisible(false)
      setDashboardOpen(false)
      setActiveItemMenu(ITEMS_MENU.DRESSING)
    } else {
      setInfoBull("You must create and claim your avatar first")
      setInfoBullVisible(true)
      setTimeout(() => {
        setInfoBullVisible(false)
      }, TIMER_TIMEOUT.DISPLAY_INFO_BULL)
    }
  }

  const handleClickLogout = () => {
    SDK.logout()
    Cookies.remove("discussionId", { path: "/" })
    Cookies.remove("jwt", { path: "/" })
    localStorage.clear()
    setLoadingUserProfile(true)
    if (isSubMenuVisible) setSubMenuIsVisible("item-hidden")
    setSideMenuAccountIsOpen(false)
    setSideMenuOrganizationIsOpen(false)
    setActiveItemMenu("")
    navigate(ROUTES.HOME)
    window.location.reload()
  }

  const handleClickOrganizationMenuCollapse = (e) => {
    e.stopPropagation()
    setActiveItemMenu(activeItemMenu === ITEMS_MENU.ORGANIZATION ? "" : ITEMS_MENU.ORGANIZATION)

    setCategorySelected(CATEGORY_ITEMS.COLLECTIBLES)
    setMenuSelected(e.target.textContent)
    setSideMenuAccountIsOpen(false)
    setSideMenuOrganizationIsOpen(!sideMenuOrganizationIsOpen)
  }

  const handleClickAccountMenuCollapse = (event) => {
    event.stopPropagation()
    setMenuSelected(event.target.textContent)
    setActiveItemMenu(activeItemMenu === ITEMS_MENU.ACCOUNT ? "" : ITEMS_MENU.ACCOUNT)

    setCategorySelected(CATEGORY_ITEMS.COLLECTIBLES)
    setSideMenuOrganizationIsOpen(false)

    setSideMenuAccountIsOpen(!sideMenuAccountIsOpen)
  }

  const { DROPDOWN_ITEMS } = getMenuDropdownData(
    handleClickOrganizationMenuCollapse,
    handleClickAccountMenuCollapse,
    handleClickDressingRoom,
    handleClickLogout,
  )

  const _diffBetweenGlobalAndRightMenu = () => {
    return Math.abs(
      storeRightMenuTimestampRef.current.timestamp - storeGlobalMenuTimestampRef.current.timestamp,
    )
  }

  const handleOverallVisibilityMenu = (event) => {
    const clickedElementId = event.currentTarget.id

    if (clickedElementId === usernameId) {
      if (!isSideMenuOpen && !isSubMenuVisible) {
        openSubMenu()
        setShowPOI(false)
        setSubTopicMenuPublicIsVisible(false)

        if (routeType !== "News") {
          setStartTransitionAnimation("start-transition")
        }
      }

      if (isSubMenuVisible) {
        closeSubMenu()
        closeSideMenu()
        setSubTopicMenuPublicIsVisible(false)

        setStartTransitionAnimation("")
        setActiveItemMenu("")
        setTimeout(() => {
          setCategorySelected(CATEGORY_ITEMS.COLLECTIBLES)
        }, TIMER_TIMEOUT.RESTORE_CATEGORY)
      }
    }

    if (clickedElementId === menuId) {
      if ((isSideMenuOpen || isSubMenuVisible) && _diffBetweenGlobalAndRightMenu() > 20) {
        closeSubMenu()
        closeSideMenu()
        setActiveItemMenu("")
        setSubTopicMenuPublicIsVisible(false)

        setTimeout(() => {
          setCategorySelected(CATEGORY_ITEMS.COLLECTIBLES)
        }, TIMER_TIMEOUT.RESTORE_CATEGORY)

        if (isSideMenuOpen) {
          setStartTransitionAnimation("decrease-all-blur")
          setTimeout(() => {
            setStartTransitionAnimation("")
          }, 1000)
        } else {
          setStartTransitionAnimation("decrease-blur-submenu")
        }
      }
    }
  }

  const handleClickPointerEventsSideMenu = () => {
    if (!isSubMenuVisible && !isSideMenuOpen) {
      return { pointerEvents: "none", display: "none" }
    }
    if (isSubMenuVisible && !isSideMenuOpen) {
      return { pointerEvents: "none", display: "block" }
    }
    if (isSideMenuOpen && isSideMenuOpen) {
      return { pointerEvents: "auto", display: "block" }
    }
    if (isSubMenuVisible) {
      return { pointerEvents: "auto", display: "none" }
    }

    return { pointerEvents: "auto" }
  }

  const handleClickExit = () => {
    if (isBrand || isDepartment) {
      navigate("/")
    } else {
      navigate(-1)
      setShowScene3D((prev) => !prev)
    }

    SDK.toggleRender(!showScene3D)
    setShowScene3D(false)
    setRoomsFromPOI(false)
    setShowPOI(false)
    setShowRenaultRooms(false)
    setSubMenuIsVisible("item-hidden")
    setSideMenuOrganizationIsOpen(false)
    setSideMenuAccountIsOpen(false)
    setStartTransitionAnimation("")
    setRoomReady(false)
  }

  const handleGlobalMenuVisibility = (e) => {
    storeGlobalMenuTimestampRef.current.timestamp = Date.now()
    handleOverallVisibilityMenu(e)
  }

  const BlurBackgroundRender = () => {
    return <div className={`blur-background ${startTransitionAnimation} ${expandBlur}`} />
  }

  const LoginRender = () => {
    if (loadingUserProfile) {
      return (
        <div style={{ paddingLeft: "5rem" }}>
          <Spin size="large" />
        </div>
      )
    }
    if (profile) {
      return (
        <div>
          {isExperienceRoom && (
            <div className="exit" onClick={() => handleClickExit()}>
              {Object.values(ROUTES.RENAULT_SUBEXPERIENCES).includes(subexperience)
                ? "back"
                : "exit"}
            </div>
          )}

          <div
            className={`logged-in `}
            id="username-id"
            onClick={(event) => handleOverallVisibilityMenu(event)}
            style={{
              color:
                isExperienceRoom && !isRenaulution
                  ? isSubMenuVisible
                    ? "#b5b5b5"
                    : "white"
                  : undefined,
            }}
          >
            {displayUsername()}
          </div>
        </div>
      )
    }
    return (
      <div>
        {isExperienceRoom && (
          <div className="exit" onClick={() => handleClickExit()}>
            exit
          </div>
        )}
        <div
          className={`not-logged `}
          onClick={() => handleClickLogin()}
          style={{
            color:
              isExperienceRoom && !isRenaulution
                ? isSubMenuVisible
                  ? "#b5b5b5"
                  : "white"
                : undefined,
          }}
        >
          Connect
        </div>
      </div>
    )
  }

  const DropdownRender = () => {
    if (logged && consented) {
      return (
        <div className={`dropdown-menu ${subMenuIsVisible} ${reverseAnimation}`}>
          {DROPDOWN_ITEMS.map(({ id, label, value, onClick, render }) => (
            <li
              key={id}
              className={`dropdown-item item-${id} ${activeItemMenu === value ? "active" : ""}`}
              onClick={onClick}
            >
              {label}
              {render}
            </li>
          ))}
        </div>
      )
    }
  }

  const SideMenuRender = () => {
    return (
      <Layout style={handleClickPointerEventsSideMenu()} className="sider-menu-layout">
        <Sider
          collapsible
          collapsed={isSideMenuOpen}
          collapsedWidth={1}
          trigger={null}
          className="custom-sider"
          style={{ backgroundColor: "transparent" }}
        >
          {DROPDOWN_ITEMS.some((item) => item.label === menuSelected) && (
            <SideOrganizationMenu
              siderIscollapsed={sideMenuOrganizationIsOpen}
              handleOverallVisibilityMenu={handleOverallVisibilityMenu}
              rightMenuId={rightMenuId}
              storeRightMenuTimestampRef={storeRightMenuTimestampRef}
            />
          )}
          {DROPDOWN_ITEMS.some((item) => item.label === menuSelected) && (
            <SideAccountMenu
              siderIscollapsed={sideMenuAccountIsOpen}
              handleOverallVisibilityMenu={handleOverallVisibilityMenu}
              rightMenuId={rightMenuId}
              storeRightMenuTimestampRef={storeRightMenuTimestampRef}
            />
          )}
        </Sider>
      </Layout>
    )
  }

  const dropdownMenuStyle = () => {
    if (isSubMenuVisible && !isSideMenuOpen) {
      return { width: "20vw" }
    }

    if (isSubMenuVisible && isSideMenuOpen) {
      return { width: "20vw" }
    }

    if (isSubMenuVisible) {
      return { with: "17vw" }
    }

    if (isSubMenuVisible) {
      return { width: "20vw" }
    }
  }

  const visibleScreens =
    !displaySearchResults &&
    location.pathname !== ROUTES.DRESSING_ROOM.path &&
    currentExperienceType !== CURRENT_EXPERIENCE_TYPE.IN_EXTERNAL_EXPERIENCE &&
    (currentPlanetView === CURRENT_PLANET_VIEW.IN_CONTINENT ||
      currentPlanetView === CURRENT_PLANET_VIEW.ENTERING_CONTINENT ||
      currentPlanetView === CURRENT_PLANET_VIEW.IN_EXPERIENCE ||
      currentPlanetView === CURRENT_PLANET_VIEW.IN_ORBIT ||
      currentPlanetView === CURRENT_PLANET_VIEW.ENTERING_ORBIT)

  return (
    <div
      id={menuId}
      className={`menu ${visibleScreens ? "is-visible" : ""}`}
      onClick={(e) => handleGlobalMenuVisibility(e)}
    >
      <nav className={`menu-desktop`} id={leftMenuId} style={dropdownMenuStyle()} ref={menuRef}>
        {BlurBackgroundRender()}

        <ol className={`${dropdownMenuPosition} ${menuAnimation}`}>
          {LoginRender()}
          {DropdownRender()}
        </ol>
      </nav>

      {SideMenuRender()}

      <div className={infoBullIsVisible()}>{infoBull}</div>
    </div>
  )
}

export default MenuDesktop
