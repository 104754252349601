/**
 * Main.js
 *
 * The Main component serves as the primary layout container for the application, integrating various key components and managing overall layout and state.
 *
 * Key functionalities include:
 * - Rendering the main content area with an optional dark theme connection widget.
 * - Displaying the SearchBar component, central to the user experience.
 * - Displaying the Aside component, which includes the 3D planet hub and experience room.
 * - Integrating the Onboarding component for user onboarding workflows.
 * - Managing state for dashboard and modal visibility.
 * - Conditionally rendering components based on the visibility of avatar setup and claim modals.
 *
 * The component uses several hooks to manage state and visibility:
 * - useAvatarSetup: Manages the visibility of the avatar setup modal.
 * - useClaimSetup: Manages the visibility of the claim setup modal and mint results.
 *
 * The component returns a main element containing the connection widget, routed content, onboarding workflow, search bar, and aside elements.
 */

import { Outlet } from "react-router-dom"
import { useAvatarSetup } from "@/hooks/onboarding/useAvatarSetup"
import { useClaimSetup } from "@/hooks/onboarding/useClaimSetup"
import SearchBar from "@/components/Layout/Main/SearchBar/SearchBar.jsx"
import Aside from "@/components/Layout/Main/Aside/Aside.jsx"
import Onboarding from "@/components/Onboarding/Onboarding.jsx"
import "./main.scss"

const Main = ({
  dashboardOpen,
  setDashboardOpen,
  modalOpen,
  setModalOpen,
  activeTab,
  setActiveTab,
}) => {
  const { modalAvatarSetupVisible } = useAvatarSetup()
  const { modalClaimVisible, mintResult } = useClaimSetup()

  return (
    <main style={mintResult && { pointerEvents: "none" }}>
      <metavrs-connection theme="dark" wallet="cloudwallet"></metavrs-connection>

      <Outlet />

      <Onboarding
        dashboardOpen={dashboardOpen}
        setDashboardOpen={setDashboardOpen}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {!modalAvatarSetupVisible && !modalClaimVisible && <SearchBar />}

      <Aside />
    </main>
  )
}

export default Main
