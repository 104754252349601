/**
 * Department.js
 *
 * Sets route type to "Department" and applies department-specific styling.
 */

import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useRouteType } from "@/hooks/interface/useRouteType"

const Department = () => {
  const { department } = useParams()
  const { setRouteType } = useRouteType()

  // Sets Route Type
  useEffect(() => {
    setRouteType("Department")
  }, [setRouteType])

  return <section className={`page department ${department}`}></section>
}

export default Department
