/**
 * CursorManager.js
 *
 * The CursorManager class provides static methods to manage cursor styles dynamically on the document body.
 * It handles setting, toggling, and clearing cursor styles based on the provided conditions.
 *
 * Key functionalities include:
 * - Setting a new cursor style while removing the previous one.
 * - Toggling a cursor style based on a condition.
 * - Clearing the current cursor style.
 *
 * Methods:
 * - setCursor: Sets a new cursor style on the document body.
 * - toggleCursor: Toggles the cursor style based on a condition.
 * - clearCursor: Clears the current cursor style from the document body.
 */
export default class CursorManager {
  static currentCursor = ""

  static setCursor(cursorStyle) {
    // Remove the previous cursor class if there was one
    if (this.currentCursor) {
      document.body.classList.remove(`${this.currentCursor}`)
    }

    // Add the new cursor class
    document.body.classList.add(`${cursorStyle}`)

    // Update the current cursor
    this.currentCursor = cursorStyle
  }

  static toggleCursor(cursorStyle, shouldToggle) {
    // If the condition is true, set the cursor, otherwise clear it.
    if (shouldToggle) {
      // If the current cursor is the same as what's being toggled, just return.
      if (this.currentCursor === cursorStyle) return

      // Otherwise, set the new cursor style.
      this.setCursor(cursorStyle)
    } else {
      // If the condition is false and the current cursor is the one to be toggled, clear it.
      if (this.currentCursor === cursorStyle) {
        this.clearCursor()
      }
    }
  }

  static clearCursor() {
    // Remove the current cursor class
    if (this.currentCursor) {
      document.body.classList.remove(`${this.currentCursor}`)
      this.currentCursor = ""
    }
  }
}
