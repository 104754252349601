/**
 * Experience.js
 *
 * Displays different types of experiences (video, external, 3D, or 2D) based on the current route.
 */

import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { useRouteType } from "@/hooks/interface/useRouteType"
import { usePlanet } from "@/hooks/interface/usePlanet"
import { useInterface } from "@/hooks/interface/useInterface"
import { usePreviousRouteType } from "@/utils/PreviousLocation"
import { useRooms } from "@/hooks/metaverse/useRooms"
import Overlay from "@/components/Common/Overlay"
import Video from "@/components/Planet3DHub/ExperienceType/Video"
import External from "@/components/Planet3DHub/ExperienceType/External"
import CursorManager from "../utils/CursorManager.js"
import { CURRENT_PLANET_VIEW } from "../constants/constants.jsx"
import Image from "../components/Planet3DHub/ExperienceType/Image.jsx"
import CONTROL_ROOM_IMAGE from "@/assets/images/control-room.jpg"

const Experience = () => {
  const { setShowRenaultRooms, roomsFromPOI, showRenaultRooms } = useRooms()
  const { showContent, setShowContent } = useInterface()
  const { currentPlanetView } = usePlanet()
  const { setRouteType } = useRouteType()
  const prevRouteType = usePreviousRouteType()
  const { experience } = useParams()
  const [fromWebGL, setFromWebGL] = useState(false)
  const [fromSubExperience, setFromSubExperience] = useState(false)

  const [showVideo, setShowVideo] = useState(false)
  const sectionRef = useRef(null)
  const overlayRef = useRef(null)

  // Set route type and handles overlay
  useEffect(() => {
    if (experience) {
      setRouteType("Experience")
    }

    CursorManager.setCursor("pointer")

    if (prevRouteType && prevRouteType.routeType === "Brand") {
      setFromWebGL(true)
    }

    if (prevRouteType && prevRouteType.routeType === "SubExperience") {
      setFromSubExperience(true)
    }

    const runAsync = async () => {
      if (overlayRef?.current) {
        await overlayRef?.current
          ?.show(
            !fromSubExperience && 500,
            fromWebGL ? 1000 : fromSubExperience || roomsFromPOI ? -1000 : 0,
          )
          .then(() => {
            setShowVideo(true)
            overlayRef?.current?.hide(0)
            setShowContent(true)
          })
      }
    }

    runAsync()

    return () => {
      setShowContent(false)
      CursorManager.clearCursor()
    }
  }, [
    fromSubExperience,
    fromWebGL,
    prevRouteType,
    roomsFromPOI,
    setRouteType,
    setShowRenaultRooms,
    experience,
    showRenaultRooms,
    setShowContent,
  ])

  const getSectionType = (experience) => {
    const typeSection = {
      engineering: "external",
      flins: "video",
      "design-twin": "video",
      meeting: "external",
      "car-walk": "external",
      "control-room": "content",
    }

    return typeSection[experience] || "content" // Default to 'content' if experiences doesn't match
  }

  return (
    <>
      <section
        ref={sectionRef}
        className={`page experiences experience-${experience} type-${getSectionType(experience)}`}
        style={{
          backgroundColor:
            getSectionType(experience) === "external" &&
            currentPlanetView === CURRENT_PLANET_VIEW.IN_EXPERIENCE
              ? "#000000"
              : "transparent",
        }}
      >
        <Overlay ref={overlayRef} />

        {(() => {
          switch (experience) {
            case "flins":
              return <Video showVideo={showVideo} showContent={showContent} />

            case "design-twin":
              return <Video showVideo={showVideo} showContent={showContent} />

            case "control-room":
              return (
                <Image showContent={showContent} imageUrl={CONTROL_ROOM_IMAGE} />
                // <External
                //   showContent={showContent}
                //   isIframe={false}
                //   link={"https://auth.pan.ope.gcp.renault.com/ssbd-app?location=screen"}
                // />
              )

            case "engineering":
              return (
                <External
                  showContent={showContent}
                  isIframe={true}
                  link={"https://pb.metav.rs/rnstg2/"}
                />
              )

            case "car-walk":
              return <External showContent={showContent} link={"https://thecarwalkrenault.com/"} />

            case "meeting":
              return (
                <External
                  showContent={showContent}
                  isIframe={false}
                  link={"https://workrooms.workplace.com/groupcall/LINK:ddquwaubonka/"}
                />
              )

            default:
              return null
          }
        })()}
      </section>
    </>
  )
}

export default Experience
