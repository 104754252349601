import { CATEGORY_ITEMS } from "@/constants/constants"

const getCategoriesData = (categorySelected, handleClick) => {
  const CATEGORIES_ITEMS = [
    {
      id: CATEGORY_ITEMS.COLLECTIBLES,
      title: CATEGORY_ITEMS.COLLECTIBLES,
      styleClass: `category-item first-item ${categorySelected === CATEGORY_ITEMS.COLLECTIBLES ? "active" : ""}`,
      customStyle: null,
      onClick: (e) => handleClick(e, CATEGORY_ITEMS.COLLECTIBLES),
    },
    {
      id: CATEGORY_ITEMS.QUESTS,
      title: CATEGORY_ITEMS.QUESTS,
      styleClass: `category-item ${categorySelected === CATEGORY_ITEMS.QUESTS ? "active" : ""}`,
      customStyle: null,
      onClick: (e) => handleClick(e, CATEGORY_ITEMS.QUESTS),
    },
  ]
  return { CATEGORIES_ITEMS }
}
export default getCategoriesData
