/**
 * Homepage.jsx
 *
 * The Homepage component sets the route type to "Home" when the component is mounted.
 */

import { useRouteType } from "@/hooks/interface/useRouteType"
import { useEffect } from "react"

const Homepage = () => {
  const { routeType, setRouteType } = useRouteType()

  // Sets Route type
  useEffect(() => {
    if (routeType !== "Home") {
      setRouteType("Home")
    }
  }, [routeType, setRouteType])

  return <></>
}

export default Homepage
