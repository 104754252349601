import { SphereGeometry, Mesh } from "three"
import ExperienceManager from "@/webgl/ExperienceManager.js"
import MATERIALS from "@/webgl/world/materials/infos.js"
import BackgroundMaterial from "@/webgl/world/materials/background/index.js"

export default class Background {
  /**
   * Initializes the background with specified configuration
   * @param {Object} planetsConfig - Configuration for the planets
   */
  constructor(planetsConfig) {
    this.initializeExperience()
    this.initializeBackground(planetsConfig)
  }

  /**
   * Initializes experience manager and related properties
   */
  initializeExperience() {
    this.experience = new ExperienceManager()
    this.scene = this.experience.scene
  }

  /**
   * Creates the background sphere with the initial material
   * @param {Object} planetsConfig - Configuration for the planets
   */
  initializeBackground(planetsConfig) {
    this.planetsConfig = planetsConfig
    this.currentPlanetConfig = this.getCurrentPlanetName()

    const geometry = new SphereGeometry(15)
    const material = new BackgroundMaterial(MATERIALS[this.currentPlanetConfig].background)
    this.background = new Mesh(geometry, material)

    this.scene.add(this.background)
  }

  /**
   * Gets the current planet name from the configuration
   * @returns {string} - The current planet's name
   */
  getCurrentPlanetName() {
    return this.planetsConfig.planets[this.planetsConfig.currentPlanet].name
  }

  /**
   * Resets the colors of the background to the current planet's default
   */
  resetColors(progress) {
    const newColors = MATERIALS[this.getCurrentPlanetName()].background
    this.switchColor(newColors, progress)
  }

  /**
   * Switches the background colors to the specified colors
   * @param {Object} colors - The colors to switch to
   */
  switchColor(colors, progress) {
    if (colors) {
      this.background.material.switchColors(colors, progress)
    }
  }

  /**
   * Updates the colors of the background based on the transition progress.
   * @param {number} progress - The current progress of the transition (0 to 1).
   * @param {Object} targetColors - The target color set { startColor, centerColor, endColor }.
   */
  updateColors(progress, planetConfig) {
    const targetColors = MATERIALS[planetConfig].background
    const { startColor, centerColor, endColor } = targetColors

    if (progress === 1) {
      this.background.material.uniforms.startColorA.value.copy(
        this.background.material.uniforms.startColorB.value,
      )
      this.background.material.uniforms.centerColorA.value.copy(
        this.background.material.uniforms.centerColorB.value,
      )
      this.background.material.uniforms.endColorA.value.copy(
        this.background.material.uniforms.endColorB.value,
      )
    }

    // Regardless of progress, update B to the new target colors
    this.background.material.uniforms.startColorB.value.set(startColor)
    this.background.material.uniforms.centerColorB.value.set(centerColor)
    this.background.material.uniforms.endColorB.value.set(endColor)

    // Update the fade progress in the shader
    this.background.material.uniforms.fadeProgress.value = progress
  }

  /**
   * Updates the background based on the new planets configuration
   * @param {Object} planetsConfig - New configuration for the planets
   */
  update(progress, planetsConfig) {
    this.currentPlanetConfig = planetsConfig

    this.resetColors(progress)
    this.background.material.needsUpdate = true
  }

  /**
   * Disposes of the background resources when no longer needed
   */
  dispose() {
    if (this.background && this.background.geometry && this.background.material) {
      this.background.geometry.dispose()
      this.background.material.dispose()
    }
  }
}
