/**
 * Preloader.jsx
 *
 * The Preloader component displays a loading screen with a progress indicator while the application is loading.
 * It visually represents the loading progress and updates dynamically based on the progress prop.
 *
 * Key functionalities include:
 * - Displaying a logo and a progress counter.
 * - Updating the progress value based on the provided progress prop.
 * - Showing a progress bar that visually represents the loading percentage.
 *
 * The component returns an aside element containing the logo, progress counter, progress bar, and background, all styled appropriately.
 */

import { useEffect, useState } from "react"
import LogoRG from "@/assets/icons/brands/logo_RG.svg"
import "./preloader.scss"

const Preloader = ({ progress }) => {
  const [value, setValue] = useState(0)

  // Update the value based on the progress if the current value is less than progress
  useEffect(() => {
    if (value < progress) {
      setValue(progress)
    }
  }, [progress, value])

  return (
    <aside id="preloader">
      <img className="logo" src={LogoRG} alt="Planet RG" />
      <p className="preloader-counter">{`${Math.trunc(value)}%`}</p>
      <figure className="preloader-progressbar" style={{ width: `${value}%` }} />
      <figure className="preloader-background" />
    </aside>
  )
}

export default Preloader
