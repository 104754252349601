precision highp float;

uniform float uFraction;
uniform float uTime;
uniform vec3 uGlowColor;
uniform float uGlowIntensity;
uniform float uPulseSpeed;
uniform float uPulseIntensity;
uniform float uRandomStartOffset;

varying vec3 vWorldPosition;

void main() {
   float normalizedPosition = vWorldPosition.y;
   normalizedPosition = mod(normalizedPosition + uTime + uRandomStartOffset, 1.0);

   float start = 0.0;
   float end = start + uFraction;

   if (normalizedPosition >= start && normalizedPosition <= end) {
         float pulse = 0.5 + 0.5 * sin(uTime * uPulseSpeed) * uPulseIntensity;
         float glow = smoothstep(0.0, 1.0, (normalizedPosition - start) / uFraction) * pulse * uGlowIntensity;
         vec3 finalColor = uGlowColor * glow;
         gl_FragColor = vec4(finalColor, glow);
   } else {
         gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
   }
}