import { Divider, Skeleton } from "antd"
import { useSearch } from "@/hooks/interface/useSearch"
import "./search-related-questions.scss"

const SearchRelatedQuestions = () => {
  const { answerWaiting, dataResponse, receivedAnswer, setInputValue, setSendValue } = useSearch()

  return (
    <>
      {
        // responseAudioIsPending ||
        answerWaiting || receivedAnswer ? (
          <Skeleton active />
        ) : (
          <div className="related-questions">
            {dataResponse?.relatedQuestions?.length > 0
              ? dataResponse.relatedQuestions.map((question, index) => (
                  <ul
                    key={index}
                    className="related-questions__content"
                    onClick={() => {
                      setInputValue(question)
                      setSendValue(question)
                    }}
                  >
                    <li>{question}</li>
                    {index !== dataResponse.relatedQuestions.length - 1 && <Divider />}
                  </ul>
                ))
              : "No related questions found"}
          </div>
        )
      }
    </>
  )
}

export default SearchRelatedQuestions
