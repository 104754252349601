import { useEffect, useRef, useState } from "react"
import { CARD_TYPE, TIMER_TIMEOUT, FAVORITES_ITEMS, EXPERIENCE_ITEMS } from "@/constants/constants"
import CustomCard from "@/components/Common/CustomCard/CustomCard.jsx"
import "./my-organization.scss"

const SideOrganizationMenu = ({
  rightMenuId,
  storeRightMenuTimestampRef,
  siderIscollapsed,
  handleOverallVisibilityMenu,
}) => {
  const [fadeClass, setFadeClass] = useState("")
  const [hideMenuInlineStyle, setHideMenuInlineStyle] = useState({})
  const [userIsScrolling, setUserIsScrolling] = useState(false)
  const organizationDiv = useRef()

  // Handle scrolling inside my organization content
  useEffect(() => {
    const organization = organizationDiv.current
    if (!organization) return

    let scrollTimeout = null

    const handleScroll = () => {
      setUserIsScrolling(true)
      clearTimeout(scrollTimeout)

      scrollTimeout = setTimeout(() => {
        setUserIsScrolling(false)
      }, 150)

      setUserIsScrolling(organization.scrollHeight > organization.clientHeight)
    }

    organization.addEventListener("scroll", handleScroll)

    return () => {
      organization.removeEventListener("scroll", handleScroll)
      clearTimeout(scrollTimeout)
    }
  }, [])

  // Handles sidebar collapse animations
  useEffect(() => {
    let timer
    if (!siderIscollapsed) {
      setFadeClass("fade-out")
      timer = setTimeout(() => {
        setHideMenuInlineStyle({ display: "none" })
      }, TIMER_TIMEOUT.FADE_SIDE_MENU)
    } else {
      setHideMenuInlineStyle({ display: "block" })
      timer = setTimeout(() => {
        setFadeClass("fade-in")
      }, TIMER_TIMEOUT.FADE_SIDE_MENU)
    }

    return () => clearTimeout(timer)
  }, [siderIscollapsed])

  const styleOrganizationPositionSideMenu = () => {
    return {
      marginTop: "10vh",
      ...hideMenuInlineStyle,
    }
  }

  const handleClickMenuVisibility = (e) => {
    handleOverallVisibilityMenu(e)
    storeRightMenuTimestampRef.current.timestamp = Date.now()
  }

  return (
    <div
      ref={organizationDiv}
      className={`sideMenu-organization  ${fadeClass}  ${userIsScrolling ? "grabbing" : ""}`}
      id={rightMenuId}
      onClick={(event) => handleClickMenuVisibility(event)}
      style={styleOrganizationPositionSideMenu()}
    >
      <div className="flex-container">
        <div className="category-title">favoris</div>

        <div className="favorites-container">
          {FAVORITES_ITEMS.map(({ id, title, circleClassStyle }) => (
            <CustomCard
              key={id}
              title={title}
              circleClassStyle={circleClassStyle}
              cardType={CARD_TYPE.FAVORITE}
            />
          ))}
        </div>
      </div>

      <div className="flex-container">
        <div className="category-title">experience</div>

        <div className="experience-container">
          {EXPERIENCE_ITEMS.map(({ id, title, circleClassStyle }) => (
            <CustomCard
              key={id}
              title={title}
              circleClassStyle={circleClassStyle}
              cardType={CARD_TYPE.EXPERIENCE}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SideOrganizationMenu
