/**
 * VideoStateProvider.jsx
 * Manages and provides the state of video playback throughout the application
 */

import { createContext, useState } from "react"

const VideoStateContext = createContext()

const VideoStateProvider = ({ children }) => {
  const [videoStarted, setVideoStarted] = useState(false)

  return (
    <VideoStateContext.Provider
      value={{
        videoStarted,
        setVideoStarted,
      }}
    >
      {children}
    </VideoStateContext.Provider>
  )
}

export { VideoStateContext, VideoStateProvider }
