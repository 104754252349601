/**
 * HubContext.jsx
 * Authentification process
 * States : logged, consented, profile
 */

import { createContext, useCallback, useEffect, useState } from "react"
import { useSDK } from "@/hooks/metaverse/useSDK"
import { useAuth } from "@/hooks/onboarding/useAuth"
import { createLogger } from "@/utils/debug/log.js"

const log = createLogger("POWER.XYZ", "#FFF", "#000").log

const HubContext = createContext(null)

const EXPERIENCE_EID = {
  DRESSING_ROOM: "-5834482549856831149",
  RENAULT_ROOM: "8558193549440678336",
  RENAULT_ROOM_NIGHT: "1238052951897235720",
  KNOWLEDGE_ROOM: "-6807016051440327007",
}

const HubProvider = ({ children }) => {
  const SDK = useSDK()
  const { logged, consented } = useAuth()
  const [experiences, setExperiences] = useState(null)
  const [experienceMintRev, setExperienceMintRev] = useState(null)
  const [experienceMintBlue, setExperienceMintBlue] = useState(null)
  const [experienceMintPop, setExperienceMintPop] = useState(null)
  const [experienceMintBold, setExperienceMintBold] = useState(null)
  const [experienceDressingRoom, setExperienceDressingRoom] = useState(null)
  const [experienceRenaultRoom, setExperienceRenaultRoom] = useState(null)
  const [experienceRenaultRoomNight, setExperienceRenaultRoomNight] = useState(null)
  const [experienceKnowledgeRoom, setExperienceKnowledgeRoom] = useState(null)

  const getFrontHub = useCallback(() => {
    /* @hint getHub() will provide you everything you need to display the front page
      (background, title, experiences links ...) */
    SDK.getHub()
      .then((hub) => {
        log(`${hub.experiences.length} experiences available`, hub.experiences)
        setExperiences(hub.experiences.filter((experience) => experience.show === true))
      })
      .catch((message) => {
        console.error("Error getting products", message)
      })
  }, [SDK])

  useEffect(() => {
    if (logged && consented) {
      getFrontHub()
    }
  }, [logged, consented, getFrontHub])

  useEffect(() => {
    if (experiences) {
      setExperienceDressingRoom(
        experiences.filter((experience) => experience.eid === EXPERIENCE_EID.DRESSING_ROOM)[0],
      )
      setExperienceRenaultRoom(
        experiences.filter((experience) => experience.eid === EXPERIENCE_EID.RENAULT_ROOM)[0],
      )
      setExperienceRenaultRoomNight(
        experiences.filter((experience) => experience.eid === EXPERIENCE_EID.RENAULT_ROOM_NIGHT)[0],
      )
      setExperienceKnowledgeRoom(
        experiences.filter((experience) => experience.eid === EXPERIENCE_EID.KNOWLEDGE_ROOM)[0],
      )
    }
  }, [experiences])

  return (
    <HubContext.Provider
      value={{
        experiences,
        setExperiences,
        experienceMintRev,
        setExperienceMintRev,
        experienceMintBlue,
        setExperienceMintBlue,
        experienceMintPop,
        setExperienceMintPop,
        experienceMintBold,
        setExperienceMintBold,
        experienceDressingRoom,
        setExperienceDressingRoom,
        experienceRenaultRoom,
        setExperienceRenaultRoom,
        experienceRenaultRoomNight,
        setExperienceRenaultRoomNight,
        experienceKnowledgeRoom,
        setExperienceKnowledgeRoom,
      }}
    >
      {children}
    </HubContext.Provider>
  )
}

export { HubContext, HubProvider }
