/**
 * Image.jsx
 *
 * The Image component manages the display and navigation for Image content, either in an iframe or a new popup window.
 * It handles specific interactions based on whether the content is shown in an iframe or a popup, and navigates back to the previous page if not in an iframe.
 *
 * Key functionalities include:
 * - Opening a link in a new popup window after a delay when in experience view and not in an iframe.
 * - Navigating back to the previous page after a delay when not in an iframe.
 * - Conditionally rendering an iframe or a link based on the showContent and isIframe props.
 *
 * The component returns either an iframe or a link to Image content, with appropriate navigation and display logic.
 */

const Image = ({ showContent, imageUrl }) => {
  return <div className="control-room">{showContent ? <img src={imageUrl} alt="" /> : null}</div>
}

export default Image
