/**
 * ClaimContext.jsx
 * Authentification process
 * States : logged, consented, profile
 */

import { createContext, useState } from "react"
import { useSDK } from "@/hooks/metaverse/useSDK"
import { useAuth } from "@/hooks/onboarding/useAuth"
import { createLogger } from "@/utils/debug/log.js"

const log = createLogger("POWER.XYZ", "#FFF", "#000").log

const ClaimContext = createContext(null)

const ClaimProvider = ({ children }) => {
  const SDK = useSDK()
  const { getUserCollectibles } = useAuth()
  const [modalClaimVisible, setModalClaimVisible] = useState(false)
  const [collectibleToMint, setCollectibleToMint] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [currentUserCollectible, setCurrentUserCollectible] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [currentUserRewards, setCurrentUserRewards] = useState(null)
  const [currentCollections, setCurrentCollections] = useState(null)
  const [mintLoading, setMintLoading] = useState(false)
  const [mintResult, setMintResult] = useState(null)

  const displayCollectibleMint = (selectedExperienceMint) => {
    /* @hint launch mint/drop/claim NFT experience
      call m.getCollectibleInfo(experience.eid) to get fresh info on the collectible you want to mint
      otherwise, it's already in exprience.configuration */

    if (selectedExperienceMint) {
      // console.log("Display Mint")
      let collectible = selectedExperienceMint.configuration

      // @hint your need to call getCollectibleSupply() to have the nb of nfts available for mint
      // You can skip this if you don't want to display "xxx/yyy nfts available"
      SDK.getCollectibleSupply(selectedExperienceMint?.eid)
        .then((response) => {
          // @hint we juste add the supply attribute to the existing collectible object for convenience
          collectible.nfts.supply = response.supply
          // console.log("We got the collectible to claim", collectible)

          resetModals()

          setCollectibleToMint(collectible)
        })
        .catch((message) => {
          console.error("Error getting collectible supply", message)
        })
    }
  }

  // on click on claim button (from mint experience)
  const onClaim = (selectedExperienceMint) => {
    if (selectedExperienceMint) {
      setMintLoading(true)
      setModalClaimVisible(true)

      SDK.claim(selectedExperienceMint)
        .then(() => {
          log("Claim success !")
          // refresh user collectibles and display success
          setTimeout(() => {
            getUserCollectibles()
          }, 8000)
          setMintResult({ success: true })
          setMintLoading(false)
        })
        .catch((message) => {
          // display error
          log("Claim  error !", message)

          setMintResult({ success: false, message: "There was an error, please try again later" })
          setMintLoading(false)
        })
    }
  }

  // mint result close
  const onMintResultClose = () => {
    setMintResult(null)
    setMintLoading(false)
    // close modal only if claim success
    setCollectibleToMint((prev) => (mintResult.success === true ? null : prev.collectibleToMint))
    setModalClaimVisible(mintResult.success === true ? false : true)
  }

  const resetModals = () => {
    setCurrentUserCollectible(null)
    setCurrentUserRewards(null)
    setCurrentCollections(null)
    setCollectibleToMint(null)
  }

  return (
    <ClaimContext.Provider
      value={{
        modalClaimVisible,
        setModalClaimVisible,
        collectibleToMint,
        setCollectibleToMint,
        displayCollectibleMint,
        mintLoading,
        mintResult,
        currentCollections,
        setCurrentCollections,
        onClaim,
        onMintResultClose,
        resetModals,
      }}
    >
      {children}
    </ClaimContext.Provider>
  )
}

export { ClaimContext, ClaimProvider }
