/**
 * ExperienceManager.js
 * Manages the entire 3D experience, including setting up the scene, camera, renderer, and world.
 * Ensures a singleton pattern for a single instance throughout the application.
 */

import { Scene } from "three"
import { EffectComposer } from "three/addons/postprocessing/EffectComposer.js"
import { createLogger } from "@/utils/debug/index.js"
import Sizes from "@/webgl/utils/Sizes.js"
import Time from "@/webgl/utils/Time.js"
import Camera from "@/webgl/base/Camera.js"
import Renderer from "@/webgl/base/Renderer.js"
import World from "@/webgl/world/World.js"
import UserDeviceConfig from "@/webgl/utils/UserDeviceConfig.js"

const log = createLogger("Config", "#ffffff", "#1b195b").log

export default class ExperienceManager {
  static instance

  constructor(canvas) {
    // Implementing the singleton pattern
    if (ExperienceManager.instance) {
      return ExperienceManager.instance
    }
    ExperienceManager.instance = this

    this.canvas = canvas
  }

  startExperience() {
    if (!this.canvas) return

    log("Experience has started")

    this.initializeBaseComponents(this.canvas)
    this.preloadAndInitWorld()
    this.setupEventListeners()
  }

  /**
   * Initialize base components like scene, camera, renderer
   * @param {*} canvas - dom cnavas
   */
  initializeBaseComponents(canvas) {
    this.canvas = canvas
    this.sizes = new Sizes()
    this.time = new Time()
    this.scene = new Scene()
    this.camera = new Camera()
    this.renderer = new Renderer()

    // Effect Composer
    this.composer = new EffectComposer(this.renderer.instance)

    // World Experience
    this.world = null
    this.interfaceIsVisible = false

    // Check if the device is likely low-end
    this.userDeviceConfig = new UserDeviceConfig()
  }

  /**
   * Initialize the world and preload necessary assets
   */
  preloadAndInitWorld() {
    if (this.world === null) {
      this.world = new World()
      this.userHasDraggedInContinent = false
      this.preload()
    }
  }

  async preload() {
    if (!this.world) return

    try {
      await this.world.preload()
      this.world.init()
    } catch (error) {
      console.error("Failed to preload world assets:", error)
    }
  }

  setupEventListeners() {
    if (this.sizes) {
      this.sizes.on("resize", () => {
        this.resize()
      })
    }

    if (this.time) {
      this.time.on("update", () => {
        this.update()
      })
    }
  }

  /**
   * Notify the React interface about events or changes happening within the WebGL context
   * @param {*} webGLEvents - events throughout the app
   */
  notifyInterface(webGLEvents) {
    this.onCurrentPlanetView = webGLEvents.onCurrentPlanetView
    this.onCurrentPlanet = webGLEvents.onCurrentPlanet
    this.onCurrentExperienceType = webGLEvents.onCurrentExperienceType
    this.onDrag = webGLEvents.onDrag
    this.onGateSubMenuOpen = webGLEvents.onGateSubMenuOpen
    this.onCurrentExplorationLevel = webGLEvents.onCurrentExplorationLevel
    this.onCurrentDepartment = webGLEvents.onCurrentDepartment
  }

  currentPlanetView(planetView) {
    if (typeof this.onCurrentPlanetView === "function") {
      this.onCurrentPlanetView(planetView)
    }
  }

  currentPlanet(planetState) {
    if (typeof this.onCurrentPlanetView === "function") {
      this.onCurrentPlanet(planetState)
    }
  }

  currentDepartment(departmentName) {
    if (typeof this.onCurrentDepartment === "function") {
      this.onCurrentPlanetView(departmentName)
    }
  }

  hasUserDragged(dragged) {
    if (typeof this.onDrag === "function") {
      this.onDrag(dragged)
    }
  }

  isGateSubMenuOpen(isOpen) {
    if (typeof this.onGateSubMenuOpen === "function") {
      this.onGateSubMenuOpen(isOpen)
    }
  }

  currentExperienceType(experienceType) {
    if (typeof this.onCurrentExperienceType === "function") {
      this.onCurrentExperienceType(experienceType)
    }
  }

  currentExplorationLevel(explorationLevel) {
    if (typeof this.onCurrentExplorationLevel === "function") {
      this.onCurrentExplorationLevel(explorationLevel)
    }
  }

  handleInterfaceStatus(subMenuIsVisible) {
    this.interfaceIsVisible = subMenuIsVisible === "item-visible" ? true : false
  }

  resize() {
    if (this.camera) {
      this.camera.resize()
    }

    if (this.renderer) {
      this.renderer.resize()
    }
  }

  update() {
    if (this.camera) {
      this.camera.update()
    }

    if (this.renderer) {
      this.renderer.update()
    }

    if (this.world) {
      this.world.update()
    }
  }

  pause() {
    if (this.time && !this.time.isPaused) {
      log("Experience is on pause")
      this.time.pause()
    }

    if (this.world) {
      this.world.pause()
    }
  }

  restart() {
    if (this.time && this.time.isPaused) {
      log("Experience has restarted")
      this.time.resume()
    }

    if (this.world) {
      this.world.resume()
    }
  }

  destroy() {
    if (this.renderer) {
      this.renderer.instance.dispose()
      this.renderer.instance.renderLists.dispose()
    }

    if (this.world) {
      this.world.dispose()
    }
  }
}
