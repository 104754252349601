/**
 * Sizes.js
 * Handles responsive changes in the window size, maintaining and broadcasting
 * updated width, height, and pixel ratio. It's useful for adjusting scenes,
 * cameras, or any other size-dependent functionality in real-time.
 */

import EventEmitter from "@/webgl/utils/EventEmitter.js"

export default class Sizes extends EventEmitter {
  /**
   * Initializes the Sizes utility.
   */
  constructor() {
    super()

    this.updateSizes()

    this.setupResizeEvent()
  }

  /**
   * Updates the width, height, and pixel ratio properties based on the current window size.
   */
  updateSizes() {
    this.width = window.innerWidth
    this.height = window.innerHeight
    this.pixelRatio = Math.min(window.devicePixelRatio, 2)
  }

  /**
   * Sets up the resize event listener on the window.
   */
  setupResizeEvent() {
    window.addEventListener("resize", () => {
      this.updateSizes()

      this.trigger("resize")
    })
  }
}
