import { Vector3 } from "three"

/**
 * Calculates the direction to move a gate based on its position relative to the clicked gate
 * @param {Object} child - The neighboring gate
 * @param {Object} gate - The clicked gate
 * @returns {Vector3} - The direction vector
 */
export const calculateMoveDirection = ({ child, gate }) => {
  // Calculate vector from the clicked gate to the neighboring gate
  const directionVector = new Vector3().subVectors(
    child.userData.updatedPosition,
    gate.userData.updatedPosition,
  )
  directionVector.y = 0 // Keep movement in the X-Z plane
  return directionVector.normalize()
}

/**
 * Calculates the move distance for a gate
 * @param {number} progress - Current progress of the animation
 * @param {number} moveAwayDistance - The configured distance to move gates away
 * @param {boolean} openingExp - If true, entering an experience
 * @param {boolean} openingSubmenu - If true, opening the submenu
 * @param {Object} child - The neighboring gate
 * @returns {number} - The calculated move distance
 */
export const calculateMoveDistance = ({
  progress,
  moveAwayDistance,
  openingExp,
  openingSubmenu,
}) => {
  // Determine if gates should move out or back to original position
  const isMovingOut = openingExp || openingSubmenu

  // Calculate move distance based on progress
  return isMovingOut ? progress * moveAwayDistance : (1 - progress) * moveAwayDistance
}

/**
 * Calculates the target position for a gate
 * @param {Object} child - The neighboring gate
 * @param {Vector3} moveDirection - The direction to move the gate
 * @param {number} moveDistance - The distance to move the gate
 * @returns {Vector3} - The calculated target position
 */
export const calculateTargetPosition = ({ child, moveDirection, moveDistance }) => {
  return child.userData.updatedPosition.clone().add(moveDirection.multiplyScalar(moveDistance))
}

/**
 * Animates the movement of a gate to a target position
 * @param {Object} gate - The gate to animate
 * @param {Vector3} targetPosition - The target position for the gate
 * @param {number} progress - Current progress of the animation
 */
export const calculateGateMovement = ({ gate, targetPosition, progress }) => {
  const startPosition = gate.position.clone()
  const newPosition = startPosition.lerp(targetPosition, progress)
  gate.position.copy(newPosition)
}

export const formatDate = (dateStr) => {
  const [year, month, day] = dateStr.split(/[_-]/)
  const date = new Date(year, month - 1, day)
  const formattedDate = date.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  })
  return formattedDate.replace(/\//g, "-")
}
