import { ShaderMaterial, Color, DoubleSide } from "three"

import vertexShader from "@/webgl/world/materials/ground/vertex.glsl"
import fragmentShader from "@/webgl/world/materials/ground/fragment.glsl"

export default class GroundMaterial extends ShaderMaterial {
  constructor({ color }, opacity = 1.0) {
    super({
      vertexShader,
      fragmentShader,

      uniforms: {
        color: { value: new Color(color) },
        opacity: { value: opacity },
      },
    })

    this.transparent = true
    this.side = DoubleSide
  }
}
