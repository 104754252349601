/**
 * SiteTitle.jsx
 *
 * The SiteTitle component displays the experience name along with a switch button, managing its visibility and style based on the current state and user actions.
 *
 * Key functionalities include:
 * - Determining the title style class based on the current planet view and exploration level.
 * - Managing header visibility and style adjustments.
 * - Handling the visibility of the switch header and department style.
 * - Providing a backToHub function to navigate back to the main hub and reset various states.
 *
 * The component renders the experience name with dynamic styles and includes a switch button for user interactions.
 */

import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  ROUTES,
  CURRENT_PLANET_VIEW,
  CURRENT_EXPLORATION_LEVEL,
  BRANDS_COLORS,
  CURRENT_PLANET,
  CATEGORY_ITEMS,
} from "@/constants/constants.jsx"
import { useClaimSetup } from "@/hooks/onboarding/useClaimSetup.jsx"
import { useAvatarSetup } from "@/hooks/onboarding/useAvatarSetup.jsx"
import { useRooms } from "@/hooks/metaverse/useRooms"
import { usePlanet } from "@/hooks/interface/usePlanet"
import { useInterface } from "@/hooks/interface/useInterface"
import { useSearch } from "@/hooks/interface/useSearch"

const SiteTitle = () => {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const { setShowScene3D } = useRooms()
  const {
    currentPlanet,
    currentPlanetView,
    webGLExperience,
    currentExplorationLevel,
    isWebGLPaused,
  } = usePlanet()
  const {
    sideMenuOrganizationIsOpen,
    setSideMenuOrganizationIsOpen,
    sideMenuAccountIsOpen,
    setSideMenuAccountIsOpen,
    setActiveItemMenu,
    setSubTopicMenuPublicIsVisible,
    setStartTransitionAnimation,
    setCategorySelected,
    setSearchBarIsVisible,
    setSearchBarResultsVisible,
    searchBarResultVisible,
  } = useInterface()
  const { displaySearchResults, isUploadOpen } = useSearch()
  const { modalClaimVisible } = useClaimSetup()
  const { modalAvatarSetupVisible } = useAvatarSetup()
  const [titleStyleClass, setTitleStyleClass] = useState("")
  const [titleHeader, setTitleHeader] = useState("")
  const [switchHeader, setSwitchHeader] = useState("")
  const [switchToggled, setSwitchToggled] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [departmentStyle, setDepartmentStyle] = useState("")
  const departmentColors = (params.brand && BRANDS_COLORS[params.brand.toUpperCase()]) || {}

  const backToHub = () => {
    if (
      currentPlanetView === CURRENT_PLANET_VIEW.IN_CONTINENT ||
      currentExplorationLevel === CURRENT_EXPLORATION_LEVEL.IN_GATES
    ) {
      navigate("/")
      webGLExperience.world?.enterOrbit()

      // Reset
      setShowScene3D(false)
      setSideMenuOrganizationIsOpen(false)
      setSideMenuAccountIsOpen(false)
      setSearchBarIsVisible(false)
      setSearchBarResultsVisible(false)
      setSubTopicMenuPublicIsVisible(false)
      setCategorySelected(CATEGORY_ITEMS.COLLECTIBLES)
      setStartTransitionAnimation("")
      setActiveItemMenu("")
    } else if (currentExplorationLevel !== CURRENT_EXPLORATION_LEVEL.IN_ORBIT) {
      webGLExperience.world.enterExploration(null, true)
    }
  }

  // Determine title style class based on the current planet and exploration level
  useEffect(() => {
    if (
      currentPlanet === CURRENT_PLANET.ENTERING_RENAULUTION ||
      currentPlanet === CURRENT_PLANET.IN_RENAULUTION
    ) {
      setTitleStyleClass("name is-white")
    } else if (
      currentPlanetView === CURRENT_PLANET_VIEW.ENTERING_CONTINENT ||
      currentPlanetView === CURRENT_PLANET_VIEW.IN_CONTINENT
    ) {
      let color = "is-black"
      switch (params.brand) {
        case "renault":
          color = "is-black"
          break

        case "alpine":
        case "dacia":
        case "mobilize":
          color = "is-white"
          break

        default:
          break
      }
      setTitleStyleClass(`name ${color} isPageBrand`)
    } else {
      const explorationClass =
        currentExplorationLevel === CURRENT_EXPLORATION_LEVEL.IN_CONTINENTAL_NEWS ||
        currentExplorationLevel === CURRENT_EXPLORATION_LEVEL.IN_DEPARTMENTS
          ? "exploration"
          : ""
      setTitleStyleClass(`name ${explorationClass}`)
    }
  }, [currentPlanet, currentPlanetView, params.brand, currentExplorationLevel])

  // Manages header visibility
  useEffect(() => {
    if (
      (currentPlanetView === CURRENT_PLANET_VIEW.IN_ORBIT &&
        location.pathname !== ROUTES.DRESSING_ROOM.path) ||
      currentPlanetView === CURRENT_PLANET_VIEW.ENTERING_ORBIT ||
      currentPlanetView === CURRENT_PLANET_VIEW.IN_CONTINENT ||
      currentPlanetView === CURRENT_PLANET_VIEW.ENTERING_CONTINENT
    ) {
      setTitleHeader("header-container-visible")
    } else {
      setTitleHeader("header-container-hidden")
    }
  }, [currentPlanetView, location.pathname])

  // Manages switch header visibility
  useEffect(() => {
    let timer

    if (
      currentPlanetView !== CURRENT_PLANET_VIEW.IN_ORBIT ||
      currentExplorationLevel !== CURRENT_EXPLORATION_LEVEL.IN_ORBIT ||
      modalClaimVisible ||
      modalAvatarSetupVisible ||
      searchBarResultVisible ||
      sideMenuOrganizationIsOpen ||
      sideMenuAccountIsOpen | isWebGLPaused ||
      displaySearchResults ||
      isUploadOpen
    ) {
      setSwitchHeader("switch-hidden")
    } else {
      timer = setTimeout(() => {
        setSwitchHeader("switch-visible")
      }, 500)
    }

    return () => clearTimeout(timer)
  }, [
    currentPlanetView,
    currentExplorationLevel,
    modalClaimVisible,
    modalAvatarSetupVisible,
    searchBarResultVisible,
    sideMenuOrganizationIsOpen,
    sideMenuAccountIsOpen,
    isWebGLPaused,
    displaySearchResults,
    isUploadOpen,
  ])

  // Manages department style
  useEffect(() => {
    let timer

    if (
      (currentExplorationLevel === CURRENT_EXPLORATION_LEVEL.IN_GATES ||
        currentPlanetView === CURRENT_PLANET_VIEW.IN_CONTINENT) &&
      !sideMenuOrganizationIsOpen &&
      !sideMenuAccountIsOpen &&
      !searchBarResultVisible &&
      !displaySearchResults
    ) {
      timer = setTimeout(() => {
        setDepartmentStyle("show")
      }, 500)
    } else {
      setDepartmentStyle("")
    }

    return () => clearTimeout(timer)
  }, [
    currentExplorationLevel,
    currentPlanetView,
    sideMenuOrganizationIsOpen,
    sideMenuAccountIsOpen,
    searchBarResultVisible,
    displaySearchResults,
  ])

  const handleToggle = async (event) => {
    if (!webGLExperience) return

    setIsDisabled(true)

    setSwitchToggled(event.target.checked)
    await webGLExperience.world?.togglePlanets()

    setIsDisabled(false)
  }

  return (
    <div className={`header-container ${titleHeader}`}>
      <div
        className={titleStyleClass}
        onClick={() => {
          backToHub()
        }}
      >
        planet <span>RG</span>
      </div>

      {(currentPlanetView === CURRENT_PLANET_VIEW.IN_ORBIT ||
        currentExplorationLevel === CURRENT_EXPLORATION_LEVEL.IN_ORBIT) && (
        <label className={`switch ${switchHeader}`}>
          <input
            type="checkbox"
            checked={switchToggled}
            onChange={handleToggle}
            disabled={isDisabled}
          />
          <span className="slider round" />
        </label>
      )}

      {params.department && (
        <div
          style={{ color: departmentColors.TEXT_COLOR }}
          className={`department-name ${departmentStyle} `}
        >
          {params.department.replace(/-/g, " ")}
        </div>
      )}
    </div>
  )
}

export default SiteTitle
