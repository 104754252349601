import { requestWrapper } from "../config/requestConfig"

// import searchRequestResponseActualSwagger from "@/data/fake/searchRequestResponseActualSwagger.json"

// codeMessage = {
// 	200: "The server successfully returned the requested data.",
// 	201: "New or modified data is successful.",
// 	202: "A request has entered the background queue (asynchronous task).",
// 	204: "The data was deleted successfully.", <
// 	400: "The request was made with an error and the server did not perform any new or modified data operations.",
// 	401: "User does not have permission (token, username, password is incorrect).",
// 	403: "The user is authorized, but access is forbidden.",
// 	404: "The request is made for a record that does not exist and the server does not operate.",
// 	406: "The format of the request is not available.",
// 	410: "The requested resource is permanently deleted and will not be retrieved.",
// 	422: "A validation error occurred when creating an object.",
// 	500: "An error occurred on the server. Please check the server.",
// 	502: "Gateway error.",
// 	503: "The service is unavailable and the server is temporarily overloaded or maintained.",
// 	504: "The gateway timed out.",
// };

// export const generatePDFForFakeData = () =>
//   requestWrapper
//     .get("c13s/back/get_documents_links")
//     .then((response) => {
//       console.info("Response Data:", response.data) // Log the response data
//       return response.data
//     })
//     .catch((error) => {
//       throw error
//     })

// generatePDFForFakeData().then((data) => {
//   // Handle the PDF links here if needed
//   console.info("PDF Links:", data)
// })

const randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

export const fakeResponse = (data) =>
  new Promise((resolve) => {
    setTimeout(
      () => {
        resolve(data)
      },
      randomIntFromInterval(200, 600),
    )
  })

export const getCreateNewDiscussionApi = () =>
  requestWrapper
    .get("c13s/back/create_new_discussion")
    .then((response) => response.data)
    .catch((error) => {
      throw error
    })

// use this if you want test the front with fakedata
// export const postSearchRequestApi = () => fakeResponse(searchRequestResponseActualSwagger)

// use this if you want use the search with api
export const postSearchRequestApi = (payload) =>
  requestWrapper
    .post("c13s/back/search_text", payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error
    })

// Post a .wav audio files
export const postAudioSiaGptApi = (formData) =>
  requestWrapper
    .post("c13s/back/search_audio", formData, {
      responseType: "arraybuffer",
      headers: { "Content-Type": "multipart/form-data", accept: "application/speech-to-text" },
    })
    .then(async (res) => res)

export const postSearchStreamingApi = (formData) => {
  return requestWrapper
    .post("c13s/back/search_streaming", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      responseType: "stream",
      adapter: "fetch",
      onUploadProgress: (progressEvent) => {
        console.info("progressEvent", progressEvent)
        // console.info(`Progress: ${progressEvent.loaded} bytes of ${progressEvent.total} bytes`)
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}
