/**
 * NotFound.js
 *
 * The NotFound component handles routes that do not match any existing paths, setting the route type to "Home".
 */

import { useEffect } from "react"
import { useRouteType } from "@/hooks/interface/useRouteType"

const NotFound = () => {
  const { routeType, setRouteType } = useRouteType()

  // Sets Route Type
  useEffect(() => {
    if (routeType !== "Home") {
      setRouteType("Home")
    }
  }, [routeType, setRouteType])

  return
}

export default NotFound
