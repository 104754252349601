/**
 * usePlanet.jsx
 */

import { useContext } from "react"
import { PlanetContext } from "@/context/interface/PlanetContext"

export const usePlanet = () => {
  return useContext(PlanetContext)
}
