/**
 * Aside.js
 *
 * The Aside component provides a container for the 3D planet hub and experience room scene. It integrates authentication data to customize the displayed content.
 *
 * Key functionalities include:
 * - Fetching the user's profile and collectibles using the useAuth hook.
 * - Displaying the Planet3DHub component with user-specific data.
 * - Displaying the Room component to show the current experience scene.
 *
 * The component returns an aside element containing the Planet3DHub and Room components, styled with a specific CSS class.
 */

import { useAuth } from "@/hooks/onboarding/useAuth"
import Planet3DHub from "@/components/Planet3DHub/Planet3DHub.jsx"
import Room from "@/components/Planet3DHub/ExperienceType/Room/Scene.jsx"
import "./aside.scss"

const Aside = () => {
  const { profile, collectibles } = useAuth()

  return (
    <aside className="webgl-container">
      <Planet3DHub
        avatar={profile?.avatar != null}
        collectibles={collectibles != null && collectibles?.length > 0}
      />
      <Room />
    </aside>
  )
}

export default Aside
