/**
 * useAuth.jsx
 */

import { useContext } from "react"
import { AuthContext } from "@/context/onboarding/AuthContext"

export const useAuth = () => {
  return useContext(AuthContext)
}
