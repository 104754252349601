/**
 * Aside.js
 *
 * The Aside component provides a message to users indicating that the current experience is only available
 * on tablets in landscape format and desktop devices. It uses a specific CSS class for styling and rotation effects.
 *
 * Key functionalities include:
 * - Displaying a user message about device and orientation requirements.
 * - Applying styles and rotation effects through CSS classes.
 *
 * The component returns an aside element with a message and appropriate styling.
 */

import "./aside.scss"

const Aside = () => {
  return (
    <aside className="aside rotate">
      This experience is only available on tablets in landscape format and desktop
    </aside>
  )
}

export default Aside
