/**
 * CollectiblesContext.jsx
 * Authentification process
 * States : logged, consented, profile
 */

import { createContext, useState } from "react"
import { useClaimSetup } from "@/hooks/onboarding/useClaimSetup.jsx"

const CollectiblesContext = createContext(null)

const CollectiblesProvider = ({ children }) => {
  const { currentCollections, setCurrentCollections } = useClaimSetup()
  const [currentCollectible, setCurrentCollectible] = useState(null)
  const [currentRewards, setCurrentRewards] = useState(null)

  const onShowCollectibleDetail = (collectible, rewards, collections) => {
    setCurrentCollectible(collectible)
    setCurrentRewards(rewards)
    setCurrentCollections(collections)
  }

  const onCloseCollectibleDetail = () => {
    setCurrentCollectible(null)
    setCurrentRewards(null)
    setCurrentCollections(null)
  }

  return (
    <CollectiblesContext.Provider
      value={{
        currentCollectible,
        setCurrentCollectible,
        currentRewards,
        setCurrentRewards,
        currentCollections,
        onShowCollectibleDetail,
        onCloseCollectibleDetail,
      }}
    >
      {children}
    </CollectiblesContext.Provider>
  )
}

export { CollectiblesContext, CollectiblesProvider }
