precision highp float;

uniform float uTime;

// Emissive uniforms
uniform vec3 uEmissiveColor;
uniform float uEmissiveIntensity;

// Light uniforms
uniform vec3 uDirectionalLightColor1;
uniform vec3 uDirectionalLightDirection1;
uniform vec3 uDirectionalLightColor2;
uniform vec3 uDirectionalLightDirection2;
uniform vec3 uAmbientLightColor;

// Shadow gradient uniforms
uniform bool uIsAdjacent;
uniform float uShadowGradientFactor;
uniform vec3 uSelectedTerritoryPosition;
uniform float uMaxDistance;
uniform float uOffset;

// Glow outline 
uniform bool uIsSelectedTerritory;
uniform float uOutlineThickness;
uniform vec3 uOutlineColor;

// Rim lighting uniforms
uniform vec3 color;
uniform vec3 rimColor;
uniform float rimForce;

// Additional shader properties
uniform float opacity;
varying vec3 vViewPosition;
varying vec3 vNormal;
varying vec3 vWorldPosition;
varying vec3 vPosition;
varying vec2 vUv; 

uniform vec2 uRectangleSize;

void main() {
   // Calculate the strength of the directional lights
   float diffuseStrength1 = max(dot(normalize(vNormal), uDirectionalLightDirection1), 0.0);
   vec3 diffuseColor1 = uDirectionalLightColor1 * diffuseStrength1;

   float diffuseStrength2 = max(dot(normalize(vNormal), uDirectionalLightDirection2), 0.0);
   vec3 diffuseColor2 = uDirectionalLightColor2 * diffuseStrength2;

   // Combine the diffuse components from both lights
   vec3 combinedDiffuse = diffuseColor1 * 0.5 + diffuseColor2 * 0.5;

   // Calculate ambient light contribution
   vec3 ambientComponent = uAmbientLightColor; 

   // Base color with diffuse and ambient lighting
   vec3 baseColor = combinedDiffuse + ambientComponent;

   // Calculate rim lighting
   float rim = 1.0 - dot(normalize(vViewPosition), normalize(vNormal));
   rim = smoothstep(1.0 - rimForce, 1.0, rim);
   vec3 rimLight = mix(color, rimColor, rim);

   // Add Emissive 
   vec3 emissiveColors = uEmissiveColor * uEmissiveIntensity;

   // Mix base color with rim light, using rimForce for control
   vec3 finalColor = mix(baseColor, rimLight, rimForce);
   finalColor += emissiveColors;



if (uIsAdjacent) {
    // Calculate the offset from the selected territory center
    vec2 offsetFromCenter = vWorldPosition.xz - uSelectedTerritoryPosition.xz;

    // Calculate half-width and half-height
    float halfWidth = uRectangleSize.x * 0.05;
    float halfHeight = uRectangleSize.y * 0.1;

    // Calculate distance from the rectangle's edge
    float distX = max(abs(offsetFromCenter.x) - halfWidth, 0.0);
    float distY = max(abs(offsetFromCenter.y) - halfHeight, 0.0);

    // Compute distance from the rectangle edge
    float distanceFromRect = length(vec2(distX, distY));

    // Create a smooth gradient effect near the boundary
    float gradientFactor = smoothstep(0.0, uMaxDistance, distanceFromRect);
    gradientFactor = 1.0 - gradientFactor; // Invert to make gradient stronger near the center

    // Mix gradient factor with timeFactor
    gradientFactor *= clamp(uTime, 0.0, 1.0);

    // Apply the gradient effect to darken the color
    vec3 darkenedColor = mix(finalColor, vec3(0.0), gradientFactor * uShadowGradientFactor);

    // Apply the darkening effect subtly
    finalColor = mix(finalColor, darkenedColor, gradientFactor * 0.1);
}
 
    

   // Final fragment color
   gl_FragColor = vec4(finalColor * rimLight, opacity);

     // Apply sharp outline for selected territory
   // if (uIsSelectedTerritory) {
   //     // Compute the shadow gradient effect
   //      vec2 offsetFromCenter = gl_FragCoord.xy - uOffset;

   //      // Calculate distance from shadow center
   //      float distance = length(offsetFromCenter);

   //      // Create a smooth gradient effect
   //      float gradient = smoothstep(0.0, 1.0, distance);

   //      // Apply shadow color with gradient effect
   //      gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0) * (1.0 - gradient);

  

   // }
}